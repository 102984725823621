import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Main from './layouts/Main';
import NotFound from "./pages/NotFound";
import axios from 'axios';




import publicRoutes from "./routes/PublicRoutes";
import protectedRoutes from "./routes/ProtectedRoutes";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
// import AdminDashboard from '../dashboard/AdminDashboard';
import AdminDashboard from "./dashboard/AdminDashboard";
import EditUser from "./pages/user/edituser";








// import css
import "./assets/css/remixicon.css";

// import scss
import "./scss/style.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EmployeeDashboard from "./dashboard/EmplooyeeDashboard";
import EditEmployee from "./pages/employee/editemployee";

import '././../src/empfile.css';
import EditDepartment from "./pages/hr/department/editdepartment";
import ViewDepartment from "./pages/hr/department/viewdepartment";
import EditHoliday from "./pages/hr/holidays/editholiday";

import EditShift from "./pages/hr/shifts/editshift";
import EditDesignation from "./pages/hr/designation/editdesignation";
// import EditBranch from "./pages/hr/branch/editbranch";
import RequireAuth from "./requireauth";
import Companyprofile from "./pages/company/companyprofile";
import Email from "./pages/Email";
import Jobs from "./apps/jobs";
import FileManager from "./apps/FileManager";
import ViewLeave from "./pages/leaves/viewleave";
import AddLeave from "./pages/leaves/addleave";
import EditLeave from "./pages/leaves/editleave";
import ViewWorkingDays from "./pages/workingdays/viewworkingdays";
import AddWorkingDays from "./pages/workingdays/addworkingdays";
import EditWorkingDays from "./pages/workingdays/editworkingday";
import Employees from "./pages/employee/employees";
import Attendance from "./pages/attendance/attendance";
import AdduserForm from "./pages/user/adduserform";
import EditEmp from "./pages/employee/editemp";
import EmpDepartmentForm from "./pages/employee/empDepartment";
import Viewuser from "./pages/user/viewuser";
import EmployeeForm from "./pages/employee/employeeform";
// import Employeehistory from "./pages/employee/employeehistory";
import EmployeeHistory from "./pages/employee/employeehistory";
import EmployeeBreakhistory from "./pages/employee/break/breakhistory";
import EmployeeBreakstatic from "./pages/employee/break/breakstatics";
import DashbaordBreakCalendar from "./pages/breakemp/dashbreakcalender";
import Employeesalary from "./pages/admin/employeesalary";
import Employeesalaryform from "./pages/admin/empsalaryform";
import Empbreakofflist from "./pages/breakoffemp/empbreakoflist";
import Addsalarysetup from "./pages/empsalarysetup/addsalarysetup";
import Addempsalarysetup from "./pages/empsalarysetup/addempsalarysetup";
import AddBreakOffForm from "./pages/breakoffemp/addbreakoffemp";
import AddEmpLeave from "./pages/employee/empleave/addempleave";
import ViewAddEmpLeave from "./pages/employee/empleave/viewempleave";
import Automateduser from "./pages/automateduser/automateduser";
import Breakreport from "./pages/hr/breakreport/breakreport";
import Changepassword from "./pages/employee/password/changepassword";
import SingleAttendanceView from "./pages/attendance/singleattendanceview";
import Attendancetotallist from "./pages/attendance/attendancetotallist";
import AttendanceTotalList from "./pages/attendance/attendancetotallist";
import EmployeeBreakCalendar from "./pages/breakemp/empcalender";
import Employeebreakdata from "./pages/admin/empbreakdata";
import Salaryreportemp from "./pages/admin/empsalaryreport";
import ViewSalarysetup from "./pages/empsalarysetup/viewsalarysetup";
import ViewempSalarysetup from "./pages/empsalarysetup/viewempsalarysetup";
import ViewCreditleave from "./pages/empsalarysetup/empcreditleave";
import Editsalarysetup from "./pages/empsalarysetup/editsalarysetup";
import Designation from "./pages/hr/designation/viewdesignation";
// import AddDesignation from "../pages/hr/designation/designationform";
// import AddDesignationForm from "./pages/hr/designation/designationform";
import ResignationForm from "./pages/hr/resignation/resignationform";
import AddDepartmentForm from "./pages/hr/department/departmentform";
import Roleform from "./pages/hr/roleform";

import Holidaysform from "./pages/hr/holidays/holidaysform";
import ShiftsForm from "./pages/hr/shifts/shiftsform";
import BranchForm from "./pages/hr/branch/branchform";
import EditBranch from "./pages/hr/branch/editbranch";
import Branch from "./pages/hr/branch/viewbranch";
import Announcement from "./pages/announcement/viewannouncement";
import Editannouncement from "./pages/announcement/editannouncement";
import AnnouncementForm from "./pages/announcement/announcementform";
import Addcompanylogo from "./pages/company/addcompanylogo";
import Employeebreakcount from "./pages/employee/break/empbreakcount";
import Empprofile from "./pages/employee/empprofile";
import ViewResignation from "./pages/hr/resignation/viewresignation";
import EditResignation from "./pages/hr/resignation/editresignation";
import Role from "./pages/hr/role";
import Holidays from "./pages/hr/holidays/holidays";
import Shift from "./pages/hr/shifts/shift";
import Filemanager from "./pages/filemanager";
import FileManageradmin from "./apps/Filemangeradmin";
import Joblist from "./pages/joblist";
import Resume from "./pages/recruitment/resume";
import Resumeform from "./pages/recruitment/resumeform";
import Contractform from "./pages/recruitment/contractform";
import Contract from "./pages/recruitment/contract";
import Candidate from "./pages/recruitment/candidate";
import Candidateform from "./pages/recruitment/candidateform";
import Interviews from "./pages/recruitment/interviews";
import Interviewform from "./pages/recruitment/interviewform";
import Job from "./pages/recruitment/job";
import Profile from "./pages/Profile";
import Breaklist from "./pages/admin/bresklist";
import Settings from "./pages/Settings";
import ChartJs from "./docs/ChartJs";
import Viewsalarysetuptwo from "./pages/empsalarysetup/viewsalarysetuptwo";
import Employeeattendance from "./pages/attendance/employeeattendance";
import Editempattendance from "./pages/attendance/editempattendance";
import EmployeeBreak from "./pages/admin/break/employeebreak";
import ABC from "./pages/hr/breakreport/abc";
import Privacy from "./Privacy";
import StaffSalary from "./pages/admin/StaffSalary";
import SalaryPDF from "./pages/admin/salarypdf";
import InviteCandidate from "./pages/recruitment/inviteCandidate";
import AddApplicantsStatus from "./pages/addApplicantsStatus";
import Applicantstatusform from "./pages/applicantstatusform";
import EditStatus from "./pages/editStatus";
import { viewLogo } from './services/api/api';
import ForgotPassword from './pages/ForgotPassword';
import { ResetPassword } from './pages/ResetPassword';
import { JobListing } from './pages/JobListing';
import { EditJobs } from './pages/recruitment/EditJobs';
import Jobform from './pages/recruitment/jobform';
import EmpLeaveStatus from './pages/employee/empleave/empLeaveStatus';
import AddDesignationForm from './pages/hr/designation/designationform';
import { PendingLeaves } from './pages/employee/empleave/PendingLeaves';
import EditProfile from './pages/employee/editProfile';
import { DeviceInfo } from './pages/admin/deviceInfo';
import { Performance } from './pages/admin/performance';
// import { EmpLeaveStatus } from './pages/employee/empleave/empLeaveStatus';


// set skin on load
window.addEventListener("load", function () {
  let skinMode = localStorage.getItem("skin-mode");
  let HTMLTag = document.querySelector("html");

  if (skinMode) {
    HTMLTag.setAttribute("data-skin", skinMode);
  }
});

const token = sessionStorage.getItem('isLoggedIn');
const isAuthenticated = token ? true : false;

export default function App() {

  const [flag, setFlag] = useState(false)


  const fetchLogoAndSetFavicon = async () => {
    try {
      const logoUrl = await viewLogo(); // Fetch the logo URL
      console.log(logoUrl);

      if (logoUrl) {
        setFlag(!flag)
      }


      let link = document.querySelector("link[rel='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        link.type = 'image/png'; // Specify the image type if needed
        document.head.appendChild(link);
      }
      link.href = `${logoUrl}?t=${new Date().getTime()}`; // Add timestamp to avoid caching issues

    } catch (error) {
      console.error('Error fetching the logo URL:', error);
    }
  };



  useEffect(() => {
    fetchLogoAndSetFavicon();

  }, [])


  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="/privacy" element={<Privacy to="/privacy" />} />
          
          <Route path="/" element={<Main />}>
            <Route path="/" element={<Navigate to="/signin" />} />
            <Route path="/staffSalary" element={<StaffSalary />} />
            <Route path="/admin/dashboard" element={<RequireAuth requiredRole="admin"><AdminDashboard /></RequireAuth>} />
            <Route path="/admin/salarypdf" element={<RequireAuth requiredRole="admin"><SalaryPDF /></RequireAuth>} />

            <Route path="/employee/dashboard" element={<RequireAuth requiredRole="employee"><EmployeeDashboard /></RequireAuth>} />
            {/* <Route path="/pages/department" element={<RequireAuth><ViewDepartment /></RequireAuth>} />
            <Route path="/pages/user/edituser/:userId" element={<RequireAuth><EditUser/></RequireAuth>}></Route>
            <Route path="/pages/employee/editemployee/:id" element={<RequireAuth><EditEmployee/></RequireAuth>}></Route>
            <Route path="/pages/hr/editdepartment/:id/:masterId" element={<RequireAuth><EditDepartment/></RequireAuth>}></Route>
            <Route path="/pages/hr/editdesignation/:id/:masterId" element={<RequireAuth><EditDesignation/></RequireAuth>}></Route>
            <Route path="/pages/hr/editholiday/:id/:masterId" element={<RequireAuth><EditHoliday/></RequireAuth>}></Route>
            <Route path="/pages/hr/editshift/:id/:masterId" element={<RequireAuth><EditShift/></RequireAuth>}></Route> */}
            {/* <Route path="/" element={<Navigate to="/signin" />} /> */}

            <Route path="/pages/department" element={<RequireAuth requiredRole="admin"><ViewDepartment /></RequireAuth>} />
            <Route path="/admin/viewemployeesalarysetup" element={<RequireAuth requiredRole="admin"><Viewsalarysetuptwo /></RequireAuth>} />


            <Route path="/edit_user/:userId" element={<RequireAuth requiredRole="admin"><EditUser /></RequireAuth>}></Route>
            <Route path="/edit_employee/:id" element={<RequireAuth requiredRole="admin"><EditEmployee /></RequireAuth>}></Route>
            <Route path="/edit_emp/:id" element={<RequireAuth requiredRole="admin"><EditEmp /></RequireAuth>}></Route>
            <Route path="/edit_department/:id/:masterId" element={<RequireAuth requiredRole="admin"><EditDepartment /></RequireAuth>}></Route>
            <Route path="/edit_empattendance/:id/:masterId" element={<RequireAuth requiredRole="admin"><Editempattendance /></RequireAuth>}></Route>
            <Route path="/edit_designation/:id/:masterId" element={<RequireAuth requiredRole="admin"><EditDesignation /></RequireAuth>}></Route>
            <Route path="/edit_holiday/:id/:masterId" element={<RequireAuth requiredRole="admin"><EditHoliday /></RequireAuth>}></Route>
            <Route path="/edit_shift/:id/:masterId/:shiftNo" element={<RequireAuth requiredRole="admin"><EditShift /></RequireAuth>}></Route>
            <Route path="/edit_branch/:id/:masterId" element={<RequireAuth requiredRole="admin"><EditBranch /></RequireAuth>}></Route>
            <Route path="/edit_announcement/:id/:mastersId" element={<RequireAuth requiredRole="admin"><Editannouncement /></RequireAuth>}></Route>
            <Route path="/edit_resignation/:id/:masterId" element={<RequireAuth requiredRole="admin"><EditResignation /></RequireAuth>}></Route>
            <Route path="/edit_leave/:id/:masterId" element={<RequireAuth requiredRole="admin"><EditLeave /></RequireAuth>}></Route>
            {/* <Route path="/pages/workingdays/editworkingday/:id/:masterId" element={<EditWorkingDays/>}></Route> */}
            <Route path="/edit_workingday/:id/:masterId" element={<RequireAuth requiredRole="admin"><EditWorkingDays /></RequireAuth>}></Route>
            <Route path="/companyprofile/:companyId" element={<RequireAuth requiredRole="admin"><Companyprofile /></RequireAuth>}></Route>
            <Route path="/deviceinfo" element={<RequireAuth requiredRole="admin"><DeviceInfo /></RequireAuth>}></Route>
            <Route path="/admin/edit_salary_setup/:id/:masterId" element={<RequireAuth requiredRole="admin"><Editsalarysetup /></RequireAuth>}></Route>
            <Route path="/pages/recruitment/editJobs/:id" element={<RequireAuth requiredRole="admin"><EditJobs /></RequireAuth>}></Route>




            <Route path="/companyprofile" element={<RequireAuth requiredRole="admin"><Companyprofile /></RequireAuth>}></Route>

            <Route path="/pages/email" element={<RequireAuth requiredRole="admin"><Email /></RequireAuth>}></Route>
            <Route path="/apps/jobs" element={<RequireAuth requiredRole="admin"><Jobs /></RequireAuth>}></Route>
            <Route path="/apps/file-manager" element={<RequireAuth requiredRole="admin"><FileManager /></RequireAuth>}></Route>

            <Route path="/pages/leave" element={<RequireAuth requiredRole="admin"><ViewLeave /></RequireAuth>}></Route>
            <Route path="/pages/leaves/addleave" element={<RequireAuth requiredRole="admin"><AddLeave /></RequireAuth>}></Route>
            <Route path="/pendingLeaves" element={<RequireAuth requiredRole="admin"><PendingLeaves /></RequireAuth>}></Route>
            <Route path="/edit_leave" element={<RequireAuth requiredRole="admin"><EditLeave /></RequireAuth>}></Route>




            <Route path="/pages/workingdays/viewworkingdays" element={<RequireAuth requiredRole="admin"><ViewWorkingDays /></RequireAuth>}></Route>
            <Route path="/pages/workingdays/addworkingdays" element={<RequireAuth requiredRole="admin"><AddWorkingDays /></RequireAuth>}></Route>



            <Route path="/pages/edit_workingday" element={<RequireAuth requiredRole="admin"><EditWorkingDays /></RequireAuth>}></Route>


            <Route path="/admin/employees" element={<RequireAuth requiredRole="admin"><Employees /></RequireAuth>}></Route>

            <Route path="/pages/attendance" element={<RequireAuth requiredRole="admin"><Attendance /></RequireAuth>}></Route>


            <Route path="/pages/user/userform" element={<RequireAuth requiredRole="admin"><AdduserForm /></RequireAuth>}></Route>



            <Route path="/edit_user" element={<RequireAuth requiredRole="admin"><EditUser /></RequireAuth>}></Route>





            <Route path="/edit_employee" element={<RequireAuth requiredRole="admin"><EditEmployee /></RequireAuth>}></Route>


            <Route path="/edit_emp" element={<RequireAuth requiredRole="admin"><EditEmp /></RequireAuth>}></Route>


            {/* {path: "/companyprofile", element: <Companyprofile />}, */}

            <Route path="/pages/employee/empDepartment" element={<RequireAuth requiredRole="admin"><EmpDepartmentForm /></RequireAuth>}></Route>



            <Route path="/pages/user/viewuser" element={<RequireAuth requiredRole="admin"><Viewuser /></RequireAuth>}></Route>

            <Route path="/admin/employeeform" element={<RequireAuth requiredRole="admin"><EmployeeForm /></RequireAuth>}></Route>
            {/* {path: "admin/employeehistory", element: <Employeehistory /> }, */}
            <Route path="/admin/employeehistory" element={<RequireAuth requiredRole="admin"><EmployeeHistory /></RequireAuth>}></Route>


            <Route path="/breakhistory" element={<RequireAuth><EmployeeBreakhistory /></RequireAuth>}></Route>

            <Route path="/breakstatic" element={<RequireAuth><EmployeeBreakstatic /></RequireAuth>}></Route>

            <Route path="/breakcalender" element={<RequireAuth><DashbaordBreakCalendar /></RequireAuth>}></Route>

            <Route path="/admin/employeesalary" element={<RequireAuth requiredRole="admin"><Employeesalary /></RequireAuth>}></Route>

            <Route path="/admin/employeesalaryform" element={<RequireAuth requiredRole="admin"><Employeesalaryform /></RequireAuth>}></Route>

            <Route path="/admin/breakofflist" element={<RequireAuth requiredRole="admin"><Empbreakofflist /></RequireAuth>}></Route>

            <Route path="/admin/salarysetup" element={<RequireAuth requiredRole="admin"><Addsalarysetup /></RequireAuth>}></Route>

            <Route path="/admin/empsalarysetup" element={<RequireAuth requiredRole="admin"><Addempsalarysetup /></RequireAuth>}></Route>

            <Route path="/admin/breakoffemployees" element={<RequireAuth requiredRole="admin"><AddBreakOffForm /></RequireAuth>}></Route>









            <Route path="/employee/addleave" element={<RequireAuth requiredRole="employee"><AddEmpLeave /></RequireAuth>}></Route>

            <Route path="/pages/empleave/empleave" element={<RequireAuth requiredRole="admin"><ViewAddEmpLeave /></RequireAuth>}></Route>

            <Route path="/automateduser" element={<RequireAuth requiredRole="admin"><Automateduser /></RequireAuth>}></Route>

            <Route path="/employee/break" element={<RequireAuth requiredRole="employee"><Breakreport /></RequireAuth>}></Route>

            <Route path="/employee/changepassword" element={<RequireAuth requiredRole="employee"><Changepassword /></RequireAuth>}></Route>





            <Route path="/employee/attendance" element={<RequireAuth requiredRole="employee"><Attendance /></RequireAuth>}></Route>

            <Route path="/employee/attendanceview" element={<RequireAuth requiredRole="employee"><SingleAttendanceView /></RequireAuth>}></Route>

            <Route path="/admin/pages/totalattendance" element={<RequireAuth requiredRole="admin"><AttendanceTotalList /></RequireAuth>}></Route>
            <Route path="/admin/pages/employeeattendance" element={<RequireAuth requiredRole="admin"><Employeeattendance /></RequireAuth>}></Route>

            <Route path="/admin/pages/employeebreak" element={<RequireAuth requiredRole="admin"><EmployeeBreak /></RequireAuth>}></Route>

            <Route path="/employee/breakview" element={<RequireAuth requiredRole="employee"><EmployeeBreakCalendar /></RequireAuth>}></Route>

            <Route path="/admin/empbreakdata" element={<RequireAuth requiredRole="admin"><Employeebreakdata /></RequireAuth>}></Route>

            <Route path="/employee/salaryreport" element={<RequireAuth requiredRole="employee"><Salaryreportemp /></RequireAuth>}></Route>

            <Route path="/admin/viewempsalary" element={<RequireAuth requiredRole="admin"><ViewSalarysetup /></RequireAuth>}></Route>

            <Route path="/admin/empsalarysetup" element={<RequireAuth requiredRole="admin"><ViewempSalarysetup /></RequireAuth>}></Route>

            <Route path="/admin/viewcreditempleave" element={<RequireAuth requiredRole="admin"><ViewCreditleave /></RequireAuth>}></Route>

            <Route path="/admin/edit_salary_setup" element={<RequireAuth requiredRole="admin"><Editsalarysetup /></RequireAuth>}></Route>





            <Route path="/admin/designation" element={<RequireAuth requiredRole="admin"><Designation /></RequireAuth>}></Route>

            {/* <Route path="/pages/hr/addDesignation" element={<RequireAuth><AddDesignation /></RequireAuth>}></Route> */}

            <Route path="/edit_designation" element={<RequireAuth requiredRole="admin"><EditDesignation /></RequireAuth>}></Route>



            <Route path="/pages/hr/adddesignationform" element={<RequireAuth requiredRole="admin"><AddDesignationForm /></RequireAuth>}></Route>

            <Route path="/emoloyee/resignation" element={<RequireAuth requiredRole="employee"><ResignationForm /></RequireAuth>}></Route>


            <Route path="/pages/hr/editdepartment" element={<RequireAuth requiredRole="admin"><EditDepartment /></RequireAuth>}></Route>

            <Route path="/admin/department" element={<RequireAuth requiredRole="admin"><ViewDepartment /></RequireAuth>}></Route>

            <Route path="/admin/adddepartment" element={<RequireAuth requiredRole="admin"><AddDepartmentForm /></RequireAuth>}></Route>

            <Route path="/pages/hr/roleform" element={<RequireAuth requiredRole="admin"><Roleform /></RequireAuth>}></Route>

            <Route path="/pages/hr/holidaysform" element={<RequireAuth requiredRole="admin"><Holidaysform /></RequireAuth>}></Route>

            <Route path="/edit_holiday" element={<RequireAuth requiredRole="admin"><EditHoliday /></RequireAuth>}></Route>

            <Route path="/pages/hr/shiftsform" element={<RequireAuth requiredRole="admin"><ShiftsForm /></RequireAuth>}></Route>

            <Route path="/pages/hr/editshift" element={<RequireAuth requiredRole="admin"><EditShift /></RequireAuth>}></Route>



            <Route path="/pages/hr/branchform" element={<RequireAuth requiredRole="admin"><BranchForm /></RequireAuth>}></Route>

            <Route path="/edit_branch" element={<RequireAuth requiredRole="admin"><EditBranch /></RequireAuth>}></Route>

            <Route path="/pages/hr/branch" element={<RequireAuth requiredRole="admin"><Branch /></RequireAuth>}></Route>

            <Route path="/admin/announcement" element={<RequireAuth requiredRole="admin"><Announcement /></RequireAuth>}></Route>
            <Route path="/pages/hr/performance" element={<RequireAuth requiredRole="admin"><Performance /></RequireAuth>}></Route>

            <Route path="/edit_announcement" element={<RequireAuth requiredRole="admin"><Editannouncement /></RequireAuth>}></Route>

            <Route path="/pages/announcementform" element={<RequireAuth requiredRole="admin"><AnnouncementForm /></RequireAuth>}></Route>

            <Route path="/companylogo" element={<RequireAuth requiredRole="admin"><Addcompanylogo /></RequireAuth>}></Route>

            <Route path="/employee/empbreaktime" element={<RequireAuth requiredRole="employee"><Employeebreakcount /></RequireAuth>}></Route>





            <Route path="/employee/profile" element={<RequireAuth requiredRole="employee"><Empprofile /></RequireAuth>}></Route>
            <Route path="/employee/editprofile" element={<RequireAuth requiredRole="employee"><EditProfile /></RequireAuth>}></Route>
            <Route path="/employee/empleavestatus" element={<RequireAuth requiredRole="employee"><EmpLeaveStatus /></RequireAuth>}></Route>











            <Route path="/edit_resignation" element={<RequireAuth requiredRole="admin"><EditDesignation /></RequireAuth>}></Route>

            <Route path="/admin/resignation" element={<RequireAuth requiredRole="admin"><ViewResignation /></RequireAuth>}></Route>



            <Route path="/pages/hr/editdepartment" element={<RequireAuth requiredRole="admin"><EditDepartment /></RequireAuth>}></Route>

            <Route path="/admin/department" element={<RequireAuth requiredRole="admin"><ViewDepartment /></RequireAuth>}></Route>

            <Route path="/admin/adddepartment" element={<RequireAuth requiredRole="admin"><AddDepartmentForm /></RequireAuth>}></Route>

            <Route path="/pages/hr/roleform" element={<RequireAuth requiredRole="admin"><Roleform /></RequireAuth>}></Route>

            <Route path="/pages/hr/holidaysform" element={<RequireAuth requiredRole="admin"><Holidaysform /></RequireAuth>}></Route>

            <Route path="/pages/hr/editholiday" element={<RequireAuth requiredRole="admin"><EditHoliday /></RequireAuth>}></Route>

            <Route path="/pages/hr/shiftsform" element={<RequireAuth requiredRole="admin"><ShiftsForm /></RequireAuth>}></Route>

            <Route path="/pages/hr/editshift" element={<RequireAuth requiredRole="admin"><EditShift /></RequireAuth>}></Route>



            <Route path="/pages/hr/branchform" element={<RequireAuth requiredRole="admin"><BranchForm /></RequireAuth>}></Route>

            <Route path="/pages/hr/editbranch" element={<RequireAuth requiredRole="admin"><EditBranch /></RequireAuth>}></Route>

            <Route path="/pages/hr/branch" element={<RequireAuth requiredRole="admin"><Branch /></RequireAuth>}></Route>






            <Route path="/pages/hr/editresignation" element={<RequireAuth requiredRole="admin"><EditResignation /></RequireAuth>}></Route>

            <Route path="/admin/resignation" element={<RequireAuth requiredRole="admin"><ViewResignation /></RequireAuth>}></Route>







            <Route path="/pages/hr/role" element={<RequireAuth><Role /></RequireAuth>}></Route>

            <Route path="/admin/holidays" element={<RequireAuth requiredRole="admin"><Holidays /></RequireAuth>}></Route>

            <Route path="/pages/hr/shift" element={<RequireAuth requiredRole="admin"><Shift /></RequireAuth>}></Route>

            <Route path="/pages/filemanager" element={<RequireAuth requiredRole="admin"><Filemanager /></RequireAuth>}></Route>

            <Route path="/filemanager" element={<RequireAuth requiredRole="admin"><FileManageradmin /></RequireAuth>}></Route>

            <Route path="/pages/roleform" element={<RequireAuth requiredRole="admin"><Roleform /></RequireAuth>}></Route>


            <Route path="/pages/joblist" element={<RequireAuth requiredRole="admin"><Joblist /></RequireAuth>}></Route>

            <Route path="/pages/recruitment/resume" element={<RequireAuth requiredRole="admin"><Resume /></RequireAuth>}></Route>

            <Route path="/pages/recruitment/resumeform" element={<RequireAuth requiredRole="admin"><Resumeform /></RequireAuth>}></Route>

            <Route path="/pages/recruitment/contractform" element={<RequireAuth requiredRole="admin"><Contractform /></RequireAuth>}></Route>


            <Route path="/pages/recruitment/contract" element={<RequireAuth requiredRole="admin"><Contract /></RequireAuth>}></Route>

            <Route path="/pages/recruitment/candidate" element={<RequireAuth requiredRole="admin"><Candidate /></RequireAuth>}></Route>
            <Route path="/pages/recruitment/invitecandidate" element={<RequireAuth requiredRole="admin"><InviteCandidate /></RequireAuth>}></Route>

            <Route path="/pages/recruitment/candidateform" element={<RequireAuth requiredRole="admin">< Candidateform /></RequireAuth>}></Route>

            <Route path="/pages/recruitment/interviews" element={<RequireAuth requiredRole="admin"><Interviews /></RequireAuth>}></Route>

            <Route path="/pages/recruitment/interviewform" element={<RequireAuth requiredRole="admin"><Interviewform /></RequireAuth>}></Route>
            <Route path="/pages/recruitment/job" element={<RequireAuth requiredRole="admin"><Job /></RequireAuth>}></Route>

            <Route path="/pages/recruitment/jobform" element={<RequireAuth requiredRole="admin"><Jobform /></RequireAuth>}></Route>

            <Route path="/pages/profile" element={<RequireAuth requiredRole="admin"><Profile /></RequireAuth>}></Route>





            <Route path="/admin/breaklist" element={<RequireAuth requiredRole="admin"><Breaklist /></RequireAuth>}></Route>






            <Route path="pages/settings" element={<RequireAuth requiredRole="admin"><Settings /></RequireAuth>}></Route>

            <Route path="pages/settings/addapplicantstatus" element={<RequireAuth requiredRole="admin"><AddApplicantsStatus /></RequireAuth>} />
            <Route path="pages/settings/addapplicantstatus/applicantstatusform" element={<RequireAuth requiredRole="admin"><Applicantstatusform /></RequireAuth>} />
            <Route path="pages/settings/addapplicantstatus/editstatusform/:id" element={<RequireAuth requiredRole="admin"><EditStatus /></RequireAuth>} />


            <Route path="/docs/chart/chartj" element={<RequireAuth><ChartJs /></RequireAuth>}></Route>



            {protectedRoutes.map((route, index) => {
              return (
                <Route
                  path={route.path}
                  element={<RequireAuth>{route.element}</RequireAuth>}
                  key={index}
                />
              );
            })}


            {protectedRoutes.map((route, index) => {
              return (
                <Route
                  path={route.path}
                  element={route.element}


                  key={index}
                />
              )
            })}
          </Route>
          {publicRoutes.map((route, index) => {
            return (
              <Route
                path={route.path}
                element={route.element}
                key={index}
              />
            )
          })}
          {/* <Route path="/signin" element={<Signin />} /> */}
          {/* <Route path="/dashboard" element={<AdminDashboard />} /> */}
          <Route path="/forgotPassword" element={<ResetPassword />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/employee/dashboard" element={<EmployeeDashboard />} />
          <Route path="*" element={<NotFound />} />

        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </React.Fragment>

  );
}