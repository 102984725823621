import React, { useState } from 'react'
import HeaderMobile from '../../layouts/HeaderMobile'
import Header from '../../layouts/Header'
import { Card } from 'react-bootstrap'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Tab, Tabs } from '@mui/material'
import { Button, Modal } from 'react-bootstrap'

export const Performance = () => {

    const [value, setValue] = useState('viewCategory');
    const [loading, setLoading] = useState(false);

    const handleTabChange = (event, newValue) => {
        console.log(newValue);
        setValue(newValue);
    };

    return (
        <React.Fragment>
            <HeaderMobile />
            <Header />

            <div className="main p-4 p-lg-5 mt-5">
                <Box sx={{ width: '100%' }}>
                    <Tabs
                        value={value}
                        onChange={handleTabChange}
                        textColor="primary"
                        indicatorColor="primary"
                        aria-label="secondary tabs example"
                    >
                        <Tab value="viewCategory" label="View Category" />
                        <Tab value="addCategory" label="Add Category" />
                    </Tabs>
                </Box>
                <Card style={{ marginTop: '2rem' }}>
                    <Card.Body style={{ marginTop: '1rem' }}>
                        {loading ? (
                            <div className="loader-container">
                                <div className="loader"></div>
                            </div>
                        ) : (
                            value == 'addCategory' ? (
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="mb-3">
                                            <label htmlFor="Category" className="form-label">
                                                Category
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="Category"
                                                name="Category"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="Points" className="form-label">
                                                Points
                                            </label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="Points"
                                                name="Points"
                                            />
                                        </div>
                                    </div>
                                    
                                    <div className="col-md-2">
                                        <Button variant="contained" className="btn btn-primary mt-3" >
                                            Add
                                        </Button>
                                    </div>


                                </div>
                            ) : value == 'viewCategory' ? (
                                < div className="row">
                                    <div className="col-md-12">
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center" style={{ backgroundColor: '#c7c4c4', fontSize: '1rem' }}>
                                                            Sr No.
                                                        </th>
                                                        <th className="text-center" style={{ backgroundColor: '#c7c4c4', fontSize: '1rem' }}>
                                                            Category
                                                        </th>
                                                        <th className="text-center" style={{ backgroundColor: '#c7c4c4', fontSize: '1rem' }}>
                                                            Points
                                                        </th>
                                                        <th className="text-center" style={{ backgroundColor: '#c7c4c4', fontSize: '1rem' }}>
                                                            Actions
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr>
                                                        <td className="text-center" style={{ fontFamily: 'sans-serif', fontWeight: 'bold', fontSize: '1rem' }}>1</td>
                                                        <td className="text-center" style={{ fontFamily: 'sans-serif', fontWeight: 'bold', fontSize: '1rem' }}>Goal Achievement (OKRs)</td>
                                                        <td className="text-center" style={{ fontFamily: 'sans-serif', fontWeight: 'bold', fontSize: '1rem' }}>10</td>
                                                        <td className="text-center">
                                                            <span style={{ cursor: 'pointer' }}><EditIcon /></span>
                                                            <span style={{ cursor: 'pointer' }} ><DeleteIcon /></span>
                                                        </td>
                                                    </tr>

                                                </tbody>

                                            </table>
                                        </div>
                                    </div>
                                </div>
                            ) : null
                        )}

                    </Card.Body>
                </Card>
            </div>
        </React.Fragment >
    )
}
