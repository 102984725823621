import React, { useState, useEffect } from 'react';
import _debounce from 'lodash/debounce';
import Header from '../../layouts/Header';
import HeaderMobile from '../../layouts/HeaderMobile';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addempSalarySetup, salarySetupAuto, searchEmployeeSalarySetup, viewEmployee, viewSalarySetup } from '../../services/api/api';
import { Form, Button, Alert, Modal } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Table } from 'react-bootstrap';
import { DataGrid } from '@mui/x-data-grid';
import { TableCell, TableRow } from '@mui/material';
import { components } from 'react-select';
import Select from 'react-select';

const Addempsalarysetup = (e, params) => {
    const [formData, setFormData] = useState({
        employee_id: '',
        name: '',
        basic: [{ name: '', deduct_type: '', amount: '' }],
        allowences: [{ name: '', deduct_type: '', amount: '' }],
        deductions: [{ name: '', deduct_type: '', amount: '' }],
        structure_id: ''
    });

    const [localTypeSetup, setLocalTypeSetup] = useState({});

    const [existingSetup, setExistingSetup] = useState('');
    const [editCombineGrossData, setEditCombineGrossData] = useState([]);
    const [existingAnnualAmt, setExistingAnnualAmt] = useState(null);
    const [existingMonthlyAmt, setExistingMonthlyAmt] = useState(null);

    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(true);
    const [structureId, setStructureId] = useState('');
    const [salaryStructure, setSalaryStructure] = useState([]);
    const [salaryData, setSalaryData] = useState(null);
    const [autoStructure, setAutoStructure] = useState(null);
    const [salarySetupData, setSalarySetupData] = useState([]);
    const [salaryctc, setSalaryctc] = useState([]);
    const [masterId, setMasterId] = useState();
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [MonthlyAmount, SetMonthlyAmount] = useState([]);
    const [flage, setFlage] = useState(false);
    const [annualAmount, setannualAmount] = useState([]);
    const [Amount, setAmount] = useState([]);
    const [submitsalary, Setsubmitsalary] = useState({
        basic: [],
        allowance: [],
        deduction: []
    });
    const [monthWithoutUpdate, setMonthWithoutUpdate] = useState(0)
    const [annualWithoutUpdate, setAnnualWithoutUpdate] = useState(0)

    const [monthlyAmountTotal, SetMonthlyAmountTotal] = useState(null);
    const [annualAmountTotal, SetAnnualAmountTotal] = useState(null);

    const [salarySetupBtn, SetSalarySetupBtn] = useState(false);
    const [isButtonDisabled, setButtonDisabled] = useState(false);

    const [basicEditedValue, setBasicEditedValue] = useState(null);
    const [allowanceEditedValue, setAllowanceEditedValue] = useState({});
    const [deductionEditedValues, setDeductionEditedValues] = useState({});
    const [monthlyGrossTotal, setMonthlyGrossTotal] = useState(0);


    const [annualGrossTotal, setAnnualGrossTotal] = useState(0);
    const [mergeBasicAlloLength, setMergeBasicAlloLength] = useState(null);
    const [mergeBasicAlloIncentiveIndex, setMergeBasicAlloIncentiveIndex] = useState(null);
    const [basicConvTotal, setBasicConvTotal] = useState(null);


    // const [flage, setFlage] = useState(false);
    const [focus, setFocus] = useState(false);



    const handleSalarySetupBtn = () => {
        SetSalarySetupBtn(!salarySetupBtn);
        if (salarySetupBtn === false) {
            setButtonDisabled(true);
        } else {
            setButtonDisabled(false);
        }
    };
    useEffect(() => {
        handleSalarySetupBtn();
        setMonthlyGrossTotal(0)
        console.log(monthlyGrossTotal);



    }, []);

    const [abc, setAbc] = useState(0);



    const calculateTotals = (items) => {
        let monthlyTotal = 0;
        let annualTotal = 0;

        // console.log(items);
        items.forEach(item => {
            console.log(item);
            monthlyTotal += Number(item.monthly);
            annualTotal += Number(item.Annually);

        });

        return { monthlyTotal, annualTotal };
    };


    const handleTotal = () => {

        if (existingSetup && existingSetup.length > 0) {
            console.log(submitsalary);
            console.log(existingSetup);


            // const basicItems = submitsalary.basic.length > 0 ? submitsalary.basic : existingSetup[0].basic;
            // const allowanceItems = submitsalary.allowance.length > 0 ? submitsalary.allowance : existingSetup[0].allowance;
            // const deductionItems = submitsalary.deduction.length > 0 ? submitsalary.deduction : existingSetup[0].deduction;
            const basicItems = submitsalary.basic.length > 0
                ? submitsalary.basic
                : existingSetup[0].basic;

            // Merge allowance items, preference to submitsalary values if they exist
            const allowanceItems = existingSetup[0].allowance.map(existingAllowance => {
                const submitAllowance = submitsalary.allowance.find(item => item.name === existingAllowance.name);
                // If submitAllowance exists, prefer the submitSalary value
                if (submitAllowance) {
                    return { ...existingAllowance, ...submitAllowance }; // Merge the submit salary value with the existing setup
                }
                return existingAllowance; // If not, keep the existing setup value
            });

            // Merge deduction items, preference to submitsalary values if they exist
            const deductionItems = existingSetup[0].deduction.map(existingDeduction => {
                const submitDeduction = submitsalary.deduction.find(item => item.name === existingDeduction.name);
                // If submitDeduction exists, prefer the submitSalary value
                if (submitDeduction) {
                    return { ...existingDeduction, ...submitDeduction }; // Merge the submit salary value with the existing setup
                }
                return existingDeduction; // If not, keep the existing setup value
            });

            const basicTotals = calculateTotals(basicItems);
            const allowanceTotals = calculateTotals(allowanceItems);
            const deductionTotals = calculateTotals(deductionItems);

            const monthlyTotal = basicTotals.monthlyTotal + allowanceTotals.monthlyTotal - deductionTotals.monthlyTotal;
            const annualTotal = basicTotals.annualTotal + allowanceTotals.annualTotal - deductionTotals.annualTotal;

            // Update state with the calculated totals
            SetMonthlyAmountTotal(monthlyTotal.toFixed(2));
            SetAnnualAmountTotal(annualTotal.toFixed(2));

            // Update localStorage with the calculated totals
            localStorage.setItem('MonthlyAmountTotal', monthlyTotal.toFixed(2));
            localStorage.setItem('AnnualAmountTotal', annualTotal.toFixed(2));

            setFocus(true); // Example update focus state
        } else {
            alert('else')
            console.log(submitsalary);

            // Calculate totals based on submitSalary when existingSetup is not available or empty
            const basicTotals = calculateTotals(submitsalary.basic);
            const allowanceTotals = calculateTotals(submitsalary.allowance);
            const deductionTotals = calculateTotals(submitsalary.deduction);

            const monthlyTotal = basicTotals.monthlyTotal + allowanceTotals.monthlyTotal - deductionTotals.monthlyTotal;
            const annualTotal = basicTotals.annualTotal + allowanceTotals.annualTotal - deductionTotals.annualTotal;

            // Update state with the calculated totals
            SetMonthlyAmountTotal(monthlyTotal.toFixed(2));
            SetAnnualAmountTotal(annualTotal.toFixed(2));

            // Update localStorage with the calculated totals
            localStorage.setItem('MonthlyAmountTotal', monthlyTotal.toFixed(2));
            localStorage.setItem('AnnualAmountTotal', annualTotal.toFixed(2));

            setFocus(true); // Example update focus state
        }
    };

    const navigate = useNavigate();

    useEffect(() => {
        if (salaryData) {
            const initialAnuualAmounts = salaryData.basic.map(() => '');
            setannualAmount(initialAnuualAmounts);

        }
        if (salaryData) {
            const initialMonthlyAmounts = salaryData.basic.map(() => '');
            SetMonthlyAmount(initialMonthlyAmounts);

        }
    }, [salaryData]);

    const AmountChange = (newValue1, rowIndex) => {
        // alert("New value")
        setFocus(true);
        console.log(newValue1);




        const newAmount = [...Amount];
        newAmount[rowIndex] = newValue1;
        setAmount(newAmount);
        setFocus(false);
        // console.log("newValue1", newValue1);
    };

    const handleAmountChange = (newValue1, rowIndex, params, currentType) => {
        setFocus(true);

        console.log(newValue1);
        console.log(currentType);


        let newValue = newValue1
        if (params.row.component == 'PF' && currentType == 'Fixed') {
            newValue = newValue1;
        }
        if (params.row.component == 'PF' && currentType == 'Percentage') {
            if (newValue1 > 1800 * 12) {
                newValue = 1800 * 12;

            }
            else {
                newValue = newValue1
                console.log(newValue);
            }
        }

        const newAnnualAmounts = [...annualAmount];

        console.log(newAnnualAmounts);

        newAnnualAmounts[rowIndex] = newValue;
        setannualAmount(newAnnualAmounts);
        setFocus(false);
    };

    const handleMonthAmountChange = (newValue1, rowIndex, params, currentType) => {
        setFocus(true);
        let newValue = newValue1
        console.log(newValue1);

        if (params.row.component == 'PF' && currentType == 'Fixed') {
            if (newValue1 > 1800) {
                newValue = newValue1;
            }
        }
        if (params.row.component == 'PF' && currentType == 'Percentage') {
            if (newValue1 > 1800) {
                newValue = 1800;
            }
            else {
                newValue = newValue1;
            }
        }

        SetMonthlyAmount((prevMonthlyAmounts) => {
            const newMothAmounts = [...prevMonthlyAmounts];
            newMothAmounts[rowIndex] = newValue; // Set new value
            console.log("Updated Monthly Amounts:", newMothAmounts);
            return newMothAmounts; // Return the updated state
        });

        handleMonthGrossAmount()
    };

    useEffect(() => {
        handleMonthGrossAmount();
        console.log('showing from useEffect', monthlyGrossTotal)
    }, [monthlyGrossTotal]);

    // const handleMonthGrossAmount = () => {
    //     setFocus(true);

    //     sessionStorage.setItem('gross', JSON.stringify(monthlyGrossTotal));

    //     console.log('Calculating gross with Monthly Amounts:', MonthlyAmount);

    //     const newMothAmounts = [...MonthlyAmount];
    //     console.log("Current value:", newMothAmounts);
    //     const total = newMothAmounts.slice(1, mergeBasicAlloLength).reduce((acc, curr) => {
    //         const value = (curr == undefined || curr == null || curr == "") ? 0 : curr;
    //         return acc + value;
    //     }, 0);

    //     console.log(total);

    //     // Subtract the value at mergeBasicAlloIncentiveIndex
    //     const incentiveValue = newMothAmounts[mergeBasicAlloIncentiveIndex] || 0; // Handle case where index might not exist
    //     const finalTotal = total
    //     console.log(finalTotal);
    //     setMonthlyGrossTotal(finalTotal)

    //     return finalTotal;
    // };

    const handleMonthGrossAmount = () => {
        setFocus(true);

        sessionStorage.setItem('gross', JSON.stringify(monthlyGrossTotal));

        console.log('Calculating gross with Monthly Amounts:', MonthlyAmount);

        const newMothAmounts = [...MonthlyAmount];
        console.log("Current values:", newMothAmounts);

        // Ensure mergeBasicAlloLength is at least 2
        // const total = MonthlyAmount.reduce((acc, curr) => {
        //     const value = Number(curr) || 0;

        //     console.log("Current:", curr, "Value to add:", value, "Accumulator:", acc);

        //     return acc + value;
        // }, 0);

        const totalarray = newMothAmounts.slice(1, mergeBasicAlloLength);
        const total = MonthlyAmount.slice(1, mergeBasicAlloLength).reduce((acc, curr) => acc + (curr || 0), 0);

        console.log("Total after reduction:", total);

        // Subtract the value at mergeBasicAlloIncentiveIndex
        const incentiveValue = newMothAmounts[mergeBasicAlloIncentiveIndex] || 0; // Handle case where index might not exist
        const finalTotal = total - incentiveValue; // Subtract incentive value
        console.log("Final total after subtracting incentive value:", finalTotal);

        setMonthlyGrossTotal(finalTotal);

        return finalTotal;
    };


    const handleChange = (e) => {

        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const [editedValue, setEditedValue] = useState(null);



    // const calculateTotalMonthlyAmount = (data) => {
    //     console.log(data);

    //     // return data.reduce((total, companyTime) => {
    //     //     console.log(companyTime);
    //     //     const basicTotal = companyTime.basic.length > 0
    //     //         ? companyTime.basic[0].basic.reduce((sum, item) => sum + (item.monthly || 0), 0)
    //     //         : 0; // If no basic items, total is 0

    //     //     // Calculate total of allowances excluding 'Incentive'
    //     //     const allowanceTotal = companyTime.basic.length > 0 // Ensure basic exists
    //     //         ? companyTime.basic[0].allowance // Accessing the allowance from the first basic item
    //     //             .filter(item => item.name !== "Incentive") // Exclude 'Incentive'
    //     //             .reduce((sum, item) => sum + (item.monthly || 0), 0)
    //     //         : 0; // If no allowances, total is 0

    //     //     console.log(total + basicTotal + allowanceTotal);

    //     //     // Return the total calculated
    //     //     return total + basicTotal + allowanceTotal  // Assuming deduction is subtracted

    //     // }, 0);
    // };

    const calculateTotalMonthlyAmount = (data) => {
        console.log(data);

        // Filter out the 'Incentive' from the data array
        const filteredData = data.filter(item => item.name !== "Incentive");

        // Calculate the total monthly amount
        const totalMonthlyAmount = filteredData.reduce((sum, item) => {
            // Add the monthly value to the sum
            return sum + (item.monthly || 0); // Ensure monthly is defined
        }, 0);

        console.log("Total Monthly Amount (excluding Incentive):", totalMonthlyAmount);
        return totalMonthlyAmount;
    };



    const handleBasicChange = (e, params) => {
        // setFlag(true)

        let inputValue = e.target.value.trim();
        const newValue1 = inputValue;
        if (newValue1 === '' || (!isNaN(newValue1) && parseFloat(newValue1) >= 0)) {
            setBasicEditedValue(newValue1);
            if (params && params.row) {
                let anamount = 0;
                let mamount = 0;
                const existingSetupp = { ...existingSetup[0] };
                console.log("existingSetup", existingSetup);
                const deduct_type = existingSetupp.basic.find(item => item.name === 'BASIC')?.type;

                if (deduct_type == 'Percentage') {
                    anamount = parseFloat((salaryctc * (newValue1 / 100)).toFixed(2));
                    mamount = parseFloat((anamount / 12));
                    if (params.rowNode.id == 1) {

                        setAbc(mamount)
                        setBasicConvTotal(mamount)
                    }
                } else if (deduct_type == 'Fixed') {
                    anamount = parseFloat((newValue1 * 12).toFixed(2));
                    mamount = parseFloat((newValue1));
                    if (params.rowNode.id == 1) {
                        setAbc(mamount)
                        setBasicConvTotal(mamount)
                    }
                }

                handleMonthGrossAmount();

                const basicIndex = submitsalary.basic.findIndex(item => item.name === 'BASIC');
                let updatedItem = {
                    name: 'BASIC',
                    type: deduct_type,
                    amount: newValue1,
                    monthly: mamount,
                    Annually: anamount
                };


                const updatedGrossData = editCombineGrossData.map(item => {
                    // Find the item that matches the updatedItem by name
                    if (item.name === updatedItem.name) {
                        return { ...item, ...updatedItem }; // Replace with the updated item
                    }
                    return item; // Return unchanged items
                });

                // Update the state with the modified array
                setEditCombineGrossData(updatedGrossData);

                // Recalculate the total monthly amount after the update
                const newTotalMonthlyAmount = calculateTotalMonthlyAmount(updatedGrossData);


                let updatedBasicList = [...submitsalary.basic];
                if (basicIndex !== -1) {
                    updatedBasicList[basicIndex] = updatedItem;
                } else {
                    updatedBasicList.push(updatedItem);
                }

                Setsubmitsalary(prevState => ({
                    ...prevState,
                    basic: updatedBasicList
                }));
                console.log(updatedBasicList);

                let existingSetupData = existingSetup[0]
                const mergedBasic = existingSetupData.basic.map(item => {
                    const found = submitsalary.basic.find(i => i.name === item.name);
                    if (typeof item === 'object' && item !== null && typeof found === 'object' && found !== null) {
                        return { ...item, ...found };
                    }
                    return item;
                });

                const mergedAllowance = existingSetupData.allowance



                console.log(existingSetup.allowance);
                console.log(mergedAllowance);

                const updatedCompanySetup = [{ ...existingSetupp, basic: updatedBasicList }];
                let payload = [{

                    basic: updatedCompanySetup,

                }];
                console.log(updatedItem);

                // Recalculate total after updating basic salary
                // const newTotalMonthlyAmount = calculateTotalMonthlyAmount(payload); // Pass the updated setup
                setMonthWithoutUpdate(newTotalMonthlyAmount); // Update the state with new total
                const newTotalAnnualAmount = newTotalMonthlyAmount * 12; // Calculate annual amount
                setAnnualWithoutUpdate(newTotalAnnualAmount);

            }
            handleTotal()
        }
    };

    const handleAllowanceChange = (e, params) => {
        console.log(abc);
        console.log(params);

        // setFlag(true)

        const newValue = e.target.value.trim();
        const rowIndex = params.rowNode.id;

        if (newValue === '' || (!isNaN(newValue) && parseFloat(newValue) >= 0)) {
            setAllowanceEditedValue(prev => ({ ...prev, [rowIndex]: newValue }));

            let anamount = 0;
            let mamount = 0;
            const existingSetupp = { ...existingSetup[0] };
            const deduct_type = existingSetupp.allowance.find(item => item.name === params.row.name)?.type;
            console.log(deduct_type);

            if (deduct_type == 'Percentage') {

                anamount = parseFloat((((newValue / 100) * abc) * 12).toFixed(2));
                mamount = parseFloat((anamount / 12));
                if (params.row.component == "Conv. All") {
                    let basicConvTotal = abc + mamount
                    console.log(basicConvTotal);

                    setBasicConvTotal(basicConvTotal);
                }
            } else if (deduct_type === 'Fixed') {
                anamount = parseFloat((newValue * 12).toFixed(2));
                mamount = parseFloat((newValue));
                if (params.row.component == "Conv. All") {
                    let basicConvTotal = abc + mamount
                    console.log(basicConvTotal);

                    setBasicConvTotal(basicConvTotal);
                }
            }

            // handleAmountChange(anamount, params.rowNode.id, params);
            // handleMonthAmountChange(mamount, params.rowNode.id, params);
            // handleMonthGrossAmount();

            const allowanceIndex = submitsalary.allowance.findIndex(item => item.name === params.row.name);
            const updatedItem = {
                name: params.row.name,
                type: deduct_type,
                amount: newValue,
                monthly: mamount,
                Annually: anamount
            };

            const updatedGrossData = editCombineGrossData.map(item => {
                // Find the item that matches the updatedItem by name
                if (item.name === updatedItem.name) {
                    return { ...item, ...updatedItem }; // Replace with the updated item
                }
                return item; // Return unchanged items
            });

            // Update the state with the modified array
            setEditCombineGrossData(updatedGrossData);

            // Recalculate the total monthly amount after the update
            const newTotalMonthlyAmount = calculateTotalMonthlyAmount(updatedGrossData);

            console.log("changed allowance", newTotalMonthlyAmount);


            let updatedAllowanceList = [...submitsalary.allowance];
            if (allowanceIndex !== -1) {
                updatedAllowanceList[allowanceIndex] = updatedItem;
            } else {
                updatedAllowanceList.push(updatedItem);
            }

            Setsubmitsalary(prevState => ({
                ...prevState,
                allowance: updatedAllowanceList
            }));

            console.log(updatedAllowanceList);

            handleTotal()


        }
    };

    const handleDeductionChange = (e, params) => {
        // setFlag(true)

        console.log(params);

        let newValue = e.target.value.trim();
        const rowIndex = params.rowNode.id;

        if (newValue === '' || (!isNaN(newValue) && parseFloat(newValue) >= 0)) {
            setDeductionEditedValues(prev => ({ ...prev, [rowIndex]: newValue }));

            let anamount = 0;
            let mamount = 0;
            const existingSetupp = { ...existingSetup[0] };

            const deduct_type = existingSetupp.deduction.find(item => item.name === params.row.name)?.type;

            console.log(deduct_type);

            if (deduct_type == 'Percentage') {
                // Deduction logic for PF
                console.log(basicConvTotal);

                if (params.row.component == 'PF') {

                    if (basicConvTotal > 15000 && newValue != 0) {
                        anamount = 1800 * 12;
                        mamount = 1800;
                    }
                    if (basicConvTotal < 15000 && newValue != 0) {
                        mamount = (newValue / 100) * basicConvTotal;
                        anamount = mamount * 12;
                    }
                    else if (newValue == 0) {
                        anamount = 0;
                        mamount = 0;
                    }
                    // else {
                    //     anamount = parseFloat((((newValue / 100) * basicConvTotal) * 12).toFixed(2));
                    //     mamount = parseFloat((anamount / 12).toFixed(2));
                    //     console.log(anamount);
                    //     console.log(mamount);

                    //     if (mamount > 1800) {
                    //         anamount = 1800 * 12;
                    //         mamount = 1800;
                    //     } else {
                    //         anamount = anamount;
                    //         mamount = mamount;
                    //     }
                    // }
                    // alert(anamount, mamount)
                    handleAmountChange(anamount, params.rowNode.id, params);
                    handleMonthAmountChange(mamount, params.rowNode.id, params);
                    const updatedMonthlyGrossTotal = calculateTotalMonthlyAmount(editCombineGrossData);
                }

                if (params.row.component == 'ESI') {
                    const updatedMonthlyGrossTotal = calculateTotalMonthlyAmount(editCombineGrossData);
                    console.log(updatedMonthlyGrossTotal);
                    if (updatedMonthlyGrossTotal > 21000) {
                        anamount = 0;
                        mamount = 0;
                    } else {
                        if (newValue != 0) {
                            mamount = parseFloat(((newValue / 100) * updatedMonthlyGrossTotal).toFixed(2));
                            anamount = parseFloat((mamount * 12).toFixed(2));
                        }
                        if (newValue == 0) {
                            mamount = parseFloat(0);
                            anamount = parseFloat(0);
                        }
                        mamount = parseFloat(((newValue / 100) * updatedMonthlyGrossTotal).toFixed(2));
                        anamount = parseFloat((mamount * 12).toFixed(2));
                    }
                }
                if (params.row.component == 'Proff. Tax') {
                    const updatedMonthlyGrossTotal = calculateTotalMonthlyAmount(editCombineGrossData);
                    if (updatedMonthlyGrossTotal < 12000) {
                        anamount = 0;
                        mamount = 0;
                    } else {
                        mamount = parseFloat(200);
                        anamount = parseFloat(2400);
                    }
                }

            } else if (deduct_type === 'Fixed') {
                // Similar fixed deduction logic for PF, ESI, and Proff. Tax
                if (params.row.component == 'PF') {
                    if (newValue > 1800) {
                        // toast.error('PF should be less or equal to 1800');
                        newValue = newValue;
                    }
                    anamount = parseFloat((newValue * 12).toFixed(2));
                    mamount = parseFloat(newValue);
                }
                if (params.row.component == 'ESI') {
                    const updatedMonthlyGrossTotal = calculateTotalMonthlyAmount(editCombineGrossData);
                    console.log(updatedMonthlyGrossTotal);
                    if (updatedMonthlyGrossTotal > 21000) {
                        anamount = 0;
                        mamount = 0;
                    } else {
                        mamount = parseFloat(((newValue)));
                        anamount = parseFloat((mamount * 12).toFixed(2));
                    }
                }
                if (params.row.component == 'Proff. Tax') {
                    const updatedMonthlyGrossTotal = calculateTotalMonthlyAmount(editCombineGrossData);
                    if (updatedMonthlyGrossTotal < 12000) {
                        anamount = 0;
                        mamount = 0;
                    } else {
                        if (newValue == 0) {
                            anamount = 0;
                            mamount = 0;
                        }
                        if (newValue != 0) {
                            mamount = parseFloat(200);
                            anamount = parseFloat(2400);
                        }
                    }
                }
                handleAmountChange(anamount, params.rowNode.id, params);
                handleMonthAmountChange(mamount, params.rowNode.id, params);
            }

            const deductionIndex = submitsalary.deduction.findIndex(item => item.name === params.row.name);
            const updatedItem = {
                name: params.row.name,
                type: deduct_type,
                amount: newValue,
                monthly: mamount,
                Annually: anamount
            };

            let updatedDeductionList = [...submitsalary.deduction];
            if (deductionIndex !== -1) {
                updatedDeductionList[deductionIndex] = updatedItem;
            } else {
                updatedDeductionList.push(updatedItem);
            }

            Setsubmitsalary(prevState => ({
                ...prevState,
                deduction: updatedDeductionList
            }));

            console.log(updatedDeductionList);
        }
        handleTotal()
    };



    let salaryval = [];



    useEffect(() => {
        const debouncedFetchexistData = _debounce(fetchExistingSetup, 100);

        debouncedFetchexistData();

        return () => {
            debouncedFetchexistData.cancel();
        };
    }, [formData.employee_id, structureId, flage]);


    const fetchExistingSetup = async () => {
        try {
            setLoading(true);
            const response = await searchEmployeeSalarySetup(formData.employee_id, structureId);
            console.log("searchEmployeeSalarySetup", response);

            if (response && response.data) {
                setExistingSetup(response.data);

                console.log(response.data);

                const combinedEditBasicAndAllowance = [
                    ...response.data[0].basic,
                    ...response.data[0].allowance
                ];

                console.log('Combined Basic and Allowance:', combinedEditBasicAndAllowance);
                setEditCombineGrossData(combinedEditBasicAndAllowance)


                const totalMonthlyAmount = calculateTotalMonthlyAmount(combinedEditBasicAndAllowance);
                console.log('Total Monthly Amount:', totalMonthlyAmount);




                setExistingMonthlyAmt(response.data[0].totalMonthlyAmount)
                console.log(response.data[0].totalMonthlyAmount);

                setExistingAnnualAmt(response.data[0].totalAnualAmount)
                console.log(abc);

                setAbc(response.data[0].basic[0].monthly)
                let basicConvTotal = response.data[0].basic[0].monthly + combinedEditBasicAndAllowance[3].monthly
                setBasicConvTotal(basicConvTotal)
                console.log(basicConvTotal);

                setMonthWithoutUpdate(totalMonthlyAmount);

                let totalAnnualAmount = totalMonthlyAmount * 12
                setAnnualWithoutUpdate(totalAnnualAmount);



            } else if (response && response.message === "Employee Salary structure Not Found") {
                setExistingSetup(response.message === "Employee Salary structure Not Found");
            }
        } catch (error) {
            console.error("Error fetching salary setup:", error);
        } finally {
            setLoading(false);
        }
    };


    const fetchempData = async () => {
        try {
            const formData = {};
            const response = await viewEmployee(formData);
            if (!response || !response.success || !response.data) {
                throw new Error('Failed to fetch data.');
            }

            setEmployees(response.data);
        } catch (error) {
            console.error(error);
            toast.error('Failed to fetch employees.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const debouncedFetchEmpData = _debounce(fetchempData, 100);

        debouncedFetchEmpData();

        return () => {
            debouncedFetchEmpData.cancel();
        };
    }, []);

    const handleEmployeeChange = async (e, empId) => {
        let selectedEmployeeId = e.target.value;

        const employee = employees.find(emp => emp && emp.employeeData && emp.employeeData._id === parseInt(selectedEmployeeId));

        setSelectedEmployee(employee ? employee.employeeData : null);
        console.log('before try ')
        try {
            console.log('before api')
            const responseData = await salarySetupAuto(selectedEmployeeId);
            console.log('after api')

            if (responseData.data[0].Salary_Structure) {

                setSalaryData(responseData.data[0].Salary_Structure[0])
            }
            else {
                setSalaryData(responseData.data[0].emp_salary_master)
            }
            setAutoStructure(responseData.val);
            console.log(salarySetupData);
            const matchingStructure = salarySetupData[0].Salary_Structure.find(
                structure => structure.name == responseData.val
            );
            const salaryStructure = salarySetupData[0].Salary_Structure; // Assuming this is your array
            const matchingIndex = salaryStructure.findIndex(
                structure => structure.name === responseData.val
            );
            console.log(matchingIndex);

            if (responseData.data[0].Salary_Structure) {
                let mergeBasicAllo;

                try {
                    const selectedStructure = responseData.data[0].Salary_Structure[0];
                    if (selectedStructure) {
                        mergeBasicAllo = [...selectedStructure.basic, ...selectedStructure.allowence];
                        console.log(mergeBasicAllo);
                        const incentiveIndex = mergeBasicAllo.findIndex(item => item.name == "Incentive");
                        console.log("Index of Incentive:", incentiveIndex);
                        setMergeBasicAlloIncentiveIndex(incentiveIndex + 1)
                        const mergeBasicAlloLength = mergeBasicAllo.length
                        console.log("length of mergeBasicAllo:", mergeBasicAlloLength);
                        setMergeBasicAlloLength(mergeBasicAlloLength)
                    }
                }
                catch {

                }
            }


            try {
                setLoading(true);
                const response = await searchEmployeeSalarySetup(selectedEmployeeId, matchingIndex);
                console.log("searchEmployeeSalarySetup", response);

                if (response && response.data) {
                    setExistingSetup(response.data);

                    console.log(response.data);

                    const combinedEditBasicAndAllowance = [
                        ...response.data[0].basic,
                        ...response.data[0].allowance
                    ];

                    console.log('Combined Basic and Allowance:', combinedEditBasicAndAllowance);
                    setEditCombineGrossData(combinedEditBasicAndAllowance)


                    const totalMonthlyAmount = calculateTotalMonthlyAmount(combinedEditBasicAndAllowance);
                    console.log('Total Monthly Amount:', totalMonthlyAmount);




                    setExistingMonthlyAmt(response.data[0].totalMonthlyAmount)
                    console.log(response.data[0].totalMonthlyAmount);

                    setExistingAnnualAmt(response.data[0].totalAnualAmount)
                    console.log(abc);

                    setAbc(response.data[0].basic[0].monthly)
                    let basicConvTotal = response.data[0].basic[0].monthly + combinedEditBasicAndAllowance[3].monthly
                    setBasicConvTotal(basicConvTotal)
                    console.log(basicConvTotal);

                    setMonthWithoutUpdate(totalMonthlyAmount);

                    let totalAnnualAmount = totalMonthlyAmount * 12
                    setAnnualWithoutUpdate(totalAnnualAmount);



                } else if (response && response.message === "Employee Salary structure Not Found") {
                    setExistingSetup(response.message === "Employee Salary structure Not Found");
                }
            } catch (error) {
                console.error("Error fetching salary setup:", error);
            } finally {
                setLoading(false);
            }


            // Log the object if it exists.
            if (matchingStructure) {
                console.log('Matching Salary Structure:', matchingStructure);
            } else {
                console.log('No matching Salary Structure found');
            }
            // setAutoStructure(responseData.val)

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (selectedEmployee) {
            setSalaryctc(selectedEmployee.salary);
        }
    }, [selectedEmployee]);


    const handleStructureChange = async (e) => {

        const { name, value } = e.target
        console.log(salarySetupData);

        const struct_id = salarySetupData[0].Salary_Structure
        const salaryStructuresArray = Object.values(struct_id);

        console.log(salaryStructuresArray);
        setSalaryStructure(salaryStructuresArray)


        let b = salaryStructuresArray.map((structure) => structure._id);
        console.log(b);


        handleSalarySetupBtn();
        console.log(name, value);

        const selectedStructureId = e.target.value;
        console.log(selectedStructureId);
        setStructureId(selectedStructureId);

        setLoading(true); // Set loading to true when the structure change starts 
        let structure = salarySetupData.find(structure => structure.Salary_Structure[selectedStructureId]);
        let Structure = structure.Salary_Structure[selectedStructureId];
        let mergeBasicAllo;
        try {
            const selectedStructure = salarySetupData.find(structure => structure.Salary_Structure[selectedStructureId]);
            if (selectedStructure) {
                setSalaryData(selectedStructure.Salary_Structure[selectedStructureId]);
                console.log(selectedStructure.Salary_Structure[selectedStructureId]);
                mergeBasicAllo = [...Structure.basic, ...Structure.allowence];
                console.log(mergeBasicAllo);
                const incentiveIndex = mergeBasicAllo.findIndex(item => item.name === "Incentive");
                console.log("Index of Incentive:", incentiveIndex);
                setMergeBasicAlloIncentiveIndex(incentiveIndex + 1)
                const mergeBasicAlloLength = mergeBasicAllo.length
                console.log("length of mergeBasicAllo:", mergeBasicAlloLength);
                setMergeBasicAlloLength(mergeBasicAlloLength)
            }

            // Call fetchExistingSetup to refresh the data
            await fetchExistingSetup();
        } finally {
            setLoading(false); // Ensure loading is set to false when the operation completes
        }
    };

    useEffect(() => {
        console.log(localTypeSetup); // Logs updated state whenever it changes
    }, [localTypeSetup]);


    // const handleTypeChange = (rowId, newValue, rowType) => {
    //     console.log(params);


    //     if (existingSetup) {

    //         const updatedSetup = [...existingSetup]; // Copy your existing data
    //         if (rowType == 'Basic') {
    //             const item = updatedSetup[0].basic.find(item => item.name == 'BASIC');
    //             if (item) {
    //                 item.type = newValue; // Update type
    //             }
    //             console.log(updatedSetup);

    //         } else if (rowType == 'Allowance') {
    //             const item = updatedSetup[0].allowance.find(item => item.name == rowId);
    //             if (item) {
    //                 item.type = newValue; // Update type
    //             }
    //         } else if (rowType == 'Deduction') {
    //             const item = updatedSetup[0].deduction.find(item => item.name == rowId);
    //             if (item) {
    //                 item.type = newValue; // Update type
    //             }
    //         }
    //         setExistingSetup(updatedSetup);
    //         // handleShow();
    //     }
    //     // else {
    //     //     setLocalTypeSetup(prevState => ({
    //     //         ...prevState,
    //     //         [rowId]: newValue
    //     //     }));
    //     // }

    // };

    const handleTypeChange = (rowId, newValue, rowType, params) => {
        console.log("Handling Type Change for:", rowId, newValue, rowType, params);

        toast.warning(`Kindly update the amount of ${params.row.name}`, {
            position: "top-center",
            autoClose: 3000,
        })

        if (existingSetup) {
            // Copy the existing setup to avoid mutating state directly
            const updatedSetup = [...existingSetup];

            // Find the correct item based on rowType and rowId
            let item;
            if (rowType === 'Basic') {
                item = updatedSetup[0].basic.find(item => item.name == 'BASIC');
            } else if (rowType == 'Allowance') {
                item = updatedSetup[0].allowance.find(item => item.name == rowId);
            } else if (rowType == 'Deduction') {
                item = updatedSetup[0].deduction.find(item => item.name == rowId);
            }

            // If the item is found, update its type and set amount to 0
            if (item) {
                item.type = newValue;  // Update type to the new value
                item.monthly = 0;  // Set the amount (e.g., monthly) to 0
                item.amount = 0;  // Set the amount (e.g., monthly) to 0
                item.Annually = 0;  // Set the amount (e.g., monthly) to 0

                console.log("Updated Item:", item);
            }

            // Set the updated setup to state
            setExistingSetup(updatedSetup);

            // Optionally, handle any other necessary state updates or logic here
            // e.g., resetting localTypeSetup if necessary

        } else {
            // In case `existingSetup` is not available or valid
            console.error("Existing setup not available.");
        }
    };



    const handleTypeChangenonExisting = (rowId, newValue, rowType, params) => {
        console.log(params);
        console.log(rowType);
        const stringRowId = String(rowId);

        setLocalTypeSetup(prevState => ({
            ...prevState,
            [stringRowId]: newValue
        }));


    };

    const fetchData = async () => {
        try {
            const responseData = await viewSalarySetup();

            if (!responseData || !responseData.data) {
                throw new Error('Failed to fetch data.');
            }

            setSalarySetupData(responseData.data);
            setMasterId(responseData.data[0]._id);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const calculateAnnualSalary = (salary) => {
        return parseInt(salary) / 12;
    };

    useEffect(() => {
        const debouncedFetchData = _debounce(fetchData, 100);

        debouncedFetchData();

        return () => {
            debouncedFetchData.cancel();
        };
    }, []);

    // const handleAddSalarySetup = async (e) => {
    //     e.preventDefault();

    //     console.log(submitsalary);

    //     const hasEmptyAmount = [
    //         ...submitsalary.basic,
    //         ...submitsalary.allowance,
    //         ...submitsalary.deduction
    //     ].some(item => item.amount == '' || item.amount == undefined || item.amount < 0);

    //     console.log(hasEmptyAmount);


    //     if (hasEmptyAmount == true) {
    //         toast.error('', {
    //         // toast.error('Fill all amount fields. Enter 0 if no value is needed', {
    //             position: "top-center",
    //             autoClose: 5000,
    //         });
    //         return; // Prevent form submission
    //     }
    //     try {
    //         let payload;

    //         if (existingSetup.length > 0) {
    //             const existingSetupp = { ...existingSetup[0] };

    //             // Merge existing data with updated data
    //             const mergedBasic = existingSetupp.basic.map(item => {
    //                 const found = submitsalary.basic.find(i => i.name === item.name);
    //                 return found ? { ...item, ...found } : item;
    //             });

    //             const mergedAllowance = existingSetupp.allowance.map(item => {
    //                 const found = submitsalary.allowance.find(i => i.name === item.name);
    //                 return found ? { ...item, ...found } : item;
    //             });

    //             const mergedDeduction = existingSetupp.deduction.map(item => {
    //                 const found = submitsalary.deduction.find(i => i.name === item.name);
    //                 return found ? { ...item, ...found } : item;
    //             });

    //             // Add any new entries that were not in the existing setup
    //             submitsalary.basic.forEach(item => {
    //                 if (!mergedBasic.find(i => i.name === item.name)) {
    //                     mergedBasic.push(item);
    //                 }
    //             });

    //             submitsalary.allowance.forEach(item => {
    //                 if (!mergedAllowance.find(i => i.name === item.name)) {
    //                     mergedAllowance.push(item);
    //                 }
    //             });

    //             submitsalary.deduction.forEach(item => {
    //                 if (!mergedDeduction.find(i => i.name === item.name)) {
    //                     mergedDeduction.push(item);
    //                 }
    //             });

    //             // Create payload with merged data
    //             payload = {
    //                 name: salaryData.name,
    //                 salary_structure_id: structureId,
    //                 basic: mergedBasic,
    //                 allowences: mergedAllowance,
    //                 deductions: mergedDeduction,
    //                 employee_id: formData.employee_id,
    //                 totalAnualAmount: annualAmountTotal,
    //                 totalMonthlyAmount: monthlyAmountTotal,
    //             };
    //         } else {

    //             const newHasEmptyAmount = [
    //                 ...submitsalary.basic,
    //                 ...submitsalary.allowance,
    //                 ...submitsalary.deduction
    //             ].some(item => item.amount === '' || item.amount === undefined || item.amount < 0);

    //             // Show error if any amount field is empty for new submission
    //             if (newHasEmptyAmount == false) {
    //                 toast.error('Fill all amount fields. Enter 0 if no value is needed', {
    //                     position: "top-center",
    //                     autoClose: 5000,
    //                 });
    //                 return; // Prevent form submission
    //             }

    //             // Create payload with new data directly
    //             payload = {
    //                 name: salaryData.name,
    //                 salary_structure_id: structureId,
    //                 basic: submitsalary.basic,
    //                 allowences: submitsalary.allowance,
    //                 deductions: submitsalary.deduction,
    //                 employee_id: formData.employee_id
    //             };
    //         }

    //         // Log payload to verify its contents
    //         console.log("Payload:", JSON.stringify(payload, null, 2));

    //         const response = await addempSalarySetup(formData.employee_id, structureId, payload);
    //         console.log("response", response);
    //         if (response) {
    //             toast.success(response.message);

    //             // Only reset form fields except employee_id
    //             setFormData(prevState => ({
    //                 ...prevState,
    //                 name: '',
    //                 salary_structure_id: '',
    //                 basic: [],
    //                 allowences: [],
    //                 deductions: []
    //             }));
    //             // Call fetchExistingSetup after successful submission
    //             fetchExistingSetup();
    //         }
    //     } catch (error) {
    //         // toast.error('An error occurred while adding the salary setup.');
    //         toast.error('Something went wrong')
    //     }


    //     navigate("/admin/empsalarysetup");

    // };

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleAddSalarySetup = async (e) => {
        e.preventDefault();

        console.log(submitsalary);

        // Check for empty amounts in all sections


        try {
            let payload;



            if (existingSetup.length > 0) {

                const hasEmptyAmount = [
                    ...submitsalary.basic,
                    ...submitsalary.allowance,
                    ...submitsalary.deduction
                ].some(item => item.amount === '' || item.amount === undefined);

                console.log(hasEmptyAmount);
                console.log(submitsalary);


                // Show error if any amount field is empty
                if (hasEmptyAmount) {
                    toast.error('Fill all amount fields. Enter 0 if no value is needed', {
                        position: "top-center",
                        autoClose: 5000,
                    });
                    return; // Prevent form submission
                }
                else {
                    const existingSetupData = { ...existingSetup[0] };

                    console.log(existingSetupData);

                    // Merge existing data with updated data
                    // const mergedBasic = existingSetupData.basic.map(item => {
                    //     const found = submitsalary.basic.find(i => i.name === item.name);
                    //     console.log(...item, ...found);

                    //     return found ? { ...item, ...found } : item;
                    // });
                    let mergedBasic
                    if (existingSetupData.basic && Array.isArray(existingSetupData.basic) && submitsalary.basic && Array.isArray(submitsalary.basic)) {
                        // Merge existing data with updated data
                        mergedBasic = existingSetupData.basic.map(item => {
                            const found = submitsalary.basic.find(i => i.name === item.name);

                            // Log items for debugging
                            console.log('Item:', item, 'Found:', found);

                            return found ? { ...item, ...found } : item;
                        })
                        console.log(mergedBasic);
                    };

                    const mergedAllowance = existingSetupData.allowance.map(item => {
                        const found = submitsalary.allowance.find(i => i.name === item.name);
                        return found ? { ...item, ...found } : item;
                    });

                    const mergedDeduction = existingSetupData.deduction.map(item => {
                        const found = submitsalary.deduction.find(i => i.name === item.name);
                        return found ? { ...item, ...found } : item;
                    });

                    // Add any new entries that were not in the existing setup
                    submitsalary.basic.forEach(item => {
                        if (!mergedBasic.find(i => i.name === item.name)) {
                            mergedBasic.push(item);
                        }
                    });

                    submitsalary.allowance.forEach(item => {
                        if (!mergedAllowance.find(i => i.name === item.name)) {
                            mergedAllowance.push(item);
                        }
                    });

                    submitsalary.deduction.forEach(item => {
                        if (!mergedDeduction.find(i => i.name === item.name)) {
                            mergedDeduction.push(item);
                        }
                    });

                    // Create payload with merged data
                    payload = {
                        name: salaryData.name,
                        // salary_structure_id: '0',
                        salary_structure_id: salaryData.salary_structure_id || '0',
                        basic: mergedBasic,
                        allowences: mergedAllowance,
                        deductions: mergedDeduction,
                        employee_id: formData.employee_id,
                        totalAnualAmount: annualAmountTotal,
                        totalMonthlyAmount: monthlyAmountTotal,
                    };

                    console.log(payload);

                }

            }

            // else {
            //     console.log(submitsalary);

            //     // Check if all arrays are empty
            //     const allArraysEmpty = submitsalary.basic.length === 0 &&
            //         submitsalary.allowance.length === 0 &&
            //         submitsalary.deduction.length === 0;

            //     // Validation for new submissions
            //     const newHasEmptyAmount = [
            //         ...submitsalary.basic,
            //         ...submitsalary.allowance,
            //         ...submitsalary.deduction
            //     ].some(item => item.amount === '' || item.amount === undefined || item.amount < 0);

            //     console.log('All arrays empty:', allArraysEmpty);
            //     console.log('New has empty amount:', newHasEmptyAmount);

            //     // Show error if any amount field is empty or all arrays are empty for new submission
            //     if (allArraysEmpty || newHasEmptyAmount) {
            //         toast.error('Fill all amount fields. Enter 0 if no value is needed', {
            //             position: "top-center",
            //             autoClose: 5000,
            //         });
            //         return; // Prevent form submission
            //     } else {
            //         console.log(submitsalary);
            //     }

            //     // For new submissions, use submitsalary directly
            //     payload = {
            //         name: salaryData.name,
            //         salary_structure_id: structureId,
            //         basic: submitsalary.basic,
            //         allowences: submitsalary.allowance,
            //         deductions: submitsalary.deduction,
            //         employee_id: formData.employee_id
            //     };
            // }



            // Log payload to verify its contents

            else {
                console.log(submitsalary);

                // Check if all arrays are empty
                const allArraysEmpty = submitsalary.basic.length === 0 &&
                    submitsalary.allowance.length === 0 &&
                    submitsalary.deduction.length === 0;

                const expectedBasicLength = salaryData.basic.length;
                const expectedAllowanceLength = salaryData.allowence.length; // Make sure this matches the spelling in your data
                const expectedDeductionLength = salaryData.deduction.length;

                // Validation for new submissions
                // const newHasEmptyAmount = [
                //     ...submitsalary.basic,
                //     ...submitsalary.allowance,
                //     ...submitsalary.deduction
                // ].some(item => {
                //     // Ensure that item.amount exists and is not empty or less than 0
                //     const isAmountInvalid = !item.amount || item.amount === '';
                //     console.log(`Checking item: ${JSON.stringify(item)} - Invalid: ${isAmountInvalid}`);
                //     return isAmountInvalid; // Return true if any amount is invalid
                // });
                let a = [
                    ...submitsalary.basic,
                    ...submitsalary.allowance,
                    ...submitsalary.deduction
                ]
                console.log(a);


                const lengthsMatch = submitsalary.basic.length == expectedBasicLength &&
                    submitsalary.allowance.length == expectedAllowanceLength &&
                    submitsalary.deduction.length == expectedDeductionLength;

                console.log('All arrays empty:', allArraysEmpty);
                // console.log('New has empty amount:', newHasEmptyAmount);
                console.log('Lengths match:', lengthsMatch);
                console.log('Lengths match:', submitsalary.basic.length == expectedBasicLength);
                console.log('Lengths match:', submitsalary.allowance.length == expectedAllowanceLength);
                console.log('Lengths match:', submitsalary.deduction.length == expectedDeductionLength);

                // Show error if any amount field is empty or all arrays are empty for new submission
                if (allArraysEmpty || !lengthsMatch) {
                    toast.error('Fill all amount fields. Enter 0 if no value is needed', {
                        position: "top-center",
                        autoClose: 5000,
                    });
                    return; // Prevent form submission
                } else {
                    console.log(submitsalary);
                }

                // For new submissions, use submitsalary directly
                payload = {
                    name: salaryData.name,
                    salary_structure_id: '0',
                    basic: submitsalary.basic,
                    allowences: submitsalary.allowance,
                    deductions: submitsalary.deduction,
                    employee_id: formData.employee_id
                };
            }



            console.log("Payload:", payload)
            // console.log("Payload:", JSON.stringify(payload, null, 2));

            // Now perform the actual submission with validated payload
            const response = await addempSalarySetup(formData.employee_id, structureId, payload);
            console.log("response", response);
            if (response) {
                toast.success(response.message);

                // Only reset form fields except employee_id
                setFormData(prevState => ({
                    ...prevState,
                    name: '',
                    // salary_structure_id: '',
                    basic: [],
                    allowences: [],
                    deductions: []
                }));
                SetMonthlyAmount([])
                setannualAmount([])
                // Call fetchExistingSetup after successful submission
                fetchExistingSetup();
                window.location.reload();
                navigate("/admin/empsalarysetup");
            }
        } catch (error) {
            toast.error('Something went wrong');
            console.log(error);
        }

    };




    const getMonthlyAmount = (params) => {
        const { type, name } = params.row;
        const rowIndex = params.rowNode.id;

        switch (type) {
            case 'Basic':
                return getValue(submitsalary.basic, existingSetup[0].basic, 'BASIC', basicEditedValue, 'monthly');
            case 'Allowance':
                return getValue(submitsalary.allowance, existingSetup[0].allowance, name, allowanceEditedValue, 'monthly');
            case 'Deduction':
                return getValue(submitsalary.deduction, existingSetup[0].deduction, name, deductionEditedValues[rowIndex], 'monthly');
            default:
                return 0;
        }
    };

    const getValue = (submitData, existingData, itemName, editedValue, key) => {
        const editedItem = submitData.find(item => item.name === itemName);
        const existingItem = existingData.find(item => item.name === itemName);

        let value = 0;

        if (editedValue !== null && editedItem) {
            value = parseFloat(editedItem[key]) || 0;
        } else if (existingItem) {
            value = parseFloat(existingItem[key]) || 0;
        }

        return value;
    };

    const getAnnualAmount = (params) => {
        const { type, name } = params.row;
        const rowIndex = params.rowNode.id;

        switch (type) {
            case 'Basic':
                return getValue(submitsalary.basic, existingSetup[0].basic, 'BASIC', basicEditedValue, 'Annually');
            case 'Allowance':
                return getValue(submitsalary.allowance, existingSetup[0].allowance, name, allowanceEditedValue, 'Annually');
            case 'Deduction':
                return getValue(submitsalary.deduction, existingSetup[0].deduction, name, deductionEditedValues[rowIndex], 'Annually');
            default:
                return 0;
        }
    };




    return (
        <React.Fragment>
            <HeaderMobile />
            <Header />
            <div className="main p-4 p-lg-5 mt-5">
                <div className="mb-4 border-bottom text-center">
                    {/* <h3 className="mb-0 pb- border-bottom custome-btn">Add Salary Setup</h3> */}
                </div>
                <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                    <div>
                        <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                            <li className="breadcrumb-item ">
                                <Link to="/admin/dashboard">Dashboard</Link>
                            </li>
                            <li className="breadcrumb-item active"><Link to="/admin/empsalarysetup">Salary Setup</Link></li>
                            <li className="breadcrumb-item active">Lists</li>
                        </ol>
                    </div>
                    {/* <nav aria-label="breadcrumb">
                        <Link to="/admin/empsalarysetup" className="btn btn-danger mb-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                            </svg>
                            Back
                        </Link>
                    </nav> */}
                </div>
                <div className="form-container">
                    <div className="row"
                        style={{
                            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                            borderRadius: "10px",
                            overflow: "hidden",
                        }}
                    >
                        <div className="col-md-12 formcontrols">
                            <div className="bg-white p-4 rounded" style={{ height: "100%" }}>
                                <Form onSubmit={handleAddSalarySetup}>



                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <Form.Group>
                                                <Form.Label>Employee Name:</Form.Label>
                                                <Select
                                                    name="employee_id"
                                                    value={
                                                        employees.find((emp) => emp.employeeData._id === formData.employee_id)
                                                            ? {
                                                                value: formData.employee_id,
                                                                label: employees.find((emp) => emp.employeeData._id === formData.employee_id).employeeData.first_name +
                                                                    ' ' +
                                                                    employees.find((emp) => emp.employeeData._id === formData.employee_id).employeeData.last_name,
                                                            }
                                                            : null
                                                    }
                                                    onChange={(selectedOption) => {
                                                        handleChange({ target: { name: "employee_id", value: selectedOption.value } });
                                                        handleEmployeeChange({ target: { name: "employee_id", value: selectedOption.value } });
                                                    }}
                                                    options={employees.map((employee) => ({
                                                        value: employee.employeeData._id,
                                                        label: `${employee.employeeData.first_name} ${employee.employeeData.last_name}`,
                                                    }))}
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            borderColor: '#e0e0e0', // Apply the grey border color here
                                                            boxShadow: 'none', // Remove focus outline if needed
                                                            '&:hover': {
                                                                borderColor: '#bdbdbd', // Optional: change border color on hover
                                                            },
                                                        }),
                                                    }}
                                                    placeholder="Select Employee"
                                                    // isClearable
                                                    isSearchable
                                                />
                                            </Form.Group>
                                        </div>
                                        {/* <div className="col-md-6 mb-3">
                                          <Form.Group>
                                                <Form.Label>Select Salary Structure:</Form.Label>
                                                <Select
                                                    name="structureId"
                                                    // value={
                                                    //     salarySetupData.length > 0 &&
                                                    //         salarySetupData[0].Salary_Structure &&
                                                    //         salarySetupData[0].Salary_Structure[structureId]
                                                    //         ?
                                                    //         {
                                                    //             value: structureId,
                                                    //             label: salarySetupData[0].Salary_Structure[structureId].name,
                                                    //         }
                                                    //         : null
                                                    // }
                                                    value={
                                                        autoStructure && salarySetupData.length > 0 && salarySetupData[0].Salary_Structure
                                                            ? {
                                                                value: autoStructure._id,
                                                                label: autoStructure.name,
                                                            }
                                                            : null
                                                    }
                                                    onChange={(selectedOption) =>
                                                        handleStructureChange({ target: { name: "structureId", value: selectedOption.value } })
                                                    }
                                                    options={
                                                        salarySetupData.length > 0 && salarySetupData[0].Salary_Structure
                                                            ? Object.keys(salarySetupData[0].Salary_Structure).map((key) => ({
                                                                value: key,
                                                                label: salarySetupData[0].Salary_Structure[key].name,
                                                            }))
                                                            : []
                                                    }
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            borderColor: '#e0e0e0', // Apply the grey border color here
                                                            boxShadow: 'none', // Remove focus outline if needed
                                                            '&:hover': {
                                                                borderColor: '#bdbdbd', // Optional: change border color on hover
                                                            },
                                                        }),
                                                    }}
                                                    placeholder="Select Salary Structure"
                                                    // isClearable
                                                    isSearchable
                                                />
                                            </Form.Group>
                                        </div> */}

                                    </div>


                                    <div>
                                        <div className="row">
                                            <h3>Payroll Summary</h3>
                                            <div className="row">
                                                {formData.employee_id && (
                                                    <div className="row">
                                                        <div className="col-md-2 mb-2">
                                                            <div className="earning-item">
                                                                <div className="earning-icon bg-primary"><i className="ri-medal-2-line"></i></div>
                                                                {/* <h4><span>₹</span>{parseInt(salaryctc).toLocaleString()}</h4> */}
                                                                <h4>
                                                                    <span>₹</span>
                                                                    {existingAnnualAmt
                                                                        ? parseInt(existingAnnualAmt)
                                                                        : parseInt(salaryctc).toLocaleString()}
                                                                </h4>

                                                                <label>Annual CTC</label>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-2 mb-2">
                                                            <div className="earning-item">
                                                                <div className="earning-icon bg-primary"><i className="ri-medal-2-line"></i></div>
                                                                {/* <h4><span>₹</span>{calculateAnnualSalary(salaryctc).toFixed(2).toLocaleString()}</h4> */}
                                                                <h4>
                                                                    <span>₹</span>
                                                                    {existingMonthlyAmt
                                                                        ? parseFloat(calculateAnnualSalary(existingAnnualAmt)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                                        : parseFloat(calculateAnnualSalary(salaryctc)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                </h4>

                                                                <label>Monthly CTC</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                            </div>

                                        </div>

                                        <div className="row">
                                            <div class="col-md-6 col-xl-12">
                                                <div class="card card-one">
                                                    <div class="card-header">
                                                        <h6 class="card-title">Employee Salary Setup</h6>

                                                    </div>
                                                    <div class="card-body">
                                                        {loading ? (
                                                            <div className="loader-container">
                                                                <div className="loader"></div>
                                                            </div>
                                                        ) : existingSetup && existingSetup.length > 0 ? (
                                                            <>
                                                                {existingSetup && existingSetup.length > 0 ? (

                                                                    <DataGrid
                                                                        rows={[
                                                                            ...existingSetup[0].basic.map((item, index) => ({ ...item, id: index + 1, type: 'Basic' })),
                                                                            ...existingSetup[0].allowance.map((item, index) => ({ ...item, id: existingSetup[0].basic.length + index + 1, type: 'Allowance' })),
                                                                            ...existingSetup[0].deduction.map((item, index) => ({ ...item, id: existingSetup[0].basic.length + existingSetup[0].allowance.length + index + 1, type: 'Deduction' }))
                                                                        ]}
                                                                        columns={[
                                                                            { field: 'id', headerName: 'S.No.', flex: 0, width: 10 },
                                                                            { field: 'type', headerName: 'TYPE', flex: 1 },
                                                                            // { field: 'name', headerName: 'COMPONENTS', flex: 1 },
                                                                            { field: 'name', headerName: 'Name', flex: 1 },
                                                                            { field: 'component', headerName: 'COMPONENTS', flex: 1 },
                                                                            // without dropdown 
                                                                            // {
                                                                            //     field: 'calculationtype',
                                                                            //     headerName: 'CALCULATION TYPE',
                                                                            //     flex: 1,
                                                                            //     renderCell: (params) => (
                                                                            //         <span
                                                                            //         >
                                                                            //             {params.row.type === 'Basic' && (
                                                                            //                 <span>{existingSetup[0].basic.find(item => item.name === 'BASIC')?.type}</span>
                                                                            //             )}
                                                                            //             {params.row.type === 'Allowance' && (
                                                                            //                 <span>{existingSetup[0].allowance.find(item => item.name === params.row.name)?.type}</span>
                                                                            //             )}
                                                                            //             {params.row.type === 'Deduction' && (
                                                                            //                 <span>{existingSetup[0].deduction.find(item => item.name === params.row.name)?.type}</span>
                                                                            //             )}
                                                                            //         </span>
                                                                            //     ),
                                                                            // },
                                                                            // with dropdown 
                                                                            {
                                                                                field: 'calculationtype',
                                                                                headerName: 'CALCULATION TYPE',
                                                                                flex: 1,
                                                                                renderCell: (params) => {
                                                                                    let currentType = localTypeSetup[params.row.name];
                                                                                    const isReadOnly = params.row.type == 'Basic';
                                                                                    if (params.row.type === 'Basic') {
                                                                                        currentType = existingSetup[0].basic.find(item => item.name === 'BASIC')?.type || 'Fixed';
                                                                                    } else if (params.row.type === 'Allowance') {
                                                                                        currentType = existingSetup[0].allowance.find(item => item.name === params.row.name)?.type || 'Fixed';
                                                                                    } else if (params.row.type === 'Deduction') {
                                                                                        currentType = existingSetup[0].deduction.find(item => item.name === params.row.name)?.type || 'Fixed';
                                                                                    }

                                                                                    return (
                                                                                        <select
                                                                                            value={currentType}
                                                                                            onChange={(e) => handleTypeChange(params.row.name, e.target.value, params.row.type, params)}
                                                                                            style={{
                                                                                                // fontWeight: 'bold',
                                                                                                fontFamily: 'sans-serif',
                                                                                                padding: '0.3rem',
                                                                                                color: '#000000de',
                                                                                                border: 'none',
                                                                                                backgroundColor: 'transparent',

                                                                                            }}
                                                                                            disabled={isReadOnly}
                                                                                        >
                                                                                            <option value="Fixed">Fixed</option>
                                                                                            <option value="Percentage">Percentage</option>
                                                                                        </select>
                                                                                    );
                                                                                },
                                                                            },
                                                                            // {
                                                                            //     field: 'amount',
                                                                            //     headerName: 'AMOUNT',
                                                                            //     flex: 1,
                                                                            //     renderCell: (params) => (

                                                                            //         < TableCell >
                                                                            //             <input
                                                                            //                 type="text"

                                                                            //                 value={
                                                                            //                     params.row.type == 'Basic' ? (basicEditedValue !== null ? basicEditedValue.toString() : params.value) :
                                                                            //                         params.row.type == 'Allowance' ? (allowanceEditedValue[params.rowNode.id] !== undefined ? allowanceEditedValue[params.rowNode.id].toString() : params.value) :
                                                                            //                             params.row.type == 'Deduction' ? (deductionEditedValues[params.rowNode.id] !== undefined ? deductionEditedValues[params.rowNode.id].toString() : params.value) :
                                                                            //                                 params.value
                                                                            //                 }
                                                                            //                 onChange={(e) => {
                                                                            //                     if (params.row.type == 'Basic') {
                                                                            //                         handleBasicChange(e, params);
                                                                            //                     }
                                                                            //                     if (params.row.type == 'Allowance') {
                                                                            //                         handleAllowanceChange(e, params);
                                                                            //                     }
                                                                            //                     if (params.row.type == 'Deduction') {
                                                                            //                         handleDeductionChange(e, params);
                                                                            //                     }
                                                                            //                 }}
                                                                            //                 style={{
                                                                            //                     width: '100%',
                                                                            //                     boxSizing: 'border-box',
                                                                            //                     padding: '8px',
                                                                            //                     border: 'none',
                                                                            //                     outline: 'none',
                                                                            //                     fontFamily: 'inherit',
                                                                            //                     fontSize: 'inherit',
                                                                            //                     backgroundColor: '#f9f9f9',
                                                                            //                     color: '#333',
                                                                            //                 }}
                                                                            //             />
                                                                            //         </TableCell>
                                                                            //     ),
                                                                            // },
                                                                            {
                                                                                field: 'amount',
                                                                                headerName: 'AMOUNT',
                                                                                flex: 1,
                                                                                renderCell: (params) => {
                                                                                    // console.log(params); 
                                                                                    return (
                                                                                        <TableCell>
                                                                                            <input
                                                                                                type="text"
                                                                                                value={
                                                                                                    params.row.type == 'Basic' ?
                                                                                                        (basicEditedValue !== null ? basicEditedValue.toString() : params.value) :
                                                                                                        params.row.type == 'Allowance' ?
                                                                                                            (allowanceEditedValue[params.rowNode.id] !== undefined ? allowanceEditedValue[params.rowNode.id].toString() : params.value) :
                                                                                                            params.row.type == 'Deduction' ?
                                                                                                                (deductionEditedValues[params.rowNode.id] !== undefined ? deductionEditedValues[params.rowNode.id].toString() : params.value) :
                                                                                                                params.value
                                                                                                }
                                                                                                onChange={(e) => {
                                                                                                    if (params.row.type == 'Basic') {
                                                                                                        handleBasicChange(e, params);
                                                                                                    }
                                                                                                    if (params.row.type == 'Allowance') {
                                                                                                        handleAllowanceChange(e, params);
                                                                                                    }
                                                                                                    if (params.row.type == 'Deduction') {
                                                                                                        handleDeductionChange(e, params);
                                                                                                    }
                                                                                                }}
                                                                                                style={{
                                                                                                    width: '100%',
                                                                                                    boxSizing: 'border-box',
                                                                                                    padding: '8px',
                                                                                                    border: 'none',
                                                                                                    outline: 'none',
                                                                                                    fontFamily: 'inherit',
                                                                                                    fontSize: 'inherit',
                                                                                                    backgroundColor: '#f9f9f9',
                                                                                                    color: '#333',
                                                                                                }}
                                                                                            />
                                                                                        </TableCell>
                                                                                    );
                                                                                },
                                                                            },

                                                                            {
                                                                                field: 'monthlyamount',
                                                                                headerName: 'MONTHLY AMOUNT',
                                                                                flex: 1,
                                                                                renderCell: (params) => (
                                                                                    <TableCell style={{ width: '100%', textAlign: 'center' }}>
                                                                                        <span
                                                                                            style={{
                                                                                                display: 'block',
                                                                                                padding: '8px',
                                                                                                fontFamily: 'inherit', // Example: use font family from parent container
                                                                                                fontSize: 'inherit', // Example: use font size from parent container
                                                                                                backgroundColor: '#f0f0f0', // Example: custom background color
                                                                                                color: '#333', // Example: custom text color
                                                                                                border: 'none',
                                                                                                borderRadius: '4px',
                                                                                            }}
                                                                                        >
                                                                                            {getMonthlyAmount(params).toFixed(2)}
                                                                                        </span>
                                                                                    </TableCell>
                                                                                ),
                                                                            },
                                                                            {
                                                                                field: 'annualamount',
                                                                                headerName: 'ANNUAL AMOUNT',
                                                                                flex: 1,
                                                                                renderCell: (params) => (
                                                                                    <TableCell style={{ width: '100%', textAlign: 'center' }}>
                                                                                        <span
                                                                                            style={{
                                                                                                display: 'block',
                                                                                                padding: '8px',
                                                                                                fontFamily: 'inherit', // Example: use font family from parent container
                                                                                                fontSize: 'inherit', // Example: use font size from parent container
                                                                                                backgroundColor: '#f0f0f0', // Example: custom background color
                                                                                                color: '#333', // Example: custom text color
                                                                                                border: 'none',
                                                                                                borderRadius: '4px',
                                                                                            }}
                                                                                        >
                                                                                            {getAnnualAmount(params).toFixed(2)}
                                                                                        </span>
                                                                                    </TableCell>
                                                                                ),
                                                                            },
                                                                        ]}
                                                                        autoHeight
                                                                        headerClassName="custom-header-color"
                                                                        className="datagrid-root"
                                                                    />


                                                                ) : null}
                                                            </>


                                                        ) : (
                                                            salaryData && (
                                                                <DataGrid
                                                                    rows={[
                                                                        ...salaryData.basic.map((item, index) => ({ ...item, id: index + 1, type: 'Basic' })),
                                                                        ...salaryData.allowence.map((item, index) => ({ ...item, id: salaryData.basic.length + index + 1, type: 'Allowance' })),
                                                                        ...salaryData.deduction.map((item, index) => ({ ...item, id: salaryData.basic.length + salaryData.allowence.length + index + 1, type: 'Deduction' }))
                                                                    ]}



                                                                    columns={[
                                                                        { field: 'id', headerName: 'S.No.', flex: 0, width: 10 },
                                                                        { field: 'type', headerName: 'TYPE', flex: 1 },
                                                                        { field: 'name', headerName: 'Name', flex: 1 },
                                                                        { field: 'component', headerName: 'COMPONENTS', flex: 1 },
                                                                        // { field: 'deduct_type', headerName: 'CALCULATION TYPE', flex: 1 },
                                                                        {
                                                                            field: 'calculationtype',
                                                                            headerName: 'CALCULATION TYPE',
                                                                            flex: 1,
                                                                            renderCell: (params) => {
                                                                                const currentType = localTypeSetup[params.row.name];
                                                                                const isReadOnly = params.row.type == 'Basic';
                                                                                return (
                                                                                    <select
                                                                                        value={currentType}
                                                                                        onChange={(e) => handleTypeChangenonExisting(params.row.name, e.target.value, params.row.type, params)}
                                                                                        style={{
                                                                                            // fontWeight: 'bold',
                                                                                            fontFamily: 'sans-serif',
                                                                                            padding: '0.3rem',
                                                                                            color: '#000000de',
                                                                                            border: 'none',
                                                                                            backgroundColor: 'transparent',
                                                                                        }}
                                                                                        disabled={isReadOnly}

                                                                                    >
                                                                                        <option value="Fixed">Fixed</option>
                                                                                        <option value="Percentage">Percentage</option>
                                                                                    </select>
                                                                                );
                                                                            },
                                                                        },
                                                                        {
                                                                            field: 'amount',
                                                                            headerName: 'AMOUNT',
                                                                            flex: 1,
                                                                            renderCell: (params) => (
                                                                                <TableCell>
                                                                                    <Form.Control
                                                                                        type="text"
                                                                                        value={params.value}
                                                                                        // onKeyPress={(e) => {
                                                                                        //     // Allow only digits (0-9)
                                                                                        //     if (!/[0-9]/.test(e.key)) {
                                                                                        //         e.preventDefault(); // Prevent the input if it's not a number
                                                                                        //     }
                                                                                        // }}
                                                                                        onChange={(e) => {
                                                                                            let inputValue = e.target.value
                                                                                            let newValue = parseFloat(inputValue);

                                                                                            if (isNaN(newValue) || newValue < 0) return;
                                                                                            console.log(params);

                                                                                            let anamount = 0;
                                                                                            let mamount = 0;
                                                                                            const currentType = localTypeSetup[params.row.name] || 'Fixed';

                                                                                            if (params.row.type === 'Basic') {
                                                                                                // setFlag(true)
                                                                                                if (params.row.deduct_type === 'Percentage') {
                                                                                                    anamount = parseFloat((salaryctc * (newValue / 100)).toFixed(2));
                                                                                                    mamount = parseFloat((anamount / 12).toFixed(2));
                                                                                                    if (params.rowNode.id == 1) {
                                                                                                        setAbc(mamount)
                                                                                                        setBasicConvTotal(mamount)
                                                                                                    }
                                                                                                    handleAmountChange(anamount, params.rowNode.id, params);
                                                                                                    handleMonthAmountChange(mamount, params.rowNode.id, params);
                                                                                                    handleMonthGrossAmount();
                                                                                                }

                                                                                                if (params.row.deduct_type === 'Fixed') {
                                                                                                    anamount = parseFloat((newValue * 12).toFixed(2));
                                                                                                    mamount = parseFloat((newValue).toFixed(2));
                                                                                                    if (params.rowNode.id == 1) {
                                                                                                        setAbc(mamount)
                                                                                                        setBasicConvTotal(mamount)
                                                                                                    }
                                                                                                    handleAmountChange(anamount, params.rowNode.id, params);
                                                                                                    handleMonthAmountChange(mamount, params.rowNode.id, params);
                                                                                                    handleMonthGrossAmount();
                                                                                                }

                                                                                                const basicIndex = submitsalary.basic.findIndex(item => item.name === 'BASIC');

                                                                                                if (basicIndex !== -1) {
                                                                                                    const updatedBasic = {
                                                                                                        ...submitsalary.basic[basicIndex],
                                                                                                        amount: newValue,
                                                                                                        monthly: mamount,
                                                                                                        Annually: anamount
                                                                                                    };

                                                                                                    const updatedList = [...submitsalary.basic];
                                                                                                    updatedList[basicIndex] = updatedBasic;
                                                                                                    Setsubmitsalary(prevState => ({
                                                                                                        ...prevState,
                                                                                                        basic: updatedList
                                                                                                    }));
                                                                                                } else {
                                                                                                    const newBasic = {
                                                                                                        name: 'BASIC',
                                                                                                        type: currentType ? currentType : params.row.deduct_type,
                                                                                                        // type: params.row.deduct_type,
                                                                                                        component: params.row.component,
                                                                                                        amount: newValue,
                                                                                                        monthly: mamount,
                                                                                                        Annually: anamount
                                                                                                    };
                                                                                                    Setsubmitsalary(prevState => ({
                                                                                                        ...prevState,
                                                                                                        basic: [...prevState.basic, newBasic]
                                                                                                    }));
                                                                                                }
                                                                                                handleMonthGrossAmount()
                                                                                            }

                                                                                            else if (params.row.type == 'Allowance') {
                                                                                                // setFlag(true)
                                                                                                // if (params.row.deduct_type === 'Percentage') {
                                                                                                if (currentType == 'Percentage') {

                                                                                                    anamount = parseFloat((((newValue / 100) * abc) * 12).toFixed(2));
                                                                                                    mamount = parseFloat((anamount / 12).toFixed(2));
                                                                                                    if (params.row.component == "Conv. All") {
                                                                                                        let basicConvTotal = abc + mamount
                                                                                                        console.log(basicConvTotal);

                                                                                                        setBasicConvTotal(basicConvTotal);
                                                                                                    }

                                                                                                    handleAmountChange(anamount, params.rowNode.id, params);
                                                                                                    handleMonthAmountChange(mamount, params.rowNode.id, params);
                                                                                                    handleMonthGrossAmount();
                                                                                                }

                                                                                                // if (params.row.deduct_type === 'Fixed') {
                                                                                                if (currentType == 'Fixed') {
                                                                                                    anamount = parseFloat((newValue * 12).toFixed(2));
                                                                                                    mamount = parseFloat((newValue).toFixed(2));
                                                                                                    if (params.row.component == "Conv. All") {
                                                                                                        let basicConvTotal = abc + mamount
                                                                                                        console.log(basicConvTotal);

                                                                                                        setBasicConvTotal(basicConvTotal);
                                                                                                    }
                                                                                                    handleAmountChange(anamount, params.rowNode.id, params);
                                                                                                    handleMonthAmountChange(mamount, params.rowNode.id, params);
                                                                                                    handleMonthGrossAmount();
                                                                                                }
                                                                                                handleMonthGrossAmount()

                                                                                                const allowanceIndex = submitsalary.allowance.findIndex(item => item.name === params.row.name);

                                                                                                if (allowanceIndex !== -1) {
                                                                                                    const updatedAllowance = {
                                                                                                        ...submitsalary.allowance[allowanceIndex],
                                                                                                        amount: newValue,
                                                                                                        monthly: mamount,
                                                                                                        Annually: anamount
                                                                                                    };

                                                                                                    const updatedList = [...submitsalary.allowance];
                                                                                                    updatedList[allowanceIndex] = updatedAllowance;
                                                                                                    Setsubmitsalary(prevState => ({
                                                                                                        ...prevState,
                                                                                                        allowance: updatedList
                                                                                                    }));
                                                                                                } else {
                                                                                                    const newAllowance = {
                                                                                                        name: params.row.name,
                                                                                                        type: currentType ? currentType : params.row.deduct_type,
                                                                                                        component: params.row.component,
                                                                                                        amount: newValue,
                                                                                                        monthly: mamount,
                                                                                                        Annually: anamount
                                                                                                    };
                                                                                                    Setsubmitsalary(prevState => ({
                                                                                                        ...prevState,
                                                                                                        allowance: [...prevState.allowance, newAllowance]
                                                                                                    }));
                                                                                                }
                                                                                            }

                                                                                            else if (params.row.type == 'Deduction') {
                                                                                                console.log(params);
                                                                                                // setFlag(true)
                                                                                                handleMonthGrossAmount()
                                                                                                console.log(currentType);

                                                                                                // if (params.row.deduct_type == 'Percentage') {
                                                                                                if (currentType == 'Percentage') {
                                                                                                    if (params.row.component == 'PF') {

                                                                                                        anamount = parseFloat((((newValue / 100) * basicConvTotal) * 12).toFixed(2));
                                                                                                        mamount = parseFloat((anamount / 12).toFixed(2));
                                                                                                        console.log(newValue);
                                                                                                        console.log(anamount);
                                                                                                        console.log(mamount);
                                                                                                        handleAmountChange(anamount, params.rowNode.id, params, currentType);
                                                                                                        handleMonthAmountChange(mamount, params.rowNode.id, params, currentType);
                                                                                                    }
                                                                                                    if (anamount > 1800 * 12) {
                                                                                                        anamount = 1800 * 12;
                                                                                                        mamount = 1800
                                                                                                        newValue = 1800 / (1800 * 12) * 100;
                                                                                                        console.log(newValue);
                                                                                                        handleAmountChange(anamount, params.rowNode.id, params, currentType);
                                                                                                        handleMonthAmountChange(mamount, params.rowNode.id, params, currentType);

                                                                                                    }
                                                                                                    const updatedMonthlyGrossTotal = handleMonthGrossAmount();
                                                                                                    handleMonthGrossAmount()
                                                                                                    if (params.row.component == 'ESI') {
                                                                                                        if (updatedMonthlyGrossTotal > 21000) {
                                                                                                            anamount = 0;
                                                                                                            mamount = 0;
                                                                                                            console.log(anamount, mamount);
                                                                                                            handleAmountChange(anamount, params.rowNode.id, params, currentType);
                                                                                                            handleMonthAmountChange(mamount, params.rowNode.id, params, currentType);
                                                                                                            handleMonthGrossAmount()
                                                                                                        }
                                                                                                        else {
                                                                                                            if (newValue != 0) {
                                                                                                                mamount = parseFloat(((newValue / 100) * updatedMonthlyGrossTotal).toFixed(2));
                                                                                                                anamount = parseFloat((mamount * 12).toFixed(2));
                                                                                                                handleAmountChange(anamount, params.rowNode.id, params, currentType);
                                                                                                                handleMonthAmountChange(mamount, params.rowNode.id, params, currentType);
                                                                                                                handleMonthGrossAmount()
                                                                                                            }
                                                                                                            if (newValue == 0) {
                                                                                                                handleAmountChange(0, params.rowNode.id, params, currentType);
                                                                                                                handleMonthAmountChange(0, params.rowNode.id, params, currentType);
                                                                                                                handleMonthGrossAmount()
                                                                                                            }
                                                                                                        }
                                                                                                    }

                                                                                                }
                                                                                                if (currentType == 'Fixed') {
                                                                                                    if (params.row.component == 'PF' && newValue > 1800) {
                                                                                                        // toast.error('PF should be less or equal to 1800')
                                                                                                        // newValue = 1800;
                                                                                                        anamount = parseFloat((newValue * 12).toFixed(2));
                                                                                                        mamount = parseFloat((newValue).toFixed(2));
                                                                                                    }
                                                                                                    else {
                                                                                                        anamount = parseFloat((newValue * 12).toFixed(2));
                                                                                                        mamount = parseFloat((newValue).toFixed(2));
                                                                                                    }



                                                                                                    handleAmountChange(anamount, params.rowNode.id, params, currentType);
                                                                                                    handleMonthAmountChange(mamount, params.rowNode.id, params, currentType);
                                                                                                }

                                                                                                if (currentType == 'Fixed') {
                                                                                                    const updatedMonthlyGrossTotal = handleMonthGrossAmount();
                                                                                                    handleMonthGrossAmount()
                                                                                                    if (params.row.component == 'ESI') {
                                                                                                        if (updatedMonthlyGrossTotal > 21000) {
                                                                                                            anamount = 0;
                                                                                                            mamount = 0;
                                                                                                            console.log(anamount, mamount);
                                                                                                            handleAmountChange(anamount, params.rowNode.id, params, currentType);
                                                                                                            handleMonthAmountChange(mamount, params.rowNode.id, params, currentType);
                                                                                                            handleMonthGrossAmount()
                                                                                                        }
                                                                                                        else {
                                                                                                            if (newValue == 0) {


                                                                                                                handleAmountChange(0, params.rowNode.id, params, currentType);
                                                                                                                handleMonthAmountChange(0, params.rowNode.id, params, currentType);
                                                                                                                handleMonthGrossAmount()
                                                                                                            }
                                                                                                            if (newValue != 0) {

                                                                                                                mamount = newValue;
                                                                                                                anamount = newValue * 12;
                                                                                                                handleAmountChange(anamount, params.rowNode.id, params, currentType);
                                                                                                                handleMonthAmountChange(mamount, params.rowNode.id, params, currentType);
                                                                                                                handleMonthGrossAmount()
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }

                                                                                                if (currentType == 'Fixed') {
                                                                                                    const updatedMonthlyGrossTotal = handleMonthGrossAmount();
                                                                                                    handleMonthGrossAmount()
                                                                                                    if (params.row.component == 'Proff. Tax') {
                                                                                                        if (updatedMonthlyGrossTotal < 12000) {

                                                                                                            anamount = 0;
                                                                                                            mamount = 0;
                                                                                                            console.log(anamount, mamount, updatedMonthlyGrossTotal);
                                                                                                            handleAmountChange(anamount, params.rowNode.id, params, currentType);
                                                                                                            handleMonthAmountChange(mamount, params.rowNode.id, params, currentType);
                                                                                                            handleMonthGrossAmount()
                                                                                                        }
                                                                                                        else {
                                                                                                            if (newValue == 0) {
                                                                                                                handleAmountChange(0, params.rowNode.id, params, currentType);
                                                                                                                handleMonthAmountChange(0, params.rowNode.id, params, currentType);
                                                                                                                handleMonthGrossAmount()
                                                                                                            }
                                                                                                            if (newValue != 0) {
                                                                                                                mamount = parseFloat(200);
                                                                                                                anamount = parseFloat(2400);
                                                                                                                handleAmountChange(anamount, params.rowNode.id, params, currentType);
                                                                                                                handleMonthAmountChange(mamount, params.rowNode.id, params, currentType);
                                                                                                                handleMonthGrossAmount()
                                                                                                            }

                                                                                                        }
                                                                                                    }
                                                                                                }

                                                                                                else {
                                                                                                    handleAmountChange(anamount, params.rowNode.id, params, currentType);
                                                                                                    handleMonthAmountChange(mamount, params.rowNode.id, params, currentType);
                                                                                                    handleMonthGrossAmount()
                                                                                                }

                                                                                                handleMonthGrossAmount()

                                                                                                const deductionIndex = submitsalary.deduction.findIndex(item => item.name === params.row.name);

                                                                                                if (deductionIndex !== -1) {
                                                                                                    const updatedDeduction = {
                                                                                                        ...submitsalary.deduction[deductionIndex],
                                                                                                        amount: newValue,
                                                                                                        monthly: mamount,
                                                                                                        Annually: anamount
                                                                                                    };

                                                                                                    const updatedList = [...submitsalary.deduction];
                                                                                                    updatedList[deductionIndex] = updatedDeduction;
                                                                                                    Setsubmitsalary(prevState => ({
                                                                                                        ...prevState,
                                                                                                        deduction: updatedList
                                                                                                    }));
                                                                                                } else {
                                                                                                    const newDeduction = {
                                                                                                        name: params.row.name,
                                                                                                        type: currentType ? currentType : params.row.deduct_type,
                                                                                                        component: params.row.component,
                                                                                                        amount: newValue,
                                                                                                        monthly: mamount,
                                                                                                        Annually: anamount
                                                                                                    };
                                                                                                    Setsubmitsalary(prevState => ({
                                                                                                        ...prevState,
                                                                                                        deduction: [...prevState.deduction, newDeduction]
                                                                                                    }));
                                                                                                }
                                                                                            }

                                                                                        }
                                                                                        }
                                                                                    />
                                                                                </TableCell>

                                                                            ),
                                                                        },


                                                                        {
                                                                            field: 'monthlyamount',
                                                                            headerName: 'MONTHLY AMOUNT',
                                                                            flex: 1,
                                                                            renderCell: (params) => {
                                                                                return (
                                                                                    <TableCell>
                                                                                        <Form.Control
                                                                                            type="text"
                                                                                            readOnly
                                                                                            style={{ cursor: 'not-allowed' }}
                                                                                            value={MonthlyAmount[params.rowNode.id]}
                                                                                            onChange={(e) => {
                                                                                                const newValue = parseFloat(e.target.value);

                                                                                                if (isNaN(newValue) || newValue < 0) return;

                                                                                                let updatedList = [];
                                                                                                if (params.row.type === 'Basic') {
                                                                                                    updatedList = [...salaryData.basic];
                                                                                                    updatedList[params.rowIndex].monthlyamount = newValue;
                                                                                                    setSalaryData((prevState) => ({ ...prevState, basic: updatedList }));
                                                                                                } else if (params.row.type === 'Allowance') {
                                                                                                    updatedList = [...salaryData.allowance];
                                                                                                    updatedList[params.rowIndex].monthlyamount = newValue;
                                                                                                    setSalaryData((prevState) => ({ ...prevState, allowance: updatedList }));
                                                                                                } else if (params.row.type === 'Deduction') {
                                                                                                    updatedList = [...salaryData.deduction];
                                                                                                    updatedList[params.rowIndex].monthlyamount = newValue;
                                                                                                    setSalaryData((prevState) => ({ ...prevState, deduction: updatedList }));
                                                                                                }

                                                                                                const newMonthlyAmounts = [...MonthlyAmount];
                                                                                                newMonthlyAmounts[params.rowIndex] = newValue;
                                                                                                SetMonthlyAmount(newMonthlyAmounts);
                                                                                            }}
                                                                                        />
                                                                                    </TableCell>
                                                                                );
                                                                            },
                                                                        },

                                                                        {
                                                                            field: 'anualamount',
                                                                            headerName: 'ANNUAL AMOUNT',
                                                                            flex: 1,
                                                                            renderCell: (params) => {


                                                                                return (
                                                                                    <TableCell>
                                                                                        <Form.Control
                                                                                            type="text"
                                                                                            readOnly
                                                                                            style={{ cursor: 'not-allowed' }}
                                                                                            value={annualAmount[params.rowNode.id]}
                                                                                            onChange={(e) => {
                                                                                                const newValue = parseFloat(e.target.value);

                                                                                                if (isNaN(newValue) || newValue < 0) return;
                                                                                                let updatedList = [];
                                                                                                if (params.row.type === 'Basic') {
                                                                                                    updatedList = [...salaryData.basic];
                                                                                                    updatedList[params.rowIndex].anualamount = newValue;
                                                                                                    setSalaryData((prevState) => ({ ...prevState, basic: updatedList }));
                                                                                                } else if (params.row.type === 'Allowance') {
                                                                                                    updatedList = [...salaryData.allowence];
                                                                                                    updatedList[params.rowIndex].anualamount = newValue;
                                                                                                    setSalaryData((prevState) => ({ ...prevState, allowence: updatedList }));
                                                                                                } else if (params.row.type === 'Deduction') {
                                                                                                    updatedList = [...salaryData.deduction];
                                                                                                    updatedList[params.rowIndex].anualamount = newValue;
                                                                                                    setSalaryData((prevState) => ({ ...prevState, deduction: updatedList }));
                                                                                                }


                                                                                                const newAnnualAmounts = [...annualAmount];
                                                                                                newAnnualAmounts[params.rowIndex] = newValue;
                                                                                                setannualAmount(newAnnualAmounts);
                                                                                            }}
                                                                                        />
                                                                                    </TableCell>
                                                                                );
                                                                            },
                                                                        },

                                                                    ]}
                                                                    autoHeight
                                                                    headerClassName="custom-header-color"

                                                                />

                                                            )
                                                        )}
                                                    </div>
                                                    {/* <div className="row align-items-center"> */}

                                                    <Alert variant="info">
                                                        <strong>Note: </strong>Calculate the salary before adding or updating it.
                                                    </Alert>
                                                    {/* </div> */}
                                                    <Button variant="primary" className='btn btn-small' onClick={(e) => { handleTotal() }}>Calculate Total</Button>
                                                    <p style={{ marginLeft: '6px' }}>
                                                        <strong>Annual Amount :</strong> {focus ? annualAmountTotal : annualWithoutUpdate}
                                                    </p>
                                                    <p style={{ marginLeft: '6px' }}>
                                                        <strong>Monthly Amount :</strong> {focus ? monthlyAmountTotal : monthWithoutUpdate}
                                                    </p>

                                                    <p style={{ fontWeight: 'bold', marginLeft: '6px' }}>Gross:- Basic + Allowance without incentives</p>
                                                    <p style={{ fontWeight: 'bold', marginLeft: '6px' }}>PF:- 12% up to 1800/- on combined basic and conveyance</p>
                                                    <p style={{ fontWeight: 'bold', marginLeft: '6px' }}>ESI:- 0.75% on Gross less than 21000/-</p>
                                                    <p style={{ fontWeight: 'bold', marginLeft: '6px' }}>Proff Tax:- 200/- on Gross more than 12000/-</p>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <br />
                                    {/* <Button variant="success" type="submit"
                                        disabled={isButtonDisabled}>{isButtonDisabled ? "Add Salary Setup" : "Update Salary Setup"}</Button> */}

                                    <Button variant="primary" type="submit">
                                        {existingSetup && existingSetup.length > 0 ? "Update Salary Setup" : "Add Salary Setup"}
                                    </Button>

                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header className="custom-modal-header">
                    <Modal.Title style={{ color: 'white' }}>Re-Enter the amount</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Please re-enter the value for the changed component before updating
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>Understood</Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment >
    );
};

export default Addempsalarysetup;