import React, { useState, useEffect, useRef } from 'react';
import _debounce from 'lodash/debounce';
import Header from '../../layouts/Header';
import HeaderMobile from '../../layouts/HeaderMobile';
import { Card, Modal, Button } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { dateviewemployeebreaklist, deletebreak, editbreakdetails, getId, refreshPagedata, refreshPagedata1, searchEmployeeBreak, searchemployeebreaklist, updatebreakdetails, viewdeviceinfo, viewShift } from '../../services/api/api';
import DatePicker from 'react-datepicker';
import { Calendar } from 'react-bootstrap-icons';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { TextField } from '@mui/material';
import OnDeviceTrainingIcon from '@mui/icons-material/OnDeviceTraining';

const Employeebreakdata = () => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [fullPageLoader, setFullPageLoader] = useState(false);
  const [filteredBreakData, setFilteredBreakData] = useState([]);
  const [isSpinning, setIsSpinning] = useState(false);
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const datepickerRef = useRef(null); // Add a new ref for the DatePicker
  const [breakcountData, setBreakcountData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [selectedDateFilter, setSelectedDateFilter] = useState(new Date());
  const [showSecondModal, setShowSecondModal] = useState(false); // Fix this line
  const [onBreakEmployees, setOnBreakEmployees] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const reversedRows = [...breakcountData];


  const [breakInData, setBreakInData] = useState([]);
  const [breakOutData, setBreakOutData] = useState([]);
  const [breakOutTime, setBreakOutTime] = useState([]);
  const [breakInTime, setBreakInTime] = useState([]);

  const [employeeName, setEmployeeName] = useState('');
  const [totalViolance, setViolationFine] = useState(null);
  const [totalMinutes, setTotalMinutes] = useState(null);
  const [ids, setIds] = useState(null);
  const [flag, setFlag] = useState(0);
  const [updatePayload, setUpdatePayload] = useState({
    violationFine: null,
    comment: ''
  });

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [paramsRow, setParamsRow] = useState(null);
  const [deviceBranches, setDeviceBranch] = useState({})
  const [devicelength, setDeviceLength] = useState(sessionStorage.getItem('devicelength'));
  let refreshLoader = sessionStorage.getItem('refresh')

  console.log(refreshLoader);



  const openConfirmationModal = (row) => {
    setParamsRow(row)
    setShowConfirmation(true);
  };

  const closeConfirmationModal = () => {
    setShowConfirmation(false);
  };


  const handleRefresh = () => {
    setIsSpinning(true);
    setTimeout(() => {
      setIsSpinning(false);
    }, 1000);
    // fetchRefreshData()
  };

  const columns = [
    {
      field: 'date',
      headerName: 'Date',
      minWidth: 100,
      flex: 1,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 200,
      flex: 1,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'breaksIn',
      headerName: 'Break Start',
      minWidth: 125,
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <div>
          {params.row.breaksIn.map((breakItem, index) => (
            <div key={index} style={{ color: 'green' }}>
              {breakItem.time}
            </div>
          ))}
        </div>
      ),
    },
    {
      field: 'breaksOut',
      headerName: 'Break End',
      minWidth: 125,
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <div>
          {params.row.breaksOut.map((breakItem, index) => (
            <div key={index} style={{ color: 'red' }}>
              {breakItem.time}
            </div>
          ))}
        </div>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 100,
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const status = params.value;
        const statusColor = status == 'OUT' ? 'red' : 'green';
        return <span style={{ color: statusColor, fontWeight: 'bold' }}>{status}</span>;
      },
    },
    {
      field: 'totalTimeFormatted',
      headerName: 'Total Time',
      minWidth: 100,
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => <span>{params.row.totalTimeFormatted || '0'}</span>,
    },
    {
      field: 'violation',
      headerName: 'Violation',
      minWidth: 100,
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => <span>{params.row.violation || 'None'}</span>, // Handle empty cases
    },
    {
      field: 'violationfine',
      headerName: 'Violation Fine',
      minWidth: 100,
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => <span>{params.row.violationfine !== null ? params.row.violationfine : 0}</span>, // Ensure default value
    },
    {
      field: 'comment',
      headerName: 'Comments',
      minWidth: 200,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        console.log(params)
        const breakOut = params.row.breaksOut && params.row.breaksOut[0];
        return (
          <span>{breakOut ? breakOut.comments || '' : 'No comment'}</span>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 100,
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <div>
          {/* Conditional Edit Icon */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill={params.row.breaksOut && params.row.breaksOut.length > 0 ? "black" : "gray"}
            className="bi bi-pencil-fill edit ebtn"
            viewBox="0 0 16 16"
            style={{ cursor: params.row.breaksOut && params.row.breaksOut.length > 0 ? 'pointer' : 'not-allowed', marginRight: "15px" }}
            onClick={params.row.breaksOut && params.row.breaksOut.length > 0 ? () => handleEdit(params.row) : undefined}
          >
            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
          </svg>

          {/* Delete Icon */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="black"
            className="bi bi-trash3-fill delete delete-button"
            viewBox="0 0 16 16"
            style={{ cursor: 'pointer' }}
            onClick={() => openConfirmationModal(params.row)}
          >
            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
          </svg>
        </div>
      ),
    }

  ];




  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    // setShowSecondModal(true);
  };

  const handleSecondModalClose = () => {
    setShowSecondModal(false);
  };

  const formatTime = (time) => {
    const formattedTime = new Date(`2022-01-01 ${time}`);
    return formattedTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const filterRows = () => {
    if (!searchText && !selectedDateFilter) {
      return reversedRows.map((row, index) => ({ ...row, id: index + 1 }));
    }

    return reversedRows
      .filter((row) =>
        Object.values(row).some(
          (value) =>
            typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase())
        ) &&
        (!selectedDateFilter || row.date === selectedDateFilter.toISOString().split('T')[0])
      )
      .map((row, index) => ({ ...row, id: index + 1 }));
  };

  function formatDateString(dateString) {
    const date = new Date(dateString);

    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = date.getUTCFullYear();

    return `${day}-${month}-${year}`;
  }

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const fetchBreakData = async (date) => {
    try {
      const shift = await viewShift();
      const companyShift = shift[0].company_time;
      const shiftArray = Object.values(companyShift).map(item => ({
        _id: item._id,
        shift_no: item.shift_no || 'N/A',
        company_start_time: item.company_start_time || 'N/A',
        company_end_time: item.company_end_time || 'N/A',
        company_break_time: item.company_break_time || 'N/A',
        company_break_fine: item.company_break_fine || 'N/A',
        company_late_fine: item.company_late_fine || 'N/A',
      }));

      const formattedDate = formatDateString(date);
      setLoading(true);
      const formattedData = await dateviewemployeebreaklist(formattedDate);

      const breakList = formattedData.data.break_list;

      const branchArray = breakList.flatMap((item, index) =>
        Object.entries(item.attendance).flatMap(([date, attendanceData]) => {
          const rows = [];
          let currentRow = {
            id: `${index}_${date}`,
            date: attendanceData.date,
            total_minutes: attendanceData.total_minutes,
            name: attendanceData.name,
            breaksIn: [],
            breaksOut: [],
            totalTime: 0,
            totalTimeFormatted: '0h 0m',
            status: '',
            violation: 'No',
            violationfine: 0,
          };

          attendanceData.breaks.forEach((breakItem, breakIndex) => {
            if (breakItem.type === "IN") {
              currentRow.breaksIn.push(breakItem);
            }

            if (breakItem.type === "OUT") {
              currentRow.breaksOut.push(breakItem);

              const inTime = currentRow.breaksIn[currentRow.breaksIn.length - 1]?.time;
              const outTime = breakItem.time;

              if (inTime && outTime) {
                const inSeconds = convertToSeconds(inTime);
                const outSeconds = convertToSeconds(outTime);
                const difference = outSeconds - inSeconds;

                // Update totalTime using your custom logic
                if (difference > 0) {
                  const countedMinutes = Math.floor(difference / 60) + (difference % 60 > 0 ? 1 : 0);
                  currentRow.totalTime += countedMinutes;
                }
              }

              const { hours, minutes } = convertToHoursAndMinutes(currentRow.totalTime);
              currentRow.totalTimeFormatted = `${hours}h ${minutes}m`;

              const companyBreakTime = shiftArray[0].company_break_time;
              const companyBreakFine = shiftArray[0].company_break_fine;

              if (currentRow.totalTime > companyBreakTime) {
                currentRow.violation = 'Yes';
                const extraMinutes = currentRow.totalTime - companyBreakTime;
                currentRow.violationfine = breakItem.totalViolance !== null ? breakItem.totalViolance : extraMinutes * companyBreakFine;
              } else {
                currentRow.violation = 'No';
                currentRow.violationfine = breakItem.totalViolance !== null ? breakItem.totalViolance : 0;
              }

              rows.push({
                ...currentRow,
                status: 'IN',
                mainId: breakItem.mainId,
                subId: breakItem.subId,
                employeeId: breakItem.employeeId,
              });

              currentRow = {
                id: `${index}_${date}_${breakIndex + 1}`,
                date: attendanceData.date,
                total_minutes: attendanceData.total_minutes,
                name: attendanceData.name,
                breaksIn: [],
                breaksOut: [],
                totalTime: 0,
                totalTimeFormatted: '0h 0m',
                status: '',
                violation: 'No',
                violationfine: 0,
              };
            }
          });

          if (currentRow.breaksIn.length > 0 || currentRow.breaksOut.length > 0) {
            const { hours, minutes } = convertToHoursAndMinutes(currentRow.totalTime);
            const companyBreakTime = shiftArray[0].company_break_time;
            const companyBreakFine = shiftArray[0].company_break_fine;

            if (currentRow.totalTime > companyBreakTime) {
              currentRow.violation = 'Yes';
              const extraMinutes = currentRow.totalTime - companyBreakTime;
              currentRow.violationfine = currentRow.violationfine !== 0 ? currentRow.violationfine : extraMinutes * companyBreakFine;
            } else {
              currentRow.violation = 'No';
              currentRow.violationfine = currentRow.violationfine !== 0 ? currentRow.violationfine : 0;
            }

            rows.push({
              ...currentRow,
              status: currentRow.breaksOut.length > 0 ? 'IN' : 'OUT',
              totalTimeFormatted: `${hours}h ${minutes}m`,
            });
          } else if (currentRow.breaksIn.length > 0) {
            rows.push({
              ...currentRow,
              status: 'OUT',
              totalTimeFormatted: '0h 0m',
            });
          }

          return rows;
        })
      );

      setBreakcountData(branchArray);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSearchBreakData = async (date) => {
    try {
      const shift = await viewShift();
      const companyShift = shift[0].company_time;
      const shiftArray = Object.values(companyShift).map(item => ({
        _id: item._id,
        shift_no: item.shift_no || 'N/A',
        company_start_time: item.company_start_time || 'N/A',
        company_end_time: item.company_end_time || 'N/A',
        company_break_time: item.company_break_time || 'N/A',
        company_break_fine: item.company_break_fine || 'N/A',
        company_late_fine: item.company_late_fine || 'N/A',
      }));

      const formattedDate = formatDateString(date);
      setLoading(true);
      const formattedData = await searchemployeebreaklist(formattedDate, searchText);

      const breakList = formattedData.data.break_list;

      const branchArray = breakList.flatMap((item, index) =>
        Object.entries(item.attendance).flatMap(([date, attendanceData]) => {
          const rows = [];
          let currentRow = {
            id: `${index}_${date}`,
            date: attendanceData.date,
            total_minutes: attendanceData.total_minutes,
            name: attendanceData.name,
            breaksIn: [],
            breaksOut: [],
            totalTime: 0,
            totalTimeFormatted: '0h 0m',
            status: '',
            violation: 'No',
            violationfine: 0,
          };

          attendanceData.breaks.forEach((breakItem, breakIndex) => {
            if (breakItem.type === "IN") {
              currentRow.breaksIn.push(breakItem);
            }

            if (breakItem.type === "OUT") {
              currentRow.breaksOut.push(breakItem);

              const inTime = currentRow.breaksIn[currentRow.breaksIn.length - 1]?.time;
              const outTime = breakItem.time;

              if (inTime && outTime) {
                const inSeconds = convertToSeconds(inTime);
                const outSeconds = convertToSeconds(outTime);
                const difference = outSeconds - inSeconds;

                // Update totalTime using your custom logic
                if (difference > 0) {
                  const countedMinutes = Math.floor(difference / 60) + (difference % 60 > 0 ? 1 : 0);
                  currentRow.totalTime += countedMinutes;
                }
              }

              const { hours, minutes } = convertToHoursAndMinutes(currentRow.totalTime);
              currentRow.totalTimeFormatted = `${hours}h ${minutes}m`;

              const companyBreakTime = shiftArray[0].company_break_time;
              const companyBreakFine = shiftArray[0].company_break_fine;

              if (currentRow.totalTime > companyBreakTime) {
                currentRow.violation = 'Yes';
                const extraMinutes = currentRow.totalTime - companyBreakTime;
                currentRow.violationfine = breakItem.totalViolance !== null ? breakItem.totalViolance : extraMinutes * companyBreakFine;
              } else {
                currentRow.violation = 'No';
                currentRow.violationfine = breakItem.totalViolance !== null ? breakItem.totalViolance : 0;
              }

              rows.push({
                ...currentRow,
                status: 'IN',
                mainId: breakItem.mainId,
                subId: breakItem.subId,
                employeeId: breakItem.employeeId,
              });

              currentRow = {
                id: `${index}_${date}_${breakIndex + 1}`,
                date: attendanceData.date,
                total_minutes: attendanceData.total_minutes,
                name: attendanceData.name,
                breaksIn: [],
                breaksOut: [],
                totalTime: 0,
                totalTimeFormatted: '0h 0m',
                status: '',
                violation: 'No',
                violationfine: 0,
              };
            }
          });

          if (currentRow.breaksIn.length > 0 || currentRow.breaksOut.length > 0) {
            const { hours, minutes } = convertToHoursAndMinutes(currentRow.totalTime);
            const companyBreakTime = shiftArray[0].company_break_time;
            const companyBreakFine = shiftArray[0].company_break_fine;

            if (currentRow.totalTime > companyBreakTime) {
              currentRow.violation = 'Yes';
              const extraMinutes = currentRow.totalTime - companyBreakTime;
              currentRow.violationfine = currentRow.violationfine !== 0 ? currentRow.violationfine : extraMinutes * companyBreakFine;
            } else {
              currentRow.violation = 'No';
              currentRow.violationfine = currentRow.violationfine !== 0 ? currentRow.violationfine : 0;
            }

            rows.push({
              ...currentRow,
              status: currentRow.breaksOut.length > 0 ? 'IN' : 'OUT',
              totalTimeFormatted: `${hours}h ${minutes}m`,
            });
          } else if (currentRow.breaksIn.length > 0) {
            rows.push({
              ...currentRow,
              status: 'OUT',
              totalTimeFormatted: '0h 0m',
            });
          }

          return rows;
        })
      );

      setBreakcountData(branchArray);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const convertToSeconds = (time) => {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    return hours * 3600 + minutes * 60 + (seconds || 0);
  };



  const handleEdit = async (row) => {
    setBreakOutTime([])
    setBreakInTime([])
    setUpdatePayload({
      violationFine: null,
      comment: ''
    })
    console.log(row);
    setViolationFine(row.violationfine)

    setShowModal(true)

    // Collect breakIn and breakOut details
    const breakInDetails = row.breaksIn.map(breakItem => ({
      mainId: breakItem.mainId,
      subId: breakItem.subId,
      employeeId: breakItem.employeeId
    }));


    const breakOutDetails = row.breaksOut.map(breakItem => ({
      mainId: breakItem.mainId,
      subId: breakItem.subId,
      employeeId: breakItem.employeeId
    }));
    console.log(breakInDetails[0]);
    console.log(breakOutDetails[0]);

    // Construct payload
    const payload = {
      breakIn: breakInDetails,
      breakOut: breakOutDetails
    };

    const outIds = breakOutDetails[0];
    setEmployeeName(row.name)

    setIds(outIds)


    try {

      const response = await editbreakdetails(payload);

      const breakIn = response.breakData.breakIn.map(item => item.employee_break);
      const breakOut = response.breakData.breakOut.map(item => item.employee_break);
      setBreakInData(breakIn);
      setBreakOutData(breakOut);
      let breakInTime = breakIn.map((value) => {
        return value.break_time
      })
      let breakOutTime = breakOut.map((value) => {
        return value.break_time
      })
      console.log(breakInTime);
      console.log(breakOutTime);

      setBreakInTime(breakInTime)
      setBreakOutTime(breakOutTime)
      console.log(breakInTime);

      if (response.success == 'Break data') {
        // Prepopulate state with API response
        const { breakOut } = response.breakData;
        if (breakOut.length > 0) {
          const breakOutDetails = breakOut[0].employee_break;
          setUpdatePayload({
            violationFine: breakOutDetails.totalViolance || 0,
            comment: breakOutDetails.comment || '',
          });
        }
      }

      const threshold = 1;

      let minutes = breakOut.map((value) => {
        const numMinutes = parseFloat(value.total_minutes);

        if (numMinutes > threshold) {
          return Math.ceil(numMinutes); // Round up if greater than the threshold
        } else {
          return Math.floor(numMinutes); // Round down if less than or equal to the threshold
        }
      });


      setTotalMinutes(minutes)


    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);

    }

  };


  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'violationFine') {
      setViolationFine(value);
    }

    setUpdatePayload((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // const handleSubmit = async () => {
  //   try {
  //     const formattedIds = ids[0] || ids;
  //     let payload = {
  //       ...formattedIds,
  //       totalViolance
  //     };

  //     console.log('Formatted IDS:', formattedIds);
  //     console.log('Payload:', payload);


  //     const response = await updatebreakdetails(payload);

  //     console.log('Response:', response);

  //   } catch (error) {
  //     console.error('Error updating data:', error);
  //   } finally {
  //     setLoading(false);
  //     setFlag(!flag)
  //   }
  // };

  const handleSubmit = async () => {
    try {
      const formattedIds = ids[0] || ids;
      let payload = {
        ...formattedIds,
        totalViolance,
        ...updatePayload,  // Spread updatePayload here to include comment and violationFine
      };

      console.log('Formatted IDS:', formattedIds);
      console.log('Payload:', payload);

      const response = await updatebreakdetails(payload);

      console.log('Response:', response);
      if (response) {
        toast.success('Break Updated Successfully', {
          position: "top-center",
          autoClose: 1000,
        });
        setBreakOutTime([])
        setBreakInTime([])
        setUpdatePayload({
          violationFine: null,
          comment: ''
        })
      }

    } catch (error) {
      console.error('Error updating data:', error);
    } finally {
      setLoading(false);
      setFlag(flag => flag + 1); // Increment the counter to force a re-render

    }
  };







  const handleCloseModal = () => setShowModal(false);



  const handleDelete = async (row) => {
    row.breaksIn.forEach(breakItem => {
      console.log(`IN - Main ID: ${breakItem.mainId}, Sub ID: ${breakItem.subId}, Employee ID: ${breakItem.employeeId}`);
    });
    row.breaksOut.forEach(breakItem => {
      console.log(`OUT - Main ID: ${breakItem.mainId}, Sub ID: ${breakItem.subId}, Employee ID: ${breakItem.employeeId}`);
    });

    const breakInDetails = row.breaksIn.map(breakItem => ({
      mainId: breakItem.mainId,
      subId: breakItem.subId,
      employeeId: breakItem.employeeId
    }));

    const breakOutDetails = row.breaksOut.map(breakItem => ({
      mainId: breakItem.mainId,
      subId: breakItem.subId,
      employeeId: breakItem.employeeId
    }));

    // Log details for debugging
    breakInDetails.forEach(breakItem => {
      console.log(`IN - Main ID: ${breakItem.mainId}, Sub ID: ${breakItem.subId}, Employee ID: ${breakItem.employeeId}`);
    });

    breakOutDetails.forEach(breakItem => {
      console.log(`OUT - Main ID: ${breakItem.mainId}, Sub ID: ${breakItem.subId}, Employee ID: ${breakItem.employeeId}`);
    });

    // Construct payload
    const payload = {
      breakIn: breakInDetails,
      breakOut: breakOutDetails
    };
    console.log(payload);

    try {
      // Make API call with the payload
      const response = await deletebreak(payload);

      console.log(response);
      if (response) {
        toast.success(`${response.success} Successfully`, {
          position: "top-center",
          autoClose: 1000,
        });
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
      setFlag(flag => flag + 1);
    }


  };



  const convertToMinutes = (timeStr) => {
    const [hours, minutes] = timeStr.split(':').map(Number);
    return hours * 60 + minutes;
  };

  const convertToHoursAndMinutes = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return { hours, minutes };
  };




  useEffect(() => {
    if (selectedDateFilter == null) {
      if (isSpinning == false) {


        if (!searchText) {

          fetchBreakData(selectedDate);
        }
        else {

          fetchSearchBreakData(selectedDate);
        }

      }

      console.log('Component re-rendered because flag changed:', flag);

    }
    else {
      if (isSpinning == false) {
        if (!searchText) {
          fetchBreakData(selectedDateFilter);
          console.log('Component re-rendered because flag changed:', flag);
        }
        else {
          fetchSearchBreakData(selectedDateFilter);

        }
      }
    }

    return () => { };
  }, [selectedDateFilter, flag, isSpinning, searchText]);



  useEffect(() => {
    fetchDevices()
  }, [])

  const fetchDevices = async () => {
    try {
      // setLoading(true)
      setFullPageLoader(true)
      const apiResponse = await viewdeviceinfo();
      console.log(apiResponse);

      if (apiResponse) {
        // Extract the branch_name and company_id from the first device in the response
        const firstDevice = apiResponse[0];  // Get the first device (or any other logic)
        setFullPageLoader(false)
        const formattedData = {
          branch_name: [firstDevice.company_device.branch_name], // Wrap branch_name in an array
          companyId: sessionStorage.getItem('companyId'),  // Retrieve companyId from sessionStorage
        };

        console.log(formattedData);  // Log the formatted data to check its structure

        // Store the formatted data in the state as a single object
        setDeviceBranch(formattedData);
        if (formattedData.branch_name.length > 0) {
          setDeviceLength(1)
        }
        else {
          setDeviceLength(-1)
        }
        // fetchRefreshData(formattedData)
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false)
    } finally {
      setLoading(false)
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // useEffect(() => {
  //   if (devicelength == 1) {

  //     fetchRefreshData()
  //   }
  // }, [devicelength])

  const fetchRefreshData = async () => {
    try {
      // setLoading(true)
      // handleShow()
      const apiResponse = await refreshPagedata1(deviceBranches);
      console.log(apiResponse);
      if (apiResponse) {
        // handleClose()
        setIsSpinning(false)
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      // handleClose()
      // setLoading(false)
    } finally {
      // setLoading(false)
    }
  };

  const filterCurrentDateBreaks = () => {
    const currentDate = new Date();
    const formattedCurrentDate = currentDate.toISOString().split('T')[0];

    const filteredData = reversedRows
      .filter((row) =>
        row.date === formattedCurrentDate &&
        row.breaks.some((breakItem) => breakItem.type === 'IN') &&
        !row.breaks.some((breakItem) => breakItem.type === 'OUT' && !breakItem.duration_minutes) &&
        row.breaks[row.breaks.length - 1]?.type !== 'OUT'
      )
      .map((row, index) => {
        const lastBreak = row.breaks[row.breaks.length - 1];
        const outValue = lastBreak && lastBreak.type === 'OUT' ? lastBreak.time : null;

        return { ...row, id: index + 1, out: outValue };
      });

    setFilteredBreakData(filteredData);
  };




  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalShow = () => {
    setShowModal(true);
  };

  const openModalWithFilteredData = () => {
    filterCurrentDateBreaks();
    handleModalShow();
  };


  return (
    <React.Fragment>
      <HeaderMobile />
      <Header />

      <div className="main p-4 p-lg-5 mt-5">
        <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
              <li className="breadcrumb-item">
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">
                <Link to="/admin/pages/employeebreak">Employee Monthly Break</Link>
              </li>
              <li className="breadcrumb-item active">
                <Link to="/admin/empbreakdata">Employee Break</Link>
              </li>
              {/* <li className="breadcrumb-item active">List</li> */}
            </ol>
          </div>
        </div>
        <Card className="popup-card empHistory">
          <Card.Body>
            {/* <div className="mb-4 border-bottom text-center">
              <h3 className="mb-0 pb- border-bottom custome-btn">Break Details</h3>
            </div> */}
            {/* <div class="alert alert-success" role="alert">
              <strong>Note :</strong> To view break details , click on the employee row.
            </div> */}
            <div className="mb-3 border rounded p-3" style={{ backgroundColor: '#f0f0f0' }}>
              <strong>Break Info Monthly:</strong> {selectedDateFilter && selectedDateFilter.toLocaleString('default', { month: 'long', year: 'numeric' })}
            </div>

            <div className="d-flex flex-column flex-md-row justify-content-md-between align-items-center mb-3">
              <div className="d-flex flex-column flex-md-row justify-content-md-between align-items-center mb-3">
                <div className="d-flex align-items-center mb-2 mb-md-0 mr-2 col-12 col-md-4">
                  <div className="input-group">
                    <DatePicker
                      selected={selectedDateFilter}
                      onChange={(date) => {
                        setSelectedDateFilter(date);
                        fetchBreakData(date);
                      }}
                      dateFormat="MM/dd/yyyy"
                      className="form-control"
                      ref={datepickerRef}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center mb-2 mb-md-0 mr-2 col-12 col-md-4">
                  <TextField
                    label="Search"
                    variant="outlined"
                    value={searchText}
                    onChange={handleSearch}
                    size="small"
                  />
                </div>

                <div className="d-flex align-items-center col-12 col-md-4" style={{ marginLeft: '20px' }}>
                  {/* <button className="btn btn-primary" onClick={openModalWithFilteredData}>
                    On a break employees
                  </button> */}


                  <Button
                    className="btn-primary border-0 ms-2"
                    onClick={handleRefresh} // Make sure the onClick is on the Button
                    style={{ cursor: 'pointer' }}
                    disabled={refreshLoader == 'true'}
                  >
                    <FontAwesomeIcon
                      icon={faSync}
                      className={isSpinning ? 'spin' : ''}
                    />
                  </Button>
                </div>
              </div>
              {/* <div className="d-flex align-items-center col-6">
                <label htmlFor="searchInput" className="me-2">

                </label>
                <input
                  type="text"
                  className="p-2 ms-2 w-100"
                  placeholder="Search"
                  value={searchText}
                  onChange={handleSearch}
                />
              </div> */}
            </div>

            {loading ? (
              <div className="loader-container">
                <div className="loader"></div>
              </div>
            ) : (

              <div style={{ height: '70vh', width: '100%', overflowX: 'auto', backgroundColor: 'white' }}>
                <DataGrid
                  rows={reversedRows}
                  columns={columns}
                  pageSize={5}
                  onRowClick={handleRowClick}
                  components={{
                    NoRowsOverlay: () => <div style={{ textAlign: 'center', padding: '20px' }}>No data available</div>,
                  }}
                />
              </div>
            )}
          </Card.Body>
        </Card>
      </div>
      {/* -----------------------second modal--------------------- */}
      <Modal show={showSecondModal} onHide={handleSecondModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedRow && (
              <>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <span>Break details of  {selectedRow.name}</span>
                </div>

              </>
            )}
          </Modal.Title>
        </Modal.Header>
        {/* <Modal.Body>
          {selectedRow && (
            <>
              <h4 style={{ backgroundColor: '#f0f0f0', marginBottom: "5px" }} className='text-center'>{selectedRow.date}</h4>
            </>
          )}
          <table className="table table-bordered border-dark">
            <thead>
              <tr>
                <th className='text-white bg-secondary' style={{ padding: '10px', textAlign: 'center' }}>Sr.</th>
                <th className='text-white bg-secondary' style={{ padding: '10px', textAlign: 'center' }}>Start Break</th>
                <th className='text-white bg-secondary' style={{ padding: '10px', textAlign: 'center' }}>End Break</th>
              </tr>
            </thead>
            <tbody>
              {selectedRow &&
                selectedRow.breaks.map((breakItem, index) => {
                  if (breakItem.type === 'IN') {
                    const nextBreak = selectedRow.breaks[index + 1];
                    return (
                      <tr key={index}>
                        <td style={{ padding: '10px', textAlign: 'center' }}>{Math.floor(index / 2) + 1}</td>
                        <td style={{ padding: '10px', textAlign: 'center' }}>{formatTime(breakItem.time)}</td>
                        <td style={{ padding: '10px', textAlign: 'center' }}>
                          {nextBreak?.type === 'OUT' ? formatTime(nextBreak.time) : ''}
                        </td>
                      </tr>
                    );
                  }
                  return null; // Skip "OUT" entries to avoid empty rows
                })}
            </tbody>
          </table>

        </Modal.Body> */}
        <Modal.Body>
          {selectedRow ? (
            <>
              <h4 style={{ backgroundColor: '#f0f0f0', marginBottom: "5px" }} className='text-center'>{selectedRow.date}</h4>
              <table className="table table-bordered border-dark">
                <thead>
                  <tr>
                    <th className='text-white bg-secondary' style={{ padding: '10px', textAlign: 'center' }}>Sr.</th>
                    <th className='text-white bg-secondary' style={{ padding: '10px', textAlign: 'center' }}>Start Break</th>
                    <th className='text-white bg-secondary' style={{ padding: '10px', textAlign: 'center' }}>End Break</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedRow.breaks && Array.isArray(selectedRow.breaks) ? (
                    selectedRow.breaks.map((breakItem, index) => {
                      if (breakItem.type === 'IN') {
                        const nextBreak = selectedRow.breaks[index + 1];
                        return (
                          <tr key={index}>
                            <td style={{ padding: '10px', textAlign: 'center' }}>{Math.floor(index / 2) + 1}</td>
                            <td style={{ padding: '10px', textAlign: 'center' }}>{formatTime(breakItem.time)}</td>
                            <td style={{ padding: '10px', textAlign: 'center' }}>
                              {nextBreak?.type === 'OUT' ? formatTime(nextBreak.time) : ''}
                            </td>
                          </tr>
                        );
                      }
                      return null; // Skip "OUT" entries to avoid empty rows
                    })
                  ) : (
                    <tr>
                      <td colSpan="3" style={{ textAlign: 'center' }}>No breaks available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </>
          ) : (
            <div style={{ textAlign: 'center' }}>No data available</div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleSecondModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* -------------------end second modal------------------------------- */}

      {/* ---------------------------open current break modal---------------- */}
      <Modal show={showModal} onHide={handleModalClose} >
        <Modal.Header closeButton>
          <Modal.Title>Filtered Break Data</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body>
          {filteredBreakData.length > 0 ? (
            <>
              <p>The employees is on a break now</p>
              <table className="table table-bordered">
                <thead className="thead-light">
                  <tr>
                    <th>Date</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredBreakData.map((rowData) => (
                    <tr key={rowData.id}>
                      <td>{rowData.date}</td>
                      <td>{rowData.name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <div style={{ textAlign: 'center', padding: '20px' }}>No employees on a break</div>
          )}
        </Modal.Body> */}
        <Modal.Body>
          {filteredBreakData.length > 0 ? (
            <>
              <p>The employees are on a break now</p>
              <table className="table table-bordered">
                <thead className="thead-light">
                  <tr>
                    <th>Date</th>
                    <th>Name</th>
                    {/* Add more headers for additional fields */}
                  </tr>
                </thead>
                <tbody>
                  {filteredBreakData.map((rowData) => (
                    <tr key={rowData.id}>
                      <td>{rowData.date}</td>
                      <td>{rowData.name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <div style={{ textAlign: 'center', padding: '20px' }}>No employees on a break</div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* ------------------------end currnet break modal--------------------------------- */}


      <Modal show={showModal} onHide={handleCloseModal} className="custom-modal">
        <Modal.Header closeButton className="custom-modal-header" style={{ backgroundColor: '#007bff', color: 'white' }}>
          <Modal.Title className="" style={{ color: 'white' }}>Edit Break Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-modal-body">
          <div className="col-md-12 formcontrols">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="first_name" className="form-label fw-bold">
                    Employee Name
                  </label>
                  <input
                    readOnly
                    className='form-control'
                    style={{ cursor: 'not-allowed' }}
                    type="text"
                    id="name"
                    name="name"
                    value={employeeName || ""}
                    placeholder="Enter"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="email" className="form-label fw-bold">
                    Total Break Time
                  </label>
                  <input
                    className='form-control'
                    readOnly
                    value={totalMinutes}
                    style={{ cursor: 'not-allowed' }}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="email" className="form-label fw-bold">
                    Break IN
                  </label>
                  <input
                    className='form-control'
                    readOnly
                    style={{ cursor: 'not-allowed' }}
                    value={breakInTime[0]}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="email" className="form-label fw-bold">
                    Break OUT
                  </label>
                  <input
                    className='form-control'
                    readOnly
                    style={{ cursor: 'not-allowed' }}
                    value={breakOutTime[0]}
                  />
                </div>
              </div>



              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="violationFine" className="form-label fw-bold">
                    Violation Fine
                  </label>
                  <input
                    className='form-control'
                    name="violationFine"
                    value={totalViolance || ""}
                    onChange={handleChange}
                  />
                </div>
              </div>


              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="comment" className="form-label fw-bold">
                    Comments
                  </label>
                  <input
                    required
                    className='form-control'
                    name="comment"
                    onChange={handleChange}
                    value={updatePayload.comment}

                  />
                </div>
              </div>

            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="custom-modal-footer">
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={async (e) => {
              await handleSubmit(); // Call handleSubmit without preventDefault if not using a form
              handleCloseModal();  // Close modal after saving
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* delete modal  */}
      <Modal show={showConfirmation} onHide={closeConfirmationModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this break?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeConfirmationModal}>
            Cancel
          </Button>
          {/* <Button
              variant="danger"
              onClick={() => {
                handledeleteHoliday(deleteHolidayId);
                closeConfirmationModal();
              }}
            >
              Delete
            </Button> */}
          <Button
            variant="danger"
            onClick={() => {
              handleDelete(paramsRow);
              closeConfirmationModal();
            }}

          >
            Delete
          </Button>

        </Modal.Footer>
      </Modal>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header className="custom-modal-header">
          <Modal.Title style={{ color: 'white' }}>Fetching Clock-in log</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please wait untill the attendance data is fetched completely...
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Understood</Button>
        </Modal.Footer> */}
      </Modal>

    </React.Fragment >
  );
};

export default Employeebreakdata;
