import React, { useState, useEffect } from "react";
import Header from "../../../layouts/Header";
import HeaderMobile from "../../../layouts/HeaderMobile";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addResignation, editResignation, updateresignation, viewemployeeresignation } from "../../../services/api/api";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Button, Card, Modal } from "react-bootstrap";
import { Box, Tab, Tabs } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';

const ResignationForm = () => {
    const navigate = useNavigate();
    const [value, setValue] = React.useState('viewStatus');
    const [submitting, setSubmitting] = useState(false);
    const empId = sessionStorage.getItem('id')
    const [loading, setLoading] = React.useState(false);
    const [resignation, setEmployeeResignation] = useState([]);
    const [state, setState] = useState({
        reason: "",
        notice_date: "",
        resignation_date: "",
        employee_id: empId,
    });

    const [errors, setErrors] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);

    const getFormattedDefaultDate = () => {
        return 'N/A';
    };

    useEffect(() => {
        fetchResignation()
    }, [value, isModalOpen])

    const fetchResignation = async () => {
        try {
            setLoading(true)
            const apiResponse = await viewemployeeresignation(empId);
            console.log(apiResponse);

            if (apiResponse) {
                setEmployeeResignation(apiResponse)
                setLoading(false)
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false)
        } finally {
            setLoading(false)
        }
    };

    const handleEdit = async (masterId, id) => {
        console.log(id, masterId);
        setIsModalOpen(true);
        try {
            setLoading(true)
            const apiResponse = await editResignation(id, masterId);
            console.log(apiResponse);

            const resignationData = apiResponse.success[0].company_resignation;

            setState(prevState => ({
                ...prevState,
                reason: resignationData.reason,
                notice_date: resignationData.notice_date,
                resignation_date: resignationData.resignation_date,
                id: id,
                masterId: masterId,
            }));

        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false)
        } finally {
            setLoading(false)
        }
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));

        // Use the updater function to make sure state is updated before validation
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",  // Clear the error when the field changes
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (submitting) {
            return;
        }
        const validationErrors = validateForm(state);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        setSubmitting(true);
        let response;
        console.log(state.reason);

        try {

            response = await addResignation(state);
            // const data = JSON.parse(response);

            if (response) {
                toast.success("Resignation updated successfully!", {
                    position: "top-center",
                    autoClose: 1000,
                });

                setState({
                    reason: "",
                    notice_date: "",
                    resignation_date: "",
                    employee_id: empId
                });

                // handleModalClose()
            } else {
                toast.success("", {
                    position: "top-center",
                    autoClose: 1000,
                });
            }
        } catch (error) {
            toast.warning("Something went wrong", {
                position: "top-center",
                autoClose: 1000,
            });
        } finally {
            setSubmitting(false);
        }
    };
    const handleUpdateSubmit = async (e) => {
        e.preventDefault();
        if (submitting) {
            return;
        }
        const validationErrors = validateForm(state);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        setSubmitting(true);
        let response;

        try {

            
            response = await updateresignation(state);


            if (response) {
                toast.success("Resignation updated successfully!", {
                    position: "top-center",
                    autoClose: 1000,
                });

                setState({
                    reason: "",
                    notice_date: "",
                    resignation_date: "",
                    employee_id: empId
                });

                handleModalClose()
            } else {
                toast.success("", {
                    position: "top-center",
                    autoClose: 1000,
                });
            }
        } catch (error) {
            toast.warning("Something went wrong", {
                position: "top-center",
                autoClose: 1000,
            });
        } finally {
            setSubmitting(false);
        }
    };


    const validateForm = (state) => {
        let errors = {};

        if (!state.reason.trim()) {
            errors.reason = "Resignation reason is required";
        }

        if (!state.notice_date) {
            errors.notice_date = "Notice date is required";
        }

        if (!state.resignation_date) {
            errors.resignation_date = "Resignation date is required";
        }

        return errors;
    };

    const handleTabChange = (event, newValue) => {
        console.log(newValue);
        setValue(newValue);
    };



    return (
        <React.Fragment>
            <HeaderMobile />
            <Header />
            <div className="main p-4 p-lg-5 mt-5">

                <Box sx={{ width: '100%' }}>
                    <Tabs
                        value={value}
                        onChange={handleTabChange}
                        textColor="primary"
                        indicatorColor="primary"
                        aria-label="secondary tabs example"
                    >
                        <Tab value="viewStatus" label="View Status" />
                        <Tab value="addResignation" label="Add Resignation" />
                    </Tabs>
                </Box>

                <Card style={{ marginTop: '2rem' }}>
                    <Card.Body style={{ marginTop: '' }}>
                        {loading ? (
                            <div className="loader-container">
                                <div className="loader"></div>
                            </div>
                        ) : (
                            value == 'addResignation' ? (
                                <div className="row"

                                >

                                    <div className="col-md-12 formcontrols">
                                        <div className="bg-white p-4 rounded" style={{ height: "100%" }}>
                                            <form onSubmit={handleSubmit}>
                                                <div className="row">

                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <label htmlFor="reason" className="form-label">
                                                                Reason
                                                            </label>


                                                            <textarea
                                                                className={`form-control ${errors.reason && "is-invalid"}`}
                                                                id="reason"
                                                                name="reason"
                                                                value={state.reason}
                                                                onChange={handleChange}
                                                                style={{ height: "100px", resize: "vertical" }}
                                                                placeholder="Enter resignation reason"
                                                            />

                                                            {errors.reason && (
                                                                <div className="invalid-feedback">{errors.reason}</div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="notice_date" className="form-label">
                                                                Notice Date
                                                            </label>
                                                            <input
                                                                type="date"
                                                                className={`form-control ${errors.notice_date && "is-invalid"}`}
                                                                id="notice_date"
                                                                name="notice_date"
                                                                value={state.notice_date}
                                                                onChange={handleChange}
                                                            />
                                                            {errors.notice_date && <div className="text-danger">{errors.notice_date}</div>}

                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label htmlFor="resignation_date" className="form-label">
                                                                Resignation Date
                                                            </label>
                                                            <input
                                                                type="date"
                                                                className={`form-control ${errors.resignation_date && "is-invalid"}`}
                                                                id="resignation_date"
                                                                name="resignation_date"
                                                                value={state.resignation_date}
                                                                onChange={handleChange}
                                                            />
                                                            {errors.resignation_date && <div className="text-danger">{errors.resignation_date}</div>}

                                                        </div>
                                                    </div>
                                                </div>



                                                <button
                                                    type="submit"
                                                    className={`custom-submit-btn ${submitting ? 'disabled' : ''}`}
                                                    disabled={submitting}
                                                >
                                                    {submitting ? 'Submitting...' : 'Add Resignation'}
                                                </button>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            ) : value == 'viewStatus' ? (
                                <div className="row"

                                >

                                    <div className="col-md-12 formcontrols">
                                        <div className="bg-white p-4 rounded" style={{ height: "100%" }}>
                                            <form onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="table-responsive">
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="text-center" style={{ backgroundColor: '#c7c4c4', fontSize: '1rem' }}>
                                                                            Sr No.
                                                                        </th>
                                                                        <th className="text-center" style={{ backgroundColor: '#c7c4c4', fontSize: '1rem' }}>
                                                                            Name
                                                                        </th>
                                                                        <th className="text-center" style={{ backgroundColor: '#c7c4c4', fontSize: '1rem' }}>
                                                                            Notice Date
                                                                        </th>
                                                                        <th className="text-center" style={{ backgroundColor: '#c7c4c4', fontSize: '1rem' }}>
                                                                            Resignation Date
                                                                        </th>
                                                                        <th className="text-center" style={{ backgroundColor: '#c7c4c4', fontSize: '1rem' }}>
                                                                            Reason
                                                                        </th>
                                                                        <th className="text-center" style={{ backgroundColor: '#c7c4c4', fontSize: '1rem' }}>
                                                                            Status
                                                                        </th>
                                                                        <th className="text-center" style={{ backgroundColor: '#c7c4c4', fontSize: '1rem' }}>
                                                                            Actions
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {resignation.map((resignation, index) => (
                                                                        <tr key={resignation._id}>
                                                                            <td className="text-center" style={{ fontFamily: 'sans-serif', fontWeight: 'bold', fontSize: '1rem' }}>{index + 1}</td>
                                                                            <td className="text-center" style={{ fontFamily: 'sans-serif', fontWeight: 'bold', fontSize: '1rem' }}>{resignation?.company_resignation?.employee_name}</td>
                                                                            <td className="text-center" style={{ fontFamily: 'sans-serif', fontWeight: 'bold', fontSize: '1rem' }}>{resignation?.company_resignation?.notice_date}</td>
                                                                            <td className="text-center" style={{ fontFamily: 'sans-serif', fontWeight: 'bold', fontSize: '1rem' }}>{resignation?.company_resignation?.resignation_date}</td>
                                                                            <td className="text-center" style={{ fontFamily: 'sans-serif', fontWeight: 'bold', fontSize: '1rem' }}>{resignation?.company_resignation?.reason}</td>
                                                                            <td className="text-center" style={{ fontFamily: 'sans-serif', fontWeight: 'bold', fontSize: '1rem' }}>{resignation?.company_resignation?.status ? 'Pending' : 'Approved'}</td>
                                                                            <td className="text-center">
                                                                                <span style={{ cursor: 'pointer' }} onClick={() => {
                                                                                    handleEdit(resignation._id, resignation.company_resignation._id)
                                                                                }}><EditIcon /></span>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            ) : null
                        )}
                    </Card.Body>
                </Card>
            </div >


            <Modal show={isModalOpen} onHide={handleModalClose} size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title>Update Resignation Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? (
                        <div className="loader-containerResignation">
                            <div className="loader"></div>
                        </div>
                    ) : (
                        <div className="row">
                            <div className="col-md-12 formcontrols">
                                <div className="bg-white p-4 rounded" style={{ height: "100%" }}>
                                    <form onSubmit={handleUpdateSubmit}>

                                        <div className="row">

                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <label htmlFor="reason" className="form-label">
                                                        Reason
                                                    </label>


                                                    <textarea
                                                        className={`form-control ${errors.reason && "is-invalid"}`}
                                                        id="reason"
                                                        name="reason"
                                                        value={state.reason}
                                                        onChange={handleChange}
                                                        style={{ height: "100px", resize: "vertical" }}
                                                        placeholder="Enter resignation reason"
                                                    />

                                                    {errors.reason && (
                                                        <div className="invalid-feedback">{errors.reason}</div>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="notice_date" className="form-label">
                                                        Notice Date
                                                    </label>
                                                    <input
                                                        type="date"
                                                        className={`form-control ${errors.notice_date && "is-invalid"}`}
                                                        id="notice_date"
                                                        name="notice_date"
                                                        value={state.notice_date}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.notice_date && <div className="text-danger">{errors.notice_date}</div>}

                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label htmlFor="resignation_date" className="form-label">
                                                        Resignation Date
                                                    </label>
                                                    <input
                                                        type="date"
                                                        className={`form-control ${errors.resignation_date && "is-invalid"}`}
                                                        id="resignation_date"
                                                        name="resignation_date"
                                                        value={state.resignation_date}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.resignation_date && <div className="text-danger">{errors.resignation_date}</div>}

                                                </div>
                                            </div>
                                        </div>



                                        <button
                                            type="submit"
                                            className={`custom-submit-btn ${submitting ? 'disabled' : ''}`}
                                            disabled={submitting}
                                        >
                                            {submitting ? 'Submitting...' : 'Update Resignation'}
                                        </button>

                                    </form>
                                </div>
                            </div>
                        </div>
                    )}
                </Modal.Body>
            </Modal>

        </React.Fragment >
    );
};

export default ResignationForm
