import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { addCandidateInfo, addEmployee, BASE_URL, matchToken, viewsingleemployeetokendetail, viewtokenlogo } from "../services/api/api";
import { useNavigate } from "react-router-dom";
import { format, parse } from 'date-fns';
import _debounce from 'lodash/debounce';
import { PDFDocument } from 'pdf-lib';
import imageCompression from 'browser-image-compression';

import DatePicker from 'react-datepicker';
import { alignProperty } from "@mui/material/styles/cssUtils";

const Candidateform = () => {
    const [tokenExists, setTokenExists] = useState(false);
    const [formVisible, setFormVisible] = useState(false);
    const [message, setMessage] = useState('');


    const [admin_name, setAdminName] = useState('');
    const navigate = useNavigate();
    const [id, setId] = useState(null);
    const [token, setToken] = useState(null);
    const [bearerToken, setBearerToken] = useState(null);
    const [logoImg, setLogoImg] = useState('');
    const [showHighSchool, setShowHighSchool] = useState(false);
    const [showBachelors, setShowBachelors] = useState(false);
    const [showMasters, setShowMasters] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [checkboxChecked, setCheckboxChecked] = useState(false);
    const [formData, setFormData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});
    const dateInputRef = useRef(null);
    const [showPreviousEmployment, setShowPreviousEmployment] = useState(false);
    const [currentDate, setCurrentDate] = useState(new Date())
    const [formattedDate, setFormattedDate] = useState('')
    console.log(currentDate);



    const [singleEmpTokenDetail, setSingleEmpTokenDetail] = useState({
        referenceby: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        dob: "",
        email: "",
        blood_grp: "",
        phone: "",
        alt_phone: "",
        resume: "",
        address: "",
        apt_unit_address: "",
        city: "",
        state: "",
        pincode: "",
        des_startdate: "",
        des_salary: "",
        position: "",
        con_crime: "",
        yes_crime: "",
        hel_issue: "",
        yes_issue: "",
        university: "",
        location: "",
        stream: "",
        passing_year: "",
        relationship1: "",
        full_name1: "",
        full_name2: "",
        last_name1: "",
        last_name2: "",
        relationship2: "",
        occupation: "",
        occupation1: "",
        phone1: "",
        phone2: "",
        address1: "",
        address2: "",
        Experinced: "",
        showPreviousEmployment: false,
        company_precom: '',
        supervisor_phone: '',
        address_precom: '',
        supervisor: '',
        responsibility: '',
        job_title_precom: '',
        salary_precom: '',
        to_precom: '',
        reason_leaving: '',
        contactSupervisor: '',
        refence_super_yes: '',
        company_precom2: '',
        supervisor_phone2: '',
        address_precom2: '',
        supervisor2: '',
        responsibility2: '',
        designationtwo: '',
        salary_precom2: '',
        to_precom2: '',
        joining_from_precom2: '',
        joining_to_precom2: '',
        reason_leaving2: '',
        refence_super2_yes: '',
        signature: "",
        date: new Date().toISOString().split('T')[0],
        highschool_university: "",
        highschool_location: "",
        highschool_stream: "",
        highschool_passing_year: "",
        bachelors_university: "",
        bachelors_location: "",
        bachelors_stream: "",
        bachelors_passing_year: "",
        masters_university: "",
        masters_location: "",
        masters_stream: "",
        masters_passing_year: "",
        showHighSchool: false,
        showBachelors: false,
        showMasters: false,
        previous_employment: [
            {
                company_precom: '',
                supervisor_phone: '',
                address_precom: '',
                supervisor: '',
                responsibility: '',
                job_title_precom: '',
                salary_precom: '',
                end_precom: '',
                to_precom: '',
                joining_from_precom: '',
                joining_to_precom: '',
                reason_leaving: '',
                refence_super: '',
                refence_super_yes: '',
            }
        ],
        referance_blood_relation: {
            relationship1: "",
            full_name1: "",
            last_name1: "",
            occupation1: "",
            phone1: "",
            address1: ""
        },
        referance_friend: {
            full_name2: "",
            last_name2: "",
            relationship2: "",
            occupation: "",
            phone2: "",
            address2: ""
        },
        id,
        bearerToken,
        token,
    });


    const [state, setState] = useState({
        referenceby: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        dob: "",
        email: "",
        blood_grp: "",
        phone: "",
        alt_phone: "",
        resume: "",
        address: "",
        apt_unit_address: "",
        city: "",
        state: "",
        pincode: "",
        des_startdate: "",
        des_salary: "",
        position: "",
        con_crime: "",
        yes_crime: "",
        hel_issue: "",
        yes_issue: "",

        university: "",
        location: "",
        stream: "",
        passing_year: "",
        relationship1: "",
        full_name1: "",
        full_name2: "",
        last_name1: "",
        last_name2: "",
        relationship1: "",
        relationship2: "",
        occupation: "",
        occupation1: "",
        phone1: "",
        phone2: "",
        address1: "",
        address2: "",
        Experinced: "",
        showPreviousEmployment: false,
        company_precom: '',
        supervisor_phone: '',
        address_precom: '',
        supervisor: '',
        responsibility: '',
        job_title_precom: '',
        salary_precom: '',
        to_precom: '',

        reason_leaving: '',
        contactSupervisor: '',
        refence_super_yes: '',
        company_precom2: '',
        supervisor_phone2: '',
        address_precom2: '',
        supervisor2: '',
        responsibility2: '',
        designationtwo: '',
        salary_precom2: '',
        to_precom2: '',
        joining_from_precom2: '',
        joining_to_precom2: '',
        reason_leaving2: '',
        refence_super2_yes: '',
        signature: "",
        date: new Date().toISOString().split('T')[0],
        highschool_university: "",
        highschool_location: "",
        highschool_stream: "",
        highschool_passing_year: "",
        bachelors_university: "",
        bachelors_location: "",
        bachelors_stream: "",
        bachelors_passing_year: "",
        masters_university: "",
        masters_location: "",
        masters_stream: "",
        masters_passing_year: "",
        showHighSchool: false,
        showBachelors: false,
        showMasters: false,
        previous_employment: [
            {
                company_precom: '',
                supervisor_phone: '',
                address_precom: '',
                supervisor: '',
                responsibility: '',
                job_title_precom: '',
                salary_precom: '',
                end_precom: '',
                to_precom: '',
                joining_from_precom: '',
                joining_to_precom: '',
                reason_leaving: '',
                refence_super: '',
                refence_super_yes: '',
            }
        ],
        // Add the extracted parameters to the state
        id,
        bearerToken,
        token
    });


    const formatDate = (currentDate) => {
        const day = currentDate.getDate().toString().padStart(2, '0');
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const year = currentDate.getFullYear().toString()

        return `${day}-${month}-${year}`;
    };

    useEffect(() => {
        const dateString = formatDate(currentDate);
        setFormattedDate(dateString)
        console.log(dateString);

    }, [currentDate])




    // fetching id,token,bearerToken from url 
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        const id = urlParams.get('id');
        const adminName = urlParams.get('admin_name');
        const bearerToken = urlParams.get('bearerToken');

        if (bearerToken) {
            sessionStorage.setItem('bearerToken', bearerToken);
            setBearerToken(bearerToken);
            console.log(`Bearer Token: ${bearerToken}`);
        }

        if (token) {
            setToken(token);
            console.log(`Token: ${token}`);
        }

        if (id) {
            setId(id);
            console.log(`ID: ${id}`);
            handleviewtokenlogo(id);
        }

        if (adminName) {
            setAdminName(adminName);
            console.log(`Admin Name: ${adminName}`);
        }

        if (token && bearerToken) {
            handleviewsingleemployeetokendetail(token, bearerToken);
        }

        return () => {
        };
    }, []);


    // checks weather their is existing form or new form 
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const data = await viewsingleemployeetokendetail(token, bearerToken);
    //             const getCurrentDate = () => {
    //                 const date = new Date();
    //                 const day = String(date.getDate()).padStart(2, '0'); // Get day and pad with 0 if necessary
    //                 const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month and pad with 0 if necessary
    //                 const year = date.getFullYear(); // Get full year
    //                 return `${day}-${month}-${year}`; // Return date in dd-mm-yyyy format
    //             };
    //             if (data && data.length > 0 && data[0].application_info) {
    //                 // alert('if')
    //                 // setFormData(data[0].application_info);
    //                 const fetchedData = data[0].application_info;
    //                 const date = fetchedData.date || getCurrentDate();

    //                 // Set the form data and update `updated_at` with the value of `_id`
    //                 setFormData({
    //                     ...fetchedData,      // Spread the fetched data
    //                     updateId: fetchedData._id, // Update `updated_at` with the value of `_id`
    //                     date: date
    //                 });
    //             } else {
    //                 // alert('else')
    //                 const date = getCurrentDate();
    //                 setFormData({
    //                     first_name: '',
    //                     middle_name: '',
    //                     last_name: '',
    //                     dob: '',
    //                     email: '',
    //                     blood_grp: '',
    //                     phone: '',
    //                     alt_phone: '',
    //                     address: '',
    //                     apt_unit_address: '',
    //                     city: '',
    //                     state: '',
    //                     pincode: '',
    //                     des_startdate: '',
    //                     des_salary: '',
    //                     position: '',
    //                     con_crime: '',
    //                     yes_crime: null,
    //                     hel_issue: '',
    //                     yes_issue: null,
    //                     supervisor_ref: null,
    //                     supervisor_yes: null,
    //                     resume: '',
    //                     reference_firstName: '',
    //                     reference_middleName: '',
    //                     reference_lastName: '',
    //                     image_path: '',
    //                     admin_name: null,
    //                     admin_id: '',
    //                     token: '',
    //                     image: {
    //                         filename: '',
    //                         Originalname: '',
    //                         filepath: ''
    //                     },
    //                     education: {
    //                         // highschool_university: '',
    //                         // highschool_location: '',
    //                         // highschool_stream: '',
    //                         // highschool_passing_year: '',
    //                         // bachelors_university: '',
    //                         // bachelors_location: '',
    //                         // bachelors_stream: '',
    //                         // bachelors_passing_year: '',
    //                         // masters_university: '',
    //                         // masters_location: '',
    //                         // masters_stream: '',
    //                         // masters_passing_year: '',
    //                         // showHighSchool: false,
    //                         // showBachelors: false,
    //                         // showMasters: false

    //                         institute_name: '',
    //                         institute_location: '',
    //                         institute_stream: '',
    //                         institute_passing_year: '',
    //                     },
    //                     referance_blood_relation: {
    //                         full_name1: '',
    //                         last_name1: '',
    //                         relationship1: '',
    //                         occupation1: '',
    //                         phone1: '',
    //                         address1: ''
    //                     },
    //                     referance_friend: {
    //                         full_name2: '',
    //                         last_name2: '',
    //                         relationship2: '',
    //                         occupation: '',
    //                         phone2: '',
    //                         address2: '',
    //                         // fresher: false,
    //                         // experience: false
    //                         experienceLevel: '',
    //                     },
    //                     previous_employment: [{
    //                         company: '',
    //                         authority_phone: '',
    //                         address: '',
    //                         authority_name: '',
    //                         responsibility: '',
    //                         job_title: '',
    //                         salary: '',
    //                         // end_precom: '',
    //                         // to_precom: '',
    //                         joined_from: '',
    //                         joined_to: '',
    //                         reason_leaving: '',
    //                         reference_authority: '',
    //                         reference_authority_no_reason: ''
    //                     }],
    //                     signature: '',
    //                     date: date,
    //                     id,
    //                     bearerToken,
    //                     token,
    //                     delete_status: 'NO',
    //                     created_at: '',
    //                     updated_at: '',
    //                     updateId: '',
    //                 });
    //             }
    //         } catch (error) {
    //             console.error('Error fetching employee data:', error);
    //         } finally {
    //             setLoading(false);
    //         }
    //     };

    //     fetchData();
    // }, [token, bearerToken]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await viewsingleemployeetokendetail(token, bearerToken);

                // Function to get the current date in yyyy-mm-dd format
                const getCurrentDate = () => {
                    const date = new Date();
                    const day = String(date.getDate()).padStart(2, '0');
                    const month = String(date.getMonth() + 1).padStart(2, '0');
                    const year = date.getFullYear();
                    return `${year}-${month}-${day}`; // Return date in yyyy-mm-dd format
                };

                if (data && data.length > 0 && data[0].application_info) {
                    const fetchedData = data[0].application_info;
                    const date = fetchedData.date || getCurrentDate(); // Use fetched date or current date

                    // Set the form data and update `updated_at` with the value of `_id`
                    setFormData({
                        ...fetchedData,      // Spread the fetched data
                        updateId: fetchedData._id, // Update `updated_at` with the value of `_id`
                        date: date // Set the date field
                    });
                } else {
                    // If no data is found, set the current date
                    const date = getCurrentDate();
                    setFormData({
                        first_name: '',
                        middle_name: '',
                        last_name: '',
                        dob: '',
                        email: '',
                        blood_grp: '',
                        phone: '',
                        alt_phone: '',
                        address: '',
                        apt_unit_address: '',
                        city: '',
                        state: '',
                        pincode: '',
                        des_startdate: '',
                        des_salary: '',
                        position: '',
                        con_crime: '',
                        yes_crime: null,
                        hel_issue: '',
                        yes_issue: null,
                        supervisor_ref: null,
                        supervisor_yes: null,
                        resume: '',
                        reference_firstName: '',
                        reference_middleName: '',
                        reference_lastName: '',
                        image_path: '',
                        admin_name: null,
                        admin_id: '',
                        token: '',
                        image: {
                            filename: '',
                            Originalname: '',
                            filepath: ''
                        },
                        education: {
                            institute_name: '',
                            institute_location: '',
                            institute_stream: '',
                            institute_passing_year: '',
                        },
                        referance_blood_relation: {
                            full_name1: '',
                            last_name1: '',
                            relationship1: '',
                            occupation1: '',
                            phone1: '',
                            address1: ''
                        },
                        referance_friend: {
                            full_name2: '',
                            last_name2: '',
                            relationship2: '',
                            occupation: '',
                            phone2: '',
                            address2: '',
                            experienceLevel: '',
                        },
                        previous_employment: [{
                            company: '',
                            authority_phone: '',
                            address: '',
                            authority_name: '',
                            responsibility: '',
                            job_title: '',
                            salary: '',
                            joined_from: '',
                            joined_to: '',
                            reason_leaving: '',
                            reference_authority: '',
                            reference_authority_no_reason: ''
                        }],
                        signature: '',
                        date: date, // Set current date when no data is available
                        id,
                        bearerToken,
                        token,
                        delete_status: 'NO',
                        created_at: '',
                        updated_at: '',
                        updateId: '',
                    });
                }
            } catch (error) {
                console.error('Error fetching employee data:', error);
            } finally {
                setLoading(false);
            }
        };

        // Call fetchData after the component mounts
        fetchData();
    }, [token, bearerToken]); // Dependencies to trigger useEffect






    const handleviewtokenlogo = async (id) => {
        const response = await viewtokenlogo(id);

        setLogoImg(response);

        console.log(response);
    }

    const handleviewsingleemployeetokendetail = async (token, bearerToken) => {
        const response = await viewsingleemployeetokendetail(token, bearerToken);


        if (response) {

            setSingleEmpTokenDetail(response[0].application_info);
        }

        // console.log(response[0].application_info.referance_blood_relation);
        // console.log(singleEmpTokenDetail);
    }



    const handleAgreeCheckboxChange = (e) => {
        setCheckboxChecked(e.target.checked);
    };

    const handleCheckboxChange = (checkboxName) => {
        if (checkboxName === 'highschool') {
            setShowHighSchool(!showHighSchool);
        } else if (checkboxName === 'bachelors') {
            setShowBachelors(!showBachelors);
        } else if (checkboxName === 'masters') {
            setShowMasters(!showMasters);
        }
    };


    const iconStyle = {
        marginRight: "0.5rem",
        color: "#4a9f56"
    };



    const addPreviousEmployment = () => {
        setFormData((prevState) => ({
            ...prevState,
            previous_employment: [
                ...prevState.previous_employment,
                {
                    company: '',
                    authority_phone: '',
                    address: '',
                    authority_name: '',
                    responsibility: '',
                    job_title: '',
                    salary: '',
                    // end_precom: '',
                    // to_precom: '',
                    joined_from: '',
                    joined_to: '',
                    reason_leaving: '',
                    reference_authority: '',
                    reference_authority_no_reason: ''
                }
            ]
        }));
    };

    const removePreviousEmployment = (index) => {
        setFormData((prevState) => ({
            ...prevState,
            previous_employment: prevState.previous_employment.filter((_, i) => i !== index),
        }));
    };


    const handleSupervisorContactChange = (e) => {
        const { value } = e.target;
        setState((prevState) => ({
            ...prevState,
            refence_super: value,
            refence_super_yes: value === "no" ? "" : prevState.refence_super_yes, // Clear the reason if selecting "yes"
        }));
    };



    const handlePreviousEmploymentChange = (index, event) => {
        const { name, value } = event.target;
        const newPreviousEmployment = formData.previous_employment.map((item, i) => {
            if (i === index) {
                return { ...item, [name]: value };
            }
            return item;
        });
        setFormData({ ...formData, previous_employment: newPreviousEmployment });
    };


    const validateForm = (state) => {
        let errors = {};

        const checkField = (field, fieldName, displayName) => {
            if (field === undefined || field === null || field === '') {
                errors[fieldName] = `${displayName} is required`;
            }
        };

        const fieldsToCheck = [
            ["referenceby", "Reference"],
            ["first_name", "First Name"],
            ["last_name", "Last Name"],
            ["dob", "Date of Birth"],
            ["email", "Email"],
            ["blood_grp", "Blood Group"],
            ["phone", "Phone"],
            ["address", "Address"],
            ["apt_unit_address", "Apartment/Unit Address"],
            ["city", "City"],
            ["state", "State"],
            ["pincode", "Pincode"],
            ["des_startdate", "Start Date"],
            ["des_salary", "Salary"],
            ["position", "Position"],
            ["resume", "Resume"],
            ["image_path", "Profile"],
            ["token", "Token"],
            ["signature", "Signature"],
            ["date", "Date"]
        ];

        fieldsToCheck.forEach(([field, displayName]) => {
            checkField(state?.[field], field, displayName);
        });

        if (state?.con_crime == "yes") {
            checkField(state?.yes_crime, "yes_crime", "Crime Explanation");
        }

        if (state?.hel_issue == "yes") {
            checkField(state?.yes_issue, "yes_issue", "Health Issue Explanation");
        }
        if (state?.con_crime == "") {
            checkField(state?.con_crime, "con_crime", "Please select a value for the committed crime.");
        }

        if (state?.hel_issue == "") {
            checkField(state?.hel_issue, "hel_issue", "Please select a value for the health issue.");
        }

        const checkEducationFields = (showField, educationFields) => {
            if (showField) {
                educationFields.forEach(([field, displayName]) => {
                    checkField(state?.education?.[field], `education.${field}`, displayName);
                });
            }
        };

        checkEducationFields(showHighSchool, [
            ["institute_name", "High School University"],
            ["institute_location", "High School Location"],
            ["institute_stream", "High School Stream"],
            ["institute_passing_year", "High School Passing Year"]
        ]);

        // Check if experienceLevel is not "Fresher"
        if (state?.referance_friend?.experienceLevel !== "fresher") {
            const excludeFields = ["refence_super", "refence_super_no_reason", "address_precom", "responsibility"];

            if (Array.isArray(state?.previous_employment)) {
                state.previous_employment.forEach((item, index) => {
                    Object.entries(item).forEach(([key, value]) => {
                        if (!excludeFields.includes(key)) {
                            checkField(value, `previous_employment[${index}].${key}`, key.replace(/_/g, ' '));
                        }
                    });

                    if (!item.reference_authority) {
                        errors[`previous_employment[${index}].reference_authority`] = 'Can we contact your supervisor is required';
                    }

                    if (item.reference_authority === "no" && !item.reference_authority_no_reason) {
                        errors[`previous_employment[${index}].reference_authority_no_reason`] = 'If no, please explain';
                    }

                    if (item.reference_authority === "yes") {
                        delete errors[`previous_employment[${index}].reference_authority_no_reason`];
                    }
                });
            }
        }

        const nestedFields = {
            referance_blood_relation: [
                ["full_name1", "First Name"],
                ["last_name1", "Last Name"],
                ["relationship1", "Relationship"],
                ["occupation1", "Occupation"],
                ["phone1", "Phone"],
                ["address1", "Address"]
            ],
            referance_friend: [
                ["full_name2", "First Name"],
                ["last_name2", "Last Name"],
                ["relationship2", "Relationship"],
                ["occupation", "Occupation"],
                ["phone2", "Phone"],
                ["address2", "Address"],
                ["experienceLevel", "Experience Level"]
            ]
        };

        Object.entries(nestedFields).forEach(([section, fields]) => {
            if (Array.isArray(state[section])) {
                state[section].forEach((item, index) => {
                    fields.forEach(([field, displayName]) => {
                        checkField(item?.[field], `${section}[${index}].${field}`, displayName);
                    });
                });
            } else {
                fields.forEach(([field, displayName]) => {
                    checkField(state[section]?.[field], `${section}.${field}`, displayName);
                });
            }
        });

        if (!checkboxChecked) {
            errors.checkbox = 'You must agree to the above statement';
        }

        // Debugging: Log the state and errors
        console.log("State:", state);
        console.log("Errors:", errors);

        return errors;
    };

    // const handleChange = (event) => {
    //     const { name, value, type, files } = event.target;

    //     console.log(name, files);



    //     if (type === 'file') {
    //         const file = files[0]; // Get the first file

    //         // Manually log the properties you want
    //         console.log("File name:", file.name);
    //         console.log("File size:", file.size);
    //         console.log("File type:", file.type);
    //         console.log("Last modified date:", file.lastModifiedDate);
    //         // Handle file inputs and set the first file from the file input to formData
    //         setFormData(prevState => ({
    //             ...prevState,
    //             [name]: files[0] // Assumes you're uploading a single file
    //         }));
    //     } else {
    //         // Split the name to determine if it's a nested property
    //         const keys = name.split('.');

    //         if (keys.length > 1) {
    //             // Handle nested properties
    //             const [category, field] = keys;
    //             setFormData(prevState => ({
    //                 ...prevState,
    //                 [category]: {
    //                     ...prevState[category],
    //                     [field]: value
    //                 }
    //             }));
    //         } else {
    //             // Handle flat properties
    //             setFormData(prevState => ({
    //                 ...prevState,
    //                 [name]: value
    //             }));
    //         }
    //     }
    // };


    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    // const handleChange = async (event) => {
    //     const { name, type, files, value } = event.target;

    //     if (type === 'file') {
    //         const file = files[0]; // Get the first file

    //         if (file) {
    //             // Define file type and size limitations
    //             const allowedTypes = ['application/pdf'];
    //             const maxSizeMB = 2; // Maximum file size in megabytes
    //             const maxSizeBytes = maxSizeMB * 1024 * 1024;

    //             // Validate file type
    //             if (!allowedTypes.includes(file.type)) {
    //                 toast.error('Only PDF files are allowed.');
    //                 event.target.value = ''; // Clear the file input
    //                 return;
    //             }

    //             // Validate file size
    //             if (file.size > maxSizeBytes) {
    //                 toast.error(`File size exceeds ${maxSizeMB}MB limit.`);
    //                 event.target.value = ''; // Clear the file input
    //                 return;
    //             }

    //             try {
    //                 // Convert file to Base64
    //                 const base64File = await fileToBase64(file);
    //                 setFormData(prevState => ({
    //                     ...prevState,
    //                     [name]: base64File
    //                 }));
    //             } catch (error) {
    //                 console.error("Error converting file to Base64:", error);
    //                 toast.error("Failed to process file. Please try again.");
    //             }
    //         } else {
    //             toast.error('No file selected.');
    //         }
    //     } else {
    //         // Handling other input types
    //         const keys = name.split('.');

    //         if (keys.length > 1) {
    //             const [category, field] = keys;
    //             setFormData(prevState => ({
    //                 ...prevState,
    //                 [category]: {
    //                     ...prevState[category],
    //                     [field]: value
    //                 }
    //             }));
    //         } else {
    //             setFormData(prevState => ({
    //                 ...prevState,
    //                 [name]: value
    //             }));
    //         }
    //     }
    // };







    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     if (submitting) {
    //         return;
    //     }

    //     setSubmitting(true);

    //     const errors = validateForm(formData);
    //     if (Object.keys(errors).length > 0) {
    //         setErrors(errors);
    //         Object.values(errors).forEach((error) => {
    //             toast.error(error);
    //         });
    //         setSubmitting(false);
    //         return;
    //     } else {
    //         setErrors({});
    //     }


    //     const { previous_employment, referance_friend, referance_blood_relation, education, ...rest } = formData;

    //     const dataToSend = {
    //         ...rest,
    //         ...referance_friend,
    //         ...referance_blood_relation,
    //         ...education,
    //         previous_employment,
    //         id,
    //         token,
    //         bearerToken
    //     };

    //     console.log(dataToSend);

    //     try {
    //         const response = await addCandidateInfo(dataToSend);

    //         console.log('Response:', response);

    //         if (response.message) {
    //             toast.success(response.message, {
    //                 position: "top-center",
    //                 autoClose: 1000,
    //             });
    //             navigate("/Thank-you");
    //         } else {
    //             toast.error(response.message, {
    //                 position: "top-center",
    //                 autoClose: 1000,
    //             });
    //         }
    //     } catch (error) {
    //         toast.error("An error occurred while submitting the form.", {
    //             position: "top-center",
    //             autoClose: 1000,
    //         });
    //     } finally {
    //         setSubmitting(false);
    //     }
    // };

    const handleChange1 = async (event) => {
        const { name, type, files, value } = event.target;

        if (type === 'file') {
            const file = files[0]; // Get the first file

            if (file) {
                let allowedTypes;
                let maxSizeMB = 2; // Default to 2MB

                // Check the name to determine allowed file types and size
                if (name === 'resume') {
                    allowedTypes = ['application/pdf'];
                } else if (name === 'image_path') {
                    allowedTypes = ['image/jpeg', 'image/png'];
                    maxSizeMB = 1; // Example max size for image
                } else {
                    toast.error('Invalid file type.', {
                        position: "top-center",
                        autoClose: 1000,
                    });
                    return;
                }

                const maxSizeBytes = maxSizeMB * 1024 * 1024;

                // Validate file type
                if (!allowedTypes.includes(file.type)) {
                    toast.error(`Only ${allowedTypes.join(', ')} files are allowed.`, {
                        position: "top-center",
                        autoClose: 1000,
                    });
                    event.target.value = ''; // Clear the file input
                    return;
                }

                // Validate file size
                if (file.size > maxSizeBytes) {
                    toast.error(`File size exceeds ${maxSizeMB}MB limit.`, {
                        position: "top-center",
                        autoClose: 1000,
                    });
                    event.target.value = ''; // Clear the file input
                    return;
                }

                try {
                    // Convert file to Base64
                    const base64File = await fileToBase64(file);
                    setFormData(prevState => ({
                        ...prevState,
                        [name]: base64File
                    }));
                } catch (error) {
                    console.error("Error converting file to Base64:", error);
                    toast.error("Failed to process file. Please try again.", {
                        position: "top-center",
                        autoClose: 1000,
                    });
                }
            } else {
                toast.error('No file selected.', {
                    position: "top-center",
                    autoClose: 1000,
                });
            }
        } else {
            // Handling other input types
            const keys = name.split('.');

            if (keys.length > 1) {
                const [category, field] = keys;
                setFormData(prevState => ({
                    ...prevState,
                    [category]: {
                        ...prevState[category],
                        [field]: value
                    }
                }));
            } else {
                setFormData(prevState => ({
                    ...prevState,
                    [name]: value
                }));
            }
        }
    };

    const handleChange = async (event) => {
        const { name, type, files, value } = event.target;

        if (type === 'file') {
            const file = files[0]; // Get the first file
            console.log(files[0]);


            if (file) {
                let allowedTypes;
                let maxSizeMB = 10; // Default to 10MB for PDF

                // Check the name to determine allowed file types and size
                if (name === 'resume') {
                    allowedTypes = ['application/pdf'];
                } else if (name === 'image_path') {
                    allowedTypes = ['image/jpeg', 'image/png'];
                    maxSizeMB = 10; // Max size for image
                } else {
                    toast.error('Invalid file type.', {
                        position: "top-center",
                        autoClose: 1000,
                    });
                    return;
                }

                const maxSizeBytes = maxSizeMB * 1024 * 1024;

                // Validate file type
                if (!allowedTypes.includes(file.type)) {
                    toast.error(`Only ${allowedTypes.join(', ')} files are allowed.`, {
                        position: "top-center",
                        autoClose: 1000,
                    });
                    event.target.value = ''; // Clear the file input
                    return;
                }

                // Validate file size
                if (file.size > maxSizeBytes) {
                    toast.error(`File size exceeds ${maxSizeMB}MB limit.`, {
                        position: "top-center",
                        autoClose: 1000,
                    });
                    event.target.value = ''; // Clear the file input
                    return;
                }

                try {
                    if (name === 'image_path') {
                        // Compress the image if it's larger than 1 MB
                        if (file.size > 1024 * 1024) { // 1 MB
                            const options = {
                                maxSizeMB: 1, // Compress to 1 MB
                                maxWidthOrHeight: 1920,
                                useWebWorker: true,
                            };
                            const compressedFile = await imageCompression(file, options);
                            const base64File = await fileToBase64(compressedFile);
                            console.log(base64File);

                            setFormData(prevState => ({
                                ...prevState,
                                [name]: base64File
                            }));
                        } else {
                            // Convert file to Base64 for non-compressed image
                            const base64File = await fileToBase64(file);
                            setFormData(prevState => ({
                                ...prevState,
                                [name]: base64File
                            }));
                        }
                    } else {
                        // Convert file to Base64 for resume (PDF)
                        const base64File = await fileToBase64(file);
                        console.log(base64File);

                        setFormData(prevState => ({
                            ...prevState,
                            [name]: base64File
                        }));
                    }
                } catch (error) {
                    console.error("Error processing file:", error);
                    toast.error("Failed to process file. Please try again.", {
                        position: "top-center",
                        autoClose: 1000,
                    });
                }
            } else {
                toast.error('No file selected.', {
                    position: "top-center",
                    autoClose: 1000,
                });
            }
        } else {
            // Handling other input types
            const keys = name.split('.');

            if (keys.length > 1) {
                const [category, field] = keys;
                setFormData(prevState => ({
                    ...prevState,
                    [category]: {
                        ...prevState[category],
                        [field]: value
                    }

                }));
            }

            else {
                // setFormData(prevState => ({
                //     ...prevState,
                //     [name]: value
                // }));
                if (name == "reference_firstName" || name == "reference_middleName" || name == "reference_lastName") {
                    // Get the current values of the reference name fields
                    const firstName = formData.reference_firstName || "";
                    const middleName = formData.reference_middleName || "";
                    const lastName = formData.reference_lastName || "";

                    // Concatenate the first name, middle name, and last name with spaces
                    const fullName = [firstName, middleName, lastName].filter(Boolean).join(" ");

                    // Update the form data with the full reference name
                    setFormData(prevState => ({
                        ...prevState,
                        [name]: value, // Update the individual name field
                        referenceby: fullName // Update the full reference name
                    }));
                } else {
                    setFormData(prevState => ({
                        ...prevState,
                        [name]: value
                    }));
                }
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (submitting) {
            return;
        }

        setSubmitting(true);

        const errors = validateForm(formData);
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            // Object.values(errors).forEach((error) => {
            //     toast.error(error);
            // });
            setSubmitting(false);
            return;
        } else {
            setErrors({});
        }

        const { previous_employment, referance_friend, referance_blood_relation, education, ...rest } = formData;

        const dataToSend = {
            ...rest,
            ...referance_friend,
            ...referance_blood_relation,
            ...education,
            previous_employment,
            id,
            token,
            bearerToken
        };

        console.log(dataToSend); // Base64 file will be part of this

        try {
            const response = await addCandidateInfo(dataToSend);

            console.log('Response:', response);

            if (response.message) {
                toast.success(response.message, {
                    position: "top-center",
                    autoClose: 1000,
                });
                navigate("/Thank-you");
            } else {
                toast.error(response.message, {
                    position: "top-center",
                    autoClose: 1000,
                });
            }
        } catch (error) {
            toast.error("An error occurred while submitting the form.", {
                position: "top-center",
                autoClose: 1000,
            });
        } finally {
            setSubmitting(false);
        }
    };

    const convertDateToYMD = (dateStr) => {
        const [day, month, year] = dateStr.split('-');  // Split the date into day, month, and year
        return `${year}-${month}-${day}`; // Return in yyyy-mm-dd format
    };



    return (
        <React.Fragment>
            <div className="p-2 p-lg-5 mt-2">

                <div className="row candidateDiv">
                    <div className="col-md-12 formcontrols">
                        <div
                            className="bg-white p-0 rounded"
                            style={{
                                height: "100%",
                                boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                                borderRadius: "10px",
                                overflow: "hidden",
                                border: "1px solid black"
                            }}
                        >
                            <div>
                                <img src={logoImg} alt="logo" style={{
                                    width: '10%', display: 'table', margin: ' 10px auto 0'
                                }} />
                                <div>
                                    <div class="card-header p-3 h2 text-center mb-3" style={{ background: '#cce5ff', margin: '20px 0' }}>
                                        Employment Application Form
                                    </div>
                                    <div className="d-flex align-items-center">
                                        {/* <div className="p-3 pb-0">
                                            <label htmlFor="referenceby" className="form-label fw-bold title_small" style={{ color: '#0c76e4', fontSize: '1rem', fontWeight: '600' }}>Reference:</label>
                                        </div> */}
                                        {/* <div className="mb-3 flex-grow-1">
                                            <input
                                                type="text"
                                                // className="form-control "
                                                className={`border-0 border-bottom border-2 form-control ${errors.referenceby && "is-invalid"}`}
                                                id="referenceby"
                                                name="referenceby"
                                                value={formData?.referenceby || ""}
                                                onChange={handleChange}
                                            // placeholder="Enter reference name"

                                            />
                                            {errors.referenceby && (
                                                <div className="invalid-feedback">{errors.referenceby}</div>
                                            )}
                                        </div> */}
                                        <div className="col-md-4 ps-4">
                                            <div className="mb-3 custom-input">
                                                <input
                                                    type="text"
                                                    className={`form-control ${errors.phone && "is-invalid"}`}
                                                    id="referenceby"
                                                    name="referenceby"
                                                    value={formData?.referenceby || ""}
                                                    onChange={handleChange}
                                                // placeholder="Enter reference"
                                                />
                                                <label htmlFor="referenceby">Reference<span style={{ color: 'red', fontSize: '1.2em' }}>*</span></label>
                                                {errors.referenceby && <div className="invalid-feedback">{errors.referenceby}</div>}
                                            </div>
                                        </div>
                                        {/* <div className="col-md-4">
                                            <div className="mb-3 custom-input">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="reference_middleName"
                                                    name="reference_middleName"
                                                    value={formData?.reference_middleName || ""}
                                                    onChange={handleChange}
                                                    placeholder="Enter reference middle name"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="mb-3 custom-input">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="reference_lastName"
                                                    name="reference_lastName"
                                                    value={formData?.reference_lastName || ""}
                                                    onChange={handleChange}
                                                    placeholder="Enter reference last name"
                                                />
                                            </div>
                                        </div> */}

                                    </div>
                                    <form onSubmit={handleSubmit} className="p-4">
                                        {/* <div className="row">

                                            <div className="col-md-4">
                                                <div className="mb-3 custom-input">
                                                    <input
                                                        type="text"
                                                        className={`form-control ${errors.referenceby && "is-invalid"}`}
                                                        id="referenceby"
                                                        name="referenceby"
                                                        value={formData?.referenceby || ""}
                                                        onChange={handleChange}
                                                        placeholder=""
                                                    />
                                                    <label htmlFor="referenceby"><span style={{ fontWeight: '600' }}></span>First Name<span style={{ color: 'red', fontSize: '1.2em', paddingLeft: '0.2rem' }}>*</span>
                                                    </label>
                                                    {errors.referenceby && (
                                                        <div className="invalid-feedback">{errors.referenceby}</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="row">

                                            <div className="col-md-4">
                                                <div className="mb-3 custom-input">
                                                    <input
                                                        type="text"
                                                        className={`form-control ${errors.first_name && "is-invalid"}`}
                                                        id="first_name"
                                                        name="first_name"
                                                        value={formData?.first_name || ""}
                                                        onChange={handleChange}
                                                        placeholder=""
                                                    />
                                                    <label htmlFor="first_name"><span style={{ fontWeight: '600' }}></span>First Name<span style={{ color: 'red', fontSize: '1.2em', paddingLeft: '0.2rem' }}>*</span>
                                                    </label>
                                                    {errors.first_name && (
                                                        <div className="invalid-feedback">{errors.first_name}</div>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="mb-3 custom-input">
                                                    <input
                                                        type="text"
                                                        className={`form-control ${errors.middle_name && "is-invalid"}`}
                                                        id="middle_name"
                                                        name="middle_name"
                                                        value={formData?.middle_name || ""}
                                                        onChange={handleChange}
                                                        placeholder=" "
                                                    />
                                                    <label htmlFor="middle_name">Middle Name<span style={{ color: 'white' }}>*</span></label>

                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-3 custom-input">
                                                    <input
                                                        type="text"
                                                        className={`form-control ${errors.last_name && "is-invalid"}`}
                                                        id="last_name"
                                                        name="last_name"
                                                        value={formData?.last_name || ""}
                                                        onChange={handleChange}
                                                        placeholder=" "
                                                    />
                                                    <label htmlFor="last_name">Last Name<span style={{ color: 'red', fontSize: '1.2em' }}>*</span>
                                                    </label>
                                                    {errors.last_name && (
                                                        <div className="invalid-feedback">{errors.last_name}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-3 custom-input2">
                                                    <input
                                                        type="date"
                                                        className={`form-control ${errors.dob && "is-invalid"} `}
                                                        id="dob"
                                                        name="dob"
                                                        value={formData?.dob || ""}
                                                        onChange={handleChange}
                                                        placeholder=" "
                                                    />
                                                    <label htmlFor="dob">Date of Birth<span style={{ color: 'red', fontSize: '1.2em' }}>*</span>
                                                    </label>
                                                    {errors.dob && (
                                                        <div className="invalid-feedback">{errors.dob}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-3 custom-input">
                                                    <input
                                                        type="email"
                                                        className={`form-control ${errors.email && "is-invalid"}`}
                                                        id="email"
                                                        name="email"
                                                        value={formData?.email || ""}
                                                        onChange={handleChange}
                                                        placeholder=" "
                                                    />
                                                    <label htmlFor="email">Email<span style={{ color: 'red', fontSize: '1.2em' }}>*</span>
                                                    </label>
                                                    {errors.email && (
                                                        <div className="invalid-feedback">{errors.email}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-3 custom-input">
                                                    <input
                                                        type="text"
                                                        className={`form-control ${errors.blood_grp && "is-invalid"}`}
                                                        id="blood_grp"
                                                        name="blood_grp"
                                                        value={formData?.blood_grp || ""}
                                                        onChange={handleChange}
                                                        placeholder=" "
                                                    />
                                                    <label htmlFor="blood_grp">Blood Group<span style={{ color: 'red', fontSize: '1.2em' }}>*</span></label>
                                                    {errors.blood_grp && (
                                                        <div className="invalid-feedback">{errors.blood_grp}</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="mb-3 custom-input">
                                                    <input
                                                        type="text"
                                                        className={`form-control ${errors.phone && "is-invalid"}`}
                                                        id="phone"
                                                        name="phone"
                                                        value={formData?.phone || ""}
                                                        onChange={handleChange}
                                                        placeholder=""
                                                    />
                                                    <label htmlFor="phone">Phone<span style={{ color: 'red', fontSize: '1.2em' }}>*</span></label>
                                                    {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-3 custom-input">
                                                    <input
                                                        type="text"
                                                        className={`form-control ${errors.alt_phone && "is-invalid"}`}
                                                        id="alt_phone"
                                                        name="alt_phone"
                                                        value={formData?.alt_phone || ""}
                                                        onChange={handleChange}
                                                        placeholder=" "
                                                    />
                                                    <label htmlFor="alt_phone">Alternate Phone</label>
                                                    {errors.alt_phone && (
                                                        <div className="invalid-feedback">{errors.alt_phone}</div>
                                                    )}
                                                </div>
                                            </div>
                                            {/* <div className="col-md-4">
                                                <div className="mb-3 custom-input2">
                                                    <input
                                                        type="file"
                                                        className={`form-control ${errors.resume && "is-invalid"}`}
                                                        id="resume"
                                                        name="resume"
                                                        onChange={handleChange}
                                                    />
                                                    <label htmlFor="resume">Resume<span style={{ color: 'red', fontSize: '1.2em' }}>*</span></label>
                                                    {errors.resume && (
                                                        <div className="invalid-feedback">{errors.resume}</div>
                                                    )}
                                                </div>
                                            </div> */}
                                            <div className="col-md-4">
                                                <div className="mb-3 custom-input2">

                                                    <input
                                                        type="file"
                                                        className={`form-control ${errors.resume && "is-invalid"}`}
                                                        name="resume"
                                                        id="resume"
                                                        accept=".pdf,.doc,.docx"
                                                        onChange={handleChange}
                                                    />

                                                    <label htmlFor="resume">Resume<span style={{ color: 'red', fontSize: '1.2em' }}>*</span>(pdf)</label>
                                                    {errors.resume && (
                                                        <div className="invalid-feedback">{errors.resume}</div>
                                                    )}
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="mb-3 custom-input">
                                                    <input
                                                        type="text"
                                                        className={`form-control ${errors.address && "is-invalid"}`}
                                                        id="address"
                                                        name="address"
                                                        value={formData?.address || ""}
                                                        onChange={handleChange}
                                                        placeholder=" "
                                                    />
                                                    <label htmlFor="address">Address<span style={{ color: 'red', fontSize: '1.2em' }}>*</span>
                                                    </label>
                                                    {errors.address && (
                                                        <div className="invalid-feedback">{errors.address}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-3 custom-input">
                                                    <input
                                                        type="text"
                                                        className={`form-control ${errors.apt_unit_address && "is-invalid"}`}
                                                        id="apt_unit_address"
                                                        name="apt_unit_address"
                                                        value={formData?.apt_unit_address || ""}
                                                        onChange={handleChange}
                                                        placeholder=" "
                                                    />
                                                    <label htmlFor="apt_unit_address">Apartment Unit Address<span style={{ color: 'red', fontSize: '1.2em' }}>*</span>
                                                    </label>
                                                    {errors.apt_unit_address && (
                                                        <div className="invalid-feedback">{errors.apt_unit_address}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-3 custom-input">
                                                    <input
                                                        type="text"
                                                        className={`form-control ${errors.city && "is-invalid"}`}
                                                        id="city"
                                                        name="city"
                                                        value={formData?.city || ""}
                                                        onChange={handleChange}
                                                        placeholder=" "
                                                    />
                                                    <label htmlFor="city">City<span style={{ color: 'red', fontSize: '1.2em' }}>*</span>
                                                    </label>
                                                    {errors.city && (
                                                        <div className="invalid-feedback">{errors.city}</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="mb-3 custom-input">
                                                    <input
                                                        type="text"
                                                        className={`form-control ${errors.state && "is-invalid"}`}
                                                        id="state"
                                                        name="state"
                                                        value={formData?.state || ""}
                                                        onChange={handleChange}
                                                        placeholder=" "
                                                    />
                                                    <label htmlFor="state">State<span style={{ color: 'red', fontSize: '1.2em' }}>*</span>
                                                    </label>
                                                    {errors.state && (
                                                        <div className="invalid-feedback">{errors.state}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-3 custom-input">
                                                    <input
                                                        type="text"
                                                        className={`form-control ${errors.pincode && "is-invalid"}`}
                                                        id="pincode"
                                                        name="pincode"
                                                        value={formData?.pincode || ""}
                                                        onChange={handleChange}
                                                        placeholder=" "
                                                    />
                                                    <label htmlFor="pincode">Pincode<span style={{ color: 'red', fontSize: '1.2em' }}>*</span>
                                                    </label>
                                                    {errors.pincode && (
                                                        <div className="invalid-feedback">{errors.pincode}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-3 custom-input2">
                                                    <input
                                                        type="date"
                                                        className={`form-control ${errors.des_startdate && "is-invalid"}`}
                                                        id="des_startdate"
                                                        name="des_startdate"
                                                        value={formData?.des_startdate || ""}
                                                        onChange={handleChange}
                                                        placeholder=" "
                                                    />
                                                    <label htmlFor="des_startdate">Expected Start Date<span style={{ color: 'red', fontSize: '1.2em' }}>*</span>
                                                    </label>
                                                    {errors.des_startdate && (
                                                        <div className="invalid-feedback">{errors.des_startdate}</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="mb-3 custom-input">
                                                    <input
                                                        type="text"
                                                        className={`form-control ${errors.des_salary && "is-invalid"}`}
                                                        id="des_salary"
                                                        name="des_salary"
                                                        value={formData?.des_salary || ""}
                                                        onChange={handleChange}
                                                        placeholder=" "
                                                    />
                                                    <label htmlFor="des_salary">Expected Salary<span style={{ color: 'red', fontSize: '1.2em' }}>*</span>
                                                    </label>
                                                    {errors.des_salary && (
                                                        <div className="invalid-feedback">{errors.des_salary}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-3 custom-input">
                                                    <input
                                                        type="text"
                                                        className={`form-control ${errors.position && "is-invalid"}`}
                                                        id="position"
                                                        name="position"
                                                        value={formData?.position || ""}
                                                        onChange={handleChange}
                                                        placeholder=" "
                                                    />
                                                    <label htmlFor="position">Preferred Position<span style={{ color: 'red', fontSize: '1.2em' }}>*</span></label>
                                                    {errors.position && (
                                                        <div className="invalid-feedback">{errors.position}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-3 custom-input2">
                                                    <input
                                                        type="file"
                                                        className={`form-control ${errors.image_path && "is-invalid"}`}
                                                        name="image_path"
                                                        id="image_path"
                                                        accept=".jpeg,.png,.jpg"
                                                        // accept=".pdf,.doc,.docx"
                                                        onChange={handleChange}
                                                    />

                                                    <label htmlFor="image_path">Profile Image(add your selfie)<span style={{ color: 'red', fontSize: '1.2em' }}>*</span></label>
                                                    {errors.image_path && (
                                                        <div className="invalid-feedback">{errors.image_path}</div>
                                                    )}
                                                </div>
                                            </div>
                                            {/* <div className="col-md-4">
                                                <label htmlFor="con_crime">Have you ever been convicted of a crime? <span style={{ color: 'red', fontSize: '1.2em' }}>*</span>
                                                </label>
                                                <div className="mb-3 custom-select-back">
                                                    <select
                                                        className={`form-select ${errors.con_crime && "is-invalid"}`}
                                                        id="con_crime"
                                                        name="con_crime"
                                                        value={formData?.con_crime || ""}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="yes">Yes</option>
                                                        <option value="no">No</option>
                                                    </select>

                                                    {errors.con_crime && (
                                                        <div className="invalid-feedback">{errors.con_crime}</div>
                                                    )}
                                                </div>
                                            </div>

                                            {formData?.con_crime === "yes" && (
                                                <div className="col-md-12">
                                                    <div className="mb-3 custom-input">
                                                        <textarea
                                                            className={`form-control ${errors.yes_crime && "is-invalid"}`}
                                                            id="yes_crime"
                                                            name="yes_crime"
                                                            value={formData?.yes_crime || ""}
                                                            onChange={handleChange}
                                                            rows="3"
                                                            placeholder=" "
                                                        ></textarea>
                                                        <label htmlFor="yes_crime">If yes,explain</label>
                                                        {errors.yes_crime && (
                                                            <div className="invalid-feedback">{errors.yes_crime}</div>
                                                        )}
                                                    </div>
                                                </div>

                                            )}

                                            <div className="col-md-4">
                                                <label htmlFor="con_crime">Health Issue<span style={{ color: 'red', fontSize: '1.2em' }}>*</span>
                                                </label>
                                                <div className="mb-3 custom-select-back">
                                                    <select
                                                        className={`form-select ${errors.hel_issue && "is-invalid"}`}
                                                        id="hel_issue"
                                                        name="hel_issue"
                                                        value={formData?.hel_issue || ""}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="yes">Yes</option>
                                                        <option value="no">No</option>
                                                    </select>

                                                    {errors.hel_issue && (
                                                        <div className="invalid-feedback">{errors.hel_issue}</div>
                                                    )}
                                                </div>
                                            </div>
                                            {formData?.hel_issue === "yes" && (
                                                <div className="col-md-12">
                                                    <div className="mb-3 custom-input">
                                                        <textarea
                                                            className={`form-control ${errors.yes_issue && "is-invalid"}`}
                                                            id="yes_issue"
                                                            name="yes_issue"
                                                            value={formData?.yes_issue || ""}
                                                            onChange={handleChange}
                                                            rows="3"
                                                            placeholder=" "
                                                        ></textarea>
                                                        <label htmlFor="yes_issue">If yes,explain</label>
                                                        {errors.yes_issue && (
                                                            <div className="invalid-feedback">{errors.yes_issue}</div>
                                                        )}
                                                    </div>
                                                </div>

                                            )} */}

                                            <div className="col-md-4">
                                                <label htmlFor="con_crime">Have you ever been convicted of a crime? <span style={{ color: 'red', fontSize: '1.2em' }}>*</span></label>
                                                <div className="mb-3 custom-select-back">
                                                    <select
                                                        className={`form-select ${errors.con_crime && "is-invalid"}`}
                                                        id="con_crime"
                                                        name="con_crime"
                                                        value={formData?.con_crime || ""}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="yes">Yes</option>
                                                        <option value="no">No</option>
                                                    </select>
                                                    {errors.con_crime && (
                                                        <div className="invalid-feedback">{errors.con_crime}</div>
                                                    )}
                                                </div>
                                            </div>

                                            {formData?.con_crime === "yes" && (
                                                <div className="col-md-12">
                                                    <div className="mb-3 custom-input">
                                                        <textarea
                                                            className={`form-control ${errors.yes_crime && "is-invalid"}`}
                                                            id="yes_crime"
                                                            name="yes_crime"
                                                            value={formData?.yes_crime || ""}
                                                            onChange={handleChange}
                                                            rows="3"
                                                            placeholder=" "
                                                        ></textarea>
                                                        <label htmlFor="yes_crime">If yes, explain</label>
                                                        {errors.yes_crime && (
                                                            <div className="invalid-feedback">{errors.yes_crime}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}

                                            <div className="col-md-4">
                                                <label htmlFor="hel_issue">Health Issue <span style={{ color: 'red', fontSize: '1.2em' }}>*</span></label>
                                                <div className="mb-3 custom-select-back">
                                                    <select
                                                        className={`form-select ${errors.hel_issue && "is-invalid"}`}
                                                        id="hel_issue"
                                                        name="hel_issue"
                                                        value={formData?.hel_issue || ""}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="yes">Yes</option>
                                                        <option value="no">No</option>
                                                    </select>
                                                    {errors.hel_issue && (
                                                        <div className="invalid-feedback">{errors.hel_issue}</div>
                                                    )}
                                                </div>
                                            </div>

                                            {formData?.hel_issue === "yes" && (
                                                <div className="col-md-12">
                                                    <div className="mb-3 custom-input">
                                                        <textarea
                                                            className={`form-control ${errors.yes_issue && "is-invalid"}`}
                                                            id="yes_issue"
                                                            name="yes_issue"
                                                            value={formData?.yes_issue || ""}
                                                            onChange={handleChange}
                                                            rows="3"
                                                            placeholder=" "
                                                        ></textarea>
                                                        <label htmlFor="yes_issue">If yes, explain</label>
                                                        {errors.yes_issue && (
                                                            <div className="invalid-feedback">{errors.yes_issue}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}

                                            <div class="card-header custBg p-3 h2 text-center mt-3 mb-2" style={{ background: '#cce5ff' }}>
                                                Highest Education
                                            </div>
                                            <div>

                                                <div className="row mt-3 mb-3">
                                                    {/* <div className="col-md-2">
                                                        <input
                                                            type="checkbox"
                                                            id="highschool"
                                                            className="large-checkbox"
                                                            checked={showHighSchool}
                                                            onChange={() => setShowHighSchool(!showHighSchool)}
                                                        />
                                                        <label htmlFor="highschool" className="large-label">High School</label>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <input
                                                            type="checkbox"
                                                            id="bachelors"
                                                            className="large-checkbox"
                                                            checked={showBachelors}
                                                            onChange={() => setShowBachelors(!showBachelors)}
                                                        />
                                                        <label htmlFor="bachelors" className="large-label">Bachelor's</label>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <input
                                                            type="checkbox"
                                                            id="masters"
                                                            className="large-checkbox"
                                                            checked={showMasters}
                                                            onChange={() => setShowMasters(!showMasters)}
                                                        />
                                                        <label htmlFor="masters" className="large-label">Master's</label>
                                                    </div> */}
                                                </div>

                                                {/* {showHighSchool && ( */}
                                                <div className="row mt-3 mb-3">
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: '20px', marginTop: '20px', position: 'relative' }}>
                                                        <h2 style={{ fontSize: '20px', fontWeight: '', zIndex: 1, position: 'relative', backgroundColor: '#fff', padding: '0 10px', margin: "10px 10px 0 0" }}>
                                                            <p className="title_small" style={{ color: '#0c76e4' }}>
                                                                (Institute Details)
                                                            </p></h2>
                                                        <div style={{ position: 'absolute', top: '50%', left: 0, right: 0, height: '1px', backgroundColor: '#000' }}></div>
                                                        {/* <div style={{ position: 'absolute', top: 'calc(50% - 1px)', left: '10px', right: '10px', height: '1px', backgroundColor: '#fff' }}></div> */}
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="mb-3 custom-input">
                                                            <input
                                                                type="text"
                                                                // className={`form-control ${errors.university && "is-invalid"}`}
                                                                className={`form-control ${errors['education.institute_name'] && "is-invalid"}`}
                                                                id="institute_name"
                                                                name="education.institute_name"
                                                                value={formData?.education.institute_name || ""}
                                                                onChange={handleChange}
                                                                placeholder=""
                                                            />
                                                            <label htmlFor="institute_name">Institute Name</label>
                                                            {/* {errors.highschool_university && (
                                                                    <div className="invalid-feedback">{errors.highschool_university}</div>
                                                                )} */}
                                                            {errors['education.institute_name'] && (
                                                                <div className="invalid-feedback">{errors['education.institute_name']}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="mb-3 custom-input">
                                                            <input
                                                                type="text"
                                                                // className={`form-control ${errors.location && "is-invalid"}`}
                                                                className={`form-control ${errors['education.institute_location'] && "is-invalid"}`}
                                                                id="institute_location"
                                                                name="education.institute_location"
                                                                value={formData?.education.institute_location || ""}
                                                                onChange={handleChange}
                                                                placeholder=""
                                                            />
                                                            <label htmlFor="highschool_location">Institute Location<span style={{ color: 'red', fontSize: '1.2em' }}>*</span>
                                                            </label>
                                                            {/* {errors.highschool_location && (
                                                                    <div className="invalid-feedback">{errors.highschool_location}</div>
                                                                )} */}
                                                            {errors['education.institute_location'] && (
                                                                <div className="invalid-feedback">{errors['education.institute_location']}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="mb-3 custom-input">
                                                            <input
                                                                type="text"
                                                                // className={`form-control ${errors.stream && "is-invalid"}`}
                                                                className={`form-control ${errors['education.institute_stream'] && "is-invalid"}`}
                                                                id="institute_stream"
                                                                name="education.institute_stream"
                                                                value={formData?.education.institute_stream || ""}
                                                                onChange={handleChange}
                                                                placeholder=""
                                                            />
                                                            <label htmlFor="institute_stream">Stream<span style={{ color: 'red', fontSize: '1.2em' }}>*</span>
                                                            </label>
                                                            {/* {errors.highschool_stream && (
                                                                    <div className="invalid-feedback">{errors.highschool_stream}</div>
                                                                )} */}
                                                            {errors['education.institute_stream'] && (
                                                                <div className="invalid-feedback">{errors['education.institute_stream']}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="mb-3 custom-input">
                                                            <input
                                                                type="text"
                                                                // className={`form-control ${errors.passing_year && "is-invalid"}`}
                                                                className={`form-control ${errors['education.institute_passing_year'] && "is-invalid"}`}
                                                                id="institute_passing_year"
                                                                name="education.institute_passing_year"
                                                                value={formData?.education.institute_passing_year || ""}
                                                                onChange={handleChange}
                                                                placeholder=""
                                                            />
                                                            <label htmlFor="institute_passing_year">Passing Year<span style={{ color: 'red', fontSize: '1.2em' }}>*</span>
                                                            </label>
                                                            {/* {errors.highschool_passing_year && (
                                                                    <div className="invalid-feedback">{errors.highschool_passing_year}</div>
                                                                )} */}
                                                            {errors['education.institute_passing_year'] && (
                                                                <div className="invalid-feedback">{errors['education.institute_passing_year']}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* )} */}


                                            </div>

                                            <div class="card-header p-3 h2 text-center" style={{ background: '#cce5ff' }}>
                                                Emergency Contact
                                            </div>
                                            {/* <div style={{ display: 'flex', alignItems: 'center',justifyContent: 'center', textAlign: 'center', marginBottom: '20px', marginTop: '20px', position: 'relative' }}>
                                                <h2 style={{ fontSize: '20px', fontWeight: '', zIndex: 1, position: 'relative', backgroundColor: '#fff', padding: '0 10px', margin: "10px 10px 0 0" }}>
                                                    <p className="title_small" style={{ color: '#0c76e4' }}>

                                                    </p></h2>
                                                <div style={{ position: 'absolute', top: '50%', left: 0, right: 0, height: '1px', backgroundColor: '#000' }}></div>
                                                <div style={{ position: 'absolute', top: 'calc(50% - 1px)', left: '10px', right: '10px', height: '1px', backgroundColor: '#fff' }}></div>
                                            </div> */}

                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: '20px', marginTop: '20px', position: 'relative' }}>
                                                <h2 style={{ fontSize: '20px', fontWeight: '', zIndex: 1, position: 'relative', backgroundColor: '#fff', padding: '0 10px ', margin: '10px 10px 0 0' }}>
                                                    <p className="title_small" style={{ color: '#0c76e4' }}>
                                                        (Blood Relation)
                                                    </p></h2>
                                                <div style={{ position: 'absolute', top: '50%', left: 0, right: 0, height: '1px', backgroundColor: '#000' }}></div>
                                                {/* <div style={{ position: 'absolute', top: 'calc(50% - 1px)', left: '10px', right: '10px', height: '1px', backgroundColor: '#fff' }}></div> */}
                                            </div>

                                            <div className="row">

                                                <div className="col-md-4">
                                                    <div className="mb-3 custom-input">
                                                        <input
                                                            type="text"
                                                            // className={`form-control ${errors.full_name1 && "is-invalid"}`}
                                                            className={`form-control ${errors['referance_blood_relation.full_name1'] && "is-invalid"}`}
                                                            id="full_name1"
                                                            name="referance_blood_relation.full_name1"
                                                            value={formData?.referance_blood_relation.full_name1 || ""}
                                                            onChange={handleChange}
                                                            placeholder=""
                                                        />
                                                        <label htmlFor="full_name1">First Name<span style={{ color: 'red', fontSize: '1.2em' }}>*</span>
                                                        </label>
                                                        {/* {errors.full_name1 && (
                                                            <div className="invalid-feedback">{errors.full_name1}</div>
                                                        )} */}
                                                        {errors['referance_blood_relation.full_name1'] && (
                                                            <div className="invalid-feedback">{errors['referance_blood_relation.full_name1']}</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="mb-3 custom-input">
                                                        <input
                                                            type="text"
                                                            // className={`form-control ${errors.last_name1 && "is-invalid"}`}
                                                            className={`form-control ${errors['referance_blood_relation.last_name1'] && "is-invalid"}`}
                                                            id="last_name1"
                                                            name="referance_blood_relation.last_name1"
                                                            value={formData?.referance_blood_relation.last_name1 || ""}
                                                            onChange={handleChange}
                                                            placeholder=""
                                                        />
                                                        <label htmlFor="last_name1">Last Name<span style={{ color: 'red', fontSize: '1.2em' }}>*</span>
                                                        </label>
                                                        {/* {errors.last_name1 && (
                                                            <div className="invalid-feedback">{errors.last_name1}</div>
                                                        )} */}
                                                        {errors['referance_blood_relation.last_name1'] && (
                                                            <div className="invalid-feedback">{errors['referance_blood_relation.last_name1']}</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="mb-3 custom-input">
                                                        <input
                                                            type="text"
                                                            // className={`form-control ${errors.relationship1 && "is-invalid"}`}
                                                            className={`form-control ${errors['referance_blood_relation.relationship1'] && "is-invalid"}`}
                                                            id="relationship1"
                                                            name="referance_blood_relation.relationship1"
                                                            value={formData?.referance_blood_relation.relationship1 || ""}
                                                            onChange={handleChange}
                                                            placeholder=""
                                                        />
                                                        <label htmlFor="relationship1">Relationship<span style={{ color: 'red', fontSize: '1.2em' }}>*</span>
                                                        </label>
                                                        {/* {errors.relationship1 && (
                                                            <div className="invalid-feedback">{errors.relationship1}</div>
                                                        )} */}
                                                        {errors['referance_blood_relation.relationship1'] && (
                                                            <div className="invalid-feedback">{errors['referance_blood_relation.relationship1']}</div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="mb-3 custom-input">
                                                        <input
                                                            type="text"
                                                            // className={`form-control ${errors.occupation1 && "is-invalid"}`}
                                                            className={`form-control ${errors['referance_blood_relation.occupation1'] && "is-invalid"}`}
                                                            id="occupation1"
                                                            name="referance_blood_relation.occupation1"
                                                            value={formData?.referance_blood_relation.occupation1 || ""}
                                                            onChange={handleChange}
                                                            placeholder=""
                                                        />
                                                        <label htmlFor="occupation1">Occupation<span style={{ color: 'red', fontSize: '1.2em' }}>*</span>
                                                        </label>
                                                        {/* {errors.occupation1 && (
                                                            <div className="invalid-feedback">{errors.occupation1}</div>
                                                        )} */}
                                                        {errors['referance_blood_relation.occupation1'] && (
                                                            <div className="invalid-feedback">{errors['referance_blood_relation.occupation1']}</div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="mb-3 custom-input">
                                                        <input
                                                            type="text"
                                                            // className={`form-control ${errors.phone1 && "is-invalid"}`}
                                                            className={`form-control ${errors['referance_blood_relation.phone1'] && "is-invalid"}`}
                                                            id="phone1"
                                                            name="referance_blood_relation.phone1"
                                                            value={formData?.referance_blood_relation.phone1 || ""}
                                                            onChange={handleChange}
                                                            placeholder=""
                                                        />
                                                        <label htmlFor="phone1">Contact number<span style={{ color: 'red', fontSize: '1.2em' }}>*</span>
                                                        </label>
                                                        {/* {errors.phone1 && (
                                                            <div className="invalid-feedback">{errors.phone1}</div>
                                                        )} */}
                                                        {errors['referance_blood_relation.phone1'] && (
                                                            <div className="invalid-feedback">{errors['referance_blood_relation.phone1']}</div>
                                                        )}
                                                    </div>
                                                </div>




                                                <div className="col-md-4">
                                                    <div className="mb-3 custom-input">
                                                        <input
                                                            type="text"
                                                            // className={`form-control ${errors.address1 && "is-invalid"}`}
                                                            className={`form-control ${errors['referance_blood_relation.address1'] && "is-invalid"}`}
                                                            id="address1"
                                                            name="referance_blood_relation.address1"
                                                            value={formData?.referance_blood_relation.address1 || ""}
                                                            onChange={handleChange}
                                                            placeholder=""
                                                        />
                                                        <label htmlFor="address1">Address<span style={{ color: 'red', fontSize: '1.2em' }}>*</span>
                                                        </label>
                                                        {/* {errors.address1 && (
                                                            <div className="invalid-feedback">{errors.address1}</div>
                                                        )} */}
                                                        {errors['referance_blood_relation.address1'] && (
                                                            <div className="invalid-feedback">{errors['referance_blood_relation.address1']}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginBottom: '20px', marginTop: '20px', position: 'relative' }}>
                                                <h2 style={{ fontSize: '20px', fontWeight: '', zIndex: 1, position: 'relative', backgroundColor: '#fff', padding: '0 10px ', margin: '10px 10px 0 0' }}>
                                                    <p className="title_small" style={{ color: '#0c76e4' }}>
                                                        (Friend/Others)
                                                    </p></h2>
                                                <div style={{ position: 'absolute', top: '50%', left: 0, right: 0, height: '1px', backgroundColor: '#000' }}></div>
                                                <div style={{ position: 'absolute', top: 'calc(50% - 1px)', left: '10px', right: '10px', height: '1px', backgroundColor: '#fff' }}></div>
                                            </div>

                                            <div className="row">


                                                <div className="col-md-4">
                                                    <div className="mb-3 custom-input">
                                                        <input
                                                            type="text"
                                                            // className={`form-control ${errors.full_name2 && "is-invalid"}`}
                                                            className={`form-control ${errors['referance_friend.full_name2'] && "is-invalid"}`}
                                                            id="full_name2"
                                                            name="referance_friend.full_name2"
                                                            value={formData?.referance_friend.full_name2 || ""}
                                                            onChange={handleChange}
                                                            placeholder=""
                                                        />
                                                        <label htmlFor="full_name2">First Name<span style={{ color: 'red', fontSize: '1.2em' }}>*</span>
                                                        </label>
                                                        {/* {errors.full_name2 && (
                                                            <div className="invalid-feedback">{errors.full_name2}</div>
                                                        )} */}
                                                        {errors['referance_friend.full_name2'] && (
                                                            <div className="invalid-feedback">{errors['referance_friend.full_name2']}</div>
                                                        )}
                                                    </div>
                                                </div>


                                                <div className="col-md-4">
                                                    <div className="mb-3 custom-input">
                                                        <input
                                                            type="text"
                                                            // className={`form-control ${errors.last_name2 && "is-invalid"}`}
                                                            className={`form-control ${errors['referance_friend.last_name2'] && "is-invalid"}`}
                                                            id="last_name2"
                                                            name="referance_friend.last_name2"
                                                            value={formData?.referance_friend.last_name2 || ""}
                                                            onChange={handleChange}
                                                            placeholder=""
                                                        />
                                                        <label htmlFor="last_name2">Last Name<span style={{ color: 'red', fontSize: '1.2em' }}>*</span>
                                                        </label>
                                                        {/* {errors.last_name2 && (
                                                            <div className="invalid-feedback">{errors.last_name2}</div>
                                                        )} */}
                                                        {errors['referance_friend.last_name2'] && (
                                                            <div className="invalid-feedback">{errors['referance_friend.last_name2']}</div>
                                                        )}
                                                    </div>
                                                </div>



                                                <div className="col-md-4">
                                                    <div className="mb-3 custom-input">
                                                        <input
                                                            type="text"
                                                            // className={`form-control ${errors.relationship2 && "is-invalid"}`}
                                                            className={`form-control ${errors['referance_friend.relationship2'] && "is-invalid"}`}
                                                            id="relationship2"
                                                            name="referance_friend.relationship2"
                                                            value={formData?.referance_friend.relationship2 || ""}
                                                            onChange={handleChange}
                                                            placeholder=""
                                                        />
                                                        <label htmlFor="relationship2">Relationship<span style={{ color: 'red', fontSize: '1.2em' }}>*</span>
                                                        </label>
                                                        {/* {errors.relationship2 && (
                                                            <div className="invalid-feedback">{errors.relationship2}</div>
                                                        )} */}
                                                        {errors['referance_friend.relationship2'] && (
                                                            <div className="invalid-feedback">{errors['referance_friend.relationship2']}</div>
                                                        )}
                                                    </div>
                                                </div>


                                                <div className="col-md-4">
                                                    <div className="mb-3 custom-input">
                                                        <input
                                                            type="text"
                                                            // className={`form-control ${errors.occupation && "is-invalid"}`}
                                                            className={`form-control ${errors['referance_friend.occupation'] && "is-invalid"}`}
                                                            id="occupation"
                                                            name="referance_friend.occupation"
                                                            value={formData?.referance_friend.occupation || ""}
                                                            onChange={handleChange}
                                                            placeholder=""
                                                        />
                                                        <label htmlFor="occupation">Occupation<span style={{ color: 'red', fontSize: '1.2em' }}>*</span>
                                                        </label>
                                                        {/* {errors.occupation && (
                                                            <div className="invalid-feedback">{errors.occupation}</div>
                                                        )} */}
                                                        {errors['referance_friend.occupation'] && (
                                                            <div className="invalid-feedback">{errors['referance_friend.occupation']}</div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="mb-3 custom-input">
                                                        <input
                                                            type="text"
                                                            // className={`form-control ${errors.phone2 && "is-invalid"}`}
                                                            className={`form-control ${errors['referance_friend.phone2'] && "is-invalid"}`}
                                                            id="phone2"
                                                            name="referance_friend.phone2"
                                                            value={formData?.referance_friend.phone2 || ""}
                                                            onChange={handleChange}
                                                            placeholder=""
                                                        />
                                                        <label htmlFor="phone2">Contact number<span style={{ color: 'red', fontSize: '1.2em' }}>*</span>
                                                        </label>
                                                        {/* {errors.phone2 && (
                                                            <div className="invalid-feedback">{errors.phone2}</div>
                                                        )} */}
                                                        {errors['referance_friend.phone2'] && (
                                                            <div className="invalid-feedback">{errors['referance_friend.phone2']}</div>
                                                        )}
                                                    </div>
                                                </div>



                                                <div className="col-md-4">
                                                    <div className="mb-3 custom-input">
                                                        <input
                                                            type="text"
                                                            // className={`form-control ${errors.address2 && "is-invalid"}`}
                                                            className={`form-control ${errors['referance_friend.address2'] && "is-invalid"}`}
                                                            id="address2"
                                                            name="referance_friend.address2"
                                                            value={formData?.referance_friend.address2 || ""}
                                                            onChange={handleChange}
                                                            placeholder=""
                                                        />
                                                        <label htmlFor="address2">Address<span style={{ color: 'red', fontSize: '1.2em' }}>*</span>
                                                        </label>
                                                        {/* {errors.address2 && (
                                                            <div className="invalid-feedback">{errors.address2}</div>
                                                        )} */}
                                                        {errors['referance_friend.address2'] && (
                                                            <div className="invalid-feedback">{errors['referance_friend.address2']}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label className="form-label fw-bold">Experience Level:</label>
                                                    <div className="form-check">
                                                        <input
                                                            type="radio"
                                                            className="form-check-input"
                                                            id="fresher"
                                                            name="referance_friend.fresher"
                                                            value="fresher"
                                                            checked={formData?.referance_friend.fresher}
                                                            onChange={handleChange}
                                                        />
                                                        <label htmlFor="fresher" className="form-check-label">
                                                            Fresher
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input
                                                            type="radio"
                                                            className="form-check-input"
                                                            id="experienced"
                                                            name="referance_friend.experience"
                                                            value="experienced"
                                                            checked={formData?.referance_friend.experience}
                                                            onChange={handleChange}
                                                        />
                                                        <label htmlFor="experienced" className="form-check-label">
                                                            Experienced
                                                        </label>
                                                    </div>
                                                </div>
                                            </div> */}

                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label className="form-label fw-bold">Experience Level:</label>
                                                    <div className="form-check">
                                                        <input
                                                            type="radio"
                                                            className="form-check-input"
                                                            id="fresher"
                                                            name="referance_friend.experienceLevel"
                                                            value="fresher"
                                                            checked={formData?.referance_friend.experienceLevel == "fresher"}
                                                            onChange={handleChange}
                                                        />
                                                        <label htmlFor="fresher" className="form-check-label">
                                                            Fresher
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input
                                                            type="radio"
                                                            className="form-check-input"
                                                            id="experienced"
                                                            name="referance_friend.experienceLevel"
                                                            value="experienced"
                                                            checked={formData?.referance_friend.experienceLevel == "experienced"}
                                                            onChange={handleChange}
                                                        />
                                                        <label htmlFor="experienced" className="form-check-label">
                                                            Experienced
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <br /><br />  <br /><br />

                                            {formData?.referance_friend.experienceLevel == 'experienced' && (
                                                <div>


                                                    <h4>Work Experience</h4>
                                                    {formData.previous_employment.map((item, index) => (
                                                        <div key={index} className="previous-employment-section">
                                                            {/* <h4>Previous Employment {index + 1}</h4> */}
                                                            <div className="row">
                                                                {/* <div className="col-md-4">
                                                                    <input
                                                                        type="text"
                                                                        className={`form-control ${errors[`previous_employment[${index}].company_precom`] && "is-invalid"}`}
                                                                        id={`company_precom-${index}`}
                                                                        name="company_precom"
                                                                        value={item.company_precom}
                                                                        onChange={(e) => handlePreviousEmploymentChange(index, e)}
                                                                        placeholder="Company Name"
                                                                    />
                                                                    {errors[`previous_employment[${index}].company_precom`] && (
                                                                        <div className="invalid-feedback">{errors[`previous_employment[${index}].company_precom`]}</div>
                                                                    )}
                                                                </div> */}
                                                                <div className="col-md-4">
                                                                    <div className="mb-3 custom-input">
                                                                        <input
                                                                            type="text"
                                                                            className={`form-control ${errors[`previous_employment[${index}].company`] && "is-invalid"}`}
                                                                            id={`company-${index}`}
                                                                            name="company"
                                                                            value={item.company}
                                                                            onChange={(e) => handlePreviousEmploymentChange(index, e)}
                                                                            placeholder=""
                                                                        />
                                                                        <label htmlFor={`company-${index}`}>
                                                                            Company Name<span style={{ color: 'red', fontSize: '1.2em' }}>*</span>
                                                                        </label>
                                                                        {errors[`previous_employment[${index}].company`] && (
                                                                            <div className="invalid-feedback">{errors[`previous_employment[${index}].company`]}</div>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-4">
                                                                    <div className="mb-3 custom-input">
                                                                        <input
                                                                            type="text"
                                                                            className={`form-control ${errors[`previous_employment[${index}].address`] && "is-invalid"}`}
                                                                            id={`address-${index}`}
                                                                            name="address"
                                                                            value={item.address}
                                                                            onChange={(e) => handlePreviousEmploymentChange(index, e)}
                                                                            placeholder=""
                                                                        />
                                                                        <label htmlFor={`address-${index}`}>
                                                                            Address
                                                                        </label>
                                                                        {errors[`previous_employment[${index}].address`] && (
                                                                            <div className="invalid-feedback">{errors[`previous_employment[${index}].address`]}</div>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-4">
                                                                    <div className="mb-3 custom-input">
                                                                        <input
                                                                            type="text"
                                                                            className={`form-control ${errors[`previous_employment[${index}].job_title`] && "is-invalid"}`}
                                                                            id={`job_title-${index}`}
                                                                            name="job_title"
                                                                            value={item.job_title}
                                                                            onChange={(e) => handlePreviousEmploymentChange(index, e)}
                                                                            placeholder=""
                                                                        />
                                                                        <label htmlFor={`job_title-${index}`}>
                                                                            Designation<span style={{ color: 'red', fontSize: '1.2em' }}>*</span>
                                                                        </label>
                                                                        {errors[`previous_employment[${index}].job_title`] && (
                                                                            <div className="invalid-feedback">{errors[`previous_employment[${index}].job_title`]}</div>
                                                                        )}
                                                                    </div>
                                                                </div>


                                                                {/* <div className="col-md-4">
                                                                    <div className="mb-3 custom-input">
                                                                        <input
                                                                            type="text"
                                                                            className={`form-control ${errors[`previous_employment[${index}].address`] && "is-invalid"}`}
                                                                            id={`address-${index}`}
                                                                            name="address"
                                                                            value={item.address}
                                                                            onChange={(e) => handlePreviousEmploymentChange(index, e)}
                                                                            placeholder=""
                                                                        />
                                                                        <label htmlFor={`address-${index}`}>
                                                                            Designation
                                                                        </label>
                                                                        {errors[`previous_employment[${index}].address`] && (
                                                                            <div className="invalid-feedback">{errors[`previous_employment[${index}].address`]}</div>
                                                                        )}
                                                                    </div>
                                                                </div> */}

                                                                <div className="col-md-4">
                                                                    <div className="mb-3 custom-input">
                                                                        <input
                                                                            type="text"
                                                                            className={`form-control ${errors[`previous_employment[${index}].responsibility`] && "is-invalid"}`}
                                                                            id={`responsibility-${index}`}
                                                                            name="responsibility"
                                                                            value={item.responsibility}
                                                                            onChange={(e) => handlePreviousEmploymentChange(index, e)}
                                                                            placeholder=""
                                                                        />
                                                                        <label htmlFor={`responsibility-${index}`}>
                                                                            Responsibility
                                                                        </label>
                                                                        {errors[`previous_employment[${index}].responsibility`] && (
                                                                            <div className="invalid-feedback">{errors[`previous_employment[${index}].responsibility`]}</div>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-4">
                                                                    <div className="mb-3 custom-input">
                                                                        <input
                                                                            type="date"
                                                                            className={`form-control ${errors[`previous_employment[${index}].joined_from`] && "is-invalid"}`}
                                                                            id={`joined_from-${index}`}
                                                                            name="joined_from"
                                                                            value={item.joined_from}
                                                                            onChange={(e) => handlePreviousEmploymentChange(index, e)}
                                                                            placeholder=""
                                                                        />
                                                                        <label htmlFor={`joined_from-${index}`}>
                                                                            Joining From<span style={{ color: 'red', fontSize: '1.2em' }}>*</span>
                                                                        </label>
                                                                        {errors[`previous_employment[${index}].joined_from`] && (
                                                                            <div className="invalid-feedback">{errors[`previous_employment[${index}].joined_from`]}</div>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-4">
                                                                    <div className="mb-3 custom-input">
                                                                        <input
                                                                            type="date"
                                                                            className={`form-control ${errors[`previous_employment[${index}].joined_to`] && "is-invalid"}`}
                                                                            id={`joined_to-${index}`}
                                                                            name="joined_to"
                                                                            value={item.joined_to}
                                                                            onChange={(e) => handlePreviousEmploymentChange(index, e)}
                                                                            placeholder=""
                                                                        />
                                                                        <label htmlFor={`joined_to-${index}`}>
                                                                            Joining To<span style={{ color: 'red', fontSize: '1.2em' }}>*</span>
                                                                        </label>
                                                                        {errors[`previous_employment[${index}].joined_to`] && (
                                                                            <div className="invalid-feedback">{errors[`previous_employment[${index}].joined_to`]}</div>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-4">
                                                                    <div className="mb-3 custom-input">
                                                                        <input
                                                                            type="text"
                                                                            className={`form-control ${errors[`previous_employment[${index}].salary`] && "is-invalid"}`}
                                                                            id={`salary-${index}`}
                                                                            name="salary"
                                                                            value={item.salary}
                                                                            onChange={(e) => handlePreviousEmploymentChange(index, e)}
                                                                            placeholder=""
                                                                        />
                                                                        <label htmlFor={`salary-${index}`}>
                                                                            Salary<span style={{ color: 'red', fontSize: '1.2em' }}>*</span>
                                                                        </label>
                                                                        {errors[`previous_employment[${index}].salary`] && (
                                                                            <div className="invalid-feedback">{errors[`previous_employment[${index}].salary`]}</div>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                {/* <div className="col-md-4">
                                                                    <div className="mb-3 custom-input">
                                                                        <input
                                                                            type="text"
                                                                            className={`form-control ${errors[`previous_employment[${index}].salary`] && "is-invalid"}`}
                                                                            id={`salary-${index}`}
                                                                            name="salary"
                                                                            value={item.salary}
                                                                            onChange={(e) => handlePreviousEmploymentChange(index, e)}
                                                                            placeholder=""
                                                                        />
                                                                        <label htmlFor={`salary-${index}`}>
                                                                            Salary To<span style={{ color: 'red', fontSize: '1.2em' }}>*</span>
                                                                        </label>
                                                                        {errors[`previous_employment[${index}].salary`] && (
                                                                            <div className="invalid-feedback">{errors[`previous_employment[${index}].salary`]}</div>
                                                                        )}
                                                                    </div>
                                                                </div> */}


                                                                <div className="col-md-4">
                                                                    <div className="mb-3 custom-input">
                                                                        <input
                                                                            type="text"
                                                                            className={`form-control ${errors[`previous_employment[${index}].reason_leaving`] && "is-invalid"}`}
                                                                            id={`reason_leaving-${index}`}
                                                                            name="reason_leaving"
                                                                            value={item.reason_leaving}
                                                                            onChange={(e) => handlePreviousEmploymentChange(index, e)}
                                                                            placeholder=""
                                                                        />
                                                                        <label htmlFor={`reason_leaving-${index}`}>
                                                                            Reason for Leaving<span style={{ color: 'red', fontSize: '1.2em' }}>*</span>
                                                                        </label>
                                                                        {errors[`previous_employment[${index}].reason_leaving`] && (
                                                                            <div className="invalid-feedback">{errors[`previous_employment[${index}].reason_leaving`]}</div>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-4">
                                                                    <div className="mb-3 custom-input">
                                                                        <input
                                                                            type="text"
                                                                            className={`form-control ${errors[`previous_employment[${index}].authority_name`] && "is-invalid"}`}
                                                                            id={`authority_name-${index}`}
                                                                            name="authority_name"
                                                                            value={item.authority_name}
                                                                            onChange={(e) => handlePreviousEmploymentChange(index, e)}
                                                                            placeholder=""
                                                                        />
                                                                        <label htmlFor={`authority_name-${index}`}>
                                                                            Authorized Person Name<span style={{ color: 'red', fontSize: '1.2em' }}>*</span>
                                                                        </label>
                                                                        {errors[`previous_employment[${index}].authority_name`] && (
                                                                            <div className="invalid-feedback">{errors[`previous_employment[${index}].authority_name`]}</div>
                                                                        )}
                                                                    </div>
                                                                </div>



                                                                <div className="col-md-4">
                                                                    <div className="mb-3 custom-input">
                                                                        <input
                                                                            type="text"
                                                                            className={`form-control ${errors[`previous_employment[${index}].authority_phone`] && "is-invalid"}`}
                                                                            id={`authority_phone-${index}`}
                                                                            name="authority_phone"
                                                                            value={item.authority_phone}
                                                                            onChange={(e) => handlePreviousEmploymentChange(index, e)}
                                                                            placeholder=""
                                                                        />
                                                                        <label htmlFor={`authority_phone-${index}`}>
                                                                            Authorized Person Number<span style={{ color: 'red', fontSize: '1.2em' }}>*</span>
                                                                        </label>
                                                                        {errors[`previous_employment[${index}].authority_phone`] && (
                                                                            <div className="invalid-feedback">{errors[`previous_employment[${index}].authority_phone`]}</div>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-4">
                                                                    <label htmlFor={`reference_authority-${index}`}>
                                                                        We may contact your previous Authorized Person for Background Verification<span style={{ color: 'red', fontSize: '1.2em' }}>*</span>
                                                                    </label>
                                                                    <div className="mb-3 custom-select-back">
                                                                        <select
                                                                            className={`form-control ${errors[`previous_employment[${index}].reference_authority`] && "is-invalid"}`}
                                                                            id={`reference_authority-${index}`}
                                                                            name="reference_authority"
                                                                            value={item.reference_authority || ''}
                                                                            onChange={(e) => handlePreviousEmploymentChange(index, e)}
                                                                        >
                                                                            <option value="abc">Select</option>
                                                                            <option value="yes">Yes</option>
                                                                            <option value="no">No</option>
                                                                        </select>
                                                                        {errors[`previous_employment[${index}].reference_authority`] && (
                                                                            <div className="invalid-feedback">
                                                                                {errors[`previous_employment[${index}].reference_authority`]}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                {item.reference_authority == "no" && (
                                                                    <div className="col-md-12">
                                                                        <div className="mb-3 custom-input">
                                                                            <textarea
                                                                                className={`form-control ${errors[`previous_employment[${index}].reference_authority_no_reason`] && "is-invalid"}`}
                                                                                id={`reference_authority_no_reason-${index}`}
                                                                                name="reference_authority_no_reason"
                                                                                value={item.reference_authority_no_reason || ""}
                                                                                onChange={(e) => handlePreviousEmploymentChange(index, e)}
                                                                                rows="3"
                                                                                placeholder=" "
                                                                            ></textarea>
                                                                            <label htmlFor={`reference_authority_no_reason-${index}`}>If no, please explain</label>
                                                                            {errors[`previous_employment[${index}].reference_authority_no_reason`] && (
                                                                                <div className="invalid-feedback">
                                                                                    {errors[`previous_employment[${index}].reference_authority_no_reason`]}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                )}










                                                                {/* <div className="col-md-4">
                                                                    <div className="mb-3 custom-input">
                                                                        <input
                                                                            type="text"
                                                                            className={`form-control ${errors[`previous_employment[${index}].refence_super`] && "is-invalid"}`}
                                                                            id={`refence_super-${index}`}
                                                                            name="refence_super"
                                                                            value={item.refence_super}
                                                                            onChange={(e) => handlePreviousEmploymentChange(index, e)}
                                                                            placeholder=""
                                                                        />
                                                                        <label htmlFor={`refence_super-${index}`}>
                                                                            Can we contact your supervisor <span style={{ color: 'red', fontSize: '1.2em' }}>*</span>
                                                                        </label>
                                                                        {errors[`previous_employment[${index}].refence_super`] && (
                                                                            <div className="invalid-feedback">{errors[`previous_employment[${index}].refence_super`]}</div>
                                                                        )}
                                                                    </div>
                                                                </div> */}




                                                                {/* <div className="col-md-4">
                                                                    <div className="mb-3 custom-input">
                                                                        <input
                                                                            type="text"
                                                                            className={`form-control ${errors[`previous_employment[${index}].refence_super_yes`] && "is-invalid"}`}
                                                                            id={`refence_super_yes-${index}`}
                                                                            name="refence_super_yes"
                                                                            value={item.refence_super_yes}
                                                                            onChange={(e) => handlePreviousEmploymentChange(index, e)}
                                                                            placeholder="Yes/No"
                                                                        />
                                                                        <label htmlFor={`refence_super_yes-${index}`}>
                                                                            Reference Supervisor<span style={{ color: 'red', fontSize: '1.2em' }}>*</span>
                                                                        </label>
                                                                        {errors[`previous_employment[${index}].refence_super_yes`] && (
                                                                            <div className="invalid-feedback">{errors[`previous_employment[${index}].refence_super_yes`]}</div>
                                                                        )}
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                            <button type="button" className="btn btn-danger btn-lg" onClick={() => removePreviousEmployment(index)}>Remove Experience</button>
                                                        </div>
                                                    ))}
                                                    <button type="button" className="btn btn-primary btn-lg" onClick={addPreviousEmployment}>Add Experience</button>
                                                </div>
                                            )}





                                            <div className="pt-3">
                                                <h5 className="alert-heading text-center disclaimer-section">Disclaimer and Signature</h5>
                                                <div className="card-header bg-custom p-3 h6">
                                                    <p className="mb-0">I certify that my answers are true and complete to the best of my knowledge.</p>
                                                    <br />
                                                    <p className="mb-0">If this application leads to employment, I understand that false or misleading information in my application or interview may result in my release.</p>
                                                    <br />
                                                    <div className="form-check">
                                                        <input
                                                            className={`form-check-input ${errors.checkbox ? 'is-invalid' : ''}`}

                                                            type="checkbox"
                                                            id="disclaimerCheckbox"
                                                            checked={checkboxChecked}
                                                            onChange={handleAgreeCheckboxChange}
                                                            style={{ transform: 'scale(1.5)' }}
                                                        />
                                                        <label className="form-check-label" htmlFor="disclaimerCheckbox">
                                                            I agree to the above statement
                                                        </label>
                                                        {errors.checkbox && <div className="invalid-feedback">{errors.checkbox}</div>}
                                                    </div>

                                                </div>
                                            </div>


                                            <div className="row mt-4">
                                                <div className="col-md-6">

                                                    <div className="mb-3">
                                                        <label htmlFor="signature" className="form-label fw-bold">
                                                            Signature
                                                        </label>
                                                        <input
                                                            type="text"
                                                            // className="form-control"
                                                            className={`form-control ${errors.signature && "is-invalid"}`}
                                                            id="signature"
                                                            name="signature"
                                                            value={formData?.signature || ""}
                                                            onChange={handleChange}
                                                        />
                                                        {errors.signature && (
                                                            <div className="invalid-feedback">{errors.signature}</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">

                                                    {/* <div className="mb-3">
                                                        <label htmlFor="date" className="form-label fw-bold">
                                                            Date
                                                        </label>
                                                        <input
                                                            type="date"
                                                           
                                                            className={`form-control ${errors.date && "is-invalid"}`}
                                                            id="date"
                                                            name="date"
                                                            value={formData?.date || ""}
                                                            onChange={handleChange}
                                                            readOnly
                                                        />
                                                        {errors.date && (
                                                            <div className="invalid-feedback">{errors.date}</div>
                                                        )}
                                                    </div> */}
                                                    <div className="mb-3">
                                                        <label htmlFor="date" className="form-label fw-bold">
                                                            Date
                                                        </label>
                                                        <input
                                                            type="date"
                                                            className={`form-control ${errors.date && "is-invalid"}`}
                                                            id="date"
                                                            name="date"
                                                            value={formData?.date || ""}
                                                            onChange={handleChange}
                                                            readOnly
                                                        />
                                                        {errors.date && (
                                                            <div className="invalid-feedback">{errors.date}</div>
                                                        )}
                                                    </div>

                                                </div>
                                            </div>

                                        </div>


                                        <div className="row justify-content-end">
                                            <div className="col-auto">
                                                <button type="submit" className="btn btn-primary btn-lg" disabled={submitting}>Submit</button>
                                            </div>
                                        </div>


                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Candidateform;