import React, { useEffect, useState, useRef, useCallback } from "react";
import { DataGrid } from '@mui/x-data-grid';
import { Button, Card, Col, Row, Nav, Table, ProgressBar, Form, Modal, Tooltip } from "react-bootstrap";
import _debounce from 'lodash/debounce';
import IconButton from '@mui/material/IconButton';

import Header from "../layouts/Header.js";
import Footer from "../layouts/Footer.js";
import { Link, Outlet, useNavigate } from "react-router-dom";
import Avatar from "../components/Avatar.js";
import { Bar } from 'react-chartjs-2';
import DatePicker from 'react-datepicker';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ReactApexChart from "react-apexcharts";
import { format } from 'date-fns';






import img6 from "../assets/img/img6.jpg";
import img7 from "../assets/img/img7.jpg";
import img8 from "../assets/img/img8.jpg";
import img9 from "../assets/img/img9.jpg";
import img10 from "../assets/img/img10.jpg";
import img11 from "../assets/img/img11.jpg";
import img12 from "../assets/img/img12.jpg";
import img15 from "../assets/img/img15.jpg";
import img16 from "../assets/img/img16.jpg";
import img17 from "../assets/img/img17.jpg";
import img18 from "../assets/img/img18.jpg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';

// files for current time clock
// import App from './App.js';
import "../assets/css/clockstyle.css";
import Clock from "../clock.js";
// import Upcomingbirthday from "./upcomingbirthday.jsx";
import { basicSetup, birthdaymonth, dateviewemployeebreaklist, deletebreak, editbreakdetails, getBasicSetup, getemployeedatewiseattendance, getId, pastemployeeleavepaggination, refreshPagedata, refreshPagedata1, searchEmployeeAttendance, searchEmployeeBreak, updatebreakdetails, viewAddEmployeeLeave, viewdeviceinfo, viewEmployee, viewEmployeehistory, viewemployeeleavepaggination, viewemployeepaggination, viewholiday, viewholidaypagination, viewShift } from "../services/api/api.js";
import { toast } from "react-toastify";
import { className } from "gridjs";
import OnDeviceTrainingIcon from '@mui/icons-material/OnDeviceTraining';
import { Loader } from "../loader.js";
import Main from "../layouts/Main.js";
import Sidebar from "../layouts/Sidebar.js";



export default function AdminDashboard() {
  const navigate = useNavigate();
  const [showModalpresentlist, setShowModalpresentlist] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);
  const [refreshFlag, setRefeshFlag] = useState(false);
  const [fullPageLoader, setFullPageLoader] = useState(false);
  const [showEmployeeList, setShowEmployeeList] = useState(false);
  const [showAbsentList, setShowAbsentList] = useState(false);
  const [attendanceData, setAttendanceData] = useState([]);
  const [attendanceDatapresentlist, setAttendanceDatapresentlist] = useState([]);
  const [attendanceDataAbsentlist, setAttendanceDataAbsentlist] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [checkedInEmployees, setCheckedInEmployees] = useState([]);
  const [currentEmployeesCount, setCurrentEmployeesCount] = useState(0);
  const [setupItems, setSetupItems] = useState({});

  const [presentCount, setPresentCount] = useState(0);
  const [absentCount, setAbsentCount] = useState(0);
  const [showSecondModal, setShowSecondModal] = useState(false);

  const [selectedDate, setSelectedDate] = useState(new Date())
  const [selectedDateFilter, setSelectedDateFilter] = useState(new Date());

  const [breakcountData, setBreakcountData] = useState([]);
  const reversedRows = [...breakcountData];
  const [showModalbreak, setShowModalbreak] = useState(false);
  const [showSetupModal, setShowSetupModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [filteredBreakData, setFilteredBreakData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentDateTime, setCurrentDateTime] = useState('');
  const [searchParams, setSearchParams] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
  });
  const [searchLeaveType, setSearchLeaveType] = useState('Current Leave')

  const [employeeName, setEmployeeName] = useState('');
  const [totalViolance, setViolationFine] = useState(null);
  const [totalMinutes, setTotalMinutes] = useState(null);
  const [ids, setIds] = useState(null);
  const [flag, setFlag] = useState(0);
  const [updatePayload, setUpdatePayload] = useState({
    violationFine: null,
    comment: ''
  });
  const [breakInData, setBreakInData] = useState([]);
  const [breakOutData, setBreakOutData] = useState([]);
  const [breakOutTime, setBreakOutTime] = useState([]);
  const [breakInTime, setBreakInTime] = useState([]);

  const [holidayData, setHolidayData] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteHolidayId, setdeleteHolidayId] = useState(null);

  const [rows, setRows] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageSize, setpageSize] = useState(100);
  const [count, setCount] = useState(0);
  const pageRef = useRef(page);

  const [breakInCountData, setBreakInCountData] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const [todayDate, setTodayDate] = useState('');
  const [birthdayEmployee, setBirthdayEmployees] = useState([]);

  const [employeeDataData, setAddEmployeeDataData] = useState([]);
  const [monthlyLeaveLen, setMonthlyLeaveLen] = useState(null);
  const [pendingCount, setPendingCount] = useState(null);

  const [deviceBranches, setDeviceBranch] = useState({});
  const [devicelength, setDeviceLength] = useState(sessionStorage.getItem('devicelength'));



  useEffect(() => {
    fetchDevices()
  }, [])

  const fetchDevices = async () => {
    try {
      // setLoading(true);
      setFullPageLoader(true)
      const apiResponse = await viewdeviceinfo();
      console.log(apiResponse);

      if (apiResponse) {
        setFullPageLoader(false)
        // Extract the branch_name and company_id from the first device in the response
        const firstDevice = apiResponse[0];  // Get the first device (or any other logic)

        const formattedData = {
          branch_name: [firstDevice.company_device.branch_name], // Wrap branch_name in an array
          companyId: sessionStorage.getItem('companyId'),  // Retrieve companyId from sessionStorage
        };

        console.log(formattedData);  // Log the formatted data to check its structure

        // Store the formatted data in the state as a single object
        setDeviceBranch(formattedData);
        refreshPagedata(formattedData);
        if (formattedData.branch_name.length > 0) {
          setDeviceLength(1)
        }
        else {
          setDeviceLength(-1)
        }

      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false)
    } finally {
      setLoading(false)
    }
  };


  // useEffect(() => {
  //   if (devicelength == 1) {

  //     fetchRefreshData1()
  //   }
  // }, [devicelength])



  const fetchRefreshData = async () => {
    try {
      setFullPageLoader(true)
      sessionStorage.setItem('refreshLoader', true)
      const apiResponse = await refreshPagedata(deviceBranches);
      console.log(apiResponse);
      if (apiResponse) {
        setFullPageLoader(false)
        // sessionStorage.setItem('refreshLoader', 'false')
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      // setLoading(false)
      setFullPageLoader(false)
    } finally {
      // setLoading(false)
      setFullPageLoader(false)
      setTimeout(() => {
        sessionStorage.setItem('refreshLoader', false)

      }, 15000);
    }
  };


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const fetchRefreshData1 = async () => {
    try {
      // handleShow()
      setFullPageLoader(true)
      sessionStorage.setItem('refresh', true)
      const apiResponse = await refreshPagedata1(deviceBranches);
      console.log(apiResponse);
      if (apiResponse.ok) {
        // handleClose()
        sessionStorage.setItem('refresh', false)
        setFullPageLoader(false)
        toast.success('Data Updated Successfully!', {
          position: "top-center",
          autoClose: 1000,
        });
      }
    } catch (error) {
      setFullPageLoader(false)
      // handleClose()
      toast.warning('Something went wrong!', {
        position: "top-center",
        autoClose: 1000,
      });
    } finally {
    }
  };

  useEffect(() => {
    const getCurrentDate = () => {
      const date = new Date();
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    };

    setTodayDate(getCurrentDate());
  }, []);

  useEffect(() => {
    fetchLeavesData()
  }, [searchLeaveType])

  const fetchLeavesData = async () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0-11

    // Use selected date or current date
    const yearToUse = selectedDate.year || currentYear;
    const monthToUse = selectedDate.month || currentMonth;

    let toastDisplayed = false;

    console.log(`Fetching data for Year: ${yearToUse}, Month: ${monthToUse}`);

    try {
      setLoading(true)
      console.log(toastDisplayed);

      let formattedData;
      if (searchLeaveType == 'Current Leave') {
        formattedData = await viewemployeeleavepaggination(page, pageSize, yearToUse, monthToUse);
      }
      if (searchLeaveType == 'Past Leave') {
        formattedData = await pastemployeeleavepaggination(page, pageSize, yearToUse, monthToUse);
      }
      console.log(formattedData);

      if (formattedData.data && formattedData.data.length > 0) {
        const employeeLeaveData = formattedData.data.map((item, index) => {
          const employeeLeave = item.employee_leave || {};

          return {
            id: item._id,
            ind: index + 1, // Adjusting index for display purposes
            subId: employeeLeave._id || '', // Ensure subId is correctly mapped
            employee_name: employeeLeave.employee_name || 'N/A',
            employee_id: employeeLeave.employee_id || 'N/A',
            total_days: employeeLeave.total_days || 'N/A',
            leave_type: employeeLeave.leave_type || 'N/A',
            from_date: employeeLeave.from_date || 'N/A',
            to_date: employeeLeave.to_date || 'N/A',
            total_days: employeeLeave.total_days || 'N/A',
            remaining_leaves: employeeLeave.remaining_leaves !== null ? String(employeeLeave.remaining_leaves) : '0',
            leave_reason: employeeLeave.leave_reason || 'N/A',
            statusName: employeeLeave.statusName == 'Approve' ? 'Unpaid leave' : employeeLeave.statusName || '',
            appliedOn: employeeLeave.created_at
            //   appliedOn: employeeLeave.created_at
            //     ? format(new Date(employeeLeave.created_at * 1000), 'MM/dd/yyyy hh:mm a')
            //     : null
          }
        });

        console.log(employeeLeaveData);
        setMasterId(formattedData.data[0]._id); // Get the master ID from the first item
        setAddEmployeeDataData(employeeLeaveData); // Set the formatted data
        setMonthlyLeaveLen(formattedData.data.length)
        setPendingCount(formattedData.pendingCount)
        console.log(formattedData.data.length);

        setTotalPages(formattedData.totalPages); // Set total pages
        setpageSize(formattedData.pageSize);
      }
    } catch (error) {
      setLoading(false)
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };


  // break states 
  const tableRef = useRef(null);
  const [onBreakEmployees, setOnBreakEmployees] = useState([]);

  // columns for break 
  const columns = [
    {
      field: 'date',
      headerName: 'Date',
      minWidth: 100,
      flex: 1,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 200,
      flex: 1,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'breaksIn',
      headerName: 'Break Start',
      minWidth: 125,
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        // <div>
        //   {params.row.breaksIn.map((breakItem, index) => (
        //     <div key={index} style={{ color: 'green' }}>
        //       {breakItem.time}
        //     </div>
        //   ))}

        // </div>
        <div>
          {Array.isArray(params.row.breaksIn) && params.row.breaksIn.length > 0 ? (
            params.row.breaksIn.map((breakItem, index) => (
              <div key={index} style={{ color: 'green' }}>
                {breakItem.time}
              </div>
            ))
          ) : (
            <div></div>
          )}
        </div>

        // console.log(params)
      ),
    },
    {
      field: 'breaksOut',
      headerName: 'Break End',
      minWidth: 125,
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        // <div>
        //   {params.row.breaksOut.map((breakItem, index) => (
        //     <div key={index} style={{ color: 'red' }}>
        //       {breakItem.time}
        //     </div>
        //   ))}
        // </div>
        <div>
          {Array.isArray(params.row.breaksOut) && params.row.breaksOut.length > 0 ? (
            params.row.breaksOut.map((breakItem, index) => (
              <div key={index} style={{ color: 'red' }}>
                {breakItem.time}
              </div>
            ))
          ) : (
            <div></div>
          )}
        </div>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 100,
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const status = params.value;
        const statusColor = status === 'OUT' ? 'red' : 'green';
        return <span style={{ color: statusColor, fontWeight: 'bold' }}>{status}</span>;
      },
    },
    {
      field: 'totalTimeFormatted',
      headerName: 'Total Time',
      minWidth: 100,
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => <span>{params.row.totalTimeFormatted || '0'}</span>, // Handle empty cases
    },
    {
      field: 'violation',
      headerName: 'Violation',
      minWidth: 100,
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => <span>{params.row.violation || 'None'}</span>, // Handle empty cases
    },
    {
      field: 'violationfine',
      headerName: 'Violation Fine',
      minWidth: 100,
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => <span>{params.row.violationfine !== null ? params.row.violationfine : 0}</span>, // Ensure default value
    },
    {
      field: 'comment',
      headerName: 'Comments',
      minWidth: 200,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const breakOut = params.row.breaksOut && params.row.breaksOut[0];
        return (
          <span>{breakOut ? breakOut.comments || '' : 'No comment'}</span>
        );
      },
    },
    // {
    //   field: 'actions',
    //   headerName: 'Actions',
    //   minWidth: 100,
    //   flex: 0.5,
    //   align: 'center',
    //   headerAlign: 'center',
    //   renderCell: (params) => (
    //     <div>
    //       <IconButton
    //         onClick={() => handleEdit(params.row)}
    //         aria-label="edit"
    //         size="small"
    //       >
    //         <EditIcon />
    //       </IconButton>
    //       <IconButton
    //         onClick={() => handleDelete(params.row)}
    //         aria-label="delete"
    //         size="small"
    //       >
    //         <DeleteIcon />
    //       </IconButton>
    //     </div>
    //   ),
    // },
  ];

  const handlePresentButtonClick = () => {
    setShowEmployeeList(true);
    setShowAbsentList(false);
  };

  const handleAbsentButtonClick = () => {
    setShowEmployeeList(false);
    setShowAbsentList(true);
  };

  const handleSubmit = async () => {
    try {
      const formattedIds = ids[0] || ids;
      let payload = {
        ...formattedIds,
        totalViolance,
        ...updatePayload,  // Spread updatePayload here to include comment and violationFine
      };

      console.log('Formatted IDS:', formattedIds);
      console.log('Payload:', payload);

      const response = await updatebreakdetails(payload);

      console.log('Response:', response);
      if (response) {
        toast.success('Break Updated Successfully', {
          position: "top-center",
          autoClose: 1000,
        });
      }

    } catch (error) {
      console.error('Error updating data:', error);
    } finally {
      setLoading(false);
      setFlag(flag => flag + 1); // Increment the counter to force a re-render
    }
  };


  const handleDelete = async (row) => {
    row.breaksIn.forEach(breakItem => {
      console.log(`IN - Main ID: ${breakItem.mainId}, Sub ID: ${breakItem.subId}, Employee ID: ${breakItem.employeeId}`);
    });
    row.breaksOut.forEach(breakItem => {
      console.log(`OUT - Main ID: ${breakItem.mainId}, Sub ID: ${breakItem.subId}, Employee ID: ${breakItem.employeeId}`);
    });

    const breakInDetails = row.breaksIn.map(breakItem => ({
      mainId: breakItem.mainId,
      subId: breakItem.subId,
      employeeId: breakItem.employeeId
    }));

    const breakOutDetails = row.breaksOut.map(breakItem => ({
      mainId: breakItem.mainId,
      subId: breakItem.subId,
      employeeId: breakItem.employeeId
    }));

    // Log details for debugging
    breakInDetails.forEach(breakItem => {
      console.log(`IN - Main ID: ${breakItem.mainId}, Sub ID: ${breakItem.subId}, Employee ID: ${breakItem.employeeId}`);
    });

    breakOutDetails.forEach(breakItem => {
      console.log(`OUT - Main ID: ${breakItem.mainId}, Sub ID: ${breakItem.subId}, Employee ID: ${breakItem.employeeId}`);
    });

    // Construct payload
    const payload = {
      breakIn: breakInDetails,
      breakOut: breakOutDetails
    };
    console.log(payload);

    try {
      // Make API call with the payload
      const response = await deletebreak(payload);

      console.log(response);

    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }


  };









  useEffect(() => {
    setShowEmployeeList(true);
  }, []);


  //Current Ticket Status
  const dataBar1 = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [{
      data: [20, 60, 50, 45, 50, 60, 70, 40, 45, 35, 25, 30],
      backgroundColor: '#506fd9',
      barPercentage: 0.5
    }, {
      data: [10, 40, 30, 40, 60, 55, 45, 35, 30, 20, 15, 20],
      backgroundColor: '#85b6ff',
      barPercentage: 0.5
    }, {
      data: [8, 30, 40, 35, 40, 45, 35, 30, 25, 10, 20, 15],
      backgroundColor: '#e2e5ec',
      barPercentage: 0.5
    }]
  };

  const optionBar1 = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 80,
        ticks: {
          color: '#a1aab3',
          font: {
            size: 10
          }
        },
        grid: {
          borderColor: '#e2e5ec',
          borderWidth: 1.5,
          color: 'rgba(65,80,95,.08)'
        }
      },
      x: {
        ticks: {
          color: '#313c47'
        },
        grid: {
          color: 'rgba(65,80,95,.08)'
        }
      }
    }
  };

  const dataBar2 = {
    labels: ['Modification', 'Code Request', 'Feature Request', 'Bug Fix', 'Integration', 'Production'],
    datasets: [{
      data: [20, 60, 50, 45, 50, 75],
      backgroundColor: ['#506fd9', '#85b6ff', '#33d685', '#ffc107', '#0dcaf0', '#ea4c89'],
      barPercentage: 0.5
    }, {
      data: [10, 40, 30, 40, 60, 55],
      backgroundColor: '#e2e5ec',
      barPercentage: 0.5
    }]
  };

  const optionBar2 = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      x: {
        beginAtZero: true,
        max: 100,
        grid: {
          borderColor: '#e2e5ec',
          color: '#f3f5f9'
        },
        ticks: {
          font: {
            size: 11
          }
        }
      },
      y: {
        grid: {
          borderWidth: 0,
          color: '#f3f5f9'
        },
        ticks: {
          color: '#465463',
          font: {
            size: 13
          }
        }
      }
    }
  };



  const optionOne = {
    chart: {
      parentHeightOffset: 0,
      toolbar: { show: false },
      stacked: true,
      sparkline: { enabled: true }
    },
    colors: ['#506fd9'],
    stroke: {
      curve: 'straight',
      width: 2
    },
    xaxis: { max: 40 },
    yaxis: {
      min: 0,
      max: 80
    },
    fill: {
      type: 'solid',
      opacity: 0.2
    },
    tooltip: { enabled: false }
  }

  // Time to Resolved Complain
  const optionDonut1 = {
    chart: { parentHeightOffset: 0 },
    colors: ['#506fd9', '#e5e9f2'],
    dataLabels: { enabled: false },
    legend: { show: false }
  };

  const optionDonut2 = {
    chart: { parentHeightOffset: 0 },
    colors: ['#4c5366', '#e5e9f2'],
    dataLabels: { enabled: false },
    legend: { show: false }
  }

  // Agent Performance Score
  const optionTwo = {
    chart: {
      parentHeightOffset: 0,
      toolbar: { show: false },
      stacked: true
    },
    colors: ['#4f6fd9'],
    grid: {
      borderColor: 'rgba(72,94,144, 0.08)',
      padding: {
        top: -20,
        left: 0,
        right: -8
      },
      yaxis: {
        lines: { show: false }
      }
    },
    dataLabels: { enabled: false },
    stroke: {
      curve: 'straight',
      width: 1.5
    },
    xaxis: {
      overwriteCategories: ['', 'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC', ''],
      tickAmount: 13,
      axisBorder: { show: false },
      labels: {
        style: {
          colors: '#6e7985',
          fontSize: '10px'
        }
      },
    },
    yaxis: {
      min: 0,
      max: 50,
      show: false
    },
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: 0.5,
        opacityTo: 0,
      }
    },
    legend: { show: false },
    tooltip: { enabled: false }
  }

  ///// Skin Switch /////
  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  }

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);


  const [employeehistoryData, setEmployeehistoryData] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [totalActiveEmployees, setTotalActiveEmployees] = useState(0);
  const [totalDeletedEmployees, setTotalDeletedEmployees] = useState(0);

  const fetchtotalempData = async () => {
    try {
      setLoading(true);
      const formData = {};
      const response = await viewEmployeehistory(formData);

      console.log(response);
      if (!response || !response.success) {
        throw new Error("Failed to fetch data or empty response.");
      }

      const employeeData = response.success;
      setEmployeehistoryData(employeeData);

      // Calculate total active employees
      const activeEmployees = employeeData.filter(employee => employee.delete_status === "NO");


      // Calculate total deleted employees
      const deletedEmployees = employeeData.filter(employee => employee.delete_status === "YES");


      setTotalActiveEmployees(activeEmployees.length);
      setTotalDeletedEmployees(deletedEmployees.length);

      console.log("Fetched Employee Data:", employeeData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {

    setupData()
  }, [flag])

  // const setupData = async () => {
  //   try {
  //     setLoading(true);

  //     const response = await getBasicSetup();
  //     console.log(response);

  //     // Storing only the 'basicSetup' part of the response in sessionStorage
  //     if (response && response.success && response.data.length > 0) {
  //       const basicSetupData = response.data[0].basicSetup;

  //       // Correct usage of sessionStorage.setItem
  //       sessionStorage.setItem('setupData', JSON.stringify(basicSetupData));
  //     }

  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const setupData1 = async () => {
    try {
      setLoading(true);

      const response = await getBasicSetup();
      console.log(response);

      if (response && response.success && response.data.length > 0) {
        const basicSetupData = response.data[0].basicSetup;

        // Store the data in sessionStorage
        sessionStorage.setItem('setupData', JSON.stringify(basicSetupData));

        // Check if all keys except 'allDone' have a value of "true"
        const allKeysExceptAllDoneTrue = Object.keys(basicSetupData).every(
          key => key === 'allDone' || basicSetupData[key] === 'true'
        );



        // Log or use the result as needed
        if (allKeysExceptAllDoneTrue) {
          console.log('All keys except "allDone" have a value of "true".');
          const storedSetupData = JSON.parse(sessionStorage.getItem('setupData'));

          // Apply the spread operator to keep the stored values, but update `companyProfile`
          const updateProfileData = {
            ...storedSetupData, // Spread the stored values
            allDone: "true", // Update companyProfile field
          };
          console.log(updateProfileData);


          // Second API call with updated data
          const secondResponse = await basicSetup(updateProfileData);
          console.log(secondResponse);
        } else {
          setShowSetupModal(true)

          console.log('Not all keys except "allDone" have a value of "true".');
        }
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const setupData = async () => {
    try {
      // Set loading state if needed
      // setLoading(true);

      const response = await getBasicSetup();
      console.log(response);

      if (response && response.success && response.data.length > 0) {
        const basicSetupData = response.data[0].basicSetup;

        // Store the data in sessionStorage
        sessionStorage.setItem('setupData', JSON.stringify(basicSetupData));

        // Filter the setup items with false values (excluding 'allDone')
        const falseItems = Object.keys(basicSetupData)
          .filter(key => key !== 'allDone' && basicSetupData[key] === 'false')
          .reduce((obj, key) => {
            obj[key] = basicSetupData[key];
            return obj;
          }, {});

        setSetupItems(falseItems);

        // Check if all keys except 'allDone' have a value of "true"
        const allKeysExceptAllDoneTrue = Object.keys(basicSetupData).every(
          key => key === 'allDone' || basicSetupData[key] === 'true'
        );

        if (allKeysExceptAllDoneTrue) {
          console.log('All keys except "allDone" have a value of "true".');
          const storedSetupData = JSON.parse(sessionStorage.getItem('setupData'));

          // Apply the spread operator to keep the stored values, but update `allDone`
          const updateProfileData = {
            ...storedSetupData, // Spread the stored values
            allDone: "true", // Update 'allDone' field
          };
          console.log(updateProfileData);

          // Second API call with updated data
          // const secondResponse = await basicSetup(updateProfileData);
          // console.log(secondResponse);
        } else {
          setShowSetupModal(true);
          console.log('Not all keys except "allDone" have a value of "true".');
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // Reset loading state if needed
      // setLoading(false);
    }
  };

  useEffect(() => {
    const debouncedFetchempData = _debounce(fetchtotalempData, 100);
    debouncedFetchempData();

    return () => {
      debouncedFetchempData.cancel();
    };
  }, []);


  const handleModalClosebreak = () => {
    setShowModalbreak(false);
  };

  const handleModalCloseSetup = () => {
    setShowSetupModal(false);
  };

  const [onLeaveEmployeesCount, setOnLeaveEmployeesCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [leaveData, setLeaveData] = useState([]);
  const [masterId, setMasterId] = useState(null);

  useEffect(() => {
    fetchLeaveData();
  }, []);

  const Leavecolumns = [

    // {
    //   field: 'ind',
    //   headerName: 'Sr. No',
    //   minWidth: 50,
    //   align: 'center', headerAlign: 'center',
    //   flex: 0.5,
    // },
    {
      field: 'employee_name', headerName: 'Employee', minWidth: 100, flex: 2, align: 'center', headerAlign: 'center', renderCell: (params) => (
        <span style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}>{params.value}</span>
      ),
    },
    { field: 'leave_type', headerName: 'Leave Type', minWidth: 100, flex: 1, align: 'center', headerAlign: 'center', },
    { field: 'from_date', headerName: 'From', minWidth: 100, flex: 1, align: 'center', headerAlign: 'center', },
    { field: 'to_date', headerName: 'To', minWidth: 100, flex: 1, align: 'center', headerAlign: 'center' },
    // { field: 'leave_reason', headerName: 'Reason', minWidth: 150, flex: 1, align: 'center', headerAlign: 'center', },
    { field: 'appliedOn', headerName: 'Applied On', minWidth: 150, flex: 2, align: 'center', headerAlign: 'center', },
    {
      field: 'statusName',
      headerName: 'Status',
      align: 'center',
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => {
        console.log(params);
        let color;
        switch (params.value) {
          case 'Pending':
            color = 'blue';
            break;
          case 'Unpaid leave':
            color = 'green';
            break;
          case 'Reject':
            color = 'red';
            break;
          case 'Paid Leave':
            color = 'purple';
            break;
          default:
            color = 'black'; // Default color if none match
        }

        return (
          <div style={{ color: color, textAlign: 'center' }}>
            {params.row.statusName}
          </div>
        );
      }
    },
  ];

  const handleCellClick = (params) => {
    // Check if the clicked field is 'employee_name'
    if (params.field == 'employee_name') {
      // Redirect to the route with the specific employee ID
      navigate(`/pages/empleave/empleave`); // Assuming `id` is the employee ID field
    }
  };

  const fetchLeaveData = async () => {
    try {
      setLoading(true);
      const formattedData = await viewAddEmployeeLeave();

      if (!formattedData || !formattedData.length) {
        throw new Error('Failed to fetch data.');
      }

      const masterData = formattedData[0];
      const employeeLeaveData = masterData.employee_leave;

      setMasterId(masterData._id);

      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0-11, so add 1
      const currentYear = currentDate.getFullYear();

      const currentMonthLeaves = employeeLeaveData.filter((item) => {
        const fromDate = new Date(item.from_date.split('-').reverse().join('-')); // Convert 'DD-MM-YYYY' to 'YYYY-MM-DD'
        const toDate = new Date(item.to_date.split('-').reverse().join('-')); // Convert 'DD-MM-YYYY' to 'YYYY-MM-DD'

        const fromMonth = fromDate.getMonth() + 1;
        const fromYear = fromDate.getFullYear();
        const toMonth = toDate.getMonth() + 1;
        const toYear = toDate.getFullYear();

        // Check if leave spans or overlaps the current month
        const isInCurrentMonth = (
          (fromYear === currentYear && fromMonth <= currentMonth && toYear === currentYear && toMonth >= currentMonth) || // Same year and spans over current month
          (fromYear < currentYear && toYear === currentYear && toMonth >= currentMonth) || // Starts in previous year and ends in current year
          (fromYear === currentYear && fromMonth <= currentMonth && toYear > currentYear)  // Starts in current year but ends in next year
        );

        return isInCurrentMonth;
      });

      // for all leaves 
      // const addEmployeeLeaveArray = Object.values(employeeLeaveData).map((item, index) => ({
      //   id: item._id,
      //   serial: index + 1,
      //   employee_id: item.employee_id,
      //   employee_name: item.employee_name || 'N/A',
      //   leave_type: item.leave_type || 'N/A',
      //   from_date: item.from_date || 'N/A',
      //   to_date: item.to_date || 'N/A',
      //   total_days: item.total_days || 'N/A',
      //   remaining_leaves: item.remaining_leaves !== null ? item.remaining_leaves.toString() : '0',
      //   leave_reason: item.leave_reason || 'N/A',
      // }));

      // for current month leaves 
      const addEmployeeLeaveArray = currentMonthLeaves.map((item, index) => ({
        id: item._id,
        serial: index + 1,
        employee_id: item.employee_id,
        employee_name: item.employee_name || 'N/A',
        leave_type: item.leave_type || 'N/A',
        from_date: item.from_date || 'N/A',
        to_date: item.to_date || 'N/A',
        total_days: item.total_days || 'N/A',
        remaining_leaves: item.remaining_leaves !== null ? item.remaining_leaves.toString() : '0',
        leave_reason: item.leave_reason || 'N/A',
        appliedOn: format(new Date(item.created_at), 'MM/dd/yyyy hh:mm a')
      }));

      console.log("*************************************************************************", addEmployeeLeaveArray);

      setLeaveData(addEmployeeLeaveArray);
      setOnLeaveEmployeesCount(currentMonthLeaves.length); // Store the count of on-leave employees
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false); // Set loading to false when data fetching is done
    }
  };


  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  // const columns = [
  //   // { field: 'employee_id', headerName: 'ID', width: 100 },
  //   { field: 'serial', headerName: 'Sr. No.', width: 120 },
  //   { field: 'employee_name', headerName: 'Name', width: 200 },
  //   { field: 'leave_type', headerName: 'Type', width: 150 },
  //   { field: 'from_date', headerName: 'From', width: 150 },
  //   { field: 'to_date', headerName: 'To', width: 150 },
  //   { field: 'total_days', headerName: 'Days', width: 100 },
  // ];


  // break functions 

  function formatDateString(dateString) {
    const date = new Date(dateString);

    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = date.getUTCFullYear();

    return `${day}-${month}-${year}`;
  }

  useEffect(() => {
    if (todayDate) {
      fetchEmployeeData();
    }
  }, [todayDate])



  // const fetchEmployeeData = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await birthdaymonth();
  //     console.log(response);

  //     const sortedEmployees = response
  //       .map(employee => {
  //         // Ensure 'formattedBirthDate' is valid and in 'DD/MM/YYYY' format
  //         const [birthDay, birthMonth, birthYear] = employee.formattedBirthDate?.split("/");

  //         if (!birthDay || !birthMonth || !birthYear) {
  //           console.error(`Invalid birthDate format for employee ID: ${employee._id}`);
  //           return null; // Skip this employee if the date is invalid
  //         }

  //         const today = new Date();
  //         const todayYear = today.getFullYear();

  //         // Calculate birthday for this year
  //         const birthDateThisYear = new Date(todayYear, birthMonth - 1, birthDay);
  //         const todayInMs = today.getTime();
  //         const birthdayInMs = birthDateThisYear.getTime();

  //         let daysToBirthday;

  //         // Check if the birthday has already passed for this year
  //         if (birthdayInMs < todayInMs) {
  //           // If passed, calculate days to the next year's birthday
  //           const birthDateNextYear = new Date(todayYear + 1, birthMonth - 1, birthDay);
  //           daysToBirthday = Math.ceil((birthDateNextYear - today) / (1000 * 60 * 60 * 24));
  //         } else {
  //           // If not passed, calculate days to this year's birthday
  //           daysToBirthday = Math.ceil((birthDateThisYear - today) / (1000 * 60 * 60 * 24));
  //         }

  //         // Return the employee data with the calculated daysToBirthday
  //         return {
  //           ...employee,
  //           daysToBirthday,
  //         };
  //       })
  //       .filter(employee => employee !== null) // Filter out invalid employees with missing birthDate
  //       .sort((a, b) => a.daysToBirthday - b.daysToBirthday); // Sort by days to birthday

  //     console.log("Sorted Employees:", sortedEmployees);
  //     setBirthdayEmployees(sortedEmployees)

  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const fetchEmployeeData = async () => {
    try {
      setLoading(true);
      const response = await birthdaymonth();
      console.log(response);

      const sortedEmployees = response
        .map(employee => {
          // Ensure 'formattedBirthDate' is valid and in 'DD/MM/YYYY' format
          const [birthDay, birthMonth, birthYear] = employee.formattedBirthDate?.split("/");

          if (!birthDay || !birthMonth || !birthYear) {
            console.error(`Invalid birthDate format for employee ID: ${employee._id}`);
            return null; // Skip this employee if the date is invalid
          }

          const today = new Date();
          const todayYear = today.getFullYear();

          // Calculate the employee's birthday date for this year
          const birthDateThisYear = new Date(todayYear, birthMonth - 1, birthDay);

          // Calculate days to the upcoming birthday
          const timeDifference = birthDateThisYear.getTime() - today.getTime();
          const daysToBirthday = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

          // Exclude employees whose birthday has already passed
          if (daysToBirthday < 0) return null;

          // Return the employee data with the calculated daysToBirthday
          return {
            ...employee,
            daysToBirthday,
          };
        })
        .filter(employee => employee !== null) // Filter out null values (past birthdays and invalid dates)
        .sort((a, b) => a.daysToBirthday - b.daysToBirthday); // Sort by days to birthday

      console.log("Sorted Employees:", sortedEmployees);
      setBirthdayEmployees(sortedEmployees);

    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };





  function getFormattedDateTime() {
    const now = new Date();

    const day = now.getDate().toString().padStart(2, '0');
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const year = now.getFullYear().toString().slice(-2);

    let hours = now.getHours();
    let minutes = now.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;

    const formattedDate = `${day}-${month}-${year}`;
    const formattedTime = `${hours}:${minutes} ${ampm}`;

    console.log(formattedDate, formattedTime);


    return `${formattedDate} ${formattedTime}`;
  }

  function getFormattedDate() {
    const now = new Date();

    const day = now.getDate().toString().padStart(2, '0');
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const year = now.getFullYear().toString().slice(-2);

    let hours = now.getHours();
    let minutes = now.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;

    const formattedDate = `${day}-${month}-${year}`;
    const formattedTime = `${hours}:${minutes} ${ampm}`;

    console.log(formattedDate, formattedTime);


    return `${formattedDate}`;
  }

  useEffect(() => {
    setCurrentDateTime(getFormattedDateTime());
    setTodayDate(getFormattedDate());
  }, []);

  useEffect(() => {
    const debouncedFetchData = _debounce(fetchData, 100);
    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
  }, [searchParams, selectedMonth, selectedYear, currentDateTime]);

  const BreakOutcolumns = [
    { field: 'name', headerName: 'Employee Name', minWidth: 300 },
    {
      field: 'breaksIn',
      headerName: 'BreakIn',
      minWidth: 180,
      valueGetter: (params) => params.row.breaksIn.map(b => b.time).join(', ')
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 130,
      renderCell: (params) => (
        <span style={{ color: 'red' }}>
          {params.value} {/* Display the status value */}
        </span>
      )
    },
  ];


  const fetchData = async () => {
    try {
      setLoading(true);
      // const currentDate = new Date().toISOString().slice(0, 10);
      const now = new Date();
      const currentDate = `${now.getDate().toString().padStart(2, '0')}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getFullYear()}`;
      console.log(currentDateTime);

      const apiResponse = await getemployeedatewiseattendance(currentDateTime);
      console.log("apiResponse", apiResponse);

      if (
        !apiResponse
        // ||
        // !apiResponse.data ||
        // !apiResponse.data.attendance_list ||
        // !apiResponse.data.attendance_list.length
      ) {
        setAttendanceData([]);
        return;
      }

      const sortedAttendanceList = apiResponse.data.attendance_list.sort((a, b) => {
        const timeA = new Date(`1970-01-01T${a.attendance[0].time}Z`);
        const timeB = new Date(`1970-01-01T${b.attendance[0].time}Z`);
        return timeB - timeA;
      });

      console.log(sortedAttendanceList);

      const attendanceList = apiResponse.data.attendance_list;
      console.log(attendanceList);
      const formattedData = attendanceList.map((employee) => {
        console.log(employee);
        return {
          id: employee.id,
          name: employee.name,
          attendances: employee.attendance.map((attendance) => {
            let lastVal = (employee.attendance.length) - 1
            console.log(attendance);
            return {
              date: attendance.date,
              status: attendance.status,
              time: employee.attendance[lastVal].time,
              // IN: attendance.IN,
              // OUT: attendance.OUT,
              // attendanceId: attendance.attendanceId,
              // name: employee.name,
            };
          }),
        };
      });

      setAttendanceData(formattedData);

      setTimeout(() => {

        console.log("formattedData", formattedData);
      }, 5000)

      console.log(currentDate);

      // const presentEmployees = formattedData.filter((employee) => {
      //   const todayAttendance = employee.attendances.find(
      //     (attendance) => attendance.date == currentDate
      //   );
      //   // return todayAttendance && todayAttendance.IN;
      //   return todayAttendance;
      // });
      // console.log(presentEmployees);
      // console.log(presentEmployees.length);
      // setPresentCount(presentEmployees.length);

      const presentEmployees = formattedData.filter((employee) => {
        const todayAttendance = employee.attendances.find(
          (attendance) => attendance.date == currentDate && attendance.status == 1
        );
        return todayAttendance;
      });

      console.log(presentEmployees);
      console.log(presentEmployees.length);
      setPresentCount(presentEmployees.length);


      setAttendanceDatapresentlist(presentEmployees);

      const formData = {};
      const response = await viewEmployee(formData);
      console.log("response", response);
      if (!response || !response.success || !response.data) {
        throw new Error("Failed to fetch data.");
      }

      setEmployeehistoryData(response.totalEmployee);
      const mappedEmployees = response.data.map((item, index) => ({
        id: item.employeeData._id,
        serial: index + 1,
        firstName: item.employeeData.first_name,
        lastName: item.employeeData.last_name,
      }));

      const absentEmployees = mappedEmployees.filter((employee) => {
        return !presentEmployees.find((emp) => emp.id == employee.id);
      });
      console.log(absentEmployees);
      console.log(absentEmployees.length);

      setAttendanceDataAbsentlist(absentEmployees);
      setAbsentCount(absentEmployees.length);


      // Update the checked-in status for each employee for the current date
      const checkedInEmployeeIds = presentEmployees.map(
        (employee) => employee.id
      );
      setCheckedInEmployees(checkedInEmployeeIds);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };


  const filterRows = () => {
    if (!searchText && !selectedDateFilter) {
      return reversedRows.map((row, index) => ({ ...row, id: index + 1 }));
    }

    return reversedRows
      .filter((row) =>
        Object.values(row).some(
          (value) =>
            typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase())
        ) &&
        (!selectedDateFilter || row.date === selectedDateFilter.toISOString().split('T')[0])
      )
      .map((row, index) => ({ ...row, id: index + 1 }));
  };
  const calculateTotalDuration = (breaks) => {
    const totalDuration = breaks.reduce((total, breakItem) => total + breakItem.duration_minutes, 0);
    const hours = Math.floor(totalDuration / 60);
    const minutes = totalDuration % 60;
    return `${hours}h ${minutes}m`;
  };

  const formatTime = (time) => {
    const formattedTime = new Date(`2022-01-01 ${time}`);
    return formattedTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  function formatDateString(dateString) {
    const date = new Date(dateString);

    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = date.getUTCFullYear();

    return `${day}-${month}-${year}`;
  }

  // const fetchBreakData = async (date) => {
  //   try {
  //     const formattedDate = formatDateString(date);
  //     setLoading(true);
  //     const year = date.getFullYear();
  //     const month = date.getMonth() + 1;
  //     const formattedData = await dateviewemployeebreaklist(formattedDate);

  //     console.log(formattedData);

  //     const breakList = formattedData.data.break_list;
  //     console.log(breakList);

  //     const branchArray = breakList.flatMap((item, index) =>
  //       Object.entries(item.attendance).map(([date, attendanceData]) => {
  //         // Get the type of the last break item
  //         const lastBreak = attendanceData.breaks.slice(-1)[0];
  //         const lastBreakType = lastBreak ? lastBreak.type : null;

  //         return {
  //           id: `${index}_${date}`,
  //           date: attendanceData.date,
  //           total_minutes: attendanceData.total_minutes,
  //           name: attendanceData.name,
  //           type: lastBreakType, // Use the type of the last break
  //           time: lastBreak ? formatTime(lastBreak.time) : null,
  //           // breaks: attendanceData.breaks.map(breakItem => ({
  //           //   type: breakItem.type,
  //           //   time: formatTime(breakItem.time),
  //           //   duration_minutes: breakItem.duration_minutes || 0,
  //           // })),
  //           breaks: attendanceData[lastBreak]
  //         };
  //       })
  //     );


  //     console.log(branchArray);

  //     setBreakcountData(branchArray);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const fetchBreakData = async (date) => {
    try {
      const shift = await viewShift();
      const companyShift = shift[0].company_time;
      const shiftArray = Object.values(companyShift).map(item => ({
        _id: item._id,
        shift_no: item.shift_no || 'N/A',
        company_start_time: item.company_start_time || 'N/A',
        company_end_time: item.company_end_time || 'N/A',
        company_break_time: item.company_break_time || 'N/A',
        company_break_fine: item.company_break_fine || 'N/A',
        company_late_fine: item.company_late_fine || 'N/A',
      }));

      const formattedDate = formatDateString(date);
      setLoading(true);
      const formattedData = await dateviewemployeebreaklist(formattedDate);

      const breakList = formattedData.data.break_list;

      const branchArray = breakList.flatMap((item, index) =>
        Object.entries(item.attendance).flatMap(([date, attendanceData]) => {
          const rows = [];
          let currentRow = {
            id: `${index}_${date}`,
            date: attendanceData.date,
            total_minutes: attendanceData.total_minutes,
            name: attendanceData.name,
            breaksIn: [],
            breaksOut: [],
            totalTime: 0,
            totalTimeFormatted: '0h 0m',
            status: '',
            violation: 'No',
            violationfine: 0,
          };

          attendanceData.breaks.forEach((breakItem, breakIndex) => {
            if (breakItem.type === "IN") {
              currentRow.breaksIn.push(breakItem);
            }

            if (breakItem.type === "OUT") {
              currentRow.breaksOut.push(breakItem);

              const inTime = currentRow.breaksIn[currentRow.breaksIn.length - 1]?.time;
              const outTime = breakItem.time;

              if (inTime && outTime) {
                const inMinutes = convertToMinutes(inTime);
                const outMinutes = convertToMinutes(outTime);
                const difference = outMinutes - inMinutes;
                currentRow.totalTime += difference;
              }

              const { hours, minutes } = convertToHoursAndMinutes(currentRow.totalTime);
              currentRow.totalTimeFormatted = `${hours}h ${minutes}m`;

              const companyBreakTime = shiftArray[0].company_break_time;
              const companyBreakFine = shiftArray[0].company_break_fine;

              if (currentRow.totalTime > companyBreakTime) {
                currentRow.violation = 'Yes';
                const extraMinutes = currentRow.totalTime - companyBreakTime;
                currentRow.violationfine = breakItem.totalViolance !== null ? breakItem.totalViolance : extraMinutes * companyBreakFine;
              } else {
                currentRow.violation = 'No';
                currentRow.violationfine = breakItem.totalViolance !== null ? breakItem.totalViolance : 0;
              }

              rows.push({
                ...currentRow,
                status: 'IN',
                mainId: breakItem.mainId,
                subId: breakItem.subId,
                employeeId: breakItem.employeeId,
              });

              currentRow = {
                id: `${index}_${date}_${breakIndex + 1}`,
                date: attendanceData.date,
                total_minutes: attendanceData.total_minutes,
                name: attendanceData.name,
                breaksIn: [],
                breaksOut: [],
                totalTime: 0,
                totalTimeFormatted: '0h 0m',
                status: '',
                violation: 'No',
                violationfine: 0,
              };
            }
          });

          if (currentRow.breaksIn.length > 0 || currentRow.breaksOut.length > 0) {
            const { hours, minutes } = convertToHoursAndMinutes(currentRow.totalTime);
            const companyBreakTime = shiftArray[0].company_break_time;
            const companyBreakFine = shiftArray[0].company_break_fine;
            if (currentRow.totalTime > companyBreakTime) {
              currentRow.violation = 'Yes';
              const extraMinutes = currentRow.totalTime - companyBreakTime;
              currentRow.violationfine = currentRow.violationfine !== 0 ? currentRow.violationfine : extraMinutes * companyBreakFine;
            } else {
              currentRow.violation = 'No';
              currentRow.violationfine = currentRow.violationfine !== 0 ? currentRow.violationfine : 0;
            }
            rows.push({
              ...currentRow,
              status: currentRow.breaksOut.length > 0 ? 'IN' : 'OUT',
              totalTimeFormatted: `${hours}h ${minutes}m`,
            });
          } else if (currentRow.breaksIn.length > 0) {
            rows.push({
              ...currentRow,
              status: 'OUT',
              totalTimeFormatted: '0h 0m',
            });
          }

          return rows;
        })
      );

      console.log(rows);

      const outStatusEmployees = branchArray.filter(row => row.status === 'OUT');
      console.log(outStatusEmployees);


      setBreakcountData(branchArray);
      setBreakInCountData(outStatusEmployees)
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const openModal = (employee) => {
    setSelectedEmployee(employee);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedEmployee(null);
  };


  const handleEdit = async (row) => {
    console.log(row);
    setViolationFine(row.violationfine)

    setShowModal(true)

    // Collect breakIn and breakOut details
    const breakInDetails = row.breaksIn.map(breakItem => ({
      mainId: breakItem.mainId,
      subId: breakItem.subId,
      employeeId: breakItem.employeeId
    }));


    const breakOutDetails = row.breaksOut.map(breakItem => ({
      mainId: breakItem.mainId,
      subId: breakItem.subId,
      employeeId: breakItem.employeeId
    }));
    console.log(breakInDetails[0]);
    console.log(breakOutDetails);

    // Construct payload
    const payload = {
      breakIn: breakInDetails,
      breakOut: breakOutDetails
    };

    const outIds = breakOutDetails[0];
    setEmployeeName(row.name)

    setIds(outIds)


    try {

      const response = await editbreakdetails(payload);

      const breakIn = response.breakData.breakIn.map(item => item.employee_break);
      const breakOut = response.breakData.breakOut.map(item => item.employee_break);
      setBreakInData(breakIn);
      setBreakOutData(breakOut);
      let breakInTime = breakIn.map((value) => {
        return value.break_time
      })
      let breakOutTime = breakOut.map((value) => {
        return value.break_time
      })
      setBreakInTime(breakInTime)
      setBreakOutTime(breakOutTime)
      console.log(breakInTime);

      if (response.success == 'Break data') {
        // Prepopulate state with API response
        const { breakOut } = response.breakData;
        if (breakOut.length > 0) {
          const breakOutDetails = breakOut[0].employee_break;
          setUpdatePayload({
            violationFine: breakOutDetails.totalViolance || 0,
            comment: breakOutDetails.comment || '',
          });
        }
      }

      const threshold = 1;

      let minutes = breakOut.map((value) => {
        const numMinutes = parseFloat(value.total_minutes);

        if (numMinutes > threshold) {
          return Math.ceil(numMinutes); // Round up if greater than the threshold
        } else {
          return Math.floor(numMinutes); // Round down if less than or equal to the threshold
        }
      });


      setTotalMinutes(minutes)


    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }

  };

  useEffect(() => {
    fetchHolidayData()
  }, [])


  const fetchHolidayData = async () => {
    try {
      const formattedData = await viewholiday();

      console.log(formattedData);

      const { success, data, totalPages } = formattedData; // Ensure to check for success

      if (success) {
        const holidaysArray = data[0].company_holiday.map(value => ({
          _id: value?._id || 'N/A', // Use company_holiday for ID
          holiday_name: value?.holiday_name || 'N/A', // Extract holiday name
          holiday_date: value?.holiday_date || 'N/A' // Extract holiday date
        }))
        // Set the master ID only if holidaysArray has data
        setMasterId(data[0]?._id || null);

        console.log(holidaysArray);

        setHolidayData(holidaysArray);
        setTotalPages(totalPages);
        setpageSize(pageSize);
        setRows(holidaysArray);
      } else {
        // Handle unsuccessful response
        setHolidayData([]);
        setTotalPages(0);
        setMasterId(null);
        setRows([]); // Ensure rows are set to an empty array when there's no data
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'violationFine') {
      setViolationFine(value);
    }

    setUpdatePayload((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  const convertToMinutes = (timeStr) => {
    const [hours, minutes] = timeStr.split(':').map(Number);
    return hours * 60 + minutes;
  };

  const convertToHoursAndMinutes = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return { hours, minutes };
  };


  useEffect(() => {
    if (selectedDateFilter == null) {

      fetchBreakData(selectedDate);
      console.log('Component re-rendered because flag changed:', flag);

    }
    else {
      fetchBreakData(selectedDateFilter);
      console.log('Component re-rendered because flag changed:', flag);

    }

    return () => { };
  }, [selectedDateFilter, flag]);







  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalShow = () => {
    setShowModal(true);
  };

  const openModalWithFilteredData = () => {
    filterCurrentDateBreaks();
    handleModalShow();
  };


  // const fetchBreakData = async (date) => {
  //   try {
  //     setLoading(true);
  //     const year = date.getFullYear();
  //     const month = date.getMonth() + 1;
  //     const formattedData = await searchEmployeeBreak(year, month);

  //     if (!formattedData || !formattedData.data.length) {
  //       throw new Error('Failed to fetch data.');
  //     }

  //     const breakList = formattedData.data[0].break_list;
  //     console.log(breakList);


  //     const branchArray = breakList.flatMap((item, index) =>
  //       Object.entries(item.attendance).map(([date, attendanceData]) => ({
  //         id: `${index}_${date}`,
  //         date: attendanceData.date,
  //         total_minutes: attendanceData.total_minutes,
  //         name: attendanceData.name,
  //         type: attendanceData.breaks[0].type,
  //         time: formatTime(attendanceData.breaks[0].time),
  //         breaks: attendanceData.breaks.map(breakItem => ({
  //           type: breakItem.type,
  //           time: formatTime(breakItem.time),
  //           duration_minutes: breakItem.duration_minutes || 0,
  //         })),
  //       }))
  //     );
  //     console.log(branchArray);

  //     setBreakcountData(branchArray);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   if (selectedDateFilter == null) {

  //     fetchBreakData(selectedDate);
  //   }
  //   else {
  //     fetchBreakData(selectedDateFilter);
  //   }

  //   return () => { };
  // }, [selectedDateFilter]);

  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    setShowSecondModal(true);
  };
  const handleSecondModalClose = () => {
    setShowSecondModal(false);
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filterCurrentDateBreaks = () => {
    const currentDate = new Date();
    const formattedCurrentDate = currentDate.toISOString().split('T')[0];

    const filteredData = breakcountData
      .filter((row) =>
        row.date === formattedCurrentDate &&
        row.breaks.some((breakItem) => breakItem.type === 'IN') &&
        !row.breaks.some((breakItem) => breakItem.type === 'OUT' && !breakItem.duration_minutes) &&
        row.breaks[row.breaks.length - 1]?.type !== 'OUT'
      );

    setFilteredBreakData(filteredData);
  };
  const datepickerRef = useRef(null);

  // const columnsNew = [
  //   { field: 'date', headerName: 'Date', width: 100, align: 'center', flex: 2, headerAlign: 'center' },
  //   { field: 'name', headerName: 'Name', width: 100, align: 'center', flex: 2, headerAlign: 'center' },
  //   {
  //     field: 'total_minutes',
  //     headerName: 'Total Break Duration(in Minutes)',
  //     width: 50,
  //     align: 'center',
  //     headerAlign: 'center',
  //     flex: 2,
  //     valueFormatter: (params) => {
  //       const value = params.value;
  //       return value ? value.toFixed(2) : '0.00';
  //     },
  //   },
  //   {
  //     field: 'status',
  //     headerName: 'Status',
  //     width: 100,
  //     align: 'center',
  //     headerAlign: 'center',
  //     flex: 2,
  //     renderCell: (params) => (
  //       // console.log(params)

  //       <div className={`status-${params.row.type}`}>{params.row.type}</div>
  //     ),
  //   },
  // ];

  const columnsNew = [
    { field: 'date', headerName: 'Date', width: 100, align: 'center', flex: 2, headerAlign: 'center' },
    { field: 'name', headerName: 'Name', width: 100, align: 'center', flex: 2, headerAlign: 'center' },
    {
      field: 'breaksIn',
      headerName: 'IN Breaks',
      width: 250,
      align: 'center',
      headerAlign: 'center',
      flex: 3,
      renderCell: (params) => (
        <div>
          {params.row.breaksIn.map((breakItem, index) => (
            <div key={index} style={{ color: 'green' }}>
              {breakItem.time}
            </div>
          ))}
        </div>
      ),
    },
    {
      field: 'breaksOut',
      headerName: 'OUT Breaks',
      width: 250,
      align: 'center',
      headerAlign: 'center',
      flex: 3,
      renderCell: (params) => (
        <div>
          {params.row.breaksOut.map((breakItem, index) => (
            <div key={index} style={{ color: 'red' }}>
              {breakItem.time}
            </div>
          ))}
        </div>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const status = params.value;
        const statusColor = status === 'OUT' ? 'red' : 'green';
        return <span style={{ color: statusColor, fontWeight: 'bold' }}>{status}</span>;
      },
    },
    {
      field: 'totalTimeFormatted',
      headerName: 'Total Time (h m)',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => <span>{params.row.totalTimeFormatted}</span>, // Render formatted total time
    },
  ];

  useEffect(() => {
    if (selectedDateFilter == null) {

      fetchBreakData(selectedDate);
    }
    else {
      fetchBreakData(selectedDateFilter);
    }

    return () => { };
  }, [selectedDateFilter]);

  // useEffect(() => {
  //   filterCurrentDateBreaks();
  // }, [breakcountData]);




  const handleClick = (event) => {
    const action = event.target.getAttribute('data-action');
    if (action == "companyProfile") {
      navigate('/companyprofile')
    }
    if (action == "companyTime") {
      navigate('/pages/hr/shiftsform')
    }
    if (action == "companyBranch") {
      navigate('/pages/hr/branchform')
    }
    if (action == "companyLogo") {
      navigate('/companylogo')
    }
    if (action == "workingDays") {
      navigate('/pages/workingdays/addworkingdays')
    }
    if (action == "department") {
      navigate('/admin/adddepartment')
    }
    if (action == "designation") {
      navigate('/admin/designation')
    }
  };

  // Example usage
  <button className="btn-primary" data-action="setup" onClick={handleClick}>Setup</button>



  const handleRefresh = () => {
    setIsSpinning(true);
    fetchRefreshData1()
    // console.log(isSpinning);
    // setRefeshFlag(!refreshFlag)
    // // fetchRefreshData()

    setTimeout(() => {
      setIsSpinning(false);
    }, 1000);
  };

  return (
    <React.Fragment>
      {fullPageLoader ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          {/* <Sidebar /> */}
          {/* <Outlet /> */}
          <Header onSkin={setSkin} />
          <div className="main main-app p-3 p-lg-4">


            <div className="d-md-flex align-items-center justify-content-between mb-4">
              <div>
                <ol className="breadcrumb fs-sm mb-1">
                  {/* <li className="breadcrumb-item"><Link href="/admin/dashboard">Dashboard</Link></li> */}
                  {/* <li className="breadcrumb-item active" aria-current="page">Helpdesk Service</li> */}
                </ol>
                <h4 className="main-title mb-0">Welcome to Dashboard</h4>
              </div>
              <div className="d-flex align-items-center gap-2 mt-3 mt-md-0">
                {/* <Button
                  className="btn btn-primary border-0 ms-2"
                  onClick={handleRefresh}
                  style={{ cursor: 'pointer' }}
                  title="Update attendance"
                >
                  <OnDeviceTrainingIcon
                    icon={faSync}
                    className={isSpinning ? 'spin' : ''}
                  />
                
                </Button> */}
                <Button variant="white" className="btn-icon"><i className="ri-share-line fs-18 lh-1"></i></Button>
                <Button variant="white" className="btn-icon"><i className="ri-printer-line fs-18 lh-1"></i></Button>
                <Button variant="primary" className="btn-icon"><i className="ri-bar-chart-2-line fs-18 lh-1"></i></Button>
              </div>
            </div>



            <Row className="g-3">
              <div class="row">
                {/* <div class="col-sm-3">
              <div class="card bg-primary text-white mb-3">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="helpdesk-icon"><i class="ri-bell-line"></i></div>
                    <div class="ms-3">
                      <div>
                        {loading ? (
                          <div className="loader-containerx">
                            <div className="loaderx"></div>
                          </div>
                        ) : (
                          <h2 class="card-value mb-0" style={{ color: 'white' }}>{totalActiveEmployees}</h2>
                        )}
                      </div>
                      <p class="card-label mb-0">Current Employee</p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}





                <div class="col-sm-3" style={{ cursor: 'pointer' }}>
                  <div class="card bg-ui-02 text-white mb-3" onClick={() => setShowModalpresentlist(true)}>
                    <div class="card-body">
                      <div class="d-flex align-items-center">
                        <div class="helpdesk-icon"><i class="ri-star-smile-line"></i></div>
                        <div class="ms-3">
                          <h3 className="mb-0" style={{ color: 'white' }}>
                            <span style={{ color: 'green' }} >P</span> - {presentCount}/
                            <span style={{ color: 'red' }}>A</span> - {absentCount}
                          </h3>
                          <span
                          >
                            Present/Absent
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-3" style={{ cursor: 'pointer' }}>
                  <div className="card bg-ui-02 text-white mb-3" onClick={() => openModal(breakInCountData[0])}>
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="helpdesk-icon"><i className="ri-blaze-fill"></i></div>
                        <div className="ms-3">
                          <div>
                            {loading ? (
                              <div className="loader-containerx">
                                <div className="loaderx"></div>
                              </div>
                            ) : (
                              <h2 className="card-value mb-0" style={{ color: 'white' }}>
                                {breakInCountData.length}
                              </h2>
                            )}
                          </div>
                          <p className="card-label mb-0">Employees on Break</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Modal show={modalIsOpen} onHide={closeModal} size="lg">
                  <Modal.Header closeButton>
                    <Modal.Title>Employee Break Information</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div style={{ height: 400, width: '100%' }}>
                      <DataGrid
                        rows={breakInCountData}
                        columns={BreakOutcolumns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                      />
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>

                <div className="col-sm-3">
                  {/* <div className="card bg-ui-02 text-white mb-3" onClick={handleShowModal} style={{ cursor: 'pointer' }}>
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="helpdesk-icon"><i className="ri-blaze-fill"></i></div>
                    <div className="ms-3">
                      <div>
                        {loading ? (
                          <div className="loader-containerx">
                            <div className="loaderx"></div>
                          </div>
                        ) : (
                          <h2 className="card-value mb-0" style={{ color: 'white' }}>
                            {monthlyLeaveLen}
                          </h2>
                        )}
                      </div>
                      <p className="card-label mb-0">Monthly Leave</p>
                    </div>
                  </div>
                </div>
              </div> */}


                  <div
                    className={`card bg-ui-02 text-white mb-3`}
                    onClick={handleShowModal}
                    style={{ cursor: 'pointer', position: 'relative' }}
                  >
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="helpdesk-icon"><i className="ri-blaze-fill"></i></div>
                        <div className="ms-3">
                          <div>
                            {loading ? (
                              <div className="loader-containerx">
                                <div className="loaderx"></div>
                              </div>
                            ) : (
                              <h2 className="card-value mb-0" style={{ color: 'white' }}>
                                {monthlyLeaveLen}
                              </h2>
                            )}
                          </div>
                          <p className="card-label mb-0">Monthly Leave</p>
                        </div>
                      </div>

                      {/* Pending Count Notification */}
                      {pendingCount > 0 &&
                        <span
                          className="badge"
                          style={{
                            position: 'absolute',
                            top: '-10px',
                            right: '-10px',
                            fontSize: '0.8rem',
                            fontWeight: 'bold',
                            padding: '5px 10px',
                            borderRadius: '50%',
                            backgroundColor: '#506FD9',
                            color: 'white',
                            animation: 'blink 1s infinite',
                          }}
                        >
                          {pendingCount}
                        </span>
                      }
                    </div>
                  </div>




                  <Modal show={showModal} onHide={handleCloseModal} size="xl">
                    <Modal.Header closeButton>
                      <Modal.Title>On Leave Employees</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {loading ? (
                        <div className="loader-container">
                          <div className="loader"></div>
                        </div>
                      ) : monthlyLeaveLen ? (
                        <div style={{ height: '70vh', width: '100%', backgroundColor: 'white' }}>
                          <Button className='btn btn-primary border-0' onClick={() => {
                            setSearchLeaveType('Current Leave')

                          }}>Current Leaves</Button>
                          <Button className='btn btn-primary border-0' onClick={() => {
                            setSearchLeaveType('Past Leave')

                          }}>Past Leaves</Button>

                          <DataGrid
                            rows={employeeDataData}
                            columns={Leavecolumns}
                            pageSize={pageSize}
                            pagination
                            paginationMode="client"
                            getRowId={(row) => row.subId}
                            onCellClick={handleCellClick}
                            // pageSize={5}

                            components={{
                              Pagination: () => (
                                <div className="custom-select-container">
                                  <div>
                                    <label htmlFor="page-size-reversed">Rows per page: </label>
                                    <select
                                      id="page-size-reversed"
                                      value={pageSize}
                                    >
                                      <option value={50}>50</option>
                                      <option value={100}>100</option>
                                    </select>
                                  </div>
                                  <div>
                                    <label htmlFor="page-number-reversed">Page: </label>
                                    <select
                                      className="page-number"
                                      id="page-number-reversed"
                                      value={page}
                                    >
                                      {[...Array(totalPages)].map((_, pageNumber) => (
                                        <option key={pageNumber} value={pageNumber + 1}>
                                          {pageNumber + 1}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                              ),
                              NoRowsOverlay: () => (
                                <div style={{ textAlign: "center", padding: "20px" }}>
                                  No data available
                                </div>
                              ),
                            }}
                          />
                        </div>
                      ) : (
                        <p>No employees are currently on leave.</p>
                      )}

                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
                <div className="col-sm-3">



                </div>
              </div>

              {/* {[
            {
              "bg": "primary",
              "icon": "ri-bell-line",
              "value": '3',
             
              "success": true,
              "label": "Current Employee",
              "avatar": [img10, img11, img9, img8],
             
            }
            , {
              "bg": "ui-02",
              "icon": "ri-blaze-fill",
              "value": "40",
             
              "success": false,
              "label": "Total Employee",
              "avatar": [img15, img16, img17, img18],
              
            }, {
              "bg": "ui-03",
              "icon": "ri-star-smile-line",
              "value": "198",
              "success": false,
              "label": "Attandance",
              "avatar": [img7, img8, img9, img10],
            }
          ].map((item, index) => (
            <Col sm="4" key={index}>
              <Card className="card-one">
                <Card.Body className="p-3">
                  <div className="d-flex d-sm-block d-xl-flex align-items-center">
                    <div className={"helpdesk-icon text-white bg-" + item.bg}><i className={item.icon}></i></div>
                    <div className="ms-3 ms-sm-0 ms-xl-3 mt-sm-3 mt-xl-0">
                      <h2 className="card-value d-flex align-items-baseline mb-0">{item.value} <small className={"text-" + (item.success ? "success" : "")}>{item.percent}</small></h2>
                      <label className="card-label fs-sm fw-medium mb-1">{item.label}</label>
                      <div className="mutual-badge">
                        <ul>
                          {item.avatar.map((avatar, ind) => (
                            <li key={ind}><Avatar img={avatar} /></li>
                          ))}
                        </ul>
                        
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))} */}


              <Col md="7" xl="8">
                <Card className="card-one">
                  <Card.Header>
                    <Card.Title as="h6">Break History</Card.Title>
                    <Nav className="nav-icon nav-icon-sm ms-auto">
                      <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                      <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                    </Nav>
                  </Card.Header>

                  <Card.Body>
                    <div className="input-group">

                      <DatePicker
                        selected={selectedDateFilter}
                        onChange={(date) => {
                          setSelectedDateFilter(date);
                          fetchBreakData(date);
                        }}
                        dateFormat="MM/dd/yyyy"
                        className="form-control"
                        ref={datepickerRef}
                      />
                    </div>
                    <br />

                    <div className="d-flex align-items-center mb-2 mb-md-0 mr-2 col-12 col-md-6">

                    </div>
                    {loading ? (
                      <div className="loader-container">
                        <div className="loader"></div>
                      </div>
                    ) : (


                      <div style={{ height: 400, width: '100%', overflowX: 'auto', backgroundColor: 'white' }}>
                        <DataGrid
                          rows={reversedRows}
                          columns={columns}
                          pageSize={5}
                          onRowClick={handleRowClick}
                          components={{
                            NoRowsOverlay: () => <div style={{ textAlign: 'center', padding: '20px' }}>No data available</div>,
                          }}
                        />
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </Col>

              <Col md="5" xl="4">
                <Card className="card-one">
                  <Card.Header>
                    <Card.Title as="h6">Current Time</Card.Title>
                    <Nav className="nav-icon nav-icon-sm ms-auto">
                      <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                      <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                    </Nav>
                  </Card.Header>
                  <Card.Body className="p-0">
                    {/* <div className="position-absolute p-4">
                  <h1 className="card-value d-flex align-items-baseline mb-0">268</h1>
                  <p className="fs-xs mb-2"><span className="text-success fs-numerals">0.3%</span> higher than last month</p>
                  <p className="fs-sm">The total number of complaints that have been received.</p>
                </div> */}
                    {/* <ReactApexChart series={seriesOne} options={optionOne} type="area" height={280} /> */}
                    {/*********clock code start from here **********/}
                    <div className="App">
                      <Clock />
                    </div>
                  </Card.Body>
                </Card>
              </Col>



              {/* <Col md="6">
            <Card className="card-one">
              <Card.Body className="p-3">
                <Row className="g-3 row-cols-auto align-items-center">
                  <Col>
                    <div className="apex-donut-one">
                      <ReactApexChart series={[86, 14]} options={optionDonut1} width={160} height={160} type="donut" />
                      <div>
                        <h4 className="ff-numerals text-dark mb-0">86%</h4>
                        <span className="fs-xs text-secondary">Reached</span>
                      </div>
                    </div>
                  </Col>
                  <Col xs="6" xl="7">
                    <h2 className="card-value mb-3"></h2>
                    <h6 className="card-label text-dark fw-semibold mb-1">Total attendance</h6>
                    <p className="fs-sm text-secondary mb-0">The average time taken to resolve complaints.</p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md="6">
            <Card className="card-one">
              <Card.Body className="p-3">
                <Row className="g-3 row-cols-auto align-items-center">
                  <Col>
                    <div className="apex-donut-one">
                      <ReactApexChart series={[68, 32]} options={optionDonut2} width={160} height={160} type="donut" />
                      <div>
                        <h4 className="ff-numerals text-dark mb-0">68%</h4>
                        <span className="fs-xs text-secondary">Reached</span>
                      </div>
                    </div>
                  </Col>
                  <Col xs="6" xl="7">
                    <h2 className="card-value mb-3"></h2>
                    <h6 className="card-label text-dark fw-semibold mb-1">Your attendance</h6>
                    <p className="fs-sm text-secondary mb-0">The average time taken to resolve complaints.</p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col> */}



              <Col md="7" xl="8">
                <Card className="card-one">
                  <Card.Header>
                    <Card.Title as="h6">Current Employee</Card.Title>
                    <Nav className="nav-icon nav-icon-sm ms-auto">
                      <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                      <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                    </Nav>
                  </Card.Header>
                  <Card.Body>
                    <div className="chartjs-one">
                      <Bar data={dataBar1} options={optionBar1} />
                    </div>
                  </Card.Body>
                </Card>
              </Col>


              <Col md="5" xl="4">
                <Card className="card-one">
                  <Card.Body className="p-4">
                    <Card.Title as="h4"><strong>Holidays</strong></Card.Title>
                    <div className="mt-4 custom-scrollbar" style={{ height: 'calc(25vh)', overflowY: 'auto', padding: "0 0.5rem" }}>
                      {holidayData && holidayData.length > 0 ? (
                        <div className="space-y-2">
                          {holidayData.map((holiday) => (
                            <div
                              key={holiday._id}
                              className="d-flex mb-3 mt-1 p-2 rounded-lg shadow-lg bg-white"
                              style={{ width: '100%' }}
                            >
                              {/* Icon or Image for the holiday */}
                              {/* <div className="w-10 h-10 rounded-full mr-3 flex justify-center items-center bg-blue-200">
                            <strong className="text-xl text-white">{holiday.holiday_name.charAt(0)}</strong>
                          </div> */}

                              <img
                                src="/images/leave_ICON-01.png"
                                alt="Holiday"
                                className="w-10 h-10 rounded-full mr-3"
                                style={{ borderRadius: '50%' }}
                              />
                              {/* Text next to the icon */}
                              <div className="flex flex-col">
                                <div className="text-sm"><strong>{holiday.holiday_name}</strong></div>
                                <div className="text-sm">
                                  {(holiday.holiday_date)}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <p className="text-center text-lg text-gray-500">No holidays available</p>
                      )}
                    </div>
                  </Card.Body>

                  <Card.Body className="p-4">
                    <Card.Title as="h4"><strong>Upcoming Birthday</strong></Card.Title>
                    <div className="mt-4 custom-scrollbar" style={{ height: 'calc(25vh)', overflowY: 'auto', padding: "0 0.5rem" }}>

                      {birthdayEmployee && birthdayEmployee.length > 0 ? (
                        <div className="space-y-2">
                          {birthdayEmployee.map((birthday) => {
                            const [birthDay, birthMonth, birthYear] = birthday.formattedBirthDate.split("/");
                            console.log(birthYear, birthMonth, birthDay);

                            // Create birth date using day, month, and current year
                            const birthDate = new Date(birthMonth - 1, birthDay, new Date().getFullYear());

                            // Today's date
                            const today = new Date();
                            const timeDifference = birthDate - today;
                            const daysToBirthday = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

                            console.log("Days to Birthday:", daysToBirthday);

                            let message;
                            let messageClass = "";
                            let icon = "";
                            let birth = "";

                            if (daysToBirthday == 0) {
                              message = `🎉 Happy Birthday, ${birthday.first_name} ${birthday.last_name}! 🎉`;
                              messageClass = "bg-blue-500";
                            } else if (daysToBirthday > 0 && daysToBirthday <= 3) {
                              message = `${birthday.first_name} ${birthday.last_name} - ${daysToBirthday} day${daysToBirthday > 1 ? 's' : ''} to go`;
                              messageClass = "bg-yellow-400";
                              icon = "⏳";
                            } else {
                              message = `${birthday.first_name} ${birthday.last_name}`;
                              birth = new Date(birthday.birth_date).toLocaleDateString('en-GB');
                              messageClass = "bg-green-500";
                              icon = "📅";
                            }

                            return (
                              <div
                                key={birthday._id}
                                className={`d-flex mb-3 mt-1 p-3 rounded-lg shadow-lg bg-white ${messageClass}`}
                                style={{ width: '100%' }}
                              >
                                <img
                                  src={birthday.image_path}
                                  alt="Profile"
                                  className="w-10 h-10 rounded-full mr-3"
                                  style={{ borderRadius: '50%' }}
                                />
                                {/* Text next to the image */}
                                <div className="flex flex-col">
                                  <div className="text-sm"><strong>{message}</strong></div>
                                  <div className="text-sm flex items-center"><strong>{icon} {birth}</strong></div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <p className="text-center text-lg text-gray-500">No birthdays today!</p>
                      )}

                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Footer />
          </div>
        </>
      )}


      <Modal
        size="lg"
        show={showModalpresentlist}
        onHide={() => setShowModalpresentlist(false)}
      >
        <Modal.Header closeButton className="custom-modal-header">
          <Modal.Title style={{ color: "#FFFFFF" }}>Employee Attendance List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="newbtn11">
            <button
              className="btn btn-primary"
              onClick={handlePresentButtonClick}
              style={{ color: 'white' }}
            >
              Present List
            </button>
            <button
              style={{ color: 'white' }}
              className="btn btn-danger"
              onClick={handleAbsentButtonClick}
            >
              Absent List
            </button>
          </div>

          {loading ? (
            <p>Loading...</p>
          ) : (
            <div>
              {showEmployeeList && (
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <thead>
                    <tr>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          backgroundColor: "#f2f2f2",
                          border: "1px solid #ddd",
                        }}
                      >
                        Name
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          backgroundColor: "#f2f2f2",
                          border: "1px solid #ddd",
                        }}
                      >
                        Status
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          backgroundColor: "#f2f2f2",
                          border: "1px solid #ddd",
                        }}
                      >
                        Time
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {attendanceDatapresentlist.map((employee) => (
                      <tr key={employee.id}>
                        <td style={{ padding: '10px', textAlign: 'center', border: '1px solid #ddd' }}>{employee.name}</td>
                        <td style={{ padding: '10px', textAlign: 'center', border: '1px solid #ddd' }}>
                          <span style={{ backgroundColor: 'green', color: 'white', borderRadius: '5px', padding: '3px 8px' }}>Present</span>
                        </td>
                      </tr>
                    ))} */}
                    {attendanceDatapresentlist.map((employee) => (
                      <tr key={employee.id}>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          {employee.name}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          <span
                            style={{
                              backgroundColor: "green",
                              color: "white",
                              borderRadius: "5px",
                              padding: "3px 8px",
                            }}
                          >
                            Present
                          </span>
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          {employee.attendances[0].time}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}

              {showAbsentList && (
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <thead>
                    <tr>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          backgroundColor: "#f2f2f2",
                          border: "1px solid #ddd",
                        }}
                      >
                        Name
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          backgroundColor: "#f2f2f2",
                          border: "1px solid #ddd",
                        }}
                      >
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {attendanceDataAbsentlist.map((employee) => (
                      <tr key={employee.id}>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          {employee.firstName} {employee.lastName}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          <span
                            style={{
                              backgroundColor: "red",
                              color: "white",
                              borderRadius: "5px",
                              padding: "3px 8px",
                            }}
                          >
                            Absent
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowModalpresentlist(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={showModalbreak} onHide={handleModalClosebreak} >
        <Modal.Header closeButton>
          <Modal.Title>Filtered Break Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {filteredBreakData.length > 0 ? (
            <>
              <p>The employees is on a break now</p>
              <table className="table table-bordered">
                <thead className="thead-light">
                  <tr>
                    <th>Date</th>
                    <th>Name</th>
                    {/* Add more headers for additional fields */}
                  </tr>
                </thead>
                <tbody>
                  {filteredBreakData.map((rowData) => (
                    <tr key={rowData.id}>
                      <td>{rowData.date}</td>
                      <td>{rowData.name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <div style={{ textAlign: 'center', padding: '20px' }}>No employees on a break</div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClosebreak}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <Modal show={showSetupModal} onHide={handleModalCloseSetup} >
        <Modal.Header closeButton>
          <Modal.Title>Get Started</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ul className="activity-group mb-5">
            <li className="activity-item like">
              <p className="d-sm-flex align-items-center mb-0">
                <span className="fs-sm">
                  <strong>Company Profile</strong>
                </span>
                <span className="fs-xs text-secondary ms-auto">
                  <button className="btn-primary" data-action="companyProfile" onClick={handleClick}>Setup</button>
                </span>
              </p>
            </li>
            <li className="activity-item like">
              <p className="d-sm-flex align-items-center">
                <span className="fs-sm">
                  <strong>Company Time</strong>
                </span>
                <span className="fs-xs text-secondary ms-auto">
                  <button className="btn-primary" data-action="companyTime" onClick={handleClick}>Setup</button>
                </span>
              </p>
            </li>
            <li className="activity-item like">
              <p className="d-sm-flex align-items-center mb-0">
                <span className="fs-sm">
                  <strong>Company Branch</strong>
                </span>
                <span className="fs-xs text-secondary ms-auto">
                  <button className="btn-primary" data-action="companyBranch" onClick={handleClick}>Setup</button>
                </span>
              </p>
            </li>
            <li className="activity-item like">
              <p className="d-sm-flex align-items-center mb-0">
                <span className="fs-sm">
                  <strong>Company Logo</strong>
                </span>
                <span className="fs-xs text-secondary ms-auto">
                  <button className="btn-primary" data-action="companyLogo" onClick={handleClick}>Setup</button>
                </span>
              </p>
            </li>
            <li className="activity-item like">
              <p className="d-sm-flex align-items-center mb-0">
                <span className="fs-sm">
                  <strong>Working Days</strong>
                </span>
                <span className="fs-xs text-secondary ms-auto">
                  <button className="btn-primary" data-action="workingDays" onClick={handleClick}>Setup</button>
                </span>
              </p>
            </li>
            <li className="activity-item like">
              <p className="d-sm-flex align-items-center mb-0">
                <span className="fs-sm">
                  <strong>Department</strong>
                </span>
                <span className="fs-xs text-secondary ms-auto">
                  <button className="btn-primary" data-action="department" onClick={handleClick}>Setup</button>
                </span>
              </p>
            </li>
            <li className="activity-item like">
              <p className="d-sm-flex align-items-center mb-0">
                <span className="fs-sm">
                  <strong>Designation</strong>
                </span>
                <span className="fs-xs text-secondary ms-auto">
                  <button className="btn-primary" data-action="designation" onClick={handleClick}>Setup</button>
                </span>
              </p>
            </li>
          </ul>
        </Modal.Body>






        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalCloseSetup}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}
      <Modal show={showSetupModal} onHide={handleModalCloseSetup}>
        <Modal.Header closeButton>
          <Modal.Title>Get Started</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="activity-group mb-5">
            {Object.keys(setupItems).map(key => (
              <li className="activity-item like" key={key}>
                <p className="d-sm-flex align-items-center mb-0">
                  <span className="fs-sm">
                    <strong>{key.replace(/([A-Z])/g, ' $1').trim()}</strong>
                  </span>
                  <span className="fs-xs text-secondary ms-auto">
                    <button className="btn-primary" data-action={key} onClick={handleClick}>
                      Setup
                    </button>
                  </span>
                </p>
              </li>
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalCloseSetup}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <Modal show={showModal} onHide={handleCloseModal} className="custom-modal">
        <Modal.Header closeButton className="custom-modal-header" style={{ backgroundColor: '#007bff', color: 'white' }}>
          <Modal.Title className="" style={{ color: 'white' }}>Edit Break Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-modal-body">
          <div className="col-md-12 formcontrols">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="first_name" className="form-label fw-bold">
                    Employee Name
                  </label>
                  <input
                    readOnly
                    className='form-control'
                    style={{ cursor: 'not-allowed' }}
                    type="text"
                    id="name"
                    name="name"
                    value={employeeName || ""}
                    placeholder="Enter"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="email" className="form-label fw-bold">
                    Total Break Time
                  </label>
                  <input
                    className='form-control'
                    readOnly
                    value={totalMinutes}
                    style={{ cursor: 'not-allowed' }}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="email" className="form-label fw-bold">
                    Break IN
                  </label>
                  <input
                    className='form-control'
                    readOnly
                    style={{ cursor: 'not-allowed' }}
                    value={breakInTime[0]}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="email" className="form-label fw-bold">
                    Break OUT
                  </label>
                  <input
                    className='form-control'
                    readOnly
                    style={{ cursor: 'not-allowed' }}
                    value={breakOutTime[0]}
                  />
                </div>
              </div>



              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="violationFine" className="form-label fw-bold">
                    Violation Fine
                  </label>
                  <input
                    className='form-control'
                    name="violationFine"
                    value={totalViolance || ""}
                    onChange={handleChange}
                  />
                </div>
              </div>


              <div className="col-md-6">
                <div className="mb-3">
                  <label htmlFor="comment" className="form-label fw-bold">
                    Comments
                  </label>
                  <input
                    required
                    className='form-control'
                    name="comment"  // This should match the key in updatePayload
                    onChange={handleChange}
                    value={updatePayload.comment}

                  />
                </div>
              </div>

            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="custom-modal-footer">
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={async (e) => {
              await handleSubmit(); // Call handleSubmit without preventDefault if not using a form
              handleCloseModal();  // Close modal after saving
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal> */}



      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header className="custom-modal-header">
          <Modal.Title style={{ color: 'white' }}>Fetching Clock-in log</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please wait untill the attendance data is fetched completely...
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary">Understood</Button>
        </Modal.Footer> */}
      </Modal>


    </React.Fragment >
  )
}