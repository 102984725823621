import React, { useEffect } from "react";
import { Outlet, useLocation, Navigate, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { Loader } from "../loader";
import { useSession } from "../context/SessionContext";

export default function Main() {

  const { isSessionExpired, resetSessionExpired } = useSession();
  const navigate = useNavigate()
  const offsets = ["/apps/file-manager", "/apps/jobs", "/apps/calendar"];
  const { pathname } = useLocation();
  const bc = document.body.classList;

  // set sidebar to offset
  (offsets.includes(pathname)) ? bc.add("sidebar-offset") : bc.remove("sidebar-offset");

  // auto close sidebar when switching pages in mobile
  bc.remove("sidebar-show");

  // scroll to top when switching pages
  window.scrollTo(0, 0);

  useEffect(() => {
    if (isSessionExpired && pathname !== "/signin") {
      navigate("/signin");
    }
  }, [isSessionExpired, pathname, navigate]);

  // If session is expired, redirect to signin
  if (isSessionExpired && pathname !== "/signin") {
    return <Navigate to="/signin" />;
  }


  return (
    <React.Fragment>
      <Sidebar />
      <Outlet />

    </React.Fragment>
  )
}