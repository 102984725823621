import React, { useState, useEffect, useRef } from "react";
import Header from "../../../layouts/Header";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addEmployee, addHoliday, getId, singleViewEmployeeAttendance, viewDepartment } from "../../../services/api/api";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { Button, Card, Col, Row, Nav, Table, ProgressBar, Form, Badge, ListGroup, Spinner, CardBody } from "react-bootstrap";
import interactionPlugin from '@fullcalendar/interaction'; // This plugin is required for dateClick

const HolidaysForm = () => {

  const navigate = useNavigate();
  const [state, setState] = useState({
    holiday_name: "",
    holiday_date: "",
    // holiday_from: "",
    // holiday_to: "",
    holiday_description: ""
  });
  const [submitting, setSubmitting] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [searchParams, setSearchParams] = useState({ year: new Date().getFullYear(), month: new Date().getMonth() + 1 });
  const calendarRef = useRef(null);
  const [events, setEvents] = useState([]);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [employeeId, setEmployeeId] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(`${searchParams.year}-${String(searchParams.month).padStart(2, '0')}-01`);
    }
  }, [searchParams]);

  const CY = new Date().getFullYear();
  const yearsRange = [];
  for (let i = CY - 100; i <= CY + 100; i++) {
    yearsRange.push(i);
  }
  const handleYearChange = (e) => {
    setSearchParams((prev) => ({ ...prev, year: e.target.value }));
    console.log(e.target.value)
  };

  const handleMonthChange = (e) => {
    setSearchParams((prev) => ({ ...prev, month: e.target.value }));
  };

  const handleDateClick = (info) => {
    console.log(info);
    setState(prevState => ({
      ...prevState,
      holiday_date: info.dateStr
    }));
    setSelectedDate(info.dateStr); // Set the selected date for the popup
  };
  // const handleDateClick = (arg) => {
  //   const date = arg.dateStr;
  //   setSelectedDate(date);
  //   setState((prevState) => ({
  //     ...prevState,
  //     holiday_from: date,
  //     holiday_to: date
  //   }));
  // };

  const handlePopupChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Close the popup
  const closePopup = () => {
    setSelectedDate(null);
    // handleSubmit()
    // setState({
    //   holiday_name: "",
    //   holiday_from: "",
    //   holiday_to: "",
    //   holiday_description: ""
    // });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    fetchEmployeeBreakCount();
  };

  const monthsMap = [
    'january', 'february', 'march', 'april', 'may', 'june',
    'july', 'august', 'september', 'october', 'november', 'december'
  ];


  const [daysInMonth, setDaysInMonth] = useState(30);
  const getLastDateOfMonth = (year, month) => {
    return new Date(year, month, 0).getDate(); // Month is 0-indexed, so use 0 for the last day
  };
  useEffect(() => {
    const empId = getId();
    setEmployeeId(empId);
  }, []);

  useEffect(() => {
    setDaysInMonth(getLastDateOfMonth(searchParams.year, searchParams.month));
  }, [searchParams.year, searchParams.month]);


  const fetchEmployeeBreakCount = async () => {
    console.log("Search parameters:", searchParams);

    try {
      setLoading(true);
      const employeeId = getId();
      let apiResponse;
      const monthName = monthsMap[searchParams.month - 1];

      if (employeeId) {
        apiResponse = await singleViewEmployeeAttendance(
          employeeId,
          searchParams.year,
          monthName
        );
        console.log("API Response:", apiResponse);
      }

      const parsedEvents = parseApiResponse(apiResponse, searchParams.year, searchParams.month);
      setEvents(parsedEvents);
      console.log("Parsed events:", parsedEvents);

    } catch (error) {
      console.error('Error fetching employee break count:', error);
    } finally {
      setLoading(false);
    }
  };

  const parseApiResponse = (apiResponse, year, month) => {
    const formattedEvents = [];
    const allDates = [];

    // Start and end date calculations
    const startDate = new Date(year, month - 1, 2); // First day of the month
    const endDate = new Date(year, month, 1); // Last day of the month

    const today = new Date().toISOString().split('T')[0]; // Current date in YYYY-MM-DD format

    // Generate dates for the entire month
    for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
      const currentDate = new Date(d).toISOString().split('T')[0]; // Format as YYYY-MM-DD
      console.log(currentDate);

      allDates.push(currentDate); // Add the date to the allDates array
    }

    console.log('All dates in month:', allDates); // Check if there are any incorrect dates
    console.log('start dates :', new Date(startDate)); // Check if there are any incorrect dates
    console.log('end dates:', endDate); // Check if there are any incorrect dates

    // Get holiday dates
    const holidayDates = apiResponse.data[0].holidayList.flatMap(holiday =>
      Object.values(holiday.company_holiday).map(h => h.holiday_date)
    );
    console.log('Holiday dates:', holidayDates);

    // Get working days and weekend days
    const workingDays = apiResponse.data[0].workingDay[0];
    const weekendDays = ['sunday', 'saturday'].filter(day => !workingDays[day]);

    // Process each date in the month
    allDates.forEach((date) => {
      const attendance = apiResponse.data[0].attendance_list.find(att => {
        const attDate = att.attendance_time.split(' ')[0]; // Extract date part from attendance
        return attDate == date;
      });

      if (attendance) {
        let title = '';
        let dotColor = '';

        switch (attendance.attendanceCheck) {
          case 1:
            title = 'Present';
            dotColor = 'green';
            break;
          case 2:
            title = 'Half Day';
            dotColor = 'orange';
            break;
          case 3:
            title = 'Weekend';
            dotColor = 'blue';
            break;
          case 4:
            title = 'Paid Leave';
            dotColor = 'purple';
            break;
          case 0:
          default:
            title = 'Absent';
            dotColor = 'red';
            break;
        }

        formattedEvents.push({
          title,
          date,
          time: attendance.attendance_time.split(' ')[1],
          dotColor,
        });
        console.log(`Attendance found for ${date}: ${title}`);

      } else if (holidayDates.includes(date)) {
        formattedEvents.push({
          title: 'Holiday',
          date: date,
          time: '',
          dotColor: 'blue',
        });
        console.log(`Holiday found for ${date}`);

      } else if (weekendDays.includes(new Date(date).toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase())) {
        formattedEvents.push({
          title: 'Weekend',
          date: date,
          time: '',
          dotColor: 'blue',
        });
        console.log(`Weekend found for ${date}`);

      } else {
        formattedEvents.push({
          title: 'Absent',
          date: date,
          time: '',
          dotColor: '#de3c3c',
        });
        console.log(`Absent for ${date}`);
      }
    });

    console.log('Formatted events:', formattedEvents);
    return formattedEvents;
  };

  useEffect(() => {
    setState({
      holiday_name: "",
      holiday_description: "",
      holiday_date: "",
    });
    setErrors({});
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (submitting) {
    //   return;
    // }
    // const validationErrors = validateForm(state);
    // if (Object.keys(validationErrors).length > 0) {
    //   setErrors(validationErrors);
    //   return;
    // }

    // setSubmitting(true);

    try {
      const response = await addHoliday(state);
      const data = JSON.parse(response);
      if (data.success == true) {
        toast.success(data.message, {
          position: "top-center",
          autoClose: 1000,
        });
        closePopup();

      } else {
        toast.success(data.message, {
          position: "top-center",
          autoClose: 1000,
        });
      }
    } catch (er) {
      toast.success("Holidays added successfully", {
        position: "top-right",
        autoClose: 1000,
      });
      navigate("/admin/holidays");
    } finally {
      setSubmitting(false);
    }
  };






  const validateForm = (state) => {
    let errors = {};

    if (!state.holiday_name.trim()) {
      errors.holiday_name = "Holiday name is required";
    }

    if (!state.holiday_date) {
      errors.holiday_date = "Holiday date is required";
    }

    return errors;
  };



  return (
    <React.Fragment>
      <Header />
      {/* Your other layout code here */}
      <div className="main p-4 p-lg-5 mt-5">


        <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
              <li className="breadcrumb-item">
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">
                <Link to="/admin/holidays">Holidays</Link>
              </li>
              <li className="breadcrumb-item active">Add Holidays</li>
            </ol>
          </div>
          <nav aria-label="breadcrumb">
            <Link to="/admin/holidays" className="btn btn-primary mb-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
              </svg>
              Back
            </Link>
          </nav>
        </div>



        <div className="row"
          style={{
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            borderRadius: "10px",
            overflow: "hidden",
          }}
        >


          <div className="col-md-6 formcontrols">
            <div className="bg-white p-4 rounded">
              <form onSubmit={handleSubmit}>

                <Form onSubmit={handleSearch}>
                  <Row className="mb-3">
                    <Col>

                      <Form.Control
                        as="select"
                        value={searchParams.year}
                        onChange={handleYearChange}
                      >
                        {yearsRange.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                    <Col>
                      <Form.Control
                        as="select"
                        value={searchParams.month}
                        onChange={handleMonthChange}
                      >
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                      </Form.Control>
                    </Col>

                  </Row>
                </Form>
              </form>
            </div>
            <FullCalendar
              ref={calendarRef}
              plugins={[dayGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              initialDate={`${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-01`}
              events={[]} // Replace with your events array
              dateClick={handleDateClick} // Attach date click handler
              headerToolbar={{
                left: '',
                center: 'title',
                right: '',
              }}
              eventContent={(eventInfo) => {
                const dotColor = eventInfo.event.extendedProps.dotColor;
                const time = eventInfo.event.extendedProps.time;
                const title = eventInfo.event.title;

                return (
                  <div style={{ display: 'flex', alignItems: 'center', backgroundColor: 'transparent' }}>
                    <div
                      style={{
                        width: '10px',
                        height: '10px',
                        borderRadius: '30%',
                        backgroundColor: dotColor,
                        border: `2px solid ${dotColor}`,
                        marginRight: '5px',
                        cursor: 'pointer',
                        position: 'absolute',
                        top: '-25px',
                        left: '4px',
                      }}
                      title={time ? `Time: ${time} - ${title}` : title}
                    ></div>
                  </div>
                );
              }}
              style={{
                width: '10%',  // Set desired width
                height: '400px',  // Set desired height
                margin: '0 auto',  // Optional: center the calendar
              }}
            />

            {selectedDate && (
              <div
                style={{
                  position: 'fixed',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  padding: '1rem', // Increased padding for a larger popup
                  backgroundColor: '#fff',
                  boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)', // Bigger shadow for emphasis
                  zIndex: 1000,
                  width: '30vw', // Set a fixed width to make it bigger
                  borderRadius: '8px', // Rounded corners
                }}
              >
                <h3>Add Holiday</h3>
                <p>Selected Date :{selectedDate}</p>

                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label htmlFor="first_name" className="form-label">
                        Holiday name
                      </label>
                      <input
                        type="text"
                        className={`form-control ${errors.holiday_name && "is-invalid"}`}
                        id="holiday_name"
                        name="holiday_name"
                        value={state.holiday_name}
                        onChange={handleChange}
                        placeholder="Enter Holiday Name"
                      />
                      {errors.holiday_name && (
                        <div className="invalid-feedback">{errors.holiday_name}</div>
                      )}
                    </div>
                  </div>
                </div>


                <button
                  onClick={handleSubmit}
                  style={{
                    backgroundColor: '#007bff',
                    color: '#fff',
                    border: 'none',
                    padding: '10px 15px',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    width: '100%',
                  }}
                >
                  Add
                </button>
              </div>
            )}

            {selectedDate && (
              <div
                onClick={closePopup}
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  zIndex: 999,
                }}
              ></div>
            )}
          </div>
        </div>
      </div >
    </React.Fragment >
  );
};

export default HolidaysForm;


