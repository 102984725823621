import React from 'react'
import Main from './layouts/Main'

export const Loader = ({ isLoading }) => {

    return (
        <>
            <div className="fullloader-container" style={{ display: isLoading ? 'block' : 'none' }}>
                <div className="loader"></div>
            </div>
            <Main loading={isLoading} />
        </>
    )
}
