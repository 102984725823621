import React, { useState, useEffect } from 'react';
import _debounce from 'lodash/debounce';
import { viewEmployeeProfile, getId, editEmployee, getemployeeId, viewdepartmentpagination, viewdesignationpagination } from '../../services/api/api';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../layouts/Header';
import HeaderMobile from '../../layouts/HeaderMobile';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Card, CardBody, Form, Spinner } from 'react-bootstrap';
import { Link } from "react-router-dom";
import $ from 'jquery';
import img05 from "../../assets/img/img05.jpg";
import { Box, Tab, Tabs, Typography } from '@mui/material'
import { Button, Modal } from 'react-bootstrap'
import { adddevicename, deletedeviceinfo, editdeviceinfo, viewBranch, viewdeviceinfo } from '../../services/api/api'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';



const Empprofile = () => {
    const [activeTab, setActiveTab] = useState('bank_details');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [id, setId] = useState('');
    useEffect(() => {
        const id = getemployeeId();
        setId(id);
    }, []);
    console.log(id);
    const data = [
        { label: 'Group A', value: 400, color: '#0088FE' },
        { label: 'Group B', value: 300, color: '#00C49F' },
        { label: 'Group C', value: 300, color: '#FFBB28' },
        { label: 'Group D', value: 200, color: '#FF8042' },
    ];
    const sizing = {
        margin: { right: 5 },
        width: 200,
        height: 200,
        legend: { hidden: true },
    };
    const TOTAL = data.map((item) => item.value).reduce((a, b) => a + b, 0);

    const getArcLabel = (params) => {
        const percent = params.value / TOTAL;
        return `${(percent * 100).toFixed(0)}%`;
    };

    const [employeeData, setEmployeeData] = useState({
        _id: '',
        company_id: '',
        first_name: '',
        last_name: '',
        employee_id: '',
        email: '',
        joining_date: '',
        birth_date: '',
        phone: '',
        department: '',
        designation: '',
        password: '', // Ensure to handle this securely
        salary: '',
        shift: '',
        branch: null,
        file: [
            {
                filename: '',
                Originalname: '',
                filesize: '',
                filepath: '',
            },
        ],
        image_path: '',
        bank_information: [],
        emergency_contact: [],
        updated_at: '',
    });
    const [value, setValue] = React.useState('bankDetails');

    const handleTabChange = (event, newValue) => {
        console.log(newValue);
        setValue(newValue);
    };


    useEffect(() => {
        const fetchEmployeeData = async () => {
            try {
                // Fetch all required data
                const employeeResponse = await editEmployee(id);
                const departmentResponse = await viewdepartmentpagination();
                const designationResponse = await viewdesignationpagination();

                console.log('Designation Response:', departmentResponse);

                if (employeeResponse.success) {
                    const designationId = employeeResponse.success.designation;
                    const companyDesignation = designationResponse.data.map((value) => {
                        return value.company_designation
                    })
                    const matchedDesignation = companyDesignation.find(designation =>
                        designation._id == designationId
                    );
                    console.log(matchedDesignation);

                    const designationName = matchedDesignation ? matchedDesignation.designation_name : 'Loading...';

                    let formattedDate = formatDate(employeeResponse.success.joining_date)
                    setEmployeeData({
                        _id: employeeResponse.success._id,
                        company_id: employeeResponse.success._id,
                        first_name: employeeResponse.success.first_name,
                        last_name: employeeResponse.success.last_name,
                        employee_id: employeeResponse.success.employee_id,
                        email: employeeResponse.success.email,
                        joining_date: formattedDate,
                        birth_date: employeeResponse.success.birth_date,
                        phone: employeeResponse.success.phone,
                        department: employeeResponse.success.department,
                        designation: designationName, // Use the matched designation name
                        password: employeeResponse.success.password,
                        salary: employeeResponse.success.salary,
                        shift: employeeResponse.success.shift,
                        branch: employeeResponse.success.branch,
                        file: employeeResponse.success.file,
                        image_path: employeeResponse.success.image_path,
                        bank_information: employeeResponse.success.bank_information,
                        emergency_contact: employeeResponse.success.emergency_contact,
                        updated_at: employeeResponse.success.updated_at,
                    });
                } else {
                    console.error('No employee data found');
                }
            } catch (error) {
                console.error('Error fetching employee data:', error);
            } finally {
                setLoading(false); // Set loading to false after fetching data
            }
        };

        const debouncedFetchEmployeeData = _debounce(fetchEmployeeData, 100);

        debouncedFetchEmployeeData();

        return () => {
            debouncedFetchEmployeeData.cancel();
        };
    }, [id]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        console.log(date);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    };

    // useEffect(() => {
    //     $('.ebtn').on('click', function () {
    //         const id = $(this).data('id');
    //         navigate(`/edit_emp/${id}`);
    //         console.log("viewemployee id", id);
    //     });
    // }, [navigate]);




    const renderTabContent = () => {
        switch (activeTab) {
            case 'bank_details':
                return (
                    <div className="col-md-12">
                        <Card style={{ boxShadow: "0 0px 0px 0 rgba(0,0,0,0)", padding: '0', borderRadius: "0px", overflow: "hidden", border: "none" }}>
                            <CardBody >
                                <h3 className='mb-4'>Bank Information</h3>
                                <div className="d-flex">
                                    <div className="div me-5">
                                        <h6><strong>Bank Name</strong></h6>
                                        <h6><strong>Bank Account No. </strong></h6>
                                        <h6><strong>IFSC Code</strong></h6>
                                        <h6><strong>Branch Name</strong></h6>
                                        <h6><strong>PAN No.</strong></h6>
                                    </div>
                                    <div className="div">
                                        {employeeData.bank_information.map((bank, index) => (
                                            <React.Fragment key={index}>
                                                <h6>{bank.bank_name}</h6>
                                                <h6>{bank.account_no}</h6>
                                                <h6>{bank.IFSC_code}</h6>
                                                <h6>{bank.branch_name}</h6>
                                                <h6>{bank.PAN_no}</h6>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>

                            </CardBody>
                        </Card>
                    </div>
                );
            case 'emergency_contact':
                return (
                    <div className="col-md-12">
                        <Card style={{ boxShadow: "0 0px 0px 0 rgba(0,0,0,0)", padding: '0', borderRadius: "0px", overflow: "hidden", border: "none" }}>
                            <CardBody >
                                <h3 className='mb-4'>Emergency Contact</h3>
                                <div className="d-flex">
                                    <div className="div me-5">
                                        <h6><strong>Name</strong> </h6>
                                        <h6><strong>Relationship</strong></h6>
                                        <h6><strong>Phone</strong></h6>
                                    </div>
                                    {/* <div className="div">
                                        <h6> khuhsal vadhavana </h6>
                                        <h6> brother</h6>
                                        <h6>1234567890</h6>
                                    </div> */}
                                    <div className="div">
                                        {employeeData.emergency_contact.map((contact, index) => (
                                            <React.Fragment key={index}>
                                                <h6>{contact.emergency_name}</h6>
                                                <h6>{contact.emergency_relation}</h6>
                                                <h6>{contact.emergency_phone}</h6>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>


                            </CardBody>
                        </Card>
                    </div>

                );
            default:
                return null;
        }
    };
    return (
        <React.Fragment>
            <HeaderMobile />
            <Header />

            <div className="main p-4 p-lg-5 mt-5">
                <div>

                    {loading ? (
                        <div className="loader-container">
                            <div className="loader"></div>
                        </div>
                    ) : (

                        <div className="row">
                            <div className="col-md-3" >
                                <Card style={{ marginTop: '2rem' }}>
                                    <CardBody >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="black"
                                            className="bi bi-pencil-fill edit ebtn"
                                            viewBox="0 0 16 16"
                                            data-id={employeeData._id}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                navigate('/employee/editprofile')
                                            }}
                                        >
                                            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                                        </svg>
                                        <div className="">
                                            <div style={{ borderBlockColor: "black", background: "white", textAlign: 'center' }} className="center">
                                                <img
                                                    src={employeeData.image_path}
                                                    alt="User Profile"
                                                    className="img-fluid rounded-circle"
                                                    style={{ width: "180px", height: "200px" }}
                                                />
                                            </div>

                                            <div className="right mt-4" >
                                                <h2 style={{ textAlign: 'center' }}>{employeeData.first_name} {employeeData.last_name}</h2>
                                                <h5 style={{ textAlign: 'center' }}>{employeeData.designation}</h5>
                                                <div className="d-flex">
                                                    <div className='me-5'>
                                                        <h6 className='mt-3'><strong>Employee ID </strong></h6>
                                                        <h6 className='mt-3'><strong>Date of Joining </strong></h6>
                                                        <h6 className='mt-3'><strong>Email Id  </strong></h6>
                                                        <h6 className='mt-3'><strong>Phone no </strong></h6>
                                                    </div>
                                                    <div>
                                                        <h6 className='mt-3'>{employeeData.company_id}</h6>
                                                        <h6 className='mt-3'>{employeeData.joining_date}</h6>
                                                        <h6 className='mt-3'>{employeeData.email}</h6>
                                                        <h6 className='mt-3'>{employeeData.phone}</h6>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            {/* <div className="col-md-12 mt-2">
                                <Card style={{ boxShadow: "0 0px 0px 0 rgba(0,0,0,0)", padding: '0', borderRadius: "0px", overflow: "hidden", border: "none" }}>
                                    <CardBody>
                                        <ul className="nav nav-tabs ">
                                            <li className="nav-item">
                                                <a className={`nav-link ${activeTab == 'bank_details' ? 'active border border-grey text-decoration-none' : ''} `} onClick={() => setActiveTab('bank_details')} href="javascript:void(0)">Bank Details</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className={`nav-link ${activeTab == 'emergency_contact' ? 'active border border-grey text-decoration-none' : ''} `} onClick={() => setActiveTab('emergency_contact')} href="javascript:void(0)">Emergency Contact</a>
                                            </li>
                                        </ul>
                                        {renderTabContent()}
                                    </CardBody>
                                </Card>
                            </div> */}

                            <div className="col-md-9" >
                                <Card style={{ marginTop: '2rem' }}>
                                    <Card.Body >
                                        <Box sx={{ width: '100%' }}>
                                            <Tabs
                                                value={value}
                                                onChange={handleTabChange}
                                                textColor="primary"
                                                indicatorColor="primary"
                                                aria-label="secondary tabs example"
                                            >
                                                <Tab value="bankDetails" label="Bank Details" style={{ fontFamily: 'sans-serif', fontWeight: 'bold' }} />
                                                <Tab value="EmergencyContact" label="Emergency Contact" style={{ fontFamily: 'sans-serif', fontWeight: 'bold' }} />
                                                <Tab value="Performance" label="Performance" style={{ fontFamily: 'sans-serif', fontWeight: 'bold' }} />
                                            </Tabs>
                                        </Box>

                                        {loading ? (
                                            <div className="loader-container">
                                                <div className="loader"></div>
                                            </div>
                                        ) : (
                                            value == 'bankDetails' ? (
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <Card style={{ boxShadow: "0 0px 0px 0 rgba(0,0,0,0)", padding: '0', borderRadius: "0px", overflow: "hidden", border: "none" }}>
                                                            <CardBody style={{ minHeight: '52vh' }}>
                                                                <div className="d-flex">
                                                                    <div className="div me-5">
                                                                        <h6><strong>Bank Name</strong></h6>
                                                                        <h6><strong>Bank Account No. </strong></h6>
                                                                        <h6><strong>IFSC Code</strong></h6>
                                                                        <h6><strong>Branch Name</strong></h6>
                                                                        <h6><strong>PAN No.</strong></h6>
                                                                    </div>
                                                                    <div className="div">
                                                                        {employeeData.bank_information.map((bank, index) => (
                                                                            <React.Fragment key={index}>
                                                                                <h6>{bank.bank_name}</h6>
                                                                                <h6>{bank.account_no}</h6>
                                                                                <h6>{bank.IFSC_code}</h6>
                                                                                <h6>{bank.branch_name}</h6>
                                                                                <h6>{bank.PAN_no}</h6>
                                                                            </React.Fragment>
                                                                        ))}
                                                                    </div>
                                                                </div>

                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                </div>
                                            ) : value == 'EmergencyContact' ? (
                                                <div className="col-md-12">
                                                    <Card style={{ boxShadow: "0 0px 0px 0 rgba(0,0,0,0)", padding: '0', borderRadius: "0px", overflow: "hidden", border: "none" }}>
                                                        <CardBody style={{ minHeight: '52vh' }}>
                                                            <div className="d-flex">
                                                                <div className="div me-5">
                                                                    <h6><strong>Name</strong> </h6>
                                                                    <h6><strong>Relationship</strong></h6>
                                                                    <h6><strong>Phone</strong></h6>
                                                                </div>

                                                                <div className="div">
                                                                    {employeeData.emergency_contact.map((contact, index) => (
                                                                        <React.Fragment key={index}>
                                                                            <h6>{contact.emergency_name}</h6>
                                                                            <h6>{contact.emergency_relation}</h6>
                                                                            <h6>{contact.emergency_phone}</h6>
                                                                        </React.Fragment>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            ) : value == 'Performance' ? (
                                                <div className="col-md-12">
                                                    <Card style={{ boxShadow: "0 0px 0px 0 rgba(0,0,0,0)", padding: '0', borderRadius: "0px", overflowY: "auto", border: "none" }}>
                                                        <CardBody style={{ height: '52vh' }}>
                                                            {/* <div className="d-flex">
                                                                <div style={{ flex: 2 }}>
                                                                    <div className="d-flex mb-3">
                                                                        <div className="div me-5" style={{ flex: 6 }}> 
                                                                            <Typography variant="h5" component="div">
                                                                                <strong>Category</strong>
                                                                            </Typography>
                                                                        </div>

                                                                        <div className="div" style={{ flex: 3 }}>
                                                                            <Typography variant="h5" component="div">
                                                                                <strong>Points</strong>
                                                                            </Typography>
                                                                        </div>
                                                                    </div>

                                                                    <div className="d-flex">
                                                                        <div className="div me-5" style={{ flex: 6 }}>
                                                                            <Typography variant="h6" component="div">
                                                                                Goal Achievement (OKRs)
                                                                            </Typography>
                                                                        </div>
                                                                        <div className="div" style={{ flex: 3 }}>
                                                                            <Typography style={{ marginLeft: '2rem' }}>
                                                                                10
                                                                            </Typography>
                                                                        </div>
                                                                    </div>

                                                                    <div className="d-flex">
                                                                        <div className="div me-5 mt-2" style={{ flex: 6 }}>
                                                                            <Typography variant="h6" component="div">
                                                                                Goal Achievement (OKRs)
                                                                            </Typography>
                                                                        </div>
                                                                        <div className="div" style={{ flex: 3 }}>
                                                                            <Typography style={{ marginLeft: '2rem' }}>
                                                                                10
                                                                            </Typography>
                                                                        </div>
                                                                    </div>

                                                                    <div className="d-flex">
                                                                        <div className="div me-5 mt-2" style={{ flex: 6 }}>
                                                                            <Typography variant="h6" component="div">
                                                                                Goal Achievement (OKRs)
                                                                            </Typography>
                                                                        </div>
                                                                        <div className="div" style={{ flex: 3 }}>
                                                                            <Typography style={{ marginLeft: '2rem' }}>
                                                                                10
                                                                            </Typography>
                                                                        </div>
                                                                    </div>

                                                                    <div className="d-flex">
                                                                        <div className="div me-5 mt-2" style={{ flex: 6 }}>
                                                                            <Typography variant="h6" component="div">
                                                                                Goal Achievement (OKRs)
                                                                            </Typography>
                                                                        </div>
                                                                        <div className="div" style={{ flex: 3 }}>
                                                                            <Typography style={{ marginLeft: '2rem' }}>
                                                                                10
                                                                            </Typography>
                                                                        </div>
                                                                    </div>

                                                                    <div className="d-flex">
                                                                        <div className="div me-5 mt-2" style={{ flex: 6 }}>
                                                                            <Typography variant="h6" component="div">
                                                                                Goal Achievement (OKRs)
                                                                            </Typography>
                                                                        </div>
                                                                        <div className="div" style={{ flex: 3 }}>
                                                                            <Typography style={{ marginLeft: '2rem' }}>
                                                                                10
                                                                            </Typography>
                                                                        </div>
                                                                    </div>

                                                                    <div className="d-flex">
                                                                        <div className="div me-5 mt-2" style={{ flex: 6 }}>
                                                                            <Typography variant="h6" component="div">
                                                                                Goal Achievement (OKRs)
                                                                            </Typography>
                                                                        </div>
                                                                        <div className="div" style={{ flex: 3 }}>
                                                                            <Typography style={{ marginLeft: '2rem' }}>
                                                                                10
                                                                            </Typography>
                                                                        </div>
                                                                    </div>

                                                                    <div className="d-flex">
                                                                        <div className="div me-5 mt-2" style={{ flex: 6 }}>
                                                                            <Typography variant="h6" component="div">
                                                                                Goal Achievement (OKRs)
                                                                            </Typography>
                                                                        </div>
                                                                        <div className="div" style={{ flex: 3 }}>
                                                                            <Typography style={{ marginLeft: '2rem' }}>
                                                                                10
                                                                            </Typography>
                                                                        </div>
                                                                    </div>

                                                                    <div className="d-flex">
                                                                        <div className="div me-5 mt-2" style={{ flex: 6 }}>
                                                                            <Typography variant="h6" component="div">
                                                                                Goal Achievement (OKRs)
                                                                            </Typography>
                                                                        </div>
                                                                        <div className="div" style={{ flex: 3 }}>
                                                                            <Typography style={{ marginLeft: '2rem' }}>
                                                                                10
                                                                            </Typography>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="div me-5 mt-2" style={{ flex: 6 }}>
                                                                            <Typography variant="h6" component="div">
                                                                                Goal Achievement (OKRs)
                                                                            </Typography>
                                                                        </div>
                                                                        <div className="div" style={{ flex: 3 }}>
                                                                            <Typography style={{ marginLeft: '2rem' }}>
                                                                                10
                                                                            </Typography>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        <div className="div me-5 mt-2" style={{ flex: 6 }}>
                                                                            <Typography variant="h6" component="div">
                                                                                Goal Achievement (OKRs)
                                                                            </Typography>
                                                                        </div>
                                                                        <div className="div" style={{ flex: 3 }}>
                                                                            <Typography style={{ marginLeft: '2rem' }}>
                                                                                10
                                                                            </Typography>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <PieChart
                                                                        series={[
                                                                            {
                                                                                outerRadius: 100, 
                                                                                data,
                                                                                arcLabel: getArcLabel,
                                                                            },
                                                                        ]}
                                                                        sx={{
                                                                            [`& .${pieArcLabelClasses.root}`]: {
                                                                                fill: 'white',
                                                                                fontSize: 14,
                                                                            },
                                                                        }}
                                                                        {...sizing}
                                                                    />
                                                                </div>

                                                            </div> */}

                                                            <div className="d-flex">
                                                                <div style={{ flex: 2 }}>
                                                                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                                                        <thead>
                                                                            <tr>
                                                                                <th style={{ width: '60%', textAlign: 'center' }}><Typography variant="h5"><strong style={{ fontSize: '20px' }}>Category</strong></Typography></th>
                                                                                <th style={{ width: '40%', textAlign: 'center' }}><Typography variant="h5"><strong style={{  fontSize: '20px' }}>Points</strong></Typography></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td style={{ padding: '8px', fontSize: '16px' }}>
                                                                                    <Typography variant="p" style={{ fontSize: '16px', fontWeight: 'normal' }}>Goal Achievement (OKRs)</Typography>
                                                                                </td>
                                                                                <td style={{ padding: '8px', fontSize: '16px', textAlign: 'center' }}>
                                                                                    <Typography>10</Typography>
                                                                                </td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td style={{ padding: '8px', fontSize: '16px' }}>
                                                                                    <Typography variant="p" style={{ fontSize: '16px', fontWeight: 'normal' }}>Goal Achievement (OKRs)</Typography>
                                                                                </td>
                                                                                <td style={{ padding: '8px', fontSize: '16px', textAlign: 'center' }}>
                                                                                    <Typography>10</Typography>
                                                                                </td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td style={{ padding: '8px', fontSize: '16px' }}>
                                                                                    <Typography variant="p" style={{ fontSize: '16px', fontWeight: 'normal' }}>Goal Achievement (OKRs)</Typography>
                                                                                </td>
                                                                                <td style={{ padding: '8px', fontSize: '16px', textAlign: 'center' }}>
                                                                                    <Typography>10</Typography>
                                                                                </td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td style={{ padding: '8px', fontSize: '16px' }}>
                                                                                    <Typography variant="p" style={{ fontSize: '16px', fontWeight: 'normal' }}>Goal Achievement (OKRs)</Typography>
                                                                                </td>
                                                                                <td style={{ padding: '8px', fontSize: '16px', textAlign: 'center' }}>
                                                                                    <Typography>10</Typography>
                                                                                </td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td style={{ padding: '8px', fontSize: '16px' }}>
                                                                                    <Typography variant="p" style={{ fontSize: '16px', fontWeight: 'normal' }}>Goal Achievement (OKRs)</Typography>
                                                                                </td>
                                                                                <td style={{ padding: '8px', fontSize: '16px', textAlign: 'center' }}>
                                                                                    <Typography>10</Typography>
                                                                                </td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td style={{ padding: '8px', fontSize: '16px' }}>
                                                                                    <Typography variant="p" style={{ fontSize: '16px', fontWeight: 'normal' }}>Goal Achievement (OKRs)</Typography>
                                                                                </td>
                                                                                <td style={{ padding: '8px', fontSize: '16px', textAlign: 'center' }}>
                                                                                    <Typography>10</Typography>
                                                                                </td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td style={{ padding: '8px', fontSize: '16px' }}>
                                                                                    <Typography variant="p" style={{ fontSize: '16px', fontWeight: 'normal' }}>Goal Achievement (OKRs)</Typography>
                                                                                </td>
                                                                                <td style={{ padding: '8px', fontSize: '16px', textAlign: 'center' }}>
                                                                                    <Typography>10</Typography>
                                                                                </td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td style={{ padding: '8px', fontSize: '16px' }}>
                                                                                    <Typography variant="p" style={{ fontSize: '16px', fontWeight: 'normal' }}>Goal Achievement (OKRs)</Typography>
                                                                                </td>
                                                                                <td style={{ padding: '8px', fontSize: '16px', textAlign: 'center' }}>
                                                                                    <Typography>10</Typography>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div style={{ flex: 1 }}>
                                                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '5rem' }}>
                                                                        <PieChart
                                                                            series={[{
                                                                                outerRadius: 100,  // Adjusted size of the pie chart
                                                                                data,
                                                                                arcLabel: getArcLabel,
                                                                            }]}
                                                                            sx={{
                                                                                [`& .${pieArcLabelClasses.root}`]: {
                                                                                    fill: 'white',
                                                                                    fontSize: 14,
                                                                                },
                                                                            }}
                                                                            {...sizing}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </CardBody>

                                                    </Card>
                                                </div>
                                            )
                                                : null
                                        )}
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment >
    );
};

export default Empprofile;



