import React, { createContext, useState, useContext, useEffect } from 'react';

const SessionContext = createContext();

export const useSession = () => {
    return useContext(SessionContext);
};

export const SessionProvider = ({ children }) => {
    const [isSessionExpired, setIsSessionExpired] = useState(false);

    const handleSessionTimeout = () => {
        if (!isSessionExpired) {
            setIsSessionExpired(true);
            alert('Your session has expired due to inactivity.');
            sessionStorage.clear();
        }
    };

    const resetSessionExpired = () => {
        setIsSessionExpired(false);  // Reset the session expired state on successful login
    };

    useEffect(() => {
        const events = ['mousemove', 'keydown', 'click'];
        let timeout;

        const resetTimer = () => {
            clearTimeout(timeout);
            timeout = setTimeout(handleSessionTimeout, 1800000);  // Set to 10 seconds for demo
        };

        events.forEach(event => {
            window.addEventListener(event, resetTimer);
        });

        resetTimer();

        return () => {
            events.forEach(event => {
                window.removeEventListener(event, resetTimer);
            });
            clearTimeout(timeout);
        };
    }, [isSessionExpired]);

    return (
        <SessionContext.Provider value={{ isSessionExpired, resetSessionExpired }}>
            {children}
        </SessionContext.Provider>
    );
};
