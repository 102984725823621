import React, { useEffect, useState } from 'react'
import HeaderMobile from '../../layouts/HeaderMobile'
import Header from '../../layouts/Header'
import { DataGrid } from '@mui/x-data-grid'
import { Box, Tab, Tabs } from '@mui/material'
import { Button, Card, Modal } from 'react-bootstrap'
import { adddevicename, deletedeviceinfo, editdeviceinfo, updatedeviceinfo, viewBranch, viewdeviceinfo } from '../../services/api/api'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify'

export const DeviceInfo = () => {
  const [value, setValue] = React.useState('Devices');
  const [loading, setLoading] = React.useState(false);

  const [state, setState] = useState({
    dev_index: '',
    device_username: '',
    device_password: '',
    branch: '',
    branch_name: '',
    curr_time: getCurrentTime(),
  });
  const [branches, setBranches] = useState([]);
  const [devices, setDevices] = useState([]);
  const [errors, setErrors] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [masterId, setMasterId] = useState(null);
  const [id, setId] = useState(null);
  function getCurrentTime() {
    const date = new Date();

    // Format as 'YYYY-MM-DDTHH:MM:SS+05:30'
    const formattedDate = date.toLocaleString('en-IN', {
      timeZone: 'Asia/Kolkata', // Specify the timezone to get +05:30
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false, // 24-hour format
    });

    // Convert the formatted date to the desired format
    const [day, month, year, hour, minute, second] = formattedDate.replace(',', '').split(/[ /:]+/);
    const timezoneOffset = "+05:30"; // Hardcoded for India timezone

    return `${year}-${month}-${day}T${hour}:${minute}:${second}${timezoneOffset}`;
  }

  console.log(getCurrentTime());

  useEffect(() => {
    fetchBranch()
    fetchDevices()
  }, [value, confirmation])

  const fetchBranch = async () => {
    try {
      const apiResponse = await viewBranch();
      console.log(apiResponse);

      const companyBranches = apiResponse[0]?.company_branch || [];
      setBranches(companyBranches);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {

    }
  };
  const fetchDevices = async () => {
    try {
      setLoading(true)
      const apiResponse = await viewdeviceinfo();
      console.log(apiResponse);

      if (apiResponse) {
        setDevices(apiResponse)
        setLoading(false)
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false)
    } finally {
      setLoading(false)
    }
  };



  const handleTabChange = (event, newValue) => {
    console.log(newValue);
    setValue(newValue);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,

    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const handleBranchChange = (e) => {
    const selectedBranchId = e.target.value;
    const selectedBranchData = branches.find(branch => branch._id == selectedBranchId);

    console.log(selectedBranchData);
    console.log(selectedBranchData?.branch_name);

    setState(prevState => ({
      ...prevState,
      branch: selectedBranchId,
      branch_name: selectedBranchData ? selectedBranchData.branch_name : '',
    }));
  };


  const handleAddDevice = async () => {
    try {
      setLoading(true)
      const apiResponse = await adddevicename(state);
      console.log(apiResponse);

      if (apiResponse) {
        setState({
          dev_index: '',
          device_username: '',
          device_password: '',
          branch: '',
          branch_name: '',
        })

        toast.success('Device added successfully', {
          position: "top-center",
          autoClose: 2000,
        });
      }



    } catch (error) {
      setLoading(false)
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false)

    }
  };

  const handleConfirmation = (masterId, id) => {
    console.log(id, masterId);

    setConfirmation(true)
    setMasterId(masterId)
    setId(id)
  }

  const handleConfirmationClose = () => {

    handleDelete()

    setConfirmation(false)
  }

  const handleDelete = async () => {
    console.log(masterId, id);

    try {
      setLoading(true)
      const apiResponse = await deletedeviceinfo(masterId, id);
      console.log(apiResponse);

      if (apiResponse) {
        toast.success('Device deleted successfully', {
          position: "top-center",
          autoClose: 2000,
        });
      }

    } catch (error) {
      setLoading(false)
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false)

    }
  };

  const handleEdit = async (masterId, id) => {
    console.log(id, masterId);
    setId(id)
    setMasterId(masterId)

    try {
      setLoading(true)
      const apiResponse = await editdeviceinfo(masterId, id);
      console.log(apiResponse);

      let companyDetails = apiResponse[0].company_device
      if (apiResponse) {
        setIsModalOpen(true);
        setState(prevState => ({
          ...prevState, // If you want to preserve other state values
          dev_index: companyDetails.devIndex,
          device_username: companyDetails.username,
          device_password: companyDetails.password,
          branch: companyDetails.branch,
          branch_name: companyDetails.branch_name,
        }));
      }


    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false)
    } finally {
      setLoading(false)
    }
  };
  const handleUpdate = async () => {
    console.log(id, masterId);

    const payload = {
      ...state,  // Spread the current state values
      masterId,  // Add the new masterId
      id,         // Add the new id
    };

    // Update the state with the new payload
    setState(payload);
    console.log(payload);


    try {
      const apiResponse = await updatedeviceinfo(payload);
      console.log(apiResponse);

      if (apiResponse) {
        setState(prevState => ({
          ...prevState, // If you want to preserve other state values
          dev_index: '',
          device_username: '',
          device_password: '',
          branch: '',
          branch_name: '',
        }));
        fetchDevices()
        handleModalClose()
      }


    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {

    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };


  return (
    <React.Fragment>
      <HeaderMobile />
      <Header />
      <div className="main p-4 p-lg-5 mt-5">
        <Box sx={{ width: '100%' }}>
          <Tabs
            value={value}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="secondary tabs example"
          >
            <Tab value="Devices" label="Devices" />
            <Tab value="addDevice" label="Add Device" />
          </Tabs>
        </Box>

        <Card style={{ marginTop: '2rem' }}>
          <Card.Body style={{ marginTop: '1rem' }}>
            {loading ? (
              <div className="loader-container">
                <div className="loader"></div>
              </div>
            ) : (
              value == 'addDevice' ? (
                <div className="row">
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="dev_index" className="form-label">
                        Index
                      </label>
                      <input
                        type="text"
                        className={`form-control ${errors.dev_index && 'is-invalid'}`}
                        id="dev_index"
                        name="dev_index"
                        value={state.dev_index}
                        onChange={handleChange}
                      />
                      {errors.dev_index && <div className="invalid-feedback">{errors.dev_index}</div>}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="device_username" className="form-label">
                        User Name
                      </label>
                      <input
                        type="text"
                        className={`form-control ${errors.device_username && 'is-invalid'}`}
                        id="device_username"
                        name="device_username"
                        value={state.device_username}
                        onChange={handleChange}
                      />
                      {errors.device_username && <div className="invalid-feedback">{errors.device_username}</div>}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="device_password" className="form-label">
                        Password
                      </label>
                      <input
                        type="text"
                        className={`form-control ${errors.device_password && 'is-invalid'}`}
                        id="device_password"
                        name="device_password"
                        value={state.device_password}
                        onChange={handleChange}
                      />
                      {errors.device_password && <div className="invalid-feedback">{errors.device_password}</div>}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="branch_select" className="form-label">
                        Select Branch
                      </label>
                      <select
                        id="branch_select"
                        name="branch"
                        className="form-select"
                        value={state.branch}
                        onChange={handleBranchChange}
                      >
                        <option value="">Select a Branch</option>
                        {branches.map((branch) => (
                          <option key={branch._id} value={branch._id}>
                            {branch.branch_name}
                          </option>
                        ))}
                      </select>

                      <Button variant="contained" className="btn btn-primary mt-3" onClick={handleAddDevice}>
                        Add
                      </Button>
                    </div>
                  </div>
                </div>
              ) : value === 'Devices' ? (
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="text-center" style={{ backgroundColor: '#c7c4c4', fontSize: '1rem' }}>
                              Sr No.
                            </th>
                            <th className="text-center" style={{ backgroundColor: '#c7c4c4', fontSize: '1rem' }}>
                              Device Index
                            </th>
                            <th className="text-center" style={{ backgroundColor: '#c7c4c4', fontSize: '1rem' }}>
                              User Name
                            </th>
                            <th className="text-center" style={{ backgroundColor: '#c7c4c4', fontSize: '1rem' }}>
                              Branch
                            </th>
                            <th className="text-center" style={{ backgroundColor: '#c7c4c4', fontSize: '1rem' }}>
                              Status
                            </th>
                            <th className="text-center" style={{ backgroundColor: '#c7c4c4', fontSize: '1rem' }}>
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {devices.map((device, index) => (
                            <tr key={device._id}>
                              <td className="text-center" style={{ fontFamily: 'sans-serif', fontWeight: 'bold', fontSize: '1rem' }}>{index + 1}</td>
                              <td className="text-center" style={{ fontFamily: 'sans-serif', fontWeight: 'bold', fontSize: '1rem' }}>{device?.company_device?.devIndex}</td>
                              <td className="text-center" style={{ fontFamily: 'sans-serif', fontWeight: 'bold', fontSize: '1rem' }}>{device?.company_device?.username}</td>
                              <td className="text-center" style={{ fontFamily: 'sans-serif', fontWeight: 'bold', fontSize: '1rem' }}>{device?.company_device?.branch_name}</td>
                              <td className="text-center" style={{ fontFamily: 'sans-serif', fontWeight: 'bold', fontSize: '1rem' }}>{device?.company_device?.status}</td>
                              <td className="text-center">
                                <span style={{ cursor: 'pointer' }} onClick={() => {
                                  handleEdit(device._id, device.company_device._id)
                                }}><EditIcon /></span>
                                <span style={{ cursor: 'pointer' }} onClick={() => {
                                  handleConfirmation(device._id, device.company_device._id)
                                }}><DeleteIcon /></span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : null
            )}


          </Card.Body>
        </Card>
      </div>


      <Modal show={isModalOpen} onHide={handleModalClose} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Update Device Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="row">
            <div className="col-md-4">
              <div className="mb-3">
                <label htmlFor="dev_index" className="form-label">
                  Index
                </label>
                <input
                  type="text"
                  className={`form-control ${errors.dev_index && 'is-invalid'}`}
                  id="dev_index"
                  name="dev_index"
                  value={state.dev_index}
                  onChange={handleChange}
                />
                {errors.dev_index && <div className="invalid-feedback">{errors.dev_index}</div>}
              </div>
            </div>

            <div className="col-md-4">
              <div className="mb-3">
                <label htmlFor="device_username" className="form-label">
                  User Name
                </label>
                <input
                  type="text"
                  className={`form-control ${errors.device_username && 'is-invalid'}`}
                  id="device_username"
                  name="device_username"
                  value={state.device_username}
                  onChange={handleChange}
                />
                {errors.device_username && <div className="invalid-feedback">{errors.device_username}</div>}
              </div>
            </div>

            <div className="col-md-4">
              <div className="mb-3">
                <label htmlFor="device_password" className="form-label">
                  Password
                </label>
                <input
                  type="text"
                  className={`form-control ${errors.device_password && 'is-invalid'}`}
                  id="device_password"
                  name="device_password"
                  value={state.device_password}
                  onChange={handleChange}
                />
                {errors.device_password && <div className="invalid-feedback">{errors.device_password}</div>}
              </div>
            </div>

            <div className="col-md-4">
              <div className="mb-3">
                <label htmlFor="branch_select" className="form-label">
                  Select Branch
                </label>
                <select
                  id="branch_select"
                  name="branch"
                  className="form-select"
                  value={state.branch}
                  onChange={handleBranchChange}
                >
                  <option value="">Select a Branch</option>
                  {branches.map((branch) => (
                    <option key={branch._id} value={branch._id}>
                      {branch.branch_name}
                    </option>
                  ))}
                </select>

                <Button variant="contained" className="btn btn-primary mt-3" onClick={handleUpdate}>
                  Update
                </Button>
              </div>
            </div>
          </div>

        </Modal.Body>
      </Modal>

      <Modal show={confirmation} onHide={handleConfirmationClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this employee?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleConfirmationClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmationClose}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

    </React.Fragment>
  )
}
