// import React, { useState, useEffect, useRef } from 'react';
// import Header from "../../layouts/Header";
// import HeaderMobile from "../../layouts/HeaderMobile";
// import { Card, Button } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
// import { Modal } from "react-bootstrap";
// import { toast } from "react-toastify";
// import { DataGrid } from '@mui/x-data-grid';
// import { viewBreakOff } from '../../services/api/api'; 

// const Empbreakofflist = () => {
//   const navigate = useNavigate();
//   const tableRef = useRef(null);
//   const [breakOffData, setBreakOffData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [showConfirmation, setShowConfirmation] = useState(false);
//   const [deleteBreakOffId, setDeleteBreakOffId] = useState(null);
//   const [deleteEmpbreakoff, setdeleteEmpbreakoff] = useState(null);




//   const fetchData = async () => {
//     try {
//       const formattedData = await viewBreakOff(); 
//       console.log(viewBreakOff);

//       if (!formattedData || !formattedData.length) {
//         throw new Error('Failed to fetch data.');
//       }

//       const breakOffArray = formattedData.map(item => ({
//         _id: item._id,
//         fieldName: item.fieldName || 'N/A',
//         createdDate: item.createdDate || 'N/A',
//       }));

//       setBreakOffData(breakOffArray);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, []);


//   const columns = [
//     {
//       field: 'id',
//       headerName: 'Sr.',
//       align: 'center',
//       headerAlign: 'center',
//       renderCell: (params) => (
//         <span>
//           {breakOffData.indexOf(params.row) + 1}
//         </span>
//       ),
//     },
//     { field: 'fieldName', headerName: 'Name', width: 500, align: 'center', flex: 2, headerAlign: 'center', },
//     { field: 'createdDate', headerName: 'Created Date', align: 'center', flex: 1, headerAlign: 'center', },
//     {
//       field: 'actions', headerName: 'Actions', align: 'center', flex: 1, headerAlign: 'center', renderCell: (params) => (
//         <>
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             width="16"
//             height="16"
//             fill="currentColor"
//             className="bi bi-pencil-fill edit ebtn"
//             viewBox="0 0 16 16"
//             data-id={params.row._id}
//             style={{ cursor: 'pointer', marginRight: "15px" }}
//             onClick={() => {
//               const id = params.row._id;
//               navigate(`/edit_breakoff/${id}`);
//             }}
//           >
//             <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
//           </svg>

//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             width="16"
//             height="16"
//             fill="currentColor"
//             className="bi bi-trash3-fill delete delete-button"
//             viewBox="0 0 16 16"
//             data-id={params.row._id}
//             style={{ cursor: 'pointer' }}
//             onClick={() => {
//               const id = params.row._id;
//               openConfirmationModal(id);
//             }}
//           >
//             <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
//           </svg>
//         </>
//       )
//     },
//   ];

//   // ... Other methods and constants
//   const openConfirmationModal = (id) => {
//     setdeleteEmpbreakoff(id);
//     setShowConfirmation(true);
//   };
//   const closeConfirmationModal = () => {
//     setShowConfirmation(false);
//   };

//   return (
//     <React.Fragment>
//       <HeaderMobile />
//       <Header />
//       <div className="main p-4 p-lg-5 mt-5">
//         <div className="breadcrumb-warning d-flex justify-content-between ot-card">
//           <div>
//             <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
//               <li class="breadcrumb-item ">
//                 <Link to="/admin/dashboard">Dashboard</Link>
//               </li>
//               <li class="breadcrumb-item active"><Link to="/pages/hr/breakofflist">Break-off List</Link></li>
//               <li class="breadcrumb-item active">Lists</li>
//             </ol>
//           </div>
//           <nav aria-label="breadcrumb">
//             <Button variant="primary" className="mb-3">
//               <Link to="/pages/hr/breakoffform">
//                 <span style={{ color: "white" }}>
//                   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
//                     <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
//                   </svg>
//                   Create Break-off
//                 </span>
//               </Link>
//             </Button>
//           </nav>
//         </div>
//         <Card className="popup-card empHistory">
//           <Card.Body>
//             <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center', justifyContent: 'flex-end', marginBottom: "1rem" }}>
//               <label htmlFor="searchInput"><strong>Search: </strong></label>
//               {/* ... Other search input field */}
//             </div>
//             {loading ? (
//               <div className="loader-container">
//                 <div className="loader"></div>
//               </div>
//             ) : (
//               <div style={{ height: 500, width: '100%' }}>
//                 <DataGrid
//                   rows={breakOffData}
//                   columns={columns}
//                   pageSize={5}
//                   responsive
//                   autoHeight
//                   getRowId={(row) => row._id}
//                   components={{
//                     NoRowsOverlay: () => <div style={{ textAlign: 'center', padding: '20px' }}>No data available</div>,
//                   }}
//                 />
//               </div>
//             )}
//           </Card.Body>
//         </Card>
//       </div>

//       <Modal show={showConfirmation} onHide={closeConfirmationModal}>
//         <Modal.Header closeButton>
//           <Modal.Title>Confirm Deletion</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           Are you sure you want to delete this break-off?
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={closeConfirmationModal}>
//             Cancel
//           </Button>
//           {/* <Button
//             variant="danger"
//             onClick={() => {
//               handleDeleteBreakOff(deleteBreakOffId);
//               closeConfirmationModal();
//             }}
//           >
//             Delete
//           </Button> */}
//         </Modal.Footer>
//       </Modal>
//     </React.Fragment>
//   );
// }

// export default Empbreakofflist;










import React, { useState, useEffect, useRef } from 'react';
import _debounce from 'lodash/debounce';
import Header from "../../layouts/Header";
import HeaderMobile from "../../layouts/HeaderMobile";
import { Card, Col, Row } from "react-bootstrap";
import $ from 'jquery';
import 'datatables.net';
import { deleteBreakoffemp, viewBreakOff, viewBreakOffemplist, viewHoliday } from '../../services/api/api';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { deleteHoliday } from '../../services/api/api';
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import { DataGrid } from '@mui/x-data-grid';
import * as XLSX from 'xlsx';
import { TextField } from '@mui/material';


const Empbreakofflist = () => {
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const [employeeData, setEmployeeData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteEmpbreakoff, setdeleteEmpbreakoff] = useState(null);
  const [masterId, setMasterId] = useState();
  const [searchText, setSearchText] = useState('');

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month starts from 0
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  // const fetchData = async () => {
  //   try {
  //     const formattedData = await viewBreakOffemplist();
  //     if (!formattedData || !formattedData.data || !formattedData.data.length) {
  //       throw new Error('Failed to fetch data.');
  //     }

  //     const breakoffempsArray = formattedData.data.map(item => ({

  //       _id: item.break_data._id,
  //       company_id:item.break_data.company_id,
  //       employee_name: item.emplyee_name,
  //       date: item.break_data.date,

  //       delete_status: item.break_data.delete_status,
  //       delete_user: item.break_data.deleteUser || 'N/A',
  //       delete_time: item.break_data.deleteTime || 'N/A',
  //     }));

  //     setEmployeeData(breakoffempsArray);
  //     setMasterId(breakoffempsArray[0]._id)
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  useEffect(() => {
    const debouncedFetchData = _debounce(fetchData, 100);
    debouncedFetchData();
    return () => {
      debouncedFetchData.cancel();
    };
  }, []);

  const fetchData = async () => {
    try {
      const formattedData = await viewBreakOffemplist();
      if (!formattedData || !formattedData.data || !formattedData.data.length) {
        throw new Error('Failed to fetch data.');
      }

      const breakoffempsArray = formattedData.data.map((item, index) => ({
        _id: item.break_data._id,
        id: index + 1,
        company_id: item.break_data.company_id,
        employee_name: item.emplyee_name,
        date: item.break_data.date,
        delete_status: item.break_data.delete_status,
        delete_user: item.break_data.deleteUser || 'N/A',
        delete_time: item.break_data.deleteTime || 'N/A',
      }));

      setEmployeeData(breakoffempsArray);
      setMasterId(breakoffempsArray[0]._id)
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };



  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
  };

  const exportToExcel = () => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    // Create a new worksheet with the data
    const worksheet = XLSX.utils.json_to_sheet(
      reversedRows.map((row, index) => ({
        'Sr.': index + 1,
        'Holiday Name': row.holiday_name || '', // Update field name here
        'Holiday Date': row.holiday_date || '', // Update field name here
      }))
    );
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    // Convert the workbook to a binary string
    const excelBinaryString = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
    // Convert the binary string to a Blob
    const excelBlob = new Blob([s2ab(excelBinaryString)], { type: 'application/octet-stream' });
    // Create a download link
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(excelBlob);
    downloadLink.download = 'Holiday Data.xlsx';
    // Trigger the download
    downloadLink.click();
  };

  const reversedRows = [...employeeData].reverse();

  const columns = [
    {
      field: 'id',
      headerName: 'Sr.',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      // renderCell: (params) => (
      //   <span>
      //     {reversedRows.indexOf(params.row) + 1}
      //   </span>
      // ),
    },
    {
      field: 'employee_name',
      headerName: 'Employee Name',
      width: 500,
      align: 'center',
      flex: 2,
      headerAlign: 'center',
    },
    {
      field: 'date',
      headerName: 'Date',
      align: 'center',
      flex: 1,
      headerAlign: 'center',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      align: 'center',
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => (
        <>


          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="black"
            className="bi bi-trash3-fill delete delete-button"
            viewBox="0 0 16 16"
            data-id={params.row._id}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              const id = params.row._id;
              openConfirmationModal(id);
            }}
          >
            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
          </svg>
        </>
      )
    },
  ];


  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const filterRows = () => {
    if (!searchText) {
      return [...employeeData].reverse();
    }

    return [...employeeData].reverse().filter((row) =>
      Object.values(row).some(
        (value) =>
          typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  };


  const handledeleteBreakoffemp = async (id, masterId) => {
    setdeleteEmpbreakoff(id);
    setShowConfirmation(true);

    try {
      const response = await deleteBreakoffemp(id, masterId);
      if (response && response.status == 'success') {
        if (!toast.isActive('holidayDeletedToast')) {
          toast.success('Holiday deleted successfully', { toastId: 'holidayDeletedToast' });
        }
        fetchData();
      } else {
        // Handle failure case
      }
    } catch (error) {
      console.error('Error deleting holiday:', error);
    }
  };


  const createActionButton = (data) => {
    const id = data[0];

    const viewButton = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-fill view" viewBox="0 0 16 16" style="cursor: pointer;">
  <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
  <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
  </svg>`;

    const editButton = `
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-pencil-fill edit edit-button"
        viewBox="0 0 16 16"
        data-id="${id}"
        data-masterId="${masterId}"
        style="cursor: pointer;">
        <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
      </svg>
    `;


    const deleteButton = `
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill delete delete-button" viewBox="0 0 16 16" 
  data-id="${id}" style="cursor: pointer;">
  //   <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"/>
  // </svg>
  `;


    return ` ${editButton} <span style="margin-right: 8px;"></span> ${deleteButton} <span style="margin-right: 8px;"></span> ${viewButton} <span style="margin-right: 8px;"></span> `;
  };



  const openConfirmationModal = (id) => {
    setdeleteEmpbreakoff(id);
    setShowConfirmation(true);
  };
  const closeConfirmationModal = () => {
    setShowConfirmation(false);
  };
  // $('#commonTable tbody').on('click', '.delete-button', function () {
  //   const id = $(this).data('id');
  //   openConfirmationModal(id);
  // });
  return (
    <React.Fragment>
      <HeaderMobile />
      <Header />
      {/* Your header components */}
      <div className="main p-4 p-lg-5 mt-5">
        {/* Your breadcrumb section */}
        <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          <div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
              {/* <li className="mx-2">
                  <h4>Holidays</h4>
                </li> */}
              <li class="breadcrumb-item ">
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li class="breadcrumb-item active"><Link to="/admin/breakofflist">Breakoff Employees</Link></li>
              {/* <li class="breadcrumb-item active">Lists</li> */}
            </ol>
          </div>
          <nav aria-label="breadcrumb">

            <Link to="/admin/breakoffemployees">
              <div><button className="btn btn-primary" type="submit"><span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                </svg>Add </span></button></div>
            </Link>

          </nav>
        </div>
        <Card className="popup-card empHistory">
          <Card.Body>
            <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center', justifyContent: 'space-between', marginBottom: "1rem" }}>
              <Button className='btn btn-primary' onClick={exportToExcel}>Export</Button>
              {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                <label htmlFor="searchInput"></label>
                <input
                  type="text"
                  className='p-1 w-75 ms-2'
                  placeholder="Search"
                  value={searchText}
                  onChange={handleSearch}
                />
              </div> */}
              <div className="col-md-11 ">
                <Row>
                  <Col md={9} className="mb-3 pt-3">
                  </Col>
                  <Col md={3} className="mb-3 pt-3 d-flex justify-content-end pe-0">
                    <TextField
                      label="Search"
                      variant="outlined"
                      value={searchText}
                      onChange={handleSearch}
                      size="small"

                    />
                  </Col>
                </Row>
              </div>
            </div>
            {loading ? (
              <div className="loader-container">
                <div className="loader"></div>
              </div>
            ) : (
              <div style={{ height: 500, width: '100%', backgroundColor: 'white' }}>
                <DataGrid
                  rows={employeeData}
                  columns={columns}
                  pageSize={5}
                  responsive
                  autoHeight
                  // checkboxSelection
                  getRowId={(row) => row._id}
                  components={{
                    NoRowsOverlay: () => <div style={{ textAlign: 'center', padding: '20px' }}>No data available</div>,
                  }}
                />
              </div>
            )}
          </Card.Body>

        </Card>
      </div>

      <Modal show={showConfirmation} onHide={closeConfirmationModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this employee?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeConfirmationModal}>
            Cancel
          </Button>
          {/* <Button
              variant="danger"
              onClick={() => {
                handledeleteBreakoffemp(deleteEmpbreakoff);
                closeConfirmationModal();
              }}
            >
              Delete
            </Button> */}
          <Button
            variant="danger"
            onClick={() => {
              handledeleteBreakoffemp(deleteEmpbreakoff, masterId);
              closeConfirmationModal();
            }}

          >
            Delete
          </Button>

        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );

}
export default Empbreakofflist;




















