import React, { useState, useEffect } from 'react';
import _debounce from 'lodash/debounce';
import { Card, Button, Form, Row, Col, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Header from '../../../layouts/Header';
import HeaderMobile from '../../../layouts/HeaderMobile';
import { searchEmployeeBreak, viewEmployee, viewEmployeeBreakCount, viewShift, viewWorkingDays } from '../../../services/api/api';

const EmployeeBreak = () => {
    const [showEmployeeList, setShowEmployeeList] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [breakData, setBreakData] = useState([]);
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [searchText, setSearchText] = useState('');
    const [searchParams, setSearchParams] = useState({
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
    });
    const [shiftData, setShiftData] = useState([]);
    const [loading, setLoading] = useState(true)
    const [isLoading, setisLoading] = useState(false)
    const [selectedEmpId, setSelectedEmpId] = useState(null)
    const [breakDetails, setBreakDetails] = useState([]);
    const [selectedDateBreak, setSelectedDateBreak] = useState('');
    const [selectedEmp, setSelectedEmp] = useState('');



    useEffect(() => {
        setShowEmployeeList(true);
    }, []);

    useEffect(() => {
        const debouncedFetchData = _debounce(fetchData, 100);
        debouncedFetchData();

        return () => {
            debouncedFetchData.cancel();
        };
    }, [searchParams, selectedMonth, selectedYear]);

    useEffect(() => {
        fetchBreakData()
    }, [])

    const fetchBreakList = async (employee_id, date) => {
        try {
            setLoading(true)
            const apiResponse = await viewEmployeeBreakCount({ employee_id });
            console.log("API Response:", apiResponse);

            console.log("***********************************************************************", date);



            let a = apiResponse.data[0];
            console.log(a);

            let b = a.break_list[0];

            const attendance = b.attendance;

            const [year, month, day] = date.split("-");
            const formattedInputDate = `${day}-${month}-${year}`;

            console.log("(((((((((((((((((((((((((((((((((((((((((((((((((((((((((", attendance[formattedInputDate]);


            const formattedApiDate = attendance[formattedInputDate];
            console.log("+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++", formattedInputDate);
            console.log("----------------------------------------------------------------", formattedApiDate);
            setSelectedDateBreak(formattedInputDate)
            setSelectedEmp(attendance[formattedInputDate].name)

            setLoading(false)
            if (attendance[formattedInputDate]) {
                let c = attendance[formattedInputDate].breaks;
                setBreakDetails(c);
                console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", c);
            } else {
                console.log("No matching date found");
                setBreakDetails([]);
            }
        } catch (error) {
            setLoading(false)
            console.error("Error fetching break list:", error);
        } finally {
            setLoading(false)
            console.log("Finished fetching break list");
        }
    };


    const fetchBreakData = async () => {
        try {
            const shift = await viewShift();
            const companyShift = shift[0].company_time;
            const shiftArray = Object.values(companyShift).map(item => ({
                _id: item._id,
                shift_no: item.shift_no || 'N/A',
                company_start_time: item.company_start_time || 'N/A',
                company_end_time: item.company_end_time || 'N/A',
                company_break_time: parseInt(item.company_break_time, 10) || 'N/A',
                company_break_fine: item.company_break_fine || 'N/A',
                company_late_fine: item.company_late_fine || 'N/A',
            }));
            setShiftData(shiftArray);


        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };



    const fetchEmployeeData = async () => {
        try {
            const formData = {};
            const response = await viewEmployee(formData);
            if (!response || !response.success || !response.data) {
                throw new Error('Failed to fetch data.');
            }

            const mappedEmployees = response.data.map((item, index) => ({
                id: item.employeeData._id,
                serial: index + 1,
                firstName: item.employeeData.first_name,
                lastName: item.employeeData.last_name,
                name: item.employeeData.first_name + ' ' + item.employeeData.last_name
            }));

            if (mappedEmployees) {
                console.log(mappedEmployees);
            }


            setEmployees(mappedEmployees);

        } catch (error) {
            toast.error('Failed to fetch employees.');
        }
    };

    useEffect(() => {
        const debouncedFetchEmpData = _debounce(fetchEmployeeData, 100);

        debouncedFetchEmpData();

        return () => {
            debouncedFetchEmpData.cancel();
        };
    }, []);

    const handleSearch = (event) => {
        setSearchText(event.target.value);
    };

    const [workingDays, setWorkingDays] = useState([]);

    const fetchWorkingDay = async () => {
        try {
            const response = await viewWorkingDays();
            console.log("response", response);

            // Assuming the response has the expected structure
            const workingDaysData = response[0]; // Assuming you have only one object in the array

            // Extract the days with value true (working days)
            const workingDays = Object.keys(workingDaysData).filter(day =>
                typeof workingDaysData[day] === 'boolean' && workingDaysData[day]
            );

            console.log("Working days:", workingDays);
            setWorkingDays(workingDays);
        } catch (error) {
            console.error(error);
            toast.error("Failed to fetch working day");
        }
    };


    const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate();
    const daysOfWeek = [];
    for (let i = 1; i <= daysInMonth; i++) {
        const date = new Date(selectedYear, selectedMonth - 1, i);
        const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'short' });
        daysOfWeek.push(dayOfWeek);
    }

    const fetchData = async () => {
        try {
            setisLoading(true)
            const apiResponse = await searchEmployeeBreak(selectedYear, selectedMonth);
            console.log(apiResponse);

            const breakList = apiResponse.data[0].break_list;
            if (apiResponse) {
                setisLoading(false)
            }
            const formattedData = breakList.map((employee) => {
                return {
                    attendances: Object.entries(employee.attendance).map(([date, attendance]) => ({
                        date,
                        total_minutes: attendance.total_minutes,
                        name: attendance.name
                    })),
                };
            });
            console.log(formattedData);


            setBreakData(formattedData);
        } catch (error) {
            setisLoading(false)
            toast.error('Failed to fetch break data.');
        }
    };


    const renderCells = () => {
        if (!selectedMonth || !selectedYear) {
            return null;
        }

        const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate();
        const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);

        return (
            <>
                <tr>
                    <th style={{ background: 'white', color: 'black' }}>Employees</th>
                    {days.map(day => (
                        <th key={day} style={{ background: 'white', color: 'black' }}>{day}</th>
                    ))}
                </tr>

                {employees.map((employee, index) => {

                    console.log(employee);

                    const employeeData = breakData.find(empData => empData.attendances.some(att => att.name == employee.name));
                    const employeeAttendances = employeeData ? employeeData.attendances : [];

                    return (
                        <tr key={employee.id}>
                            <td style={{ whiteSpace: 'nowrap' }}>{employee.firstName} {employee.lastName}</td>

                            {days.map(day => {
                                const formattedDate = `${selectedYear}-${selectedMonth.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
                                const breakMatchDate = `${day.toString().padStart(2, '0')}-${selectedMonth.toString().padStart(2, '0')}-${selectedYear}`;
                                const attendanceForDay = employeeAttendances.find(att => att.date == formattedDate);


                                // Use companyBreakTime from shiftArray
                                const companyBreakTime = shiftData[0]?.company_break_time || 0; // Ensure shiftArray is populated

                                let totalBreakMinutes = attendanceForDay?.total_minutes || 0;

                                // Use companyBreakTime instead of the fixed value
                                totalBreakMinutes = totalBreakMinutes > companyBreakTime ? Math.ceil(totalBreakMinutes) : Math.ceil(totalBreakMinutes);

                                const currentDate = new Date(selectedYear, selectedMonth - 1, day);
                                const dayOfWeek = currentDate.toLocaleString('en-US', { weekday: 'long' }).toLowerCase();
                                const isWorkingDay = workingDays.includes(dayOfWeek);

                                // Determine the text color based on the value
                                let cellColor;
                                if (totalBreakMinutes > companyBreakTime) {
                                    cellColor = 'red';
                                } else if (totalBreakMinutes === 0) {
                                    cellColor = 'black'; // Set 0 to black
                                } else {
                                    cellColor = 'green';
                                }

                                if (attendanceForDay) {
                                    return (
                                        <td key={day} style={{ color: cellColor }} onClick={() => {
                                            console.log(employee.id)
                                            setSelectedEmpId(employee.id)
                                            fetchBreakList(employee.id, attendanceForDay.date)
                                            setAttendanceModal(true);
                                        }}>
                                            {totalBreakMinutes}
                                        </td>
                                    );
                                } else {
                                    const isWeekend = !isWorkingDay && (currentDate.getDay() === 0 || currentDate.getDay() === 6);
                                    return (
                                        <td key={day} className={isWeekend ? 'status-weekend' : 'status-absent'} style={{ color: isWeekend ? 'blue' : 'red' }}>
                                            {isWeekend ? 'W' : <span style={{ color: 'black' }}>0</span>}
                                        </td>
                                    );
                                }
                            })}
                        </tr>
                    );
                })}
            </>
        );
    };

    const [showAttendanceModal, setAttendanceModal] = useState(false);





    return (
        <React.Fragment>
            <HeaderMobile />
            <Header />
            <div className="main p-4 p-lg-5 mt-5">
                <div className="breadcrumb-warning d-flex justify-content-between ot-card">
                    <div>
                        <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
                            <li className="breadcrumb-item">
                                <Link to="/admin/dashboard">Dashboard</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to="/admin/empbreakdata">Employee Break</Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <Link to="/admin/pages/employeebreak">Employee Monthly Break</Link>
                            </li>
                            {/* <li className="breadcrumb-item active">List</li> */}
                        </ol>
                    </div>
                </div>
                <Card className="popup-card empHistory">
                    <Card.Body>
                        <div className="mb-3 border rounded p-3" style={{ backgroundColor: '#f0f0f0' }}>
                            <strong>Break Info Monthly:</strong> {`${searchParams.month}/${searchParams.year}`}
                        </div>
                        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mb-3">
                            <div className="d-flex align-items-center mb-2 mb-md-0 mr-md-2 col-12 col-md-8">
                                <div className="dropdowns-container">
                                    <div>
                                        <select value={`${selectedMonth}/${selectedYear}`} onChange={(e) => {
                                            const [month, year] = e.target.value.split('/');
                                            setSelectedMonth(parseInt(month));
                                            setSelectedYear(parseInt(year));
                                        }} className="dropdownBox1">
                                            <option value="">Select Month</option>
                                            {Array.from({ length: 12 }, (_, i) => (
                                                <option key={i + 1} value={`${i + 1}/${currentYear}`}>{`${i + 1}/${currentYear}`}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex align-items-center col-12 col-md-4">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search by name"
                                    value={searchText}
                                    onChange={handleSearch}
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-start">

                        </div>
                        <div className="calendar">
                            <div className="calendar-table-container">
                                {isLoading ? (
                                    <div className="loader-container">
                                        <div className="loader"></div>
                                    </div>
                                ) : (
                                    <table>
                                        <thead>
                                            <tr>
                                                <th style={{ background: '#ffc107', color: 'black' }}>{new Date(selectedYear, selectedMonth - 1).toLocaleString('default', { month: 'long' })} {selectedYear}</th>
                                                {daysOfWeek.map((day, index) => (
                                                    <th key={index}>{day}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {renderCells()}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>


            <Modal
                show={showAttendanceModal}
                onHide={() => setAttendanceModal(false)}
                size="md"
                centered
            >
                <Modal.Header closeButton className="custom-modal-header">
                    <Modal.Title style={{ color: "#FFFFFF" }}>Break Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row ">
                        <div className="col-md-12">
                            <div className="card p-3 mb-5 bg-white rounded" style={{ boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)", overflow: 'auto', height: '65vh' }}>
                                {loading ? (
                                    <div className="loader-containerResignation">
                                        <div className="loader"></div>
                                    </div>
                                ) : (

                                    <>
                                        <Modal.Title style={{ fontWeight: 'bold', fontSize: "26px", color: "black" }}>Name : {selectedEmp} </Modal.Title>
                                        <p style={{ fontWeight: 'bold', fontSize: '18px' }}>Date : {selectedDateBreak}</p>
                                        <ul className="activity-group mb-5">

                                            {breakDetails && breakDetails.length > 0 ? (
                                                breakDetails.map((breakItem, index) => (
                                                    <li className="activity-item" key={index}>
                                                        <p className="d-sm-flex align-items-center mb-2">
                                                            <span className="fs-sm" style={{ color: "black" }}>
                                                                <strong style={{ fontSize: '15px', fontWeight: 'bold' }}>{breakItem.type == "IN" ? "Break In at" : "Break Out at"} -  {breakItem.time}</strong>
                                                            </span>
                                                        </p>
                                                    </li>
                                                ))
                                            ) : (
                                                <p>No break data available.</p>
                                            )}
                                        </ul>
                                    </>
                                )}
                            </div>
                        </div>




                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );
};

export default EmployeeBreak;
