import React, { useState, useEffect, useRef } from 'react';
import _debounce from 'lodash/debounce';
import _ from 'lodash';
import { Card, Form } from 'react-bootstrap';
import { DataGrid } from '@mui/x-data-grid';
import { TextField, Tooltip } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import $ from 'jquery';
import 'datatables.net';
import { getemployeeId, viewEmployee, editEmployee, updateEmployeePassword, deleteEmployee, viewBranch, viewemployeepaggination, BASE_URL, searchviewemployee, viewdeviceinfo, activateEmployee, searchfilteremployee, viewdesignationpagination, viewdepartmentpagination } from '../../services/api/api';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Modal, OverlayTrigger, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import HeaderMobile from '../../layouts/HeaderMobile';
import Header from '../../layouts/Header';
import * as XLSX from 'xlsx'; // Import XLSX library
import CastIcon from '@mui/icons-material/Cast';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const Employees = (props) => {
  const [branches, setBranches] = useState([]);
  const [branchData, setBranchData] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState('');
  const navigate = useNavigate();
  const tableRef = useRef(null);



  const [loading, setLoading] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState({ type: '', value: '' });
  const [errors, setErrors] = useState({ type: false, value: false });
  const [helperText, setHelperText] = useState({ type: '', value: '' });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filterTypes, setFilterTypes] = useState([]);
  const [deleteEmployeeId, setDeleteEmployeeId] = useState(null);
  const [actualPassword, setActualPassword] = useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [updating, setUpdating] = React.useState(false);
  const [empId, setEmpid] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [excelData, setExcelData] = useState(null);
  const [location, setLocation] = useState('');
  const [filteredRows, setFilteredRows] = useState([]);
  const [filterParameters, setFilterParameters] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const [rows, setRows] = useState([]);
  const [devices, setDevices] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [pageSize, setpageSize] = useState(100);
  const [count, setCount] = useState(0);
  const pageRef = useRef(page);
  const [error, setError] = useState(null);
  const [branchName, setBranchName] = React.useState([]);
  const [status, setStatus] = useState('');
  const [selectedEmpId, setSelectedEmpId] = useState(null);
  let companyId = sessionStorage.getItem('companyId')
  const [open, setOpen] = useState(false);
  const [designationData, setDesignationData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);

  const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
  ];

  const handleSelectOpen = () => {
    setOpen(true);
  };

  const handleSelectClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    pageRef.current = page;
  }, [page]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const customStyles = {
    grid: {
      border: '1px solid #ddd',
    },
    cell: {
      border: 'none', // Remove all borders
      borderBottom: '1px solid #ddd', // Only horizontal border
      padding: '0.5rem 0.5rem',
      color: 'var(--bs-table-color-state, var(--bs-table-color-type, #000000b8))', // Using custom properties for text color
      // backgroundColor: 'white', // Using custom properties for background color
      fontWeight: '500', // Making text important
      fontFamily: 'system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif', // Highly standard font family
    },
    columnHeader: {
      border: 'none', // Remove all borders
      borderBottom: '1px solid #ddd', // Only horizontal border
      padding: '0.5rem 0.5rem',
      color: 'var(--bs-table-color-state, var(--bs-table-color-type, #000))', // Using custom properties for text color
      // backgroundColor: 'var(--bs-table-bg)', // Using custom properties for background color

      fontWeight: 'bold',
      fontFamily: 'system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif', // Highly standard font family
    },
    lastColumn: {
      borderRight: 'none',
    },
  };


  useEffect(() => {
    fetchDesignation()
    fetchDepartment()
  }, [])

  const fetchDepartment = async () => {
    try {
      const formattedData = await viewdepartmentpagination(1, 100);

      console.log(formattedData);

      const { success, data, totalPages } = formattedData; // Ensure you check success

      if (success) {
        const departmentsArray = data.map(value => ({
          _id: value.item.company_department?._id || 'N/A', // Use company_department for ID
          department_name: value.item.company_department?.department_name || 'N/A', // Extract department name
        }));

        if (formattedData.data.length == 0) {
          setDepartmentData([]);
          console.log('No data available');
        }
        else {

          const departments = formattedData.data.map((item, index) => {
            const departmentData = item.item.company_department || {};

            return {

              id: index + 1,
              _id: departmentData._id,
              department_name: departmentData.department_name || 'N/A',

            };
          });

          console.log(departments);


          setDepartmentData(departments); // Use the response data directly
        }

      } else {
        setDepartmentData([]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDesignation = async () => {
    try {
      const response = await viewdesignationpagination(page, pageSize);
      const { data, totalPages } = response;
      console.log(response);

      // If data is empty, set designationsArray to an empty array
      const designationsArray = data.length > 0
        ? data.map(value => ({
          _id: value.company_designation?._id || 'N/A',
          designation_name: value.company_designation?.designation_name,
        }))
        : []; // Set to empty array if no data

      console.log(designationsArray);


      if (response.data.length == 0) {
        setDesignationData([]); // Set to empty array if no records
        console.log('No data available');
      }
      else {

        const designations = response.data.map((item, index) => {
          const designationData = item.company_designation || {};

          return {

            id: index + 1,
            _id: designationData._id,
            designation: designationData.designation_name || 'N/A',

          };
        });

        console.log(designations);


        setDesignationData(designations); // Use the response data directly

      }


    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!searchTerm) {
      // if (!selectedFilter.type && !selectedFilter.value) {
      fetchData();
    }
  }, [page, pageSize, searchTerm]);

  // const fetchData = async () => {
  //   console.log(page);

  //   try {
  //     setLoading(true);
  //     const response = await viewemployeepaggination(page, pageSize);
  //     console.log(response);

  //     if (!response || !response.success || !response.data) {
  //       throw new Error('Failed to fetch data.');
  //     }

  //     setRows(response.data);
  //     console.log(response.data);

  //     setFilteredRows(response.data);
  //     setTotalPages(response.totalPages);
  //     setpageSize(pageSize);

  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await viewemployeepaggination(page, pageSize);
      console.log(response);

      // Check for a valid response
      if (!response || !response.success || !Array.isArray(response.data)) {
        throw new Error('Failed to fetch data or no data available.');
      }

      // Set rows based on data length
      if (response.data.length == 0) {
        setRows([]); // Set to empty array if no records
        setFilteredRows([]); // Clear filtered rows as well
        console.log('No data available');
      } else {

        const employeeData = response.data.map((item, index) => {
          const empData = item.employeeData || {};

          return {
            // id: item._id,
            // subId: employeeLeave._id || '', // Ensure subId is correctly mapped
            id: index + 1, // Adjusting index for display purposes
            _id: empData._id, // Adjusting index for display purposes
            image_path: empData.image_path,
            name: `${empData.first_name} ${empData.last_name}` || 'N/A',
            email: empData.email || 'N/A',
            joiningDate: empData.joining_date || 'N/A',
            department: item.departmentName || 'N/A',
            designation: item.designationName || 'N/A',
            status: empData.employee_status ? empData.employee_status : null,
            filterOptions: [
              { name: 'All', inputType: 'text' },
              { name: 'Name', inputType: 'text' },
              { name: 'Email', inputType: 'text' },
              { name: 'JoiningDate', inputType: 'date' },
              { name: 'Department', inputType: 'select' },
              { name: 'Designation', inputType: 'select' },
              { name: 'Branch', inputType: 'select' },
              { name: 'Device', inputType: 'select' },
            ]
          };
        });


        // setRows(response.data); // Use the response data directly
        // setFilteredRows(response.data); // Set filtered rows
        setRows(employeeData); // Use the response data directly
        setFilteredRows(employeeData); // Set filtered rows
        setFilterParameters(employeeData)
      }

      setTotalPages(response.totalPages); // Set total pages
      setpageSize(pageSize); // Set page size
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSearchData = async (searchText) => {
    try {
      setLoading(true);
      const response = await searchviewemployee(searchText);
      console.log(response);

      // Check for a valid response
      if (!response || !response.success || !Array.isArray(response.data)) {
        throw new Error('Failed to fetch data or no data available.');
      }

      if (response.data.length == 0) {
        setRows([]); // Set to empty array if no records
        setFilteredRows([]); // Clear filtered rows as well
        console.log('No data available');
      } else {

        const employeeData = response.data.map((item, index) => {
          const empData = item.employeeData || {};

          return {
            // id: item._id,
            // subId: employeeLeave._id || '', // Ensure subId is correctly mapped
            id: index + 1, // Adjusting index for display purposes
            _id: empData._id, // Adjusting index for display purposes
            image_path: empData.image_path,
            name: `${empData.first_name} ${empData.last_name}` || 'N/A',
            email: empData.email || 'N/A',
            joiningDate: empData.joining_date || 'N/A',
            department: item.departmentName || 'N/A',
            designation: item.designationName || 'N/A',

          };
        });


        // setRows(response.data); // Use the response data directly
        // setFilteredRows(response.data); // Set filtered rows
        setRows(employeeData); // Use the response data directly
        setFilteredRows(employeeData); // Set filtered rows
      }

      // setRows(response.data);
      // setFilteredRows(response.data);
      setTotalPages(response.totalPages); // Set total pages
      setpageSize(pageSize);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDevices()
  }, [])


  const fetchDevices = async () => {
    try {
      setLoading(true)
      const apiResponse = await viewdeviceinfo();
      console.log(apiResponse);

      if (apiResponse) {
        setDevices(apiResponse)
        setLoading(false)
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false)
    } finally {
      setLoading(false)
    }
  };

  const handleMultipleBranchChange = (event) => {
    const {
      target: { value },
    } = event;
    setBranchName(
      // On autofill we get a stringified value.
      typeof value == 'string' ? value.split(',') : value,
    );

    setTimeout(() => {
      console.log(branchName);

    }, 10000);
  };

  const handleChange = (event) => {
    setStatus(event.target.value); // Update the state with the selected radio button value
  };

  const handleActivateEmp = () => {
    console.log(branchName);
    console.log(status);
    console.log(selectedEmpId);
    console.log(sessionStorage.getItem('companyId'));

  }

  const handleFetchData = async () => {
    if (searchTerm.trim()) {
      await fetchSearchData(searchTerm); // Call search API if search term exists
    } else {
      await fetchData(); // Otherwise, call normal employee API
    }
  };

  const handlePageChange = (event) => {
    setPage(parseInt(event.target.value, 10));
    console.log(event.target.value)
  };

  const getToken = () => {
    const token = sessionStorage.getItem('token');
    return token ? `Bearer ${token}` : '';
  };

  const handlePageSizeChange = (event) => {
    setpageSize(parseInt(event.target.value, 10));
  };

  const handleSearch = (event) => {
    const searchText = event.target.value.toLowerCase(); // Convert search term to lowercase
    setSearchTerm(searchText); // Update search term state

    if (searchTerm) {
      fetchSearchData(searchTerm);
    } else {
      // If search term is cleared, fetch default data
      fetchData();
    }

  };
  const handleClickSearch = (e) => {
    if (e.key == "Enter") {

      if (searchTerm) {
        fetchSearchData(searchTerm);
      } else {
        // If search term is cleared, fetch default data
        fetchData();
      }
    }
  }

  const filterRows = (searchText, rows) => {
    if (!searchText) {
      return rows.slice().reverse(); // Reverse all rows if no searchText
    }

    const filteredRows = rows.filter((row) =>
      row.employeeData.first_name
        .toString()
        .toLowerCase()
        .includes(searchText.toLowerCase())
    );

    return filteredRows.slice().reverse(); // Reverse filtered rows
  };



  const reversedRows = [...rows].reverse();
  console.log(reversedRows);

  const columns = [


    {
      field: 'id',
      headerName: 'Sr. No',
      width: 50, align: 'left',
      headerAlign: 'left',
      flex: 1,
    },
    // {
    //   field: 'image',
    //   headerName: 'Profile',
    //   width: 80,
    //   align: 'center',
    //   headerAlign: 'center',
    //   renderCell: (params) => (
    //     // console.log(params)

    //     <img src={`${params.row.employeeData.image_path}?cb=${Date.now()}`} style={{ width: '40px', height: '40px', borderRadius: '50%', }} />
    //   )
    // },
    {
      field: 'image_path', headerName: 'Profile', width: 150, align: 'center', headerAlign: 'center', flex: 1, renderCell: (params) => (
        // console.log(params)
        <img src={`${params.row.image_path}?cb=${Date.now()}`} style={{ width: '40px', height: '40px', borderRadius: '50%', }} />
      )
    },
    { field: 'name', headerName: 'Name', width: 150, align: 'center', headerAlign: 'center', flex: 2 },
    { field: 'email', headerName: 'Email', width: 150, align: 'center', headerAlign: 'center', flex: 2 },
    { field: 'joiningDate', headerName: 'Joining Date', width: 150, align: 'center', headerAlign: 'center', flex: 2 },
    { field: 'department', headerName: 'Department', width: 150, align: 'center', headerAlign: 'center', flex: 2 },
    { field: 'designation', headerName: 'Designation', width: 150, align: 'center', headerAlign: 'center', flex: 2 },
    // {
    //   field: 'email', headerName: 'Email', width: 150, align: 'center', headerAlign: 'center', flex: 2, renderCell: (params) => (
    //     <span
    //       data-toggle="tooltip"
    //       data-placement="top"
    //       title={params.row.employeeData.email || ''}
    //       style={{
    //         whiteSpace: 'nowrap',
    //         cursor: 'pointer',
    //         position: 'relative',
    //       }}
    //     >
    //       <Tooltip title={`${params.row.employeeData.email}`} arrow>
    //         {params.row.employeeData.email ? (params.row.employeeData.email.length > 10 ? `${params.row.employeeData.email.slice(0, 15)}...` : params.row.email) : ''}
    //         <span
    //           className="tooltip-text ellipsis"
    //           style={{
    //             visibility: 'hidden',
    //             width: 'auto',
    //             backgroundColor: 'black',
    //             color: 'white',
    //             whiteSpace: 'nowrap',
    //             textAlign: 'left',
    //             borderRadius: '6px',
    //             padding: '5px',
    //             position: 'absolute',
    //             zIndex: '1',
    //             bottom: '125%',
    //             left: '50%',
    //             marginLeft: '-60px',
    //             opacity: '0',
    //             transition: 'opacity 0.3s',
    //           }}
    //         >
    //           {params.row.employeeData.email || ''}
    //         </span>
    //       </Tooltip>
    //     </span>
    //   )
    // },

    // {
    //   field: 'departmentName',
    //   headerName: 'Department',
    //   width: 150,
    //   align: 'center',
    //   headerAlign: 'center',
    //   flex: 2,
    //   renderCell: (params) => (
    //     <span>
    //       {params.row.departmentName}
    //     </span>
    //   ),
    // },
    // {
    //   field: 'designationName',
    //   headerName: 'Designation',
    //   width: 150,
    //   align: 'center',
    //   headerAlign: 'center',
    //   flex: 2,
    //   renderCell: (params) => (
    //     <span>
    //       {params.row.designationName}
    //     </span>
    //   ),
    // },
    // {
    //   field: 'joining_date',
    //   headerName: 'Joining Date',
    //   width: 150,
    //   align: 'center',
    //   headerAlign: 'center',
    //   flex: 2,
    //   renderCell: (params) => (
    //     <span>
    //       {formatDate(params.row.employeeData.joining_date)}
    //     </span>
    //   ),
    // },


    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      flex: 2,
      renderCell: (params) => (
        <>
          {/* {console.log('params:', params)} */}
          {
            params.row.status != null ? (
              <Tooltip disableFocusListener disableTouchListener title="Employee Activated">
                <span
                  style={{
                    cursor: 'pointer',
                    marginRight: '15px',
                    color: params.row.status === 1 ? 'green' : 'inherit',
                  }}
                  onClick={() => {
                    setStatusModal(true);
                    setSelectedEmpId(params.row._id);
                  }}
                >
                  <CastIcon />
                </span>
              </Tooltip>
            ) : (
              <span
                style={{
                  cursor: 'pointer',
                  marginRight: '15px',
                  color: params.row.status === 1 ? 'green' : 'inherit',
                }}
                onClick={() => {
                  setStatusModal(true);
                  setSelectedEmpId(params.row._id);
                }}
              >
                <CastIcon />
              </span>
            )
          }





          <Tooltip title="Update Password" arrow>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="black"
              style={{ cursor: 'pointer', marginRight: '15px' }}
              height="16"
              viewBox="0 0 448 512"
              onClick={() => {
                const id = params.row._id;
                setSelectedRow(id);
                setIsModalOpen(true);
              }}
            >
              <path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64zM160 288h128c8.8 0 16 7.2 16 16s-7.2 16-16 16H160c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
            </svg>
          </Tooltip>
          {/* Delete Employee */}

          < svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="black"
            className="bi bi-pencil-fill edit ebtn"
            viewBox="0 0 16 16"
            data-id={params.row._id}
            style={{ cursor: 'pointer', marginRight: "15px" }}
            onClick={() => {
              const id = params.row._id;
              navigate(`/edit_employee/${id}`);
              console.log("viewemployee id", id);
            }}
          >
            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
          </svg >

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="black"
            className="bi bi-trash3-fill delete delete-button"
            viewBox="0 0 16 16"
            data-id={params.row._id}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              const id = params.row._id;
              openConfirmationModal(id);
            }}
          >
            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
          </svg>
        </>
      ),
    },
  ];

  useEffect(() => {

    const storedBranchId = localStorage.getItem('selectedBranchId');
    if (storedBranchId) {
      setSelectedBranch(storedBranchId);
    }
  }, []);
  const handleChangeBranch = (event) => {
    const selectedBranchId = event.target.value;
    setSelectedBranch(selectedBranchId);

    localStorage.setItem('selectedBranchId', selectedBranchId);
  };

  const [id, setId] = useState('');
  useEffect(() => {
    const id = getemployeeId();
    setId(id);
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const [state, setState] = useState({
    _id: '',
    company_id: '',
    password: '',
  });

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setState((prevState) => ({
      ...prevState,
      password: newPassword,
    }));
  };

  useEffect(() => {
    if (sessionStorage.getItem("userType") !== "employee") {
      const debouncedFetchbranchesData = _.debounce(fetchbranchesData, 200);

      debouncedFetchbranchesData();

      return () => {
        debouncedFetchbranchesData.cancel();

      };
    }
  }, []);

  const fetchbranchesData = async () => {
    try {
      const formattedData = await viewBranch();
      console.log("formattedData", formattedData);

      if (!formattedData || !formattedData.length) {
        throw new Error('Failed to fetch data.');
      }

      const companyBranch = formattedData[0].company_branch;
      const branchsArray = Object.values(companyBranch).map(item => ({
        _id: item._id,
        branch_name: item.branch_name || 'N/A',
      }));
      setBranches(branchsArray);
      setBranchData(branchsArray);

    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleActiveEmployee = async () => {
    try {
      setLoading(true);
      const formattedData = await activateEmployee(branchName, status, selectedEmpId, companyId);
      console.log("formattedData", formattedData);



      // const companyBranch = formattedData[0].company_branch;
      // const branchsArray = Object.values(companyBranch).map(item => ({
      //   _id: item._id,
      //   branch_name: item.branch_name || 'N/A',
      // }));
      // setBranches(branchsArray);
      // setBranchData(branchsArray);
      if (formattedData) {
        toast.success("Employee activated successfully!", {
          position: "top-center",
          autoClose: 2000,
        });
        handleStatusModalClose()
      }


    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  // const handleExportEmployee = async () => {
  //   setLoading(true); // Show loading state

  //   try {
  //     const response = await fetch(`${BASE_URL}/exportEmployeeData`, {
  //       method: 'POST',
  //       headers: {
  //         'Accept': 'application/json',
  //         "Content-Type": "application/json",
  //         "Authorization": getToken(),
  //       },
  //     });

  //     if (!response.ok) {
  //       throw new Error('Network response was not ok');
  //     }

  //     const data = await response.json();

  //     // Convert JSON data to CSV
  //     const csvData = convertToCSV(data); // Ensure you have a function to convert data to CSV

  //     // Create a Blob and trigger the download
  //     const blob = new Blob([csvData], {type: 'text/csv' });
  //     const link = document.createElement('a');
  //     link.href = URL.createObjectURL(blob);
  //     link.download = 'employee_data.csv'; // Change the file name as needed
  //     link.click();

  //     // Clean up
  //     URL.revokeObjectURL(link.href);



  //   } catch (error) {
  //     console.error("Fetch error: ", error);
  //     setError(error.message || 'An error occurred');



  //   } finally {
  //     setLoading(false); // Hide loading state
  //   }
  // };

  const handleExportEmployee = async () => {
    setLoading(true); // Show loading state

    try {
      const response = await fetch(`${BASE_URL}/exportEmployeeData`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          "Content-Type": "application/json",
          "Authorization": getToken(),
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      // Convert JSON data to CSV
      const csvData = convertToCSV(data); // Ensure you have a function to convert data to CSV

      // Create a Blob and trigger the download
      const blob = new Blob([csvData], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'employee_data.csv'; // Change the file name as needed
      link.click();

      // Clean up
      URL.revokeObjectURL(link.href);

      // Show success toast message
      toast.success("Employee data exported successfully!", {
        position: "top-center",
        autoClose: 1000,
      });

    } catch (error) {
      console.error("Fetch error: ", error);

      // Show error toast message
      toast.error(error.message || 'An error occurred during export');

      setError(error.message || 'An error occurred');
    } finally {
      setLoading(false); // Hide loading state
    }
  };


  function convertToCSV(data) {
    const array = [Object.keys(data[0])].concat(data);

    return array.map(row => {
      return Object.values(row).map(value =>
        typeof value === 'string' ? `"${value.replace(/"/g, '""')}"` : value
      ).join(',');
    }).join('\n');
  }



  const handleLocationChange = (event) => {
    const selectedLocation = event.target.value;
    setLocation(selectedLocation);
    fetchData(selectedLocation); // Fetch data immediately when location changes
  };

  const openConfirmationModal = (id) => {
    setDeleteEmployeeId(id);
    setShowConfirmation(true);
  };

  const handleDelete = async (id) => {
    setDeleteEmployeeId(id);
    setShowConfirmation(true);
  };

  const handleDeleteConfirm = async () => {
    setShowConfirmation(false);

    try {
      await deleteEmployee(deleteEmployeeId);
      toast.success('Employee deleted successfully', {
        position: 'top-center',
        autoClose: 1000,
      });
      fetchData(); // Refresh data after deletion
    } catch (error) {
      console.error('Error deleting employee:', error);
      toast.error('An error occurred', {
        position: 'top-right',
        autoClose: 1000,
      });
    }
  };




  const handleDeleteCancel = () => {
    setShowConfirmation(false);
    setDeleteEmployeeId(null);
  };

  const handleFilterModal = () => {
    setShowFilterModal(false);
    // setSelectedFilter({ type: '', value: '' })
  };


  const handleActualPasswordChange = (e) => {
    setActualPassword(e.target.value);
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    if (updating) {
      return;
    }
    setUpdating(true);

    try {
      // Call your API function to update the employee password
      const response = await updateEmployeePassword(selectedRow, state.password, actualPassword);

      if (response && response.success && response.success.length > 0) {
        toast.success(response.success[0].message, {
          position: 'top-center',
          autoClose: 1000,
        });

        // Close the modal after a successful update
        setIsModalOpen(false);

        // Clear the password field in the state
        setState((prevState) => ({
          ...prevState,
          password: '',
        }));
      } else {
        toast.success('Password updated Successfully', {
          position: 'top-right',
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.error('An error occurred:', error);
      toast.error('An error occurred', {
        position: 'top-right',
        autoClose: 1000,
      });
    } finally {
      setUpdating(false);
      fetchData(); // Refresh data after password update
      setIsModalOpen(false);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleStatusModalClose = () => {
    setStatusModal(false);
    setStatus('')
    setBranchName([])
  };


  // const handleFilterChange = (event) => {
  //   const { name, value } = event.target;

  //   // Reset value when 'type' changes
  //   if (name == 'type') {
  //     setSelectedFilter((prev) => ({
  //       ...prev,
  //       [name]: value,
  //       value: '', // Reset value when the type changes
  //     }));
  //   } else {
  //     setSelectedFilter((prev) => ({
  //       ...prev,
  //       [name]: value,
  //     }));
  //   }

  //   // Clear the error and helper text when the value is changed
  //   if (name == 'type' && value !== '') {
  //     setErrors((prev) => ({ ...prev, type: false }));
  //     setHelperText((prev) => ({ ...prev, type: '' }));
  //   } else if (name == 'value' && value !== '') {
  //     setErrors((prev) => ({ ...prev, value: false }));
  //     setHelperText((prev) => ({ ...prev, value: '' }));
  //   }

  //   console.log(selectedFilter);


  // };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;

    // If 'type' changes to 'All', set both type and value to 'All'
    if (name == 'type' && value == 'All') {
      setSelectedFilter((prev) => ({
        ...prev,
        [name]: value,
        value: 'All', // Set value to 'All' when 'All' is selected
      }));
    } else if (name == 'type') {
      setSelectedFilter((prev) => ({
        ...prev,
        [name]: value,
        value: '', // Reset value when the type changes
      }));
    } else {
      // For 'value' changes, update it normally
      setSelectedFilter((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    // Clear the error and helper text when the value or type is changed
    if (name == 'type' && value != '') {
      setErrors((prev) => ({ ...prev, type: false }));
      setHelperText((prev) => ({ ...prev, type: '' }));
    } else if (name == 'value' && value != '') {
      setErrors((prev) => ({ ...prev, value: false }));
      setHelperText((prev) => ({ ...prev, value: '' }));
    }

    console.log(selectedFilter); // You can remove this in production if you want
  };


  const validateForm = () => {
    let formValid = true;
    let newErrors = { type: false, value: false };
    let newHelperText = { type: '', value: '' };

    if (!selectedFilter.type) {
      newErrors.type = true;
      newHelperText.type = 'Type is required';
      formValid = false;
    }

    if (!selectedFilter.value) {
      newErrors.value = true;
      newHelperText.value = 'Value is required';
      formValid = false;
    }

    setErrors(newErrors);
    setHelperText(newHelperText);

    return formValid;
  };


  const ApplyFilter = async () => {
    if (validateForm()) {
      console.log('Form Submitted:', selectedFilter);

      try {
        setLoading(true)
        // Call your API function to update the employee password
        const response = await searchfilteremployee(selectedFilter);

        if (response) {
          toast.success("Filter applied", {
            position: 'top-center',
            autoClose: 1000,
          });

          setLoading(false)
          handleFilterModal()

          if (response.data.length == 0) {
            setRows([]); // Set to empty array if no records
            setFilteredRows([]); // Clear filtered rows as well
            console.log('No data available');
          } else {

            const employeeData = response.data.map((item, index) => {
              const empData = item.employeeData || {};

              return {

                id: index + 1,
                _id: empData._id,
                image_path: empData.image_path,
                name: `${empData.first_name} ${empData.last_name}` || 'N/A',
                email: empData.email || 'N/A',
                joiningDate: empData.joining_date || 'N/A',
                department: item.departmentName || 'N/A',
                designation: item.designationName || 'N/A',
                status: empData.employee_status ? empData.employee_status : null,
                filterOptions: [
                  { name: 'Name', inputType: 'text' },
                  { name: 'Email', inputType: 'text' },
                  // { name: 'Joining Date', inputType: 'date' },
                  // { name: 'Department', inputType: 'text' },
                  // { name: 'Designation', inputType: 'text' },
                  { name: 'Branch', inputType: 'select' }
                ]
              };
            });



            setRows(employeeData);
            setFilteredRows(employeeData);
          }
          setTotalPages(response.totalPages); // Set total pages
          setpageSize(pageSize); // Set page size

        }
        else {
          toast.warning('An error occured', {
            position: 'top-center',
            autoClose: 1000,
          });
        }
      } catch (error) {
        console.error('An error occurred:', error);
        toast.error('An error occurred', {
          position: 'top-center',
          autoClose: 1000,
        });
      } finally {
        setLoading(false)
      }
    }
  };

  return (
    <React.Fragment>
      <HeaderMobile />
      <Header
        branches={branches}
        handleLocationChange={handleLocationChange} />
      <div className="main p-4 p-lg-0 mt-0">

        <Card>
          <Card.Body style={{ marginTop: '73px' }}>
            <div className="breadcrumb-warning d-flex justify-content-between ot-card">
            </div>
            {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: "1rem" }}>
              <Tooltip title="Add" placement="bottom">
                <Link to="/admin/employeeform">
                  <Button className="btn btn-primary border-0" style={{ whiteSpace: 'nowrap' }}>
                    <AddCircleIcon />
                  </Button>
                </Link>
              </Tooltip>

              <Tooltip title="Filter" placement="bottom" onClick={() => {
                setShowFilterModal(true)
                let filterOptions = filterParameters[0]?.filterOptions || [];
                setFilterTypes(filterOptions)
              }}>
                <Button className="btn btn-primary border-0" style={{ whiteSpace: 'nowrap' }}>
                  <FilterAltIcon />
                </Button>
              </Tooltip>

              <Tooltip title="Export" placement="bottom">
                <Button className="btn btn-primary border-0" onClick={() => handleExportEmployee()} style={{ whiteSpace: 'nowrap' }}>
                  <FileUploadIcon />
                </Button>
              </Tooltip>

              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
                <TextField
                  label="Search"
                  variant="outlined"
                  value={searchTerm}
                  onChange={handleSearch}
                  onKeyDown={handleClickSearch}

                  size="small"
                  style={{ marginBottom: 9, marginTop: '20px' }}
                />
              </div>

            </div> */}
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: "1rem" }}>
              <Tooltip title="Add" placement="bottom">
                <Link to="/admin/employeeform">
                  <Button className="btn btn-primary border-0" style={{ whiteSpace: 'nowrap' }}>
                    <AddCircleIcon />
                  </Button>
                </Link>
              </Tooltip>

              <Tooltip title="Filter" placement="bottom" onClick={() => {
                setShowFilterModal(true)
                let filterOptions = filterParameters[0]?.filterOptions || [];
                setFilterTypes(filterOptions)
              }}>
                <Button className="btn btn-primary border-0" style={{ whiteSpace: 'nowrap' }}>
                  <FilterAltIcon />
                </Button>
              </Tooltip>

              <Tooltip title="Export" placement="bottom">
                <Button className="btn btn-primary border-0" onClick={() => handleExportEmployee()} style={{ whiteSpace: 'nowrap' }}>
                  <FileUploadIcon />
                </Button>
              </Tooltip>

              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
                <TextField
                  label="Search"
                  variant="outlined"
                  value={searchTerm}
                  onChange={handleSearch}

                  size="small"
                  style={{ marginBottom: 9, marginTop: '20px' }}
                />
              </div>

            </div>

            {loading ? (
              <div className="loader-container">
                <div className="loader"></div>
              </div>
            ) : (



              <div style={{ width: '100%' }}>




                <div style={{ height: '70vh', width: '100%', backgroundColor: 'white' }}>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={pageSize}
                    pagination
                    paginationMode="client"
                    getRowId={(row) => row._id} // Use '_id' as the unique identifier
                    components={{
                      Pagination: () => (
                        <div className="custom-select-container">
                          <div>
                            <label htmlFor="page-size">Rows per page: </label>
                            <select
                              id="page-size"
                              value={pageSize}
                              onChange={handlePageSizeChange} // Handle page size changes
                            >
                              <option value={5}>5</option>
                              <option value={10}>10</option>
                              <option value={20}>20</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </select>
                          </div>
                          <div>
                            <label htmlFor="page-number">Page: </label>
                            <select
                              className="page-number"
                              id="page-number"
                              value={page}
                              onChange={handlePageChange} // Handle page changes
                            >
                              {[...Array(totalPages).keys()].map((pageNumber) => (
                                <option key={pageNumber} value={pageNumber + 1}>
                                  {pageNumber + 1}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      ),
                      NoRowsOverlay: () => (
                        <div style={{ textAlign: 'center', padding: '20px' }}>
                          No Employee available
                        </div>
                      ),
                    }}

                    rowCount={rows.length} // Use the length of rows to show total records
                    page={page - 1} // Current page (0-based index)
                    onPageChange={(newPage) => setPage(newPage + 1)} // Handle page change
                    rowsPerPageOptions={[]} // Avoid displaying the default rows per page options
                  />
                </div>
              </div>
            )}

          </Card.Body>
        </Card>

        {/* Password Update Modal */}
        <Modal show={isModalOpen} onHide={handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Update Employee Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleUpdatePassword}>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <input
                  type={showPassword ? 'text' : 'password'}
                  className="form-control"
                  id="password"
                  value={state.password}
                  onChange={handlePasswordChange}
                  required
                />
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="showPassword"
                    checked={showPassword}
                    onChange={() => setShowPassword(!showPassword)}
                  />
                  <label className="form-check-label" htmlFor="showPassword">
                    Show Password
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="actualPassword" className="form-label">
                  Confirm Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="actualPassword"
                  value={actualPassword}
                  onChange={handleActualPasswordChange}
                  required
                />
              </div>
              <div className="text-center">
                <button type="submit" className="btn btn-primary">
                  {updating ? 'Updating...' : 'Update Password'}
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>


        <Modal show={statusModal} onHide={handleStatusModalClose}>
          <Modal.Header closeButton>
            <Modal.Title> Update Employee Device Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>


            <form>
              <div className="mb-3 d-flex">
                <label>
                  <input
                    type="radio"
                    name="status"
                    id="activate"
                    value="activate"
                    className="me-2"
                    checked={status == 'activate'}
                    onChange={handleChange}
                  />
                  Activate
                </label>

                <label>
                  <input
                    type="radio"
                    name="status"
                    id="deactivate"
                    value="deactivate"
                    className="ms-3 me-2"
                    checked={status == 'deactivate'}
                    onChange={handleChange}
                  />
                  Deactivate
                </label>
              </div>
            </form>

            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="demo-multiple-checkbox-label">Branch</InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={branchName}
                onChange={handleMultipleBranchChange}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => selected.join(', ')}
                // MenuProps={MenuProps}
                MenuProps={{
                  PaperProps: {
                    onClick: handleSelectClose,
                  },
                }}
                open={open}
                onOpen={handleSelectOpen}
                onClose={handleSelectClose}
              >
                {/* {devices.map((device, index) => (
                  <MenuItem key={device?.company_device?._id} value={device?.company_device?.branch_name}>
                    <Checkbox checked={devices.includes(device?.company_device?.branch_name)} />
                    <ListItemText primary={device?.company_device?.branch_name} />
                  </MenuItem>
                ))} */}

                {devices
                  .filter(device => device?.company_device?.status == 'online')  // Filter devices with status 'online'
                  .map((device, index) => (
                    <MenuItem key={device?.company_device?._id} value={device?.company_device?.branch_name}>
                      <ListItemText primary={device?.company_device?.branch_name} />
                    </MenuItem>
                  ))}

              </Select>
            </FormControl>

            {loading ? (
              <div className="loader-containerActivate">
                <div className="loader"></div>
              </div>
            ) : (
              <div>
                <Button variant="contained" className="btn btn-primary mt-3" onClick={handleActiveEmployee}>
                  Update Status
                </Button>
              </div>
            )}
          </Modal.Body>
        </Modal>

        {/* Delete Confirmation Modal */}
        <Modal show={showConfirmation} onHide={handleDeleteCancel}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete this employee?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleDeleteCancel}>
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDeleteConfirm}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>




        <Modal show={showFilterModal} onHide={handleFilterModal} centered size="md">
          <Modal.Header closeButton>
            <Modal.Title>Add Filter</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small" error={errors.type}>
              <InputLabel id="demo-select-small-label">Type</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                name="type"
                value={selectedFilter.type}
                onChange={handleFilterChange}
                label="Type"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {filterTypes.map((option, index) => (
                  <MenuItem key={index} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
              <div>{errors.type && <span style={{ color: 'red' }}>{helperText.type}</span>}</div>
            </FormControl>



            {selectedFilter.type == 'JoiningDate' ? (
              <TextField
                id="outlined-basic"
                label="Joining Date"
                variant="outlined"
                size="small"
                name="value"
                value={selectedFilter.value}
                onChange={handleFilterChange}
                type="date"
                error={errors.value}
                helperText={errors.value ? helperText.value : ''}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ marginTop: '0.5rem' }}
              />
            ) : selectedFilter.type == 'Branch' ? (
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small" error={errors.value}>
                <InputLabel id="branch-select-label">Branch</InputLabel>
                <Select
                  labelId="branch-select-label"
                  id="branch-select"
                  name="value"
                  value={selectedFilter.value}
                  onChange={handleFilterChange}
                  label="Branch"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {branches.map((branch, index) => (
                    <MenuItem key={index} value={branch._id}>
                      {branch.branch_name}
                    </MenuItem>
                  ))}
                </Select>
                <div>{errors.value && <span style={{ color: 'red' }}>{helperText.value}</span>}</div>
              </FormControl>
            ) : selectedFilter.type == 'Designation' ? (
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small" error={errors.value}>
                <InputLabel id="designation-select-label">Designation</InputLabel>
                <Select
                  labelId="designation-select-label"
                  id="designation-select"
                  name="value"
                  value={selectedFilter.value}
                  onChange={handleFilterChange}
                  label="Designation"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {designationData.map((designation, index) => (
                    <MenuItem key={index} value={designation._id}>
                      {designation.designation}
                    </MenuItem>
                  ))}
                </Select>
                <div>{errors.value && <span style={{ color: 'red' }}>{helperText.value}</span>}</div>
              </FormControl>
            ) : selectedFilter.type == 'Device' ? (
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small" error={errors.value}>
                <InputLabel id="device-select-label">Device</InputLabel>
                <Select
                  labelId="device-select-label"
                  id="device-select"
                  name="value"
                  value={selectedFilter.value}
                  onChange={handleFilterChange}
                  label="device"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>

                  <MenuItem value={1}>
                    Activate
                  </MenuItem>
                  <MenuItem value={0}>
                    Deactivate
                  </MenuItem>
                </Select>
                <div>{errors.value && <span style={{ color: 'red' }}>{helperText.value}</span>}</div>
              </FormControl>
            ) : selectedFilter.type == 'Department' ? (
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small" error={errors.value}>
                <InputLabel id="department-select-label">Department</InputLabel>
                <Select
                  labelId="department-select-label"
                  id="department-select"
                  name="value"
                  value={selectedFilter.value}
                  onChange={handleFilterChange}
                  label="Department"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {departmentData.map((department, index) => (
                    <MenuItem key={index} value={department._id}>
                      {department.department_name}
                    </MenuItem>
                  ))}
                </Select>
                <div>{errors.value && <span style={{ color: 'red' }}>{helperText.value}</span>}</div>
              </FormControl>
            ) : (
              <TextField
                id="outlined-basic"
                label={selectedFilter.type == 'Joining Date' ? '' : 'Value'}
                variant="outlined"
                size="small"
                name="value"
                value={selectedFilter.value}
                onChange={handleFilterChange}
                error={errors.value}
                helperText={errors.value ? helperText.value : ''}
                style={{ marginTop: '0.5rem' }}
              />
            )}

          </Modal.Body>
          {loading ? (
            <div className="loader-containerResignation">
              <div className="loaderx"></div>
            </div>
          ) : (
            <Modal.Footer>
              <Button variant="secondary" onClick={handleFilterModal}>
                Cancel
              </Button>
              <Button variant="primary" onClick={ApplyFilter}>
                Apply
              </Button>
            </Modal.Footer>
          )}
        </Modal>

      </div>
    </React.Fragment>
  );

};

export default Employees;