import React, { useState, useEffect } from "react";
import _debounce from "lodash/debounce";
import { Checkbox, Box, Typography, IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { Card, Button, Form, Row, Col, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

import Header from "../../layouts/Header";
import HeaderMobile from "../../layouts/HeaderMobile";
import DatePicker from "react-datepicker";
import * as XLSX from "xlsx";
import { Tooltip } from "@mui/material";
import { format } from 'date-fns';



import {
  addEmployeeAttendanceAdmin,
  addManualAttendanceAdmin,
  deleteEmployeeAttendanceAdmin,
  getemployeedatewiseattendance,
  getemployeeId,
  searchEmployeeAttendance,
  searchPerEmployee,
  updateEmployeeLatestAttendanceAdmin,
  viewEmpAttendance,
  viewEmployee,
  viewEmployeeBreakCount,
  viewShift,
  viewWorkingDays,
} from "../../services/api/api";
import { id } from "date-fns/locale";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { styled } from '@mui/material/styles';

const EmployeeAttendance = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);
  const [showAttendanceModal, setAttendanceModal] = useState(false);
  const [showAbsentModal, setAbsentModal] = useState(false);

  const [showModalpresentlist, setShowModalpresentlist] = useState(false);
  const [masterId, setMasterId] = useState();
  const [attendanceData, setAttendanceData] = useState([]);
  const [attendanceDatapresentlist, setAttendanceDatapresentlist] = useState(
    []
  );
  const [attendanceDataAbsentlist, setAttendanceDataAbsentlist] = useState([]);
  const [showAbsentList, setShowAbsentList] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const [attendanceStatus, setAttendanceStatus] = useState("IN");
  const [checkedInEmployees, setCheckedInEmployees] = useState([]);
  // const [attendanceTime, setAttendanceTime] = useState(new Date(defaultDate));
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const [dateWiseAllEmployees, setDateWiseAllEmployees] = useState([])
  const [dateWiseAllEmployeesIDS, setDateWiseAllEmployeesIDS] = useState([])
  const [dateWiseAttendedEmployeesIDS, setDateWiseAttendedEmployeesIDS] = useState([])
  const [searchTerm, setSearchTerm] = useState('')

  //modal state
  const [employeeTimeSchedule, setEmployeeTimeSchedule] = useState({
    employee_id: "",
    newDateFormate: "",
    punchTime: "",
    status: null,
    attendanceId: null,
    name: '',
  });

  const [absentDate, setAbsentDate] = useState({
    attendance_date: "",
    status: '',
    employee_id: '',
    name: '',
  });

  const [random, setRandom] = useState({
    status: employeeTimeSchedule.status,
  })


  // console.log("employeeTimeSchedule", employeeTimeSchedule);

  const [breakTime, setBreakTime] = useState("");
  const [selectedOption, setSelectedOption] = useState("FULL");

  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  // const [daysOfWeek, setDaysOfWeek] = useState([]);

  const [searchParams, setSearchParams] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
  });
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const debouncedFetchData = _debounce(fetchData, 100);
    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
  }, [searchParams, selectedMonth, selectedYear, isSpinning]);
  const chicagoTimeZone = "America/Chicago";
  const chicagoDate = new Date().toLocaleString("en-US", {
    timeZone: chicagoTimeZone,
  });

  const [attendanceTime, setAttendanceTime] = useState();
  // const [attendanceTime, setAttendanceTime] = useState(new Date(chicagoDate));
  const [employees, setEmployees] = useState([]);
  const [checkedEmployees, setCheckedEmployees] = useState([]);

  const [employee_id, setEmployeeId] = useState(getemployeeId());
  const [break_list, setBreakList] = useState([])
  const [weekend, setWeekend] = useState([])
  const [holiday, setHoliday] = useState([])
  const [breakDetails, setBreakDetails] = useState([]);


  useEffect(() => {
    const debouncedFetchData = _debounce(fetchWorkingDay, 100);
    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
  }, [])

  const fetchWorkingDay = async () => {
    try {
      const response = await viewWorkingDays();
      console.log("response", response); // Print the entire response

      // Assuming the response has the expected structure
      const workingDaysData = response[0]; // Assuming you have only one object in the array

      // Extract the days with value false
      const nonWorkingDays = Object.keys(workingDaysData).filter(day =>
        typeof workingDaysData[day] === 'boolean' && !workingDaysData[day]
      );

      console.log("Non-working days:", nonWorkingDays);
      setWeekend(nonWorkingDays);

    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch working day");
    } finally {
      // Any cleanup if necessary
    }
  };


  const fetchempData = async () => {
    try {
      const formData = {};
      const response = await viewEmployee(formData);
      console.log("response", response);
      if (!response || !response.success || !response.data) {
        throw new Error("Failed to fetch data.");
      }

      const mappedEmployees = response.data.map((item, index) => ({
        // id: index + 1,
        id: item.employeeData._id,
        serial: index + 1,
        firstName: item.employeeData.first_name,
        lastName: item.employeeData.last_name,
      }));

      setEmployees(mappedEmployees);
      console.log(mappedEmployees);
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch employees.");
    } finally {
    }
  };


  const handleRefresh = () => {
    setIsSpinning(true);
    setTimeout(() => {
      setIsSpinning(false);
    }, 1000);
  };


  const CustomCheckbox = ({ checked, onChange }) => (
    <div
      style={{
        display: "inline-block",
        width: 17,
        height: 17,
        backgroundColor: checked ? "#007bff" : "transparent",
        border: "1px solid #ccc",
        borderRadius: 4, // Slightly increased border radius
        marginRight: 8,
        cursor: "pointer",
        textAlign: "center",
        lineHeight: "13px", // Matching height for vertical centering
      }}
      onClick={onChange}
    >
      {checked && <span style={{ color: "#fff", fontSize: 10 }}>✔</span>}{" "}

    </div>
  );


  // const fetchEmployeeDateWiseAttendance = async (date) => {
  //   const formattedDate = format(date, 'dd-MM-yy h:mm a');
  //   try {
  //     setLoading(true);
  //     const apiResponse = await getemployeedatewiseattendance(formattedDate);
  //     const data = apiResponse.data[0];

  //     const allEmployees = data.employeeData.map((employee) => ({
  //       id: employee.id,
  //       name: employee.name
  //     }));

  //     setDateWiseAllEmployees(allEmployees);
  //     const allEmployeesId = allEmployees.map((employee) => employee.id);
  //     setDateWiseAllEmployeesIDS(allEmployeesId);

  //     const attendedIds = data.attendance_list.map(emp => emp.id);
  //     setDateWiseAttendedEmployeesIDS(attendedIds);

  //     // Initialize checked employees state
  //     setCheckedInEmployees(attendedIds);
  //     setSelectedRowIds(attendedIds);

  //   } catch (error) {
  //     console.error("Error fetching employee date-wise attendance:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const fetchEmployeeDateWiseAttendance = async (date) => {
    const formattedDate = format(date, 'dd-MM-yy h:mm a');
    try {
      const apiResponse = await getemployeedatewiseattendance(formattedDate);
      const data = apiResponse.data;

      const allEmployees = data.employeeData.map((employee) => ({
        id: employee.id,
        name: employee.name
      }));

      setDateWiseAllEmployees(allEmployees);
      const allEmployeesId = allEmployees.map((employee) => employee.id);
      setDateWiseAllEmployeesIDS(allEmployeesId);

      const attendedIds = data.attendance_list
        .filter(emp => [1, 2].includes(emp.attendance[0].status)) // Include both status 1 and 2
        .map(emp => emp.id);

      setDateWiseAttendedEmployeesIDS(attendedIds);

      // Initialize checked employees state
      setCheckedInEmployees(attendedIds);
      setSelectedRowIds(attendedIds);

    } catch (error) {
      console.error("Error fetching employee date-wise attendance:", error);
    } finally {
    }
  };

  // const handleCheckboxChange = (employeeId) => {
  //   setCheckedInEmployees((prevChecked) =>
  //     prevChecked.includes(employeeId)
  //       ? prevChecked.filter((id) => id !== employeeId)
  //       : [...prevChecked, employeeId]
  //   );

  //   setSelectedRowIds((prevSelected) =>
  //     prevSelected.includes(employeeId)
  //       ? prevSelected.filter((id) => id !== employeeId)
  //       : [...prevSelected, employeeId]
  //   );
  // };

  const handleCheckboxChange = (employeeId) => {
    setCheckedInEmployees((prevChecked) =>
      prevChecked.includes(employeeId)
        ? prevChecked.filter((id) => id !== employeeId)
        : [...prevChecked, employeeId]
    );

    setSelectedRowIds((prevSelected) =>
      prevSelected.includes(employeeId)
        ? prevSelected.filter((id) => id !== employeeId)
        : [...prevSelected, employeeId]
    );
  };

  // const handleAddAttendance = async () => {
  //   try {
  //     setLoading(true);

  //     const allEmployeesSelected = checkedInEmployees.length === dateWiseAllEmployeesIDS.length;
  //     const employeeIds = allEmployeesSelected ? dateWiseAllEmployeesIDS : selectedRowIds.filter(Boolean);

  //     if (!employeeIds.length) {
  //       throw new Error("No employees selected.");
  //     }

  //     const formattedDate = format(selectedDate, 'dd-MM-yy h:mm a');
  //     const employeeIdString = JSON.stringify(employeeIds);

  //     if (!isNaN(attendanceStatus)) {
  //       await addEmployeeAttendanceAdmin(employeeIdString, attendanceStatus, formattedDate);
  //       toast.success("Employee Attendance Added successfully", {
  //         position: "top-center",
  //         autoClose: 1000,
  //       });
  //       setShowModal(false);
  //       fetchEmployeeDateWiseAttendance(selectedDate); // Re-fetch data to update state

  //       // Reset the states
  //       setCheckedInEmployees([]);
  //       setSelectedRowIds([]);
  //       setAttendanceStatus("");
  //       setSelectedDate(null);
  //     } else {
  //       toast.error("Please select an attendance status");
  //     }
  //   } catch (error) {
  //     toast.error("Failed to add employee attendance");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleAddAttendance = async () => {
    try {

      const allEmployeesSelected = checkedInEmployees.length === dateWiseAllEmployeesIDS.length;
      const employeeIds = allEmployeesSelected ? dateWiseAllEmployeesIDS : selectedRowIds.filter(Boolean);

      if (!employeeIds.length) {
        throw new Error("No employees selected.");
      }

      const formattedDate = format(selectedDate, 'dd-MM-yy h:mm a');
      const employeeIdString = JSON.stringify(employeeIds);

      if (!isNaN(attendanceStatus)) {
        await addEmployeeAttendanceAdmin(employeeIdString, attendanceStatus, formattedDate);
        toast.success("Employee Attendance Added successfully", {
          position: "top-center",
          autoClose: 1000,
        });
        setShowModal(false);
        // fetchEmployeeDateWiseAttendance(selectedDate); // Re-fetch data to update state

        // Reset the states
        setCheckedInEmployees([]);
        setSelectedRowIds([]);
        setAttendanceStatus("");
        setSelectedDate(null);
      } else {
        toast.error("Please select an attendance status");
      }
    } catch (error) {
      toast.error("Failed to add employee attendance");
    } finally {
    }
  };

  const columnsModal = [
    {
      field: "select",
      headerName: "Select",
      width: 150,
      align: "center",
      flex: 1,
      headerAlign: "center",
      // renderHeader: (params) => (
      //   <CustomCheckbox
      //     checked={dateWiseAttendedEmployeesIDS.length === dateWiseAllEmployeesIDS.length}
      //     onChange={() => {
      //       const allEmployeeIds = dateWiseAllEmployees.map((row) => row.id);
      //       if (dateWiseAttendedEmployeesIDS.length === dateWiseAllEmployeesIDS.length) {
      //         setSelectedRowIds([]);
      //         setCheckedInEmployees([]);
      //       } else {
      //         setSelectedRowIds(allEmployeeIds);
      //         setCheckedInEmployees(allEmployeeIds);
      //       }
      //     }}
      //   />
      // ),

      renderHeader: (params) => {
        const allSelected = dateWiseAttendedEmployeesIDS.length > 0 &&
          dateWiseAttendedEmployeesIDS.every(id => checkedInEmployees.includes(id));

        return (
          <CustomCheckbox
            checked={allSelected}
            onChange={() => {
              const allEmployeeIds = dateWiseAllEmployees.map((row) => row.id);
              if (allSelected) {
                setSelectedRowIds([]);
                setCheckedInEmployees([]);
              } else {
                setSelectedRowIds(allEmployeeIds);
                setCheckedInEmployees(allEmployeeIds);
              }
            }}
            style={{ transform: 'scale(0.1)', width: '16px', height: '16px' }}

          />
        );
      },
      renderCell: (params) => {
        const employeeId = params.row.id;
        const isCheckedIn = checkedInEmployees.includes(employeeId);

        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CustomCheckbox
              checked={isCheckedIn}
              onChange={() => handleCheckboxChange(employeeId)}
              className="small-checkbox"
              size="small" // Use 'small' or adjust according to your implementation
            />
          </div>
        );
      },
    },
    {

      field: "name",
      headerName: "Name",
      width: 150,
      align: "left",
      flex: 1,
      headerAlign: "left",
    },


  ];



  useEffect(() => {
    const debouncedFetchEmpData = _debounce(fetchempData, 100);

    debouncedFetchEmpData();

    return () => {
      debouncedFetchEmpData.cancel();
    };
  }, []);

  const handleSearchDateChange = (date) => {
    setSearchParams({
      year: date.getFullYear(),
      month: date.getMonth() + 1,
    });
  };



  const filteredAttendanceData = attendanceData.filter((row) =>
    row.name.toLowerCase().includes(searchText.toLowerCase())
  );

  const columns = [
    {
      field: "serial",
      headerName: "Sr.",
      width: 50,
      align: "center",
      flex: 1,
      headerAlign: "center",
    },

    {
      field: "name",
      headerName: "Name",
      width: 100,
      align: "center",
      flex: 2,
      headerAlign: "center",
    },
    {
      field: "attendance_in",
      headerName: "IN",
      width: 100,
      align: "center",
      flex: 1,
      headerAlign: "center",
      valueGetter: (params) => params.row.attendance_status_in || "",
    },
    {
      field: "attendance_out",
      headerName: "OUT",
      width: 100,
      align: "center",
      flex: 1,
      headerAlign: "center",
      valueGetter: (params) => params.row.attendance_status_out || "0",
    },
  ];

  const columnstwo = [
    {
      field: "serial",
      headerName: "Sr.",
      width: 50,
      align: "center",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "id",
      headerName: "Checkbox",
      width: 50,
      align: "center",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <Checkbox
          checked={selectedRowIds.includes(params.row.id)}
          onChange={(event) => {
            const checked = event.target.checked;
            const employeeId = params.row.id;
            setSelectedRowIds((prevSelected) => {
              if (checked) {
                return [...prevSelected, employeeId];
              } else {
                return prevSelected.filter((id) => id !== employeeId);
              }
            });
          }}
        />
      ),
    },

    {
      field: "name",
      headerName: "Name",
      width: 100,
      align: "center",
      flex: 2,
      headerAlign: "center",
    },
  ];





  const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate();
  const daysOfWeek = [];

  for (let i = 1; i <= daysInMonth; i++) {
    const date = new Date(selectedYear, selectedMonth - 1, i);
    const dayOfWeek = date.toLocaleDateString("en-US", { weekday: "short" });
    daysOfWeek.push(dayOfWeek);
  }

  // const fetchBreakList = async (employee_id) => {
  //   try {
  //     const apiResponse = await viewEmployeeBreakCount({ employee_id });
  //     console.log("apiResponse", apiResponse);

  //     // Use optional chaining to prevent errors
  //     const breakList = apiResponse?.data?.break_list?.flatMap(b =>
  //       Object.values(b.attendance).flatMap(att => att.breaks || [])
  //     ) || []; // Default to an empty array if undefined

  //     setBreakList(breakList);
  //     console.log(breakList);
  //   } catch (error) {
  //     console.error("Error fetching break list:", error);
  //   }
  // };

  // const fetchBreakList = async (employee_id, date) => {
  //   try {
  //     alert('aaaaaaa');
  //     const apiResponse = await viewEmployeeBreakCount({ employee_id });
  //     console.log("API Response:", apiResponse);

  //     console.log(date);


  //     let a = apiResponse.data[0];
  //     console.log(a);

  //     let b = a.break_list[0];
  //     console.log('bbbbbbbbbbbbbbbbbbb', b);

  //     const attendance = b.attendance;

  //     // Convert date from the input (yyyy-mm-dd) to dd-mm-yyyy format
  //     const [year, month, day] = date.split("-");
  //     const formattedInputDate = `${day}-${month}-${year}`; // '21-09-2024'
  //     console.log("Formatted Input Date:", formattedInputDate);

  //     // Use the API's attendance date directly (assuming it's already in dd-mm-yyyy)
  //     const formattedApiDate = attendance[formattedInputDate]; // '03-09-2024'
  //     let c = formattedApiDate.breaks
  //     console.log('*********************************************', c);

  //     console.log("Formatted API Date:", formattedApiDate);
  //     setBreakDetails(c);



  //   } catch (error) {
  //     console.error("Error fetching break list:", error);
  //   } finally {
  //     console.log("Finished fetching break list");
  //   }
  // };
  const fetchBreakList = async (employee_id, date) => {
    try {
      setLoading(true)
      const apiResponse = await viewEmployeeBreakCount({ employee_id });
      console.log("API Response:", apiResponse);

      console.log(date);

      let a = apiResponse.data[0];
      console.log(a);

      let b = a.break_list[0];

      const attendance = b.attendance;

      const [year, month, day] = date.split("-");
      const formattedInputDate = `${day}-${month}-${year}`;

      const formattedApiDate = attendance[formattedInputDate];

      setLoading(false)
      if (formattedApiDate) {
        let c = formattedApiDate.breaks;
        setBreakDetails(c);
      } else {
        console.log("No matching date found");
        setBreakDetails([]);
      }
    } catch (error) {
      setLoading(false)
      console.error("Error fetching break list:", error);
    } finally {
      setLoading(false)
      console.log("Finished fetching break list");
    }
  };














  const fetchData = async () => {
    try {
      setLoading(true);
      const currentDate = new Date().toISOString().slice(0, 10);
      const apiResponse = await searchEmployeeAttendance(
        selectedYear,
        selectedMonth
      );
      console.log("apiResponse", apiResponse);

      if (
        !apiResponse ||
        !apiResponse.data ||
        !apiResponse.data.attendance_list ||
        !apiResponse.data.attendance_list.length
      ) {
        setAttendanceData([]); // Set empty array if no records found
        return;
      }

      // const holidayDates = response.holiday_list.map(item => item.company_holiday.holiday_date);

      const extractAndFormatDay = (dateString) => {
        const day = parseInt(dateString.split('-')[2], 10);
        return day;
      };

      const formattedDays = apiResponse.data.holiday_list.map(item => extractAndFormatDay(item.company_holiday.holiday_date));

      console.log('Formatted Days:', formattedDays);
      setHoliday(formattedDays)


      const attendanceList = apiResponse.data.attendance_list;
      console.log(attendanceList);
      const formattedData = attendanceList.map((employee) => {
        console.log(employee);
        return {
          id: employee.id,
          name: employee.name,
          attendances: employee.attendance.map((attendance) => {
            console.log(attendance);
            return {
              date: attendance.date,
              IN: attendance.IN,
              OUT: attendance.OUT,
              status: attendance.status,
              attendanceId: attendance.attendanceId,
              name: employee.name,
            };
          }),
        };
      });

      setAttendanceData(formattedData);

      setTimeout(() => {

        console.log("formattedData", formattedData);
      }, 5000)



      const sortedAttendanceList = apiResponse.data.attendance_list.sort((a, b) => {
        const timeA = new Date(`1970-01-01T${a.attendance[0].time}Z`);
        const timeB = new Date(`1970-01-01T${b.attendance[0].time}Z`);
        return timeB - timeA;
      });

      console.log(sortedAttendanceList);


      // setEmployeeTimeSchedule({
      //   employee_id: formattedData.id,
      //   punchTime: attendanceList[0].attendance[0].IN,
      // });

      // setEmployeeTImeSchedule(attendanceList[0].attendance[0].IN);

      const presentEmployees = formattedData.filter((employee) => {
        const todayAttendance = employee.attendances.find(
          (attendance) => attendance.date === currentDate
        );
        return todayAttendance && todayAttendance.IN;
      });
      console.log(presentEmployees);

      setAttendanceDatapresentlist(presentEmployees);

      const formData = {};
      const response = await viewEmployee(formData);
      console.log("response", response);
      if (!response || !response.success || !response.data) {
        throw new Error("Failed to fetch data.");
      }

      const mappedEmployees = response.data.map((item, index) => ({
        id: item.employeeData._id,
        serial: index + 1,
        firstName: item.employeeData.first_name,
        lastName: item.employeeData.last_name,
      }));

      const absentEmployees = mappedEmployees.filter((employee) => {
        return !presentEmployees.find((emp) => emp.id === employee.id);
      });

      setAttendanceDataAbsentlist(absentEmployees);

      // Update the checked-in status for each employee for the current date
      const checkedInEmployeeIds = presentEmployees.map(
        (employee) => employee.id
      );
      setCheckedInEmployees(checkedInEmployeeIds);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    console.log(e.target.value);

    searchAttendance(e.target.value)
  };

  const searchAttendance = async (searchTerm) => {
    try {
      setLoading(true);
      const currentDate = new Date().toISOString().slice(0, 10);
      const apiResponse = await searchPerEmployee(
        selectedYear,
        selectedMonth,
        searchTerm,
      );
      console.log("apiResponse", apiResponse);

      if (
        !apiResponse ||
        !apiResponse.data ||
        !apiResponse.data.attendance_list ||
        !apiResponse.data.attendance_list.length
      ) {
        setAttendanceData([]); // Set empty array if no records found
        return;
      }

      // const holidayDates = response.holiday_list.map(item => item.company_holiday.holiday_date);

      const extractAndFormatDay = (dateString) => {
        const day = parseInt(dateString.split('-')[2], 10);
        return day;
      };

      const formattedDays = apiResponse.data.holiday_list.map(item => extractAndFormatDay(item.company_holiday.holiday_date));

      console.log('Formatted Days:', formattedDays);
      setHoliday(formattedDays)


      const attendanceList = apiResponse.data.attendance_list;
      console.log(attendanceList);
      setEmployees(attendanceList)
      const formattedData = attendanceList.map((employee) => {
        console.log(employee);
        return {
          id: employee.id,
          name: employee.name,
          attendances: employee.attendance.map((attendance) => {
            console.log(attendance);
            return {
              date: attendance.date,
              IN: attendance.IN,
              OUT: attendance.OUT,
              status: attendance.status,
              attendanceId: attendance.attendanceId,
              name: employee.name,
            };
          }),
        };
      });

      setAttendanceData(formattedData);

      setTimeout(() => {

        console.log("formattedData", formattedData);
      }, 5000)





      // setEmployeeTimeSchedule({
      //   employee_id: formattedData.id,
      //   punchTime: attendanceList[0].attendance[0].IN,
      // });

      // setEmployeeTImeSchedule(attendanceList[0].attendance[0].IN);

      const presentEmployees = formattedData.filter((employee) => {
        const todayAttendance = employee.attendances.find(
          (attendance) => attendance.date === currentDate
        );
        return todayAttendance && todayAttendance.IN;
      });
      console.log(presentEmployees);

      setAttendanceDatapresentlist(presentEmployees);

      const formData = {};
      const response = await viewEmployee(formData);
      console.log("response", response);
      if (!response || !response.success || !response.data) {
        throw new Error("Failed to fetch data.");
      }

      const mappedEmployees = response.data.map((item, index) => ({
        id: item.employeeData._id,
        serial: index + 1,
        firstName: item.employeeData.first_name,
        lastName: item.employeeData.last_name,
      }));

      const absentEmployees = mappedEmployees.filter((employee) => {
        return !presentEmployees.find((emp) => emp.id === employee.id);
      });

      setAttendanceDataAbsentlist(absentEmployees);

      // Update the checked-in status for each employee for the current date
      const checkedInEmployeeIds = presentEmployees.map(
        (employee) => employee.id
      );
      setCheckedInEmployees(checkedInEmployeeIds);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };


  console.log(employees);


  const renderCells = () => {
    if (!selectedMonth || !selectedYear) {
      return null;
    }

    const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate();
    const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);

    console.log(daysInMonth);

    return (
      <>
        <tr className="sticky-header">
          <th style={{ background: "#FFC107", color: "black" }}>Employees</th>
          {days.map((day) => (
            <th key={day} style={{ background: "#13245E", color: "white" }}>
              {day}
            </th>
          ))}
        </tr>



        {employees.map((employee) => {
          const employeeName = employee.firstName && employee.lastName
            ? `${employee.firstName} ${employee.lastName}`
            : employee.name;
          return (
            <tr key={employee.id}>
              <Tooltip title={`${employeeName}`} arrow>
                <td style={{ whiteSpace: "nowrap" }}>
                  {/* {employee.firstName} {employee.lastName} */}
                  {employeeName}
                </td>
              </Tooltip>
              {days.map((day) => {
                const attendanceForDay = attendanceData
                  .find((att) => att.id === employee.id)
                  ?.attendances.find(
                    (att) =>
                      att.date ===
                      `${selectedYear}-${selectedMonth
                        .toString()
                        .padStart(2, "0")}-${day.toString().padStart(2, "0")}`
                  );

                const currentDate = new Date();
                const isCurrentDate =
                  currentDate.getFullYear() == selectedYear &&
                  currentDate.getMonth() + 1 == selectedMonth &&
                  day == currentDate.getDate();

                const date = new Date(selectedYear, selectedMonth - 1, day);

                const dayName = date
                  .toLocaleString("en-us", { weekday: "long" })
                  .toLowerCase();
                const isWeekend = weekend.includes(dayName);

                // Check if the day is a holiday using the holiday state
                const isHoliday = holiday.includes(day);

                if (attendanceForDay) {
                  return (
                    <td
                      key={day}
                      onClick={() => {
                        fetchBreakList(employee.id, attendanceForDay.date);
                        setEmployeeTimeSchedule({
                          employee_id: employee.id,
                          newDateFormate: attendanceForDay.date,
                          punchTime: attendanceForDay.IN,
                          status: attendanceForDay.status,
                          attendanceId: attendanceForDay.attendanceId,
                          name: attendanceForDay.name,
                        });
                        setAttendanceModal(true);
                      }}
                      className={`status-${attendanceForDay.IN ? "IN" : "OUT"}`}
                    >
                      {attendanceForDay.status == 1
                        ? "P"
                        : attendanceForDay.status == 2
                          ? <span style={{ color: "#918f8f" }}>P</span>
                          : attendanceForDay.status == 3
                            ? <span style={{ color: "blue" }}>W</span>
                            : attendanceForDay.status == 4
                              ? <span style={{ color: "#bf27fe" }}>PL</span>
                              : <span style={{ color: "red" }}>A</span>}
                    </td>
                  );
                } else {
                  return (
                    <td
                      key={day}
                      className={
                        isWeekend || isHoliday
                          ? "status-weekend"
                          : isCurrentDate
                            ? "status-absent"
                            : "status-none"
                      }
                      onClick={() => {
                        setAbsentModal(true);
                        setAbsentDate({
                          attendance_date: `${selectedYear}-${selectedMonth
                            .toString()
                            .padStart(2, "0")}-${day.toString().padStart(2, "0")}`,
                          employee_id: employee.id,
                          name: employeeTimeSchedule.name,
                        });
                        setEmployeeTimeSchedule({
                          newDateFormate: `${selectedYear}-${selectedMonth
                            .toString()
                            .padStart(2, "0")}-${day.toString().padStart(2, "0")}`,
                        });
                      }}
                    >
                      {isWeekend || isHoliday ? "W" : <span style={{ color: "red" }}>A</span>}
                    </td>
                  );
                }
              })}
            </tr>
          );
        })}
      </>
    );
  };







  const [showEmployeeList, setShowEmployeeList] = useState(false);
  useEffect(() => {
    setShowEmployeeList(true);
  }, []);
  const handlePresentButtonClick = () => {
    setShowEmployeeList(true);
    setShowAbsentList(false);
  };

  const handleAbsentButtonClick = () => {
    setShowEmployeeList(false);
    setShowAbsentList(true);
  };
  // const OnChangeAttendanceModal = (e) => {
  //   setEmployeeTimeSchedule({
  //     ...employeeTimeSchedule,
  //     punchTime: e.target.value, // Assuming e.target.value is in format "HH:mm"
  //     status: e.target.value, // Assuming e.target.value is "IN" or "OUT"
  //   });
  // };

  const OnChangeAttendanceModal = (e, a) => {
    const { name, value } = e.target;
    console.log(name, value);
    console.log(a);
    setEmployeeTimeSchedule({
      ...employeeTimeSchedule,
      [name]: value,
    })
  };

  const getStatusValue = (status) => {
    if (status == "0") return 'ABSENT';
    if (status == null || status == "1") return 'FULL DAY';
    if (status == "2") return 'HALF DAY';
    if (status == "3") return 'WEEKEND';
    if (status == "4") return 'PAID LEAVE';
    return '1'; // default to '1' for Full Day if status is unrecognized
  };



  const padNumber = (number) => {
    return number.toString().padStart(2, "0");
  };

  const OnChangeAbsentAttendanceModal = (e) => {

    const { name, value } = e.target;
    setAbsentDate((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log(absentDate)
  };

  const handleAbsentAtandance = async () => {
    const { employee_id, status, attendance_date } = absentDate;


    try {
      const result = await addManualAttendanceAdmin(employee_id, status, attendance_date);
      if (result) {
        toast.success('Attendance Updated Successfully', {
          position: "top-center",
          autoClose: 1000,
        });
      }
      else {
        toast.error('Something went wrong', {
          position: "top-center",
          autoClose: 1000,
        });
      }
      console.log("Update result:", result);
    } catch (error) {
      console.error("Error in update:", error);
    }

  };

  // call update_employee_latest_attendance_admin
  const handleModalSubmit = async () => {
    console.log(employeeTimeSchedule);
    const { punchTime, status, attendanceId } = employeeTimeSchedule;

    // Ensure punchTime is in "HH:mm" format
    const formattedTime = punchTime; // Assuming seconds as 00

    // Create a Date object for the current date
    const currentDate = employeeTimeSchedule.newDateFormate;
    const dateParts = currentDate.split('-');
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];
    // console.log('abc',currentDate);
    // const year = currentDate.getFullYear();
    // console.log('year',year);
    // const month = padNumber(currentDate.getMonth() + 1);
    // console.log('month',month);
    // const day = padNumber(currentDate.getDate());
    // console.log('day',day);

    // Combine date and time into "YYYY-MM-DD hh:mm:ss" format
    const combinedDateTime = `${year}-${month}-${day} ${formattedTime}`;

    try {
      // Update the state with the formatted datetime string
      setEmployeeTimeSchedule({
        ...employeeTimeSchedule,
        punchTime: combinedDateTime,
        status: status,
        attendanceId: attendanceId,
      });

      // Call your update function with formatted datetime
      const result = await updateEmployeeLatestAttendanceAdmin(
        employeeTimeSchedule.employee_id,
        combinedDateTime, // Pass combinedDateTime to your update function
        status,
        attendanceId,
      );

      if (result) {
        toast.success('Attendance Updated Successfully', {
          position: "top-center",
          autoClose: 1000,
        });
      }
      else {
        toast.error('Something went wrong', {
          position: "top-center",
          autoClose: 1000,
        });
      }

      // Handle success or show message
      console.log("Update result:", result);
    } catch (error) {
      // Handle error
      console.error("Error in update:", error);
      // Optionally, set error state or show error message
    }
    setEmployeeTimeSchedule({
      ...employeeTimeSchedule,
      punchTime: combinedDateTime,
      status: status,
      attendanceId: attendanceId,
    });
  };

  const date = new Date();
  const localYear = date.getFullYear();
  const localMonth = date.getMonth() + 1; // getMonth() returns 0-based index
  const localDay = date.getDate();
  const localDate = `${localDay}-${localMonth}-${localYear}`;



  return (
    <React.Fragment>
      <HeaderMobile />
      <Header />
      <div className="main p-4 p-lg-5 mt-5">
        <div className="breadcrumb-warning d-flex justify-content-between ot-card">
          {/* <div>
            <ol className="breadcrumb ot-breadcrumb ot-breadcrumb-basic">
              <li className="breadcrumb-item">
                <Link to="/admin/dashboard">Dashboard</Link>
              </li>
              <li className="breadcrumb-item active">
                <Link to="/admin/pages/totalattendance">
                  Employee Attendance
                </Link>
              </li>
              <li className="breadcrumb-item active">
                <Link to="/admin/pages/employeeattendance">
                  Employee Monthly Attendance
                </Link>
              </li>
            </ol>
          </div> */}
        </div>
        <Card className="popup-card empHistory">
          <Card.Body>
            <div
              className="mb-3 border rounded p-3"
              style={{ backgroundColor: "#f0f0f0" }}
            >
              <strong>Attendance Info Monthly:</strong>{" "}
              {`${searchParams.month}/${searchParams.year}`}
            </div>
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
              <div className="d-flex align-items-center mb-2 mb-md-0 mr-md-2 col-12 col-md-8">
                <div className="dropdowns-container">
                  <div>
                    <select
                      // value={`${selectedMonth}-${selectedYear}`}
                      onChange={(e) => {
                        const [month, year] = e.target.value.split("/");
                        setSelectedMonth(parseInt(month));
                        setSelectedYear(parseInt(year));
                      }}
                      className="dropdownBox1"
                    >
                      <option value="">Select Month</option>
                      {Array.from({ length: 12 }, (_, i) => (
                        <option
                          key={i + 1}
                          value={`${i + 1}/${currentYear}`}
                        >{`${i + 1}/${currentYear}`}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <Form className="mb-3">
                  <Row>
                    <Col>
                      <Button
                        className="btn btn-primary border-0 ms-2 mt-3"
                        onClick={() => setShowModal(true)}
                      >
                        Add
                      </Button>
                      <Button
                        className="btn btn-primary border-0 ms-2 mt-3"
                        onClick={() => setShowModalpresentlist(true)}
                      >
                        Present/Absent
                      </Button>
                      <Button
                        className="btn btn-primary border-0 ms-2 mt-3"
                        onClick={() => navigate('/admin/pages/totalattendance')}
                      >
                        Daily
                      </Button>
                      <Button
                        className="btn btn-primary border-0 ms-2 mt-3"
                        onClick={handleRefresh} // Make sure the onClick is on the Button
                        style={{ cursor: 'pointer' }}
                      >
                        <FontAwesomeIcon
                          icon={faSync}
                          className={isSpinning ? 'spin' : ''}
                        />
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
              <div className="d-flex align-items-center col-12 col-md-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by name"
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </div>
            </div>
            <div className="d-flex justify-content-start"></div>
            {
              <div className="calendar">
                <div className="calendar-table-container">
                  {loading ? (
                    <div className="loader-container att-load">
                      <div className="loader"></div>
                    </div>
                  ) : (
                    <table >
                      <thead>
                        <tr>
                          <th style={{ background: "#ffc107", color: "black" }}>
                            {new Date(
                              selectedYear,
                              selectedMonth - 1
                            ).toLocaleString("default", { month: "long" })}{" "}
                            {selectedYear}
                          </th>
                          {daysOfWeek.map((day, index) => (
                            <th key={index}>{day}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody >{renderCells()}</tbody>

                    </table>

                  )}
                </div>
              </div>
            }
          </Card.Body>
        </Card>
        <p style={{ marginTop: "20px" }}><strong>Full Day:</strong><span style={{ color: "green", fontWeight: 'bold' }}>&nbsp;&nbsp;P</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Half Day:</strong><span style={{ color: "#918f8f", fontWeight: 'bold' }}>&nbsp;&nbsp;P</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Paid Leave:</strong><span style={{ color: "#bf27fe", fontWeight: 'bold' }}>&nbsp;&nbsp;PL</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>ABSENT:</strong><span style={{ color: "red", fontWeight: 'bold' }}>&nbsp;&nbsp;A</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>Weekend/Holiday:</strong><span style={{ color: "blue", fontWeight: 'bold' }}>&nbsp;&nbsp;W</span></p>



      </div>

      {/* absrent modal */}
      <Modal
        show={showAbsentModal}
        onHide={() => setAbsentModal(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton className="custom-modal-header">
          <Modal.Title style={{ color: "#FFFFFF" }}> Attendance Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row ">
            <div className="col-md-6">
              <Form>
                <Modal.Title style={{ fontSize: "26px" }}>
                  {"  TimeSheet "}
                  <span
                    style={{
                      fontSize: "16px",
                      color: "gray",
                    }}
                  >
                    {/* {localDate} */}
                  </span>
                </Modal.Title>

                <Form.Group
                  style={{
                    margin: "10px",
                  }}
                  controlId="attendanceDate"
                >
                  <Form.Label
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    Selected Date
                  </Form.Label>

                  <Form.Control
                    style={{
                      padding: "20px",
                    }}
                    type="text" // Use type="time" for time input
                    name="attendance_date"
                    value={employeeTimeSchedule.newDateFormate}
                    onChange={OnChangeAbsentAttendanceModal}
                    placeholder="Attendace Date"
                  />
                </Form.Group>

                {/* <Form.Group
                  style={{
                    margin: "10px",
                  }}
                  controlId="formBasicStatus"
                >
                  <Form.Label
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    Status
                  </Form.Label>

                  <Form.Control
                    style={{
                      padding: "20px",
                    }}
                    as="select" // Use as="select" for selection field
                    name="status"
                    value={absentDate.status == null ? '1' : absentDate.status}
                    onChange={OnChangeAbsentAttendanceModal}
                    placeholder="Select status"
                  >
                    <option value="0">ABSENT</option>
                    <option value="1">FULL DAY</option>
                    <option value="2">HALF DAY</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group
                  style={{
                    margin: "10px",
                  }}
                  controlId="formBasicStatus"
                >
                  <Form.Label
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    Status
                  </Form.Label>

                  <Form.Control
                    style={{
                      padding: "20px",
                    }}
                    as="select" // Use as="select" for selection field
                    name="status"
                    value={absentDate.status == null ? '1' : absentDate.status}
                    onChange={OnChangeAbsentAttendanceModal}
                    placeholder="Select status"
                  >
                    <option value="0">ABSENT</option>
                    <option value="1">FULL DAY</option>
                    <option value="2">HALF DAY</option>
                  </Form.Control>
                </Form.Group> */}

                <Form.Group
                  style={{
                    margin: "10px",
                  }}
                  controlId="formBasicStatus"
                >
                  <Form.Label
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    Attendance Status
                  </Form.Label>

                  <Form.Control
                    style={{
                      padding: "20px",
                    }}
                    readOnly
                    type="text"
                    name="status"
                    value={
                      absentDate.status == 1
                        ? "FULL DAY"
                        : absentDate.status == 2
                          ? "HALF DAY"
                          : absentDate.status == 3
                            ? "WEEKEND"
                            : absentDate.status == 4
                              ? "PAID LEAVE"
                              : "ABSENT"
                    }
                  />
                </Form.Group>

                <Form.Group
                  style={{
                    margin: "10px",
                  }}
                  controlId="formBasicStatus"
                >
                  <Form.Label
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    Status
                  </Form.Label>

                  <Form.Control
                    style={{
                      padding: "20px",
                    }}
                    as="select"
                    name="status"
                    value={getStatusValue(absentDate.status)}
                    onChange={OnChangeAbsentAttendanceModal}
                  >
                    <option value="">--Select Status--</option>
                    <option value="0">ABSENT</option>
                    <option value="1">FULL DAY</option>
                    <option value="2">HALF DAY</option>
                    <option value="3">WEEKEND</option>
                    <option value="4">PAID LEAVE</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group
                  style={{
                    margin: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  controlId="formBasicSelect"
                >
                  <Button
                    className=" border-0 ms-2 mt-3 btn btn-primary"
                    style={{
                      // backgroundColor: "#506fd9",
                      color: "white",
                      fontSize: "22px",
                      margin: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "10px",
                      padding: "5px 50px",
                    }}
                    variant="primary"
                    onClick={() => {
                      handleAbsentAtandance();
                      setAbsentModal(false);
                    }}
                  >
                    Change
                  </Button>
                </Form.Group>
              </Form>
            </div>
            <div className="col-md-6">
              <Modal.Title style={{ fontSize: "26px", color: "#FFFFFF" }} >Activity</Modal.Title>
              <div
                className="row"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  className="col-md-6"
                  style={{
                    width: "10%",
                    height: "100px",
                    borderLeft: "2px solid black",
                  }}
                ></div>
                <div
                  className="col-md-6"
                  style={{
                    width: "90%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >

                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* absent modal end */}



      {/* showAttendanceModal start here */}
      <Modal
        show={showAttendanceModal}
        onHide={() => setAttendanceModal(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton className="custom-modal-header">
          <Modal.Title style={{ color: "#FFFFFF" }}> Attendance Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row ">
            <div className="col-md-6">
              <div className="card p-3 mb-5 bg-white rounded" style={{ boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)" }}>
                <Form>
                  <Modal.Title style={{ fontSize: "26px" }}>
                    {"  TimeSheet "}
                    <span
                      style={{
                        fontSize: "16px",
                        color: "gray",
                      }}
                    >

                      {employeeTimeSchedule.newDateFormate}
                    </span>
                  </Modal.Title>
                  <Form.Group
                    style={{
                      margin: "10px",
                    }}
                    controlId="formBasicStatus"
                  >
                    <Form.Label
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    >
                      Name
                    </Form.Label>

                    <Form.Control
                      style={{
                        padding: "20px",
                      }}
                      readOnly
                      type="text"
                      name="name"
                      value={employeeTimeSchedule.name}
                    >

                    </Form.Control>
                  </Form.Group>
                  <Form.Group
                    style={{
                      margin: "10px",
                    }}
                    controlId="formBasicPunchTime"
                  >
                    <Form.Label
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    >
                      Punch In At
                    </Form.Label>

                    <Form.Control
                      style={{
                        padding: "20px",
                      }}
                      type="time" // Use type="time" for time input
                      name="punchTime"
                      value={employeeTimeSchedule.punchTime || ""}
                      onChange={(e) => {
                        OnChangeAttendanceModal(e, employeeTimeSchedule.name);
                      }}
                      placeholder="Punch time"
                    />
                  </Form.Group>
                  <Form.Group
                    style={{
                      margin: "10px",
                    }}
                    controlId="formBasicPunchTime"
                  >
                    <Form.Label
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    >
                      Attendance Status
                    </Form.Label>

                    <Form.Control
                      style={{
                        padding: "20px",
                      }}
                      readOnly
                      type="text"
                      name="status"

                      value={
                        employeeTimeSchedule.status == 1
                          ? "FULL DAY"
                          : employeeTimeSchedule.status == 2
                            ? "HALF DAY"
                            : employeeTimeSchedule.status == 3
                              ? "WEEKEND"
                              : employeeTimeSchedule.status == 4
                                ? "PAID LEAVE"
                                : "ABSENT"
                      }
                      onChange={OnChangeAttendanceModal}

                    />
                  </Form.Group>
                  <Form.Group
                    style={{
                      margin: "10px",
                    }}
                    controlId="formBasicStatus"
                  >
                    <Form.Label
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    >
                      Status
                    </Form.Label>

                    <Form.Control
                      style={{
                        padding: "20px",
                      }}
                      as="select" // Use as="select" for selection field
                      name="status"
                      value={employeeTimeSchedule?.status || ""}
                      onChange={OnChangeAttendanceModal}
                    // placeholder="Select status"
                    >
                      <option value="">--Select Status--</option>
                      <option value="0">ABSENT</option>
                      <option value="1">FULL DAY</option>
                      <option value="2">HALF DAY</option>
                      <option value="3">WEEKEND</option>
                      <option value="4">PAID LEAVE</option>

                    </Form.Control>
                  </Form.Group>




                  <Form.Group
                    style={{
                      margin: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    controlId="formBasicSelect"
                  >
                    <Button
                      className=" border-0 ms-2 mt-3 btn btn-primary"
                      style={{
                        // backgroundColor: "#506fd9",
                        color: "white",
                        fontSize: "22px",
                        margin: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "10px",
                        padding: "5px 50px",
                      }}
                      variant="secondary"
                      onClick={() => {
                        handleModalSubmit();
                        setAttendanceModal(false);
                      }}
                    >
                      Change
                    </Button>
                  </Form.Group>
                </Form>
              </div>
            </div>




            {/* <div className="col-md-6">
              <Modal.Title style={{ fontSize: "26px", color: "black" }}>Activity</Modal.Title>
              <ul className="activity-group mb-5">
                {breakDetails && breakDetails.length > 0 ? (
                  breakDetails.map((breakItem, index) => {
                    if (breakItem.type === "IN" && breakDetails[index + 1]?.type === "OUT") {
                      const outBreak = breakDetails[index + 1];

                      return (
                        <li className="activity-item" key={index}>
                          <p className="d-sm-flex align-items-center mb-2">
                            <span className="fs-sm" style={{color: "black" }}>
                              <strong>{breakItem.type}</strong> - {breakItem.time}
                            </span>
                            <span className="fs-sm ms-4" style={{color: "black" }}>
                              <strong>{outBreak.type}</strong> - {outBreak.time}
                            </span>
                            <span className="fs-xs ms-auto" style={{ color: "black" }}>
                              Duration: {outBreak.duration_minutes} minutes
                            </span>
                          </p>
                        </li>
                      );
                    }
                    return null;
                  })
                ) : (
                  <p>No break data available.</p>
                )}
              </ul>
            </div> */}
            <div className="col-md-6 ">
              <div className="card p-3 mb-5 bg-white rounded" style={{ boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)", overflow: 'auto', height: '65vh' }}>
                <Modal.Title style={{ fontSize: "26px", color: "black" }}>Activity</Modal.Title>

                {loading ? (
                  <div className="loader-containerResignation">
                    <div className="loader"></div>
                  </div>
                ) : (
                  <ul className="activity-group mb-5">
                    {breakDetails && breakDetails.length > 0 ? (
                      breakDetails.map((breakItem, index) => (
                        <li className="activity-item" key={index}>
                          <p className="d-sm-flex align-items-center mb-2">
                            <span className="fs-sm" style={{ color: "black" }}>
                              <strong style={{ fontSize: '15px', fontWeight: 'bold' }}>{breakItem.type === "IN" ? "Break In at" : "Break Out at"} -  {breakItem.time}</strong>
                            </span>
                          </p>
                        </li>
                      ))
                    ) : (
                      <p>No break data available.</p>
                    )}
                  </ul>
                )}
              </div>
            </div>




          </div>
        </Modal.Body>
      </Modal>
      {/* close showAttendanceModal End here */}

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton className="custom-modal-header">
          <Modal.Title style={{ color: "#FFFFFF" }}>Add Employee Attendance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2}>
                <p style={{ fontWeight: "bold" }}>Status</p>
              </Form.Label>
              <Col sm={10}>
                <Form.Select
                  value={attendanceStatus}
                  onChange={(e) => {
                    setAttendanceStatus(e.target.value)
                    console.log(e.target.value);
                  }
                  }
                >
                  <option value="--Select Employee--">--Select Status--</option>
                  <option value="0">ABSENT</option>
                  <option value="1">FULL DAY</option>
                  <option value="2">HALF DAY</option>
                  <option value="3">WEEKEND</option>
                  <option value="4">PAID LEAVE</option>

                </Form.Select>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={2} >
                <p style={{ fontWeight: "bold" }}>Time</p>
              </Form.Label>
              <Col sm={10}>
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => {
                    setSelectedDate(date)

                  }}
                  showTimeSelect
                  timeIntervals={1}  // Set time intervals to 1 minute for finer control
                  timeCaption="Time"
                  dateFormat="MMMM d, yyyy h:mm aa"  // Customize date and time format
                  placeholderText="Select Date and Time"
                  className="form-control"
                />
                <Button style={{ 'margin-left': '1rem', 'padding': '6px 23px 6px 23px', 'background-color': '#25255A' }} onClick={(e) => {
                  fetchEmployeeDateWiseAttendance(selectedDate)
                }}>
                  Show Employees
                </Button>
              </Col>

            </Form.Group>
          </Form>
          {loading ? (
            <div className="loader-container">
              <div className="loader"></div>
            </div>

          ) : (
            <div style={{ height: 400, width: "100%", backgroundColor: 'white' }}>
              <DataGrid
                rows={dateWiseAllEmployees}
                columns={columnsModal}
                pageSize={5}
                components={{
                  NoRowsOverlay: () => (
                    <div style={{ textAlign: "center", padding: "40px" }}>
                      No data available
                    </div>
                  ),
                }}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddAttendance}>
            Add Attendance
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <Modal show={showModalpresentlist} onHide={() => setShowModalpresentlist(false)}>
        <Modal.Header closeButton className='modalHeader'>
          <Modal.Title>Employee Attendance List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='newbtn11'>
            <button className="btn btnnew-light" onClick={handlePresentButtonClick}>Present List</button>
            <button className="btn btnnew-light" onClick={handleAbsentButtonClick}>Absent List</button>
          </div>

          {loading ? (
            <p>Loading...</p>
          ) : (
            <div>
              {showEmployeeList && (
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                  <thead>
                    <tr>
                      <th style={{ padding: '10px', textAlign: 'center', backgroundColor: '#f2f2f2', border: '1px solid #ddd' }}>Name</th>
                      <th style={{ padding: '10px', textAlign: 'center', backgroundColor: '#f2f2f2', border: '1px solid #ddd' }}>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {attendanceDatapresentlist.map((employee) => (
                      <tr key={employee.id}>
                        <td style={{ padding: '10px', textAlign: 'center', border: '1px solid #ddd' }}>{employee.name}</td>
                        <td style={{ padding: '10px', textAlign: 'center', border: '1px solid #ddd' }}>
                          <span style={{ backgroundColor: 'green', color: 'white', borderRadius: '5px', padding: '3px 8px' }}>Present</span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

              )}

              {showAbsentList && (
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                  <thead>
                    <tr>
                      <th style={{ padding: '10px', textAlign: 'center', backgroundColor: '#f2f2f2', border: '1px solid #ddd' }}>Name</th>
                      <th style={{ padding: '10px', textAlign: 'center', backgroundColor: '#f2f2f2', border: '1px solid #ddd' }}>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {attendanceDataAbsentlist.map((employee) => (
                      <tr key={employee.id}>
                        <td style={{ padding: '10px', textAlign: 'center', border: '1px solid #ddd' }}>{employee.name}</td>
                        <td style={{ padding: '10px', textAlign: 'center', border: '1px solid #ddd' }}>
                          <span style={{ backgroundColor: 'red', color: 'white', borderRadius: '5px', padding: '3px 8px' }}>Absent</span>
                        </td>

                      </tr>
                    ))}
                  </tbody>
                </table>
              )}

            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModalpresentlist(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}
      <Modal
        show={showModalpresentlist}
        onHide={() => setShowModalpresentlist(false)}
      >
        <Modal.Header closeButton className="modalHeader">
          <Modal.Title style={{ color: "#FFFFFF" }}>Employee Attendance List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="newbtn11">
            <button
              className="btn btnnew-light"
              onClick={handlePresentButtonClick}
            >
              Present List
            </button>
            <button
              className="btn btnnew-light"
              onClick={handleAbsentButtonClick}
            >
              Absent List
            </button>
          </div>

          {loading ? (
            <p>Loading...</p>
          ) : (
            <div>
              {showEmployeeList && (
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <thead>
                    <tr>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          backgroundColor: "#f2f2f2",
                          border: "1px solid #ddd",
                        }}
                      >
                        Name
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          backgroundColor: "#f2f2f2",
                          border: "1px solid #ddd",
                        }}
                      >
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {attendanceDatapresentlist.map((employee) => (
                      <tr key={employee.id}>
                        <td style={{ padding: '10px', textAlign: 'center', border: '1px solid #ddd' }}>{employee.name}</td>
                        <td style={{ padding: '10px', textAlign: 'center', border: '1px solid #ddd' }}>
                          <span style={{ backgroundColor: 'green', color: 'white', borderRadius: '5px', padding: '3px 8px' }}>Present</span>
                        </td>
                      </tr>
                    ))} */}
                    {attendanceDatapresentlist.map((employee) => (
                      <tr key={employee.id}>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          {employee.name}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          <span
                            style={{
                              backgroundColor: "green",
                              color: "white",
                              borderRadius: "5px",
                              padding: "3px 8px",
                            }}
                          >
                            Present
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}

              {showAbsentList && (
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <thead>
                    <tr>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          backgroundColor: "#f2f2f2",
                          border: "1px solid #ddd",
                        }}
                      >
                        Name
                      </th>
                      <th
                        style={{
                          padding: "10px",
                          textAlign: "center",
                          backgroundColor: "#f2f2f2",
                          border: "1px solid #ddd",
                        }}
                      >
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {attendanceDataAbsentlist.map((employee) => (
                      <tr key={employee.id}>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          {employee.firstName} {employee.lastName}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            textAlign: "center",
                            border: "1px solid #ddd",
                          }}
                        >
                          <span
                            style={{
                              backgroundColor: "red",
                              color: "white",
                              borderRadius: "5px",
                              padding: "3px 8px",
                            }}
                          >
                            Absent
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowModalpresentlist(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment >
  );
};

export default EmployeeAttendance;